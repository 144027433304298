<template>
    <div>
        <div class="row row-spacing">
            <div :class="isStaff ? 'col-md-2' : 'col-md-3'" class="form-group">
                <label>Select Date from</label>
                <div class="input-group">
                    <input type="date" class="form-control" v-model="workOrdersState.date" @change="getAllClientWorkOrders()" :min="minDate">
                    <div class="input-group-append">
                        <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
            <div :class="isStaff ? 'col-md-2' : 'col-md-3'" class="form-group" v-if="isStaff">
                <label>Filter by type</label>
                <select
                    class="form-control"
                    placeholder="Select Type"
                    v-model="workOrdersState.selectedType"
                >
                    <option value="">All</option>
                    <option v-for="type in workOrderTypes" :key="type" :value="type">{{type}}</option>
                </select>
            </div>
            <div class="form-group col-md-2" v-if="isStaff">
                <label>Filter by status</label>
                <select
                    class="form-control"
                    placeholder="Select Status"
                    v-model="workOrdersState.status"
                >
                    <option value="">All</option>
                    <option v-for="status in statuses" :key="status" :value="status">{{status.charAt(0) + status.slice(1).toLowerCase()}}</option>
                </select>
            </div>
            <div class="form-group col-md-3">
                <label>Search {{ isStaff ? 'Quotes/' : '' }}Workorders</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search" v-model="workOrdersState.searchTerm">
                    <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                    </div>
                </div>
            </div>
            <div class="form-group col-md mt-4" v-if="isStaff">
                <button
                    class="btn btn-primary dropdown-toggle pull-right"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                New Document
                </button>
                <div class="dropdown-menu">
                    <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="createWorkOrder('quote')"
                    >
                        New Quote
                    </a>
                    <a
                        class="dropdown-item"
                        href="javascript:void(0)"
                        @click="createWorkOrder('workorder')"
                    >
                        New Work Order
                    </a>
                </div>
            </div>
        </div>
        <hr>
        <div class="card table-responsive">
            <table class="table table-hover table-bordered" v-if="isLoaded === true">
                <thead>
                    <tr>
                        <th @click="sortBy('workOrderType')" v-if="isStaff">Type
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'workOrderType' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'workOrderType' && !reverse"></i>
                        </th>
                        <th @click="sortBy('created')" style="width: 10%">Created On
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'created' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'created' && !reverse"></i>
                        </th>
                        <th @click="sortBy('description')">Work Order Description
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                        </th>
                        <th @click="sortBy('workOrderNumber')">Work Order Number
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'workOrderNumber' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'workOrderNumber' && !reverse"></i>
                        </th>
                        <th>Ticket Ref</th>
                        <th @click="sortBy('username')" v-if="isStaff">Created By
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'username' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'username' && !reverse"></i>
                        </th>
                        <th @click="sortBy('status')">Status
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'status' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'status' && !reverse"></i>
                        </th>
                        <th @click="sortBy('nextAction')">Next Action
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'nextAction' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'nextAction' && !reverse"></i>
                        </th>
                        <th @click="sortBy('nextActionDate')">Next Action Date
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'nextActionDate' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'nextActionDate' && !reverse"></i>
                        </th>
                        <th @click="sortBy('totalValue')">Total
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'totalValue' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'totalValue' && !reverse"></i>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="filteredWorkOrders.length > 0">
                    <tr v-for="workOrder in filteredWorkOrders" :key="workOrder.workOrderNumber" @click="goToWorkOrderDetail(workOrder.workOrderNumber)">
                        <td v-if="isStaff">{{ workOrder.workOrderType }}</td>
                        <td>{{ dateFormat(workOrder.created) }}</td>
                        <td>{{ workOrder.description }}</td>
                        <td>{{ workOrder.workOrderNumber }}</td>
                        <td>
                            <input
                                v-if="workOrder.ticketReference.length > 20"
                                type="text"
                                style="cursor: pointer"
                                v-tooltip="workOrder.ticketReference"
                                class="form-control-plaintext btn-link"
                                readonly
                                :value="'Multiple tickets'"
                            />
                            <input
                                v-else
                                type="text"
                                class="form-control-plaintext"
                                style="cursor: pointer"
                                readonly
                                :value="workOrder.ticketReference"
                            />
                        </td>
                        <td v-if="isStaff">{{ workOrder.username }}</td>
                        <td><span :class="getStatusBadge(workOrder.status)">{{ workOrder.status }}</span></td>
                        <td>{{ workOrder.nextAction }}</td>
                        <td>{{ dateFormat(workOrder.nextActionDate)  }}</td>
                        <td>{{ currencyFormat(workOrder.totalValue.total) }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8">No Work Orders/Quotes available</td>
                    </tr>
                </tbody>
            </table>
            <div v-else-if="isLoaded === false">
                    <DataLoader 
                        :loadingText="'Loading Work Orders...'"
                        style="min-height: 300px"
                    />
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from "@/components/GeneralComponents/DataLoader";
import { WorkOrdersState } from '@/stores/pages/Client/WorkOrders.ts';

export default {
    components: {
        DataLoader
    },
    data() {
        const workOrdersState = WorkOrdersState();
        return {
            workOrdersState,
            isLoaded: false,
            clientNumber: null,
            workOrderInfo: {},
            workOrderList: [],
            workOrderRefs: [],
            workOrdersFiltered: [],
            newWorkOrder: {},
            totalAmounts: [],
            workOrderTypes: ["Quote", "Work Order"],
            statuses: [],
            minDate: new Date().toISOString().split('T')[0],

            sortKey: 'created',
            reverse: false,
        }
    },
    mounted() {
        if (this.userContainsPermission(["CLIENT"])) {
            this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Work Orders'); 
        };
        this.clientNumber = this.$route.params.clientNumber;
        this.minDate = (this.minDate.substring(0, 4) - 4) + "-01-01";
        this.getAllClientWorkOrders();
    },
    computed: {
        isStaff() {
            return this.userContainsPermission(["STAFF"]);
        },
        filteredWorkOrders() {
            this.workOrdersFiltered = this.workOrderList;

            if (this.workOrdersState.status) {
                this.workOrdersFiltered = this.workOrdersFiltered.filter((workOrder) => workOrder.status == this.workOrdersState.status);
            }
            if (this.workOrdersState.searchTerm) {
                this.workOrdersFiltered = this.workOrdersFiltered.filter((workOrder) => 
                    workOrder.description.toLowerCase().includes(this.workOrdersState.searchTerm.toLowerCase()) ||
                    workOrder.customerReference.toLowerCase().includes(this.workOrdersState.searchTerm.toLowerCase()) ||
                    workOrder.workOrderNumber.toString().includes(this.workOrdersState.searchTerm) ||
                    workOrder.ticketReference.toString().includes(this.workOrdersState.searchTerm)
                );
            }
            if (this.workOrdersState.selectedType) {
                this.workOrdersFiltered = this.workOrdersFiltered.filter((workOrder) => workOrder.workOrderType == this.workOrdersState.selectedType);
            }
            if (this.userContainsPermission(["CLIENT"])) {
                this.workOrdersFiltered = this.workOrdersFiltered.filter((workOrder) =>  workOrder.workOrderType != 'CANCELLED' && workOrder.workOrderType != 'OPEN' && workOrder.workOrderType != 'Quote'); 
            }

            if (this.reverse) {
                return _.orderBy(this.workOrdersFiltered, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.workOrdersFiltered, this.sortKey, 'desc');
            }
        },
    },
    methods: {
        getAllClientWorkOrders: function() {   
            this.isLoaded = false;        
            const hostUrl = this.$config.aimsAPIv2;
            const clientNumber = this.$route.params.clientNumber;
        
            if (this.workOrdersState && this.workOrdersState.date) {
                if (this.workOrdersState.date.substring(0, 4) < this.minDate.substring(0, 4)) {
                    this.workOrdersState.date = this.minDate;
                    this.showWarningAlert("Can't select a date before: " + this.minDate);
                }
            }

            this.$http.get(`${hostUrl}workorders/client/${clientNumber}?startDate=${this.workOrdersState.date}`).then(
                (response) => {
                    this.workOrderInfo = response.data;
                    this.workOrderList = response.data.workOrders;
                    this.workOrderRefs = response.data.parentTicketRefs;
                    this.totalAmounts = response.data.totalAmounts;

                    for (let i = 0; i < this.workOrderList.length; i++) {
                        const workOrder = this.workOrderList[i];
                        const totalAmount = this.totalAmounts[i];
                        workOrder.totalValue = totalAmount;
                        workOrder.ticketReference = this.workOrderRefs[i];
                    }

                    this.getWorkOrderStatuses();  
                    this.getWorkOrderTypes();

                    this.isLoaded = true;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Fetching Client Work Orders", error);
                }
            )
        },
        getWorkOrderStatuses: function() {
            this.statuses = [];
            this.workOrderList.forEach((workOrder) => {
                if (!this.statuses.includes(workOrder.status) && this.userContainsPermission(["STAFF"])) {
                    this.statuses.push(workOrder.status);
                } else if (!this.statuses.includes(workOrder.status) && this.userContainsPermission(["CLIENT"])) {
                    this.statuses.push(workOrder.status);
                    this.statuses = this.statuses.filter((status) => status != 'CANCELLED' && status != 'OPEN')
                }        
            });
        },
        getWorkOrderTypes: function() {
            this.workOrderTypes = [];
            this.workOrderList.forEach((workOrder) => {
                if (!this.workOrderTypes.includes(workOrder.workOrderType)) {
                this.workOrderTypes.push(workOrder.workOrderType);
                }
            });
        },
        createWorkOrder: function(workordertype) {
            const hostUrl = this.$config.aimsAPI;
            const clientNumber = this.$route.params.clientNumber;

            this.$http.get(`${hostUrl}workorders/client/${clientNumber}/create/${workordertype}`).then(
                (response) => {
                    this.newWorkOrder = response.data;
                    if (this.newWorkOrder.workOrderNumber != 0) {
                        this.$router.push({
                        name: "workorderdetails",
                            params: {
                                workOrderNumber: this.newWorkOrder.workOrderNumber,
                                startInEditMode: true,
                            },
                        });
                    } else {
                        this.showWarningAlert("Could not load New Quote / Work Order");  
                        this.getAllClientWorkOrders();
                    }    
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Creating New Quote/Work Order", error);
                }
                );
        },
        goToWorkOrderDetail: function(data) {
            this.$router.push({
                  name: 'workorderdetails',
                  params: { workOrderNumber: data },
            })
        },
        getStatusBadge: function(status) {
            let badge = "badge badge-success";

            if (status == "OPEN") {
                badge = "badge bg-green";
            }
            if (status == "CANCELLED") {
                badge = "badge bg-red text-white";
            }

            return badge;
        },
        sortBy(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
    },
}
</script>

<style scoped>
th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.pull-right {
  float: right;
}
</style>