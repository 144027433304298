import { defineStore } from "pinia"

export const ProductListState = defineStore("ProductListState", {
    state: () => ({
        showLegacy: false,
        searchInput: "",
        filters: {
            classificationType: "",
            supplier: { name: "" },
            vendor: { name: "" },
            segment: { name: "" },
            subsegment: { name: "" },
            forSale: { name: "" },
        },
    }),
    actions: {
        setShowLegacy(data) {
            this.showLegacy = data;
        },
    }
});