<template>
  <div>
    <div class="mt-2">
      <div
        class="card border-primary row-spacing"
        v-show="archivedGroup.filteredSaleCount > 0"
        v-for="(archivedGroup, index) in groupedArchives"
        :key="index"
      >
        <div
          class="card-header bg-primary"
          style="cursor: pointer"
          data-tool="card-collapse"
          @click="expandGroup(archivedGroup)"
        >
          <a
            class="float-right item-icon"
            :class="{ rotate: isOpen ? selectedGroup.groupName === archivedGroup.groupName : '', default: !isOpen }"
            data-tool="card-collapse"
          >
          <i class="fa fa-chevron-down"></i>
          </a>
          <span class="badge badge-success float-right">{{
            archivedGroup.filteredSaleCount
          }}</span>
          <div class="card-title">Archived Services</div>
        </div>
        <div
          class="card-wrapper collapse"
          :class="{ show: saleSearchInput.length > 0 }"
        >
          <div class="card-body">
            <div class="table-responsive table-bordered">
              <table
                class="table table-bordered table-hover"
                v-if="archivedSales.length"
              >
                <thead>
                  <tr class="text-nowrap">
                    <th>Service Name</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="sale in filteredSales(archivedGroup)"
                    @click="viewSale(sale)"
                    :key="sale.saleNumber"
                    :class="{ 'new-table-row': sale.isNew }"
                  >
                    <td>
                      <div class="d-flex flex-column">
                        <p>{{ sale.description }}</p>
                      </div>
                    </td>
                    <td class="text-nowrap">
                      {{ dateFormat(sale.endDate) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="alert alert-info" v-if="archivedSales.length == 0">
          {{ noSalesMessage ? noSalesMessage : "No Services" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    archivedSales: {
      type: Array,
      required: true,
    },
    groupedArchives: {
      type: Array,
      required: true,
    },
    prices: {
      type: Array,
      required: true,
    },
    saleSearchInput: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showPrices: true,
      isOpen: false,
      selectedGroup: {},
    };
  },
  methods: {
    expandGroup: function(data) {
      this.selectedGroup = data;
      if (data.groupName) {
        return (this.isOpen = !this.isOpen);
      }
    },
    filteredSales: function (archivedGroup) {
      let filteredSales = archivedGroup.archivedSales.filter((sale) => {
        return (
          sale.description.toLowerCase().includes(this.saleSearchInput.toLowerCase()) ||
          sale.product.description.toLowerCase().includes(this.saleSearchInput.toLowerCase())
        );
      });
      if (this.showUnauditedSales) {
        filteredSales = filteredSales.filter((sale) => sale.audited != "Audited");
      }
      archivedGroup.filteredSaleCount = filteredSales.length;
      return filteredSales;
    },

    viewSale: function(sale) {
      this.$emit("viewSale", sale);
    },
    getPrice: function ({ saleNumber }, inclVat) {
      if (inclVat) {
        const price = this.prices.find((p) => p.saleNumber == saleNumber);
        return price ? price.amountInclVat : 0;
      }
      const price = this.prices.find((p) => p.saleNumber == saleNumber);
      return price ? price.amountExclVat : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-dark-grey {
    background-color: #717171;

    .card-title {
        color: #ffffff;
    }

    .sale-group-counter {
        background-color: #ffffff;
        color: #2D2D2D;
        border-radius: 5%;
        padding: 4px;
        font-size: 14px;
        font-weight: 700;
        width: 30px;
        text-align: center;
    }
}

.default {
  transform: rotate(0deg);
  transition: all 0.5s;
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.5s;
}

.badge.badge-active {
  width: 100px;
  color: #ffffff;
}
</style>