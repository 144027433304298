<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-default">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col-md-4">
                  <label>Select Orders to view</label>
                  <select
                      v-model="orderTypeSelected"
                      class="form-control"
                      @change="getLineOrders"
                    >
                      <option
                        :value="orderType.value"
                        v-for="orderType in orderTypes"
                        :key="orderType.value"
                      >
                        {{ orderType.name }}
                      </option>
                    </select>
                </div>
              </div>
              <hr />
              <div class="row"></div>
            </div>
            <div class="card-body pt-0" v-if="dataLoaded">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex align-items-center justify-content-between">
                      <h3 class="mr-2">
                        {{ filteredOrders[0].name }} Line Orders <span v-if="orderTypeSelected !== 'lte'">({{ connectivityOrderAmountText }})</span> <span v-else>({{ lteOrderAmountText }})</span></h3>
                      <div class="form-group col-md-2 pr-0">
                        <label>Filter by status</label>
                        <v-select
                            :options="lineOrderStatus"
                            v-model="status"
                            :clearable="true"
                            placeholder="Select Status"
                        >
                        </v-select>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-bordered table-responsive" v-if="filteredLineOrders && orderTypeSelected !== 'lte'">
                      <thead>
                        <tr class="dark-row">
                          <th @click="sortBy('client')" class="data-col">
                            Client
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'client' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'client' && !reverse"></i>
                          </th>
                          <th @click="sortBy('description')">
                            Sale Description
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                          </th>
                          <th @click="sortBy('ticket')">
                            Ticket Number
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ticket' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ticket' && !reverse"></i>
                          </th>
                          <th @click="sortBy('provider')">
                            Provider
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'provider' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'provider' && !reverse"></i>
                          </th>
                          <th @click="sortBy('comment')">
                            Latest Comment
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'comment' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'comment' && !reverse"></i>
                          </th>
                          <th @click="sortBy('update')">
                            Last Update
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'update' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'update' && !reverse"></i>
                          </th>
                          <th @click="sortBy('circuit')" v-if="showCircuitNumber">
                            Circuit Number
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'circuit' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'circuit' && !reverse"></i>
                          </th>
                          <th @click="sortBy('status')" class="data-col">
                            Status
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'status' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'status' && !reverse"></i>
                          </th>
                          <th class="btn-col"></th>
                        </tr>
                      </thead>
                      <tbody v-if="filteredLineOrders.length > 0">
                        <tr v-for="lineOrder in filteredLineOrders" :key="lineOrder.objKey">
                          <td v-if="lineOrder.connectivityline">{{ getClientName(lineOrder.connectivityline.sale.client) }}</td>
                          <td v-if="lineOrder.axxessservice">{{ getClientName(lineOrder.axxessservice.sale.client) }}</td>
                          <td v-if="lineOrder.connectivityline" @click="goToOrderSale(lineOrder.connectivityline)" v-tooltip="'Go to sale'" class="sale-link">{{ lineOrder.connectivityline.sale.description }}</td>
                          <td v-if="lineOrder.axxessservice" @click="goToOrderSale(lineOrder.axxessservice)" v-tooltip="'Go to sale'" class="sale-link">{{ lineOrder.axxessservice.sale.description }}</td>
                          <td>{{ lineOrder.orderReference }}</td>
                          <td v-if="lineOrder.connectivityline">
                              {{ lineOrder.connectivityline.sale.product.classificationProvider }}
                          </td>
                          <td v-if="lineOrder.axxessservice">
                              {{ lineOrder.axxessservice.sale.product.classificationProvider  }}
                          </td>
                          <td>{{ lineOrder.comment }}</td>
                          <td>{{ lineOrder.modified }}</td>
                          <td v-if="lineOrder.connectivityline">
                            {{ lineOrder.connectivityline.circuitNumber }}
                          </td>
                          <td>
                            <span :class="getStatusBadge(lineOrder.status)">
                              {{ lineOrder.status }}
                            </span>  
                          </td>
                          <td class="text-center">
                            <button class="btn btn-info" v-tooltip="'Click to edit'"  @click="editConnectivityLineOrder(lineOrder)"><i class="fa fa-edit"></i></button>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="8">No line orders available</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-hover table-bordered table-responsive" v-if="filteredLteLineOrders && orderTypeSelected === 'lte'">
                      <thead>
                        <tr class="dark-row">
                          <th  @click="sortBy('lte-client')">
                            Client
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-client' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-client' && !reverse"></i>
                          </th>
                          <th @click="sortBy('lte-description')">
                            Sale Description
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-description' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-description' && !reverse"></i>
                          </th>
                          <th @click="sortBy('lte-courier')">
                            Courier Ref
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-courier' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-courier' && !reverse"></i>
                          </th>
                          <th @click="sortBy('lte-provider')">
                            Provider
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-provider' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-provider' && !reverse"></i>
                          </th>
                          <th @click="sortBy('lte-comment')">
                            Courier Comment
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-comment' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-comment' && !reverse"></i>
                          </th>
                          <th @click="sortBy('lte-update')">
                            Last Update
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-update' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-update' && !reverse"></i>
                          </th>
                          <th @click="sortBy('lte-status')">
                            Status
                            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'lte-status' && reverse"></i>
                            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'lte-status' && !reverse"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="filteredLteLineOrders.length > 0">
                        <tr v-for="lineOrder in filteredLteLineOrders" :key="lineOrder.objKey" v-tooltip="'Click to go to line order sale'"  @click="goToLteLineOrder(lineOrder)">
                          <td>{{ getClientName(lineOrder.lteaccount.sale.client) }}</td>
                          <td>{{ lineOrder.lteaccount.sale.description }}</td>
                          <td>{{ lineOrder.courierReference }}</td>
                          <td>{{ lineOrder.lteaccount.sale.product.classificationProvider }}</td>
                          <td>
                            <p
                            v-for="lteaccountorderhistory in lineOrder.lteaccountorderhistorys"
                            :key="lteaccountorderhistory.objKey"
                            >
                            <small v-if="lteaccountorderhistory.traceData_CourierComment.length">
                              {{ lteaccountorderhistory.traceData_CourierComment }}
                            </small>
                          </p>
                        </td>
                        <td>{{ lineOrder.modified }}</td>
                        <td>
                          <span :class="getStatusBadge(lineOrder.statusRain)">
                            {{ lineOrder.statusRain }}
                          </span>
                        </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="8">No line orders available</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
            <div class="card-body" v-if="fetchingData">
              <DataLoader 
                :loadingText="'Loading Orders...'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConnectivityLineOrderModal 
      ref="connectivityLineOrderModal"
      :modalTitle="modalTitle"
      :selectedLineOrder="selectedLineOrder"
      @updateLineOrder="updateLineOrder"
    />
  </div>
</template>

<script>
import DataLoader from "@/components/GeneralComponents/DataLoader";
import ConnectivityLineOrderModal from "@/components/Admin/Modals/ConnectivityLineOrderModal";

  export default {
    components: {
      DataLoader,
      ConnectivityLineOrderModal,
    },
    data() {
      return {
        orderTypeSelected: "",
        orderTypes: [
          {
            name: "FTTH",
            value: "za.co.adept.aims.fibre.home.FtthLineOrder"
          },
          {
            name: "FTTB & Broadband",
            value: "za.co.adept.aims.fibre.business.FttbLineOrder"
          },
          {
            name: "LTE",
            value: "lte"
          },
          {
            name: "Axxess FTTH",
            value: "za.co.adept.aims.axxess.fttx.AxxessFtthServiceOrder"
          },
          {
            name: "Axxess LTE",
            value: "za.co.adept.aims.axxess.lte.AxxessLteServiceOrder"
          },
          {
            name: "Wireless",
            value: "za.co.adept.aims.wireless.voconnect.WirelessLineOrder"
          },
          {
            name: "ADSL",
            value: "za.co.adept.aims.openserve.adsl.AdslLineOrder"
          },
          {
            name: "FTTO",
            value: "za.co.adept.aims.fibre.office.FttoLineOrder"
          },
        ],
        ordersLoaded: false,
        lineOrders: [],
        lineOrdersFiltered: [],
        filteredOrders: [],
        provider: "Frogfoot",
        providerArray: [],
        lineOrderStatus: ["New", "Ordered", "Cancelled", "Delivered"],
        status: "",
        showCircuitNumber: false,
        lteLineOrders: [],
        ltelineOrdersFiltered: [],
        daysAgo: 30,
        editLineOrder: null,
        modalTitle: "",
        selectedLineOrder: {},
        sortKey: "",
        reverse: false
      }
    },
    created() {
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
      this.$root.$emit("SET_PAGE_TITLE_BADGES", []);
    },
    computed: {
      connectivityOrderAmountText() {
        return this.lineOrders.length > 1 ? `${this.lineOrders.length} orders` : `${this.lineOrders.length} order`;
      },
      lteOrderAmountText() {
        return this.lteLineOrders.length > 1 ? `${this.lteLineOrders.length} orders` : `${this.lteLineOrders.length} order`;
      },
      fetchingData() {
        return !this.ordersLoaded && this.orderTypeSelected !== '';
      },
      dataLoaded() {
        return this.ordersLoaded && this.orderTypeSelected !== '';
      },
      filteredLineOrders() {
        this.lineOrdersFiltered = this.lineOrders;

        if (this.status) {
            this.lineOrdersFiltered = this.lineOrdersFiltered.filter((lineOrder) => lineOrder.status === this.status);    
        }

        return this.lineOrdersFiltered;
      },
      filteredLteLineOrders() {
        this.lteLineOrdersFiltered = this.lteLineOrders;

        if (this.status) {
            this.lteLineOrdersFiltered = this.lteLineOrdersFiltered.filter((lteLineOrder) => lteLineOrder.statusRain === this.status);   
        }

        return this.lteLineOrdersFiltered;
      },
    },
    methods: {
      getLineOrders: function() {
        if (this.orderTypeSelected === 'lte') {
          this.getLteLineOrder();
        } else if (this.orderTypeSelected === 'za.co.adept.aims.axxess.fttx.AxxessFtthServiceOrder') {
          this.getAxxessConnectivityLineOrder();
        } else if (this.orderTypeSelected === 'za.co.adept.aims.axxess.lte.AxxessLteServiceOrder') {
          this.getAxxessLteLineOrder();
        } else {
          this.getConnectivityLineOrders();
        }
      },
      getConnectivityLineOrders: function() {
        const hostUrl = this.$config.aimsAPIv2;
        const orderType = this.orderTypeSelected;

        this.ordersLoaded = false;

        this.$http.get(`${hostUrl}orders/connectivitylines/pending/${orderType}`).then(
          (response) => {
            this.lineOrders = response.data;            
            this.getOrderType(this.orderTypeSelected);

            this.filteredLineOrders.forEach(lo => {              
              return this.showCircuitNumber = typeof lo.connectivityline !== 'undefined' ? true : false;  
            }) 

            this.sortKey = 'client';
            this.reverse = false;
            this.ordersLoaded = true;
          },
          (error) => {
            console.error(error);
            this.showError('Error Fetching Connectivity Line Orders', error);
          });
      },
      getAxxessConnectivityLineOrder: function() {
        const hostUrl = this.$config.aimsAPIv2;
        const orderType = this.orderTypeSelected;

        this.ordersLoaded = false;

        this.$http.get(`${hostUrl}orders/axxess/pending/${orderType}`).then(
          (response) => {
            this.lineOrders = response.data;
            this.getOrderType(this.orderTypeSelected);

            this.filteredLineOrders.forEach(lo => {              
              return this.showCircuitNumber = typeof lo.connectivityline !== 'undefined' ? true : false;  
            }) 

            this.sortKey = 'client',
            this.reverse = false;
            this.ordersLoaded = true;
          },
          (error) => {
            console.error(error);
            this.showError('Error Fetching Axxess Connectivity Line Orders', error);
          });
      },
      getAxxessLteLineOrder: function() {
         const hostUrl = this.$config.aimsAPIv2;
         const orderType = this.orderTypeSelected;

         this.ordersLoaded = false;

          this.$http.get(`${hostUrl}orders/axxess/pending/${orderType}`).then(
              (response) => {                
                this.lineOrders = response.data;
                this.getOrderType(this.orderTypeSelected);

                this.filteredLineOrders.forEach(lo => {              
                  return this.showCircuitNumber = typeof lo.connectivityline !== 'undefined' ? true : false;  
                }) 

                this.sortKey = 'client',
                this.reverse = false;
                this.ordersLoaded = true;
              },
              (error) => {
                console.error(error);
                this.showError('Error Fetching Connectivity Line Orders', error);
              });           
      },
      getLteLineOrder: function() {
        const hostUrl = this.$config.aimsAPI;

        this.ordersLoaded = false;

        this.$http.get(`${hostUrl}orders/lte/pending?daysAgo=${this.daysAgo}`).then(
            (response) => {                
              this.lteLineOrders = response.data;
              
              this.getOrderType(this.orderTypeSelected);

              this.sortKey = 'lte-client',
              this.reverse = false;
              this.ordersLoaded = true;
            },
            (error) => {
              console.error(error);
              this.showError('Error Fetching Connectivity Line Orders', error);
            });
      },
      editConnectivityLineOrder: function(lineOrder) {
        this.$refs.connectivityLineOrderModal.open();

        if (lineOrder.connectivityline) {
          this.modalTitle = lineOrder.connectivityline.sale.description;
        } else {
          this.modalTitle = lineOrder.axxessservice.sale.description;
        }   
        
        this.selectedLineOrder = this.deepCopyObject(lineOrder);

        this.fetchCommentHistory(lineOrder);        
      },
      updateLineOrder: function() {
        const hostUrl = this.$config.aimsAPI;
        
        if (this.selectedLineOrder.newComment && this.selectedLineOrder.newComment != "") {
          this.selectedLineOrder.comment = this.selectedLineOrder.newComment;
        }  
        
        if (this.selectedLineOrder.connectivityline) {

          this.$http.post(`${hostUrl}orders/connectivitylines/save`, this.selectedLineOrder).then(
            (response) => {
              this.fetchCommentHistory(this.selectedLineOrder);
              this.$emit("lineOrderUpdated", response.data);
              this.showSuccess("Order Updated");
              this.getLineOrders();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Line Order", error);
            });
          
        } else {
          
          this.$http.post(`${hostUrl}axxess/serviceorder/save`, this.selectedLineOrder).then(
            (response) => {
              this.fetchCommentHistory(this.selectedLineOrder);
              this.$emit("lineOrderUpdated", response.data);
              this.showSuccess("Order Updated");
              this.getLineOrders();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Line Order", error);
            });
        }
      },
      fetchCommentHistory: function(lineOrder) {        
        const hostUrl = this.$config.aimsAPI;
        const lineOrderObjKey = lineOrder.objKey;
        const lineOrderClass = lineOrder.class;

        if (event) {
          event.stopPropagation();
        }
        this.$http.get(`${hostUrl}orders/connectivitylines/${lineOrderObjKey}/comments/?objClassName=${lineOrderClass}`).then(
          (response) => {
            this.$set(this.selectedLineOrder, "commentHistory", response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Line Order Comments", error);
          }
        )
      },
      goToLteLineOrder: function(lineOrder) {
        this.$router.push({
          name: "lteservice",
          params: {
            clientNumber: lineOrder.lteaccount.sale.clientNumber,
            saleNumber: lineOrder.lteaccount.saleNumber,
          },
        });
      },
      goToOrderSale: function(order) {
        const productRoute = this.getProductDefinition(order.sale.product.productDefinition);
        
        this.$router.push({
          name: productRoute.routeName,
          params: {
            clientNumber: order.sale.clientNumber,
            saleNumber: order.sale.saleNumber,
          },
        });
      },
      getOrderType: function(selectedType) {
        this.filteredOrders = this.orderTypes;
        this.filteredOrders = this.orderTypes.filter(type => type.value.includes(selectedType));
        return this.filteredOrders;
      },
      getStatusBadge: function(status) {          
            let badge = "badge badge-success";

            switch (status) {
              case "New":
                badge = "badge bg-green";
                break;
              case "Cancelled":
                badge = "badge bg-red text-white";
                break;
              case "Delivered":
                badge = "badge bg-warning text-white";
                break;
              case "Ordered":
                badge = "badge bg-info text-white";
                break;
              case "Consigned":
                badge = "badge bg-success text-white";
                break;
            
              default:
                badge = "badge";
                break;
            }

            return badge;
        },
        sortBy: function(sortKey) {
            this.sortKey = sortKey;            

            if (sortKey === this.sortKey) {
              this.reverse = !this.reverse;
              this.lineOrdersFiltered.reverse();
              this.lteLineOrders.reverse();
            } 
        }
    },
  }
</script>

<style lang="scss" scoped>
.dark-row {

  th {
    background-color: #3a3f51;
    color: #FFFFFF;
  
    position: sticky;
    top: 0;
    z-index: 2;

    padding: 1.2em .1em 1.2em .5em;
    width: 500px;
    font-size: .95em;

  }
  .btn-col, .data-col {
    width: 50px;
  }
}

.sale-link {
  &:hover {
    color: #3a3f51;
    font-weight: 600;
    cursor: pointer;
  }
}

</style>
