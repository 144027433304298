<template>
    <router-view></router-view>
</template>

<script>
import ClientFunctions from "../pages/ClientFunctions.vue";
const clientNavbarStructure = require("@/structures/clientNavbarStructure.json");

export default {
  extends: ClientFunctions,
  data() {
    return {
      clientNumber: null,
      navbarStructure: {},
      sidebarRemoved: false,
      clientMetaData: {},
    };
  },
  created() {
    this.clientNumber = parseInt(this.$route.params.clientNumber);
    this.navbarStructure = this.getRestrictedNavStructure(clientNavbarStructure);
    if (!this.userContainsPermission(["STAFF", "RESELLER", "CLIENT", "SYSTEM"])) {
      this.sidebarRemoved = true;
    }
  },
  mounted() {
    this.getClient(this.clientNumber).then(
      (success) => {
        this.clientDetail = success.data;
        if (this.clientDetail.type != "Reseller") {
          this.navbarStructure = this.removeResellerMenuItems(this.navbarStructure);
        }
        const clientName = this.getClientName(this.clientDetail);
        this.$root.$emit(
          "SET_PAGE_TITLE",
          clientName + " [" + this.clientDetail.type + "]"
        );
        this.$root.$emit("SET_CLIENT_NAVBAR", this.navbarStructure, this.clientNumber);
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Client", error);
      }
    );

    this.getClientMetaData(this.clientNumber).then(
      (success) => {
        this.clientMetaData = success.data;
        const titleBadges = this.clientMetaData.badges;

        if (this.clientMetaData.hasNotes) {
          const notesMenuItem = this.navbarStructure.menu.find(
            (n) => n.id === "clientnotesview"
          );

          if (notesMenuItem) {
            notesMenuItem.badge = {
              class: "badge badge-warning",
              text: "!",
            };
          }
        }

        this.$root.$emit("SET_PAGE_TITLE_BADGES", titleBadges);
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Client Meta Data", error);
      }
    );

    if (this.userContainsPermission(["STAFF", "RESELLER"])) {
      $("body").addClass("aside-collapsed");
    }
  },
  methods: {
    removeResellerMenuItems(navStructure) {
      var resultNavStructure = {
        heading: navStructure.heading,
        menu: [],
      };

      if (navStructure.backRoute) {
        resultNavStructure.backRoute = navStructure.backRoute;
      }

      for (var m = 0; m < navStructure.menu.length; m++) {
        var menuItem = navStructure.menu[m];

        var userMenuItem = JSON.parse(JSON.stringify(menuItem));

        if (menuItem.title != "Clients" && menuItem.title != "Commission Setup") {
          resultNavStructure.menu.push(userMenuItem);
        }
      }
      return resultNavStructure;
    },
    documentUploaded(type) {
      this.clientMetaData.outstandingDocuments.splice(
        this.clientMetaData.outstandingDocuments.findIndex((o) => o == type),
        1
      );
    },
  },
};
</script>
