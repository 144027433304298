<template>
    <modal 
        ref="addPackageModal"
        :title="'Add New Package'"
        @save="savePackage()"
    >
        <div class="row">
            <div class="form-group col-md-12">
                <label>Description</label>
                <input type="text" class="form-control" v-model="newWebhostingPackage.description" ref="description">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Enable PHP</label>
                <select class="form-control" v-model="newWebhostingPackage.enablePHP">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Enable ModSecurity</label>
                <select class="form-control" v-model="newWebhostingPackage.enableModSecurity">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Enable FTP</label>
                <select class="form-control" v-model="newWebhostingPackage.enableFtp">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Enable HTTPS</label>
                <select class="form-control" v-model="newWebhostingPackage.enableHttps">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Enable Sql</label>
                <select class="form-control" v-model="newWebhostingPackage.enableSql">
                      <option
                        :value="option.value"
                        v-for="option in options"
                        :key="option.description"
                      >
                        {{ option.description }}
                      </option>
                </select>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from '../../GeneralComponents/Modal.vue';
export default {
    components: {
        Modal
    },
    props: {
        newWebhostingPackage: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            options: [
                {
                    description: 'Yes',
                    value: true
                },
                {
                    description: 'No',
                    value: false
                },
            ],
        }
    },
    methods: {
        open: function() {
            this.$refs.addPackageModal.open();
        },
        savePackage: function() {
            this.$refs.addPackageModal.isLoading();
            this.$emit("savePackage", this.newWebhostingPackage);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>