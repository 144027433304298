<template>
  <div>
    <div class="row row-spacing">
      <div
        class="col-md-4"
        v-for="widget in dashboardLayout.widgets"
        :key="widget.title"
      >
        <div class="card border-0">
          <div class="row row-flush">
            <div
              :class="getWidgetColour(widget)"
              class="
                col-4
                text-center
                d-flex
                align-items-center
                justify-content-center
                rounded-left
              "
            >
              <em><i :class="getWidgetIcon(widget)"></i></em>
            </div>
            <div class="col-8">
              <div class="card-body text-center">
                <h4 class="mt-0">{{ widget.value }}</h4>
                <p class="mb-0 text-muted">{{ widget.title }}</p>
                <small class="">{{ widget.subtitle }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AgentClients
      v-if="clientsList.length > 0"
      :clientsList="clientsList"
      :loadingState="loadingState"
    />
  </div>
</template>

<script>
import AgentClients from "@/components/Dashboard/AgentClients";

export default {
  components: {
    AgentClients,
  },
  data() {
    return {
      dashboardLoading: true,
      loadingState: true,
      clientsList: [],
      dashboardLayout: {},
      notifications: [],
    };
  },
  mounted() {
    if (this.$route.path !== "/main/dashboard") {
      this.$router.push("/main/dashboard");
    }
    const usageMonthDate = new Date();
    usageMonthDate.setDate(1);
    usageMonthDate.setMonth(usageMonthDate.getMonth());

    this.usageYear = usageMonthDate.getFullYear();
    this.usageMonth = usageMonthDate.getMonth();

    this.$http.get(this.$config.aimsAPI + "dashboards/").then(
      (response) => {
        this.dashboardLayout = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Dashboard", error);
      }
    );
    this.$http.get(this.$config.aimsAPI + "alerts").then(
      (response) => {
        this.notifications = response.data;
      },
      (error) => {
        this.showError("Error fetching alerts", error);
        console.error(error);
      }
    );
    this.getclientList();
  },
  methods: {
    getclientList: function() {
      const hostUrl = this.$config.aimsAPI;

       this.$http.get(`${hostUrl}staff/clients/assigned`).then(
        (response) => {
          this.clientsList = response.data;
          this.loadingState = false;
        }, 
        (error) => {
          console.error(error);
          this.showError("Error Fetching Logged In Staff", error);
        }
     );
    },
    getWidgetIcon(widget) {
      if (widget.icon === "users") {
        return "fa fa-users fa-2x";
      }

      if (widget.icon === "user-plus") {
        return "fa fa-user-plus fa-2x";
      }

      if (widget.icon === "money") {
        return "fa fa-money fa-2x";
      }
    },
    getWidgetColour(widget) {
      if (widget.colour === "green") {
        return "bg-green";
      }
      if (widget.colour === "blue") {
        return "bg-info";
      }
      if (widget.colour === "orange") {
        return "bg-warning";
      }
    },
    goTo(titleLink, { goTo }) {
      if (goTo == "document") {
        this.$router.push({
          name: "search",
          query: {
            documentNumber: titleLink,
          },
        });
      }

      if (goTo == "supportingrateofexchange") {
        this.$router.push({
          name: goTo,
          query: {
            product: titleLink,
          },
        });
      }
    },
    graphOptions(graph) {
      const options = {
        chart: {
          type: "line",
          xaxis: {
            type: "datetime",
            labels: {
              datetimeUTC: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: graph.title,
          },
          subtitle: {
            text: graph.subtitle,
            align: "left",
          },
          fill: {},
          yaxis: {
            title: {
              text: "What",
            },
          },
        },
      };
      return options;
    },
    translateGraphSeries(graph) {
      const series = [];
      for (let key in graph.graphSeries) {
        const seriesObj = {
          name: key,
        };
        seriesObj.data = graph.graphSeries[key];
        series.push(seriesObj);
      }
      return series;
    },
  },
};
</script>
