<template>
  <ServiceScreenLayout :parts="parts" v-if="saleLoaded === true">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-8">
          <div class="form-group d-flex align-items-baseline col-md-10 row-spacing">
            <label class="col-md-3">Name: </label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled
              v-model="saleDefinition.definition.hosting.name"
            />
          </div>
          <div class="form-group d-flex align-items-baseline col-md-10 row-spacing">
            <label class="col-md-3">Origin: </label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled
              v-model="saleDefinition.definition.hosting.origin"
            />
          </div>
          <div class="form-group d-flex align-items-baseline col-md-10">
            <label class="col-md-3">Minimum TTL: </label>
            <div class="input-group with-focus col-md-5">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.definition.hosting.minimumTtl"
                :disabled="!staffOrClientAccess(productRestriction)"
              />
              <div class="input-group-append" v-if="staffOrClientAccess(productRestriction)">
                <span
                  @click="saveSale()"
                  class="
                    input-group-text
                    btn btn-info
                    text-white
                  "
                >
                  <i class="fa fa-save"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <div class="card card-default" style="text-align: center;">
            <div class="card-header">
              <h4>DNS View Details</h4>
            </div>
            <div class="card-body" style="padding-top: 0%;">
              <div class="row">
                <div class="col-md-12">Name: 
                  <strong>
                    {{saleDefinition.definition.hosting.dnsView.name}}
                  </strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">Description: 
                  <strong>
                    {{saleDefinition.definition.hosting.dnsView.description}}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row row-spacing">
        <div class="col-md-12">
          <h4>Default NS Records</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <th>Type</th>
              <th>TTL</th>
              <th>Destination</th>
            </thead>
            <tbody>
              <tr
                v-for="host in saleDefinition.definition.hosting.defaultNsHosts"
                :key="host.internalHostNumber"
              >
                <td>{{ host.type }}</td>
                <td>{{ host.ttl }}</td>
                <td style="word-break: break-word">{{ host.destination }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:hosting>
      <div class="row row-spacing">
        <div class="col-md-4 mb-3">
          <button type="button" @click="addHost()" class="btn btn-info" v-if="staffOrClientAccess(productRestriction)">
            Add Host Record
          </button>
        </div>
        <div class="col-md-4 mb-3"></div>
        <div class="form-group col-md-4">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search" v-model="searchTerm">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive table-bordered" v-if="saleDefinition.definition.hosting.hosts">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center" v-if="staffOrClientAccess(productRestriction)" style="width: 1em;">
                    <input 
                      type="checkbox"
                      class="checkbox"
                      :checked="deleteAll"
                      @change="addRemoveToGlobal"
                    />
                  </th>
                  <th @click="sortBy('name')">Name
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                  </th>
                  <th @click="sortBy('type')">Type
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'type' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'type' && !reverse"></i>
                  </th>
                  <th @click="sortBy('ttl')">TTL
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ttl' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ttl' && !reverse"></i>
                  </th>
                  <th @click="sortBy('destination')">Destination
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'destination' && reverse"
                    :class="!staffOrClientAccess(productRestriction) ? 'pdr3' : ''"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'destination' && !reverse"
                    :class="!staffOrClientAccess(productRestriction) ? 'pdr3' : ''"></i>
                  </th>
                  <th @click="sortBy('addReverse')">Has Reverse Record
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'addReverse' && reverse"
                    :class="!staffOrClientAccess(productRestriction) ? 'pdr3' : ''"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'addReverse' && !reverse"
                    :class="!staffOrClientAccess(productRestriction) ? 'pdr3' : ''"></i>
                  </th>
                  <th class="text-center" v-if="staffOrClientAccess(productRestriction)">
                    <TableToFile 
                      v-tooltip="'Download Excel'"
                      :small="true"
                      :fileName="'Internal DNS Hosts'"
                      :fieldProp="{'Name' : 'name', 'Type' : 'type', 'TTL' : 'ttl', 'Destination' : 'destination', 'Has Reverse Record' : 'addReverse'}"
                      :dataProp="filteredHostRecords"
                      :propDisable="isDeleting"
                    />
                    <button
                      v-tooltip="'Delete Selected Hosts'"
                      type="button"
                      @click="deleteSelectedHosts()"
                      class="btn btn-sm btn-primary"
                      :disabled="!isDeleting"
                    >
                      <em class="fa fa-trash fa-fw"></em>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="host in filteredHostRecords"
                  :key="host.internalHostNumber"
                >
                  <td class="text-center" v-if="staffOrClientAccess(productRestriction)" style="width: 1em;">
                    <input 
                        type="checkbox"
                        class="checkbox"
                        :id="host.hostNumber" 
                        :checked="isRowSelected(host.hostNumber)" 
                        @change="(e) => addRemoveToDelete(e, host)"
                      />
                  </td>
                  <td>{{ host.name }}</td>
                  <td class="center">{{ host.type }}</td>
                  <td>{{ host.ttl }}</td>
                  <td class="wrap-text">{{ host.destination }}</td>
                  <td class="center">
                    <span :class="getBadgeColor(host)">
                      {{ getBadgeText(host) }}
                    </span>
                  </td>
                  <td class="text-center" v-if="staffOrClientAccess(productRestriction)">
                    <button
                      v-tooltip="'Edit Host'"
                      type="button"
                      @click="editHost(host)"
                      class="btn btn-sm btn-info m-1"
                      :disabled="isDeleting"
                    >
                      <em class="fa fa-edit fa-fw"></em>
                    </button>
                    <button
                      v-tooltip="'Delete Host'"
                      type="button"
                      @click="deleteHost(host)"
                      class="btn btn-sm btn-primary"
                      :disabled="isDeleting"
                    >
                      <em class="fa fa-trash fa-fw"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <modal
        :title="modalTitle"
        ref="hostModal"
        size="modal-lg"
        @save="saveHost()"
      >
        <form>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Name:</label>
              <div class="input-group col-md-10">
                <input
                  placeholder="Enter Name"
                  :disabled="modalTitle.includes('Edit')"
                  type="text"
                  v-model="host.name"
                  class="form-control"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    >.{{ saleDefinition.definition.hosting.name }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Type:</label>
              <v-select
                :options="availableHostTypes"
                ref="hostType"
                :disabled="modalTitle.includes('Edit')"
                placeholder="Select Type"
                class="col-md-10"
                v-model="host.type"
                valueProp="name"
                label="description"
              ></v-select>

            </div>
          </div>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">TTL:</label>
              <div class="col-md-10">
                <input
                  type="number"
                  min="0"
                  placeholder="Enter TTL"
                  v-model="host.ttl"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div v-if="host.type == 'SRV' || host.type == 'MX'">
            <div class="row">
              <div
                class="
                  form-group
                  col-md-12
                  d-flex
                  align-items-baseline
                  justify-content-center
                "
              >
                <label class="col-md-2">Destination:</label>
                <div class="col-md-10">
                  <input
                    v-if="host.type == 'SRV'"
                    class="form-control"
                    type="text"
                    placeholder="[priority, e.g. 1] [weight, e.g. 0] [port, e.g. 443] [target, e.g. example.domain.extension]"
                    v-model="host.destination"
                  />
                  <input
                    v-if="host.type == 'MX'"
                    class="form-control"
                    type="text"
                    placeholder="[priority, e.g. 10] [example.mailserver.extension]"
                    v-model="host.destination"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Destination:</label>
              <div class="col-md-10">
                <input
                  type="text"
                  name="host"
                  placeholder="Enter Destination"
                  v-model="host.destination"
                  class="form-control"
                />
                <small v-if="host.type == 'TXT'"
                  >Quotations will be added automatically</small
                >
              </div>
            </div>
          </div>
          <div class="row" v-if="host.type == 'A'">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Reverse Record:</label>
              <div class="col-md-10">
                <div class="form-group">
                  <input class="ml-2" type="checkbox" v-model="host.addReverse">
                </div>  
              </div>
            </div>
          </div>
        </form>
      </modal>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout';
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";
import TableToFile from "@/components/GeneralComponents/TableToFile";

export default {  
    extends: SaleFunctions,
    components: { ServiceScreenLayout, Modal, TableToFile },
    data() {
      return {
        saleNumber: null,
        saleLoaded: false,
        saleDefinition: {
          definition: {
            
          },
          selectableOptions: {
            
          },
          supportingObjs: {
            
          }
        },
        parts: [
          {
            name: "service",
            icon: "fa fa-circle-user",
            displayName: "Service",
            permissions: ["*"],
          },
          {
            name: "hosting",
            icon: "fa fa-server",
            displayName: "Hosting",
            permissions: ["*"],
          },
        ],

        hosts: [],
        modalTitle: "",
        availableHostTypes: [],
        host: { destination: '', name: '', displayName: '' },
        
        deleteAll: false,
        deletingHosts: [],

        searchTerm: "",
        sortKey: 'type',
        reverse: false,
        productRestrictions: [],
        productRestriction: {},
      }
    },
    mounted() {
      this.getSaleNumber();
      this.loadSale();      
    },
    computed: {
      filteredHostRecords() {
        this.hostRecordFiltered = this.saleDefinition.definition.hosting.hosts;

        if (this.searchTerm) {
            this.hostRecordFiltered = this.hostRecordFiltered.filter((host) => 
                host.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                host.type.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                host.ttl.toString().includes(this.searchTerm) ||
                host.destination.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        }

        if (this.reverse) {
            return _.orderBy(this.hostRecordFiltered, this.sortKey, 'asc');
        } else {
            return _.orderBy(this.hostRecordFiltered, this.sortKey, 'desc');
        }
      },
      isDeleting() {
        if (this.deletingHosts.length > 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    methods: {
      getSaleNumber: function() {
        this.saleNumber =  this.$route.params.saleNumber;
      },
      loadSale: function() {
        const hostUrl = this.$config.aimsAPI;
        const saleNumber = this.saleNumber;

        this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
          (response) => {
            this.saleDefinition = response.data;   
            this.saleLoaded = true;
            this.getAllProductRestrictions();
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Sale Definition", error);
          }
        )
      },
      getAllProductRestrictions: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}products/restrictions`).then(
          (response) => {
            this.productRestrictions = response.data;

            this.productRestrictions.filter((restriction) => {
              if (restriction.description.toLowerCase() === this.saleDefinition.product.clientAccessRestriction.toLowerCase()) {
                this.productRestriction = restriction;
              }
            });
          },
          (error) => {
            console.error(error);
            this.showError("Error fetch all product restrictions", error);
          }
        );
      },
      addHost() {
        this.$refs.hostType.clearSelection();
        this.modalTitle = "Add Host";
        this.host = {
          class: this.saleDefinition.supportingObjs.hosts.class,
          internalDomainNumber: this.saleDefinition.definition.hosting.internalDomainNumber,
          ttl: this.saleDefinition.definition.hosting.minimumTtl,
          destination: '',
          displayName: '',
          name: ''
        };
        this.availableHostTypes =
          this.saleDefinition.supportingEnums.InternalHost.type.filter(
            (t) => t.name != "PTR"
          );
        this.$refs.hostModal.open();
      },
      editHost(host) {
        this.host = Object.assign({}, host);
        this.modalTitle = "Edit Host";
        this.$refs.hostModal.open();
      },
      saveHost() {
        let save = true;
        this.host.displayName = this.host.name;
        if ((this.host.type == "NS") && ((this.host.name === undefined) || (this.host.name === ""))) {
          save = false;
        }

        if (this.host.type == "TXT") {
          const firstChar = this.host.destination.substring(0, 1);
          const lastChar = this.host.destination.substring(
            this.host.destination.length - 1
          );

          if (firstChar != '"') {
            this.host.destination = '"' + this.host.destination;
          }
          if (lastChar != '"') {
            this.host.destination = this.host.destination + '"';
          }
        }
        
        if (save) {
          this.$refs.hostModal.isLoading();
          if (this.host.objKey === 0) {
            this.$http
              .post(
                this.$config.aimsAPI +
                  "sales/definition/" +
                  this.saleDefinition.sale.saleNumber +
                  "/obj/create",
                this.host
              )
              .then(
                (response) => {
                  this.loadSale();
                  this.$refs.hostModal.close();
                  this.showSuccess("Host Added Successfully");
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Saving Host", error);
                }
              )
              .finally(() => {
                this.$refs.hostModal.isLoading();
              });
          } else {
            this.$http
              .put(
                this.$config.aimsAPI +
                  "sales/definition/" +
                  this.saleDefinition.sale.saleNumber +
                  "/obj/update",
                this.host
              )
              .then(
                (response) => {
                  this.loadSale();
                  this.$refs.hostModal.close();
                  this.showSuccess("Host Updated Successfully");
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Saving Host", error);
                }
              )
              .finally(() => {
                this.$refs.hostModal.isLoading();
              });
          }
        } else {
          this.showWarningAlert("NS host record must have Name")
        }
      },
      deleteHost(host) {
        this.$swal({
          title: "Are you sure you want to delete this host?",
          text: host.type + ": " + host.name + " " + host.destination,
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.$http
              .post(
                this.$config.aimsAPI +
                  "sales/definition/" +
                  this.saleDefinition.sale.saleNumber +
                  "/obj/delete/",
                host
              )
              .then(
                (response) => {
                  this.showSuccess(response.data.message);
                  this.loadSale();
                },
                (error) => {
                  console.error(error);
                  this.showError("Error deleting host", error);
                }
              );
          }
        });
      },
      saveSale: function() {
        const hostUrl = this.$config.aimsAPI;
        const saleNumber = this.saleNumber;

        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
              (response) => {
                this.showSuccess("Save Success");
                this.getSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.getSaleDefinition();
              }
            );
          } 
        });
      },isRowSelected(data){
      var isDeleting = false;

      for (let i = 0; i < this.deletingHosts.length; i++) {
        if (this.deletingHosts[i].internalHostNumber == data) {
          isDeleting = true;
        }
      }

      return isDeleting;
    },
    addRemoveToDelete(e, host) {
      if (e.target.checked) {
        this.deletingHosts.push(host);
      } else {
        this.deletingHosts = this.deletingHosts.filter((i) => i.internalHostNumber != host.internalHostNumber);
      }
    },
    addRemoveToGlobal(e) {
      if (e.target.checked) {
        this.deletingHosts = [];
        for (let i = 0; i < this.filteredHostRecords.length; i++) {
          this.deletingHosts.push(this.filteredHostRecords[i]);
        }
      } else {
        this.deletingHosts = [];
      }
    },
    deleteSelectedHosts() {
        var tempText = 'Hosts: ';

        for (let i = 0; i < this.deletingHosts.length; i++) {
          tempText += this.deletingHosts[i].name + " [" + this.deletingHosts[i].type + "]";
          if (i != this.deletingHosts.length-1) {
            tempText += ', ';
          }
          
        }

        this.$swal({
          title: 'Delete Selected Hosts',
          text: tempText,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#e2231a',
          cancelButtonColor: '#58b0c2',
          confirmButtonText: 'Delete'
        }).then((result) => {
          if (result.value) {
            this.$http.post(this.$config.aimsAPI + 'sales/definition/' + this.saleNumber + '/objs/delete', this.deletingHosts)
              .then(
                (response) => {
                  this.$swal({
                    type: 'success',
                    title: 'Success',
                    text: "Hosts have been deleted"
                  })

                  this.deletingHosts = [];

                  this.loadSale()
                },(error) => {
                  console.error(error);

                  this.$swal({
                    type: 'error',
                    title: 'Error',
                    text: "Couldn't delete hosts"
                  })
              });
          }
        })
      },
      getBadgeColor: function(document) {
        let docClass = "";

        if (document.type != 'A') {
          return "badge bg-info"
        }

        switch (document.addReverse) {
          case true:
            docClass = "badge bg-green";
            break;
          case false:
            docClass = "badge bg-danger";
            break;
          default:
            docClass = "badge bg-info";
        }

        return docClass;
      },
      getBadgeText: function(document) {
        let docText = "";

        if (document.type != 'A') {
          return "N/A"
        }

        switch (document.addReverse) {
          case true:
            docText = "TRUE";
            break;
          case false:
            docText = "FALSE";
            break;
          default:
            docText = "N/A";
        }

        return docText;
      },
      sortBy(sortKey) {
        this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
        this.sortKey = sortKey;
      },
    },
}
</script>

<style scoped>
tr>th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.table>thead>th {
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.nsHead {
  color: royalblue;
  display: inline;
}

.terminalText{
  font-family: monospace;
  letter-spacing: -1px;
  word-spacing: 6px;
}

.icon-green {
  color: #37BC9B
}

.icon-yellow {
  color: #FF902B
}

.icon-red {
  color: #F05050
}

.wrap-text {
  width: 35%;
  word-break: break-all;
}

.pdr3 {
  padding-right: 3em;
}

.rotateEffect {
  transition-duration: 0.3s;
}

.rotateEffect:active {
   transform: rotate(180deg);
}

.toLeft {
  float: right;
  clear: both;
}

.center {
  text-align: center;
}
</style>
