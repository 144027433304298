<template>
<WidgetLayout 
    :heading="'Escalations'"
    :icon="'fa fa-plus'"
    :showFooterButtons="false"
  >
    <div class="table-responsive">
          <table class="table">
            <tbody>
              <tr v-for="escalation in Object.keys(escalations)" :key="escalation">
                <td class="text-muted">
                  <div>{{ escalation }}</div>
                  <small>{{ escalations[escalation] }}</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
</WidgetLayout>
</template>

<script>
import WidgetLayout from './WidgetLayout';
export default {
  components: {
    WidgetLayout
  },
  computed: {},
  data() {
    return {
      escalations: [],
    };
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(
        this.$config.aimsAPI + "dashboards/client/escalations/" + clientNumber
      )
      .then(
        (response) => {
          this.escalations = response.data;
        },
        (error) => {
          this.showError("Error fetching Escalation Contacts", error);
          console.error(error);
        }
      );
  },
  methods: {},
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}
</style>