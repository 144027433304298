<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-7">
          <div class="card-header" >
              <h4>{{saleDefinition.product.toString}}</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Description</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="saleDefinition.definition.details.description"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Details</label>
                  <div class="input-group">
                    <textarea 
                      type="text"
                      class="form-control"
                      rows="6" 
                      cols="50"
                      v-model="saleDefinition.definition.details.text"
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "service",
          icon: "fa fa-user-circle",
          displayName: "Service",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
            details: {}
        },
        product: {

        }
      },

    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },

  },
};
</script>