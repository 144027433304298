<template>
  <div>
      <div class="form-row mt-4">
        <div class="form-group col-auto" v-allowed:view="['STAFF']">
          <button
            class="btn btn-default"
            @click="updateWorkOrderStatus('reopen')"
            :disabled="invoiced ? true : !open"
            type="button"
          >
            Re-open
          </button>
        </div>
        <div class="form-group col-auto" v-allowed:view="['STAFF']">
          >
          <button
            class="btn btn-default"
            @click="updateWorkOrderStatus('complete')"
            :disabled="open || editMode"
            type="button"
          >
            Complete
          </button>
        </div>
        <div class="form-group col-auto" v-allowed:view="['ADMIN', 'ACCOUNTS_STAFF']">
          >
          <button
            class="btn btn-default"
            @click="updateWorkOrderStatus('invoice')"
            :disabled="workOrder.status != 'COMPLETED'"
            type="button"
          >
            Invoice
          </button>
        </div>
        <div v-if="workorder" class="form-group col-auto" v-allowed:view="['STAFF']">
          >
          <button
            class="btn btn-default"
            @click="emailDialog()"
            :disabled="invoiced ? false : open || editMode"
            type="button"
          >
            Email
          </button>
        </div>
        <div class="form-group col-auto">
          <button
            class="btn btn-default"
            @click="downloadWorkOrderPdf()"
            :disabled="invoiced ? false : open || editMode"
            type="button"
          >
            Download
          </button>
        </div>
        <div class="form-group col-auto" v-allowed:view="['STAFF']">
          <button
            class="btn btn-default"
            @click="updateWorkOrderStatus('cancel')"
            :disabled="open || editMode"
            type="button"
          >
            Cancel
          </button>
        </div>
        <div v-if="quote && isStaff && workOrder.status != 'INVOICED'" class="form-group col-auto">
          <button
            class="btn btn-default"
            :disabled="editMode"
            @click="addDeposit()"
            type="button"
          >
            Deposit
          </button>
        </div>
      </div>
      <hr />
      <WorkOrderDetailCapture 
        :workOrder="workOrder"
        :editMode="editMode"
        :ticketsString="ticketsString"
        :open="open"
        @cancel="cancel"
        @addEntry="addEntry"
      />
      <hr v-if="isStaff" />
      <div class="row" v-if="isStaff">
          <div class="col-md-6">
            <div class="form-row">
              <div class="form-group col-auto" v-if="!editMode">
                <button
                  class="btn btn-primary"
                  :disabled="open"
                  @click="editMode = true"
                  type="button"
                >
                  Edit
                </button>
              </div>
              <div class="form-group col-auto" v-else>
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="updateWorkOrder()"
                >
                  Save
                </button>
              </div>
              <div class="form-group col-auto">
                <button
                  class="btn btn-default"
                  @click="cancel"
                  :disabled="!editMode"
                  type="button"
                >
                  Cancel
                </button>
              </div>
              <div class="form-group col-auto">
                <button
                  class="btn btn-default"
                  @click="deleteWorkOrder()"
                  :disabled="workOrder.status != 'CANCELLED'"
                  type="button"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row pull-right">
              <div class="form-group col-auto">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  :disabled="!editMode"
                >
                  + Add Entry
                </button>
                <div class="dropdown-menu">
                    <button class="dropdown-item" v-for="(option, index) in entryOptions" :key="index" @click.prevent="addEntry(option)">
                        {{ option.name }}
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <hr>
      <WorkOrderDetailTable 
        :workOrderLines="workOrderLines"
        :editMode="editMode"
        :totalAmount="totalAmount"
        @editEntry="editEntry"
        @deleteEntry="deleteEntry"
        @evaluateTypeForDescription="evaluateTypeForDescription"
      />

      <WorkOrderDetailEmailModal 
        ref="emailDialogModal"
        :workOrder="workOrder"
        :clientContacts="clientContacts"
        :additionalContacts="additionalContacts"
        @sendEmail="sendEmail"
        @contactClicked="contactClicked"
        @isSelected="isSelected"
      />

      <WorkOrderDetailDepositModal 
        ref="addDepositModal"
        @getWorkOrder="getWorkOrder"
      />
      
      <WorkOrderDetailEntryModal 
        ref="addEntryModal"
        :entryTypeDescription="entryTypeDescription"
        :entryType="entryType"
        :entry="entry"
        :cost="cost"
        :exVatCost="exVatCost"
        :bulkCost="bulkCost"
        :bulkCostExVat="bulkCostExVat"
        :selectedSale="selectedSale"
        :clientSales="clientSales"
        :workorder="workorder"
        @saveEntry="saveEntry"
        @setLinkedSale="setLinkedSale"
      />
    
  </div>
</template>

<script>
import ClientFunctions from "@/pages/ClientFunctions";

import WorkOrderDetailCapture from "@/components/WorkOrders/WorkOrderDetailCapture.vue";
import WorkOrderDetailTable from "@/components/WorkOrders/WorkOrderDetailTable.vue";

import WorkOrderDetailEmailModal from "@/components/WorkOrders/WorkOrderDetailEmailModal.vue";
import WorkOrderDetailDepositModal from "@/components/WorkOrders/WorkOrderDetailDepositModal.vue";
import WorkOrderDetailEntryModal from "@/components/WorkOrders/WorkOrderDetailEntryModal.vue";

export default {
  extends: ClientFunctions,
  components: {
    WorkOrderDetailCapture,
    WorkOrderDetailTable,
    WorkOrderDetailEmailModal,
    WorkOrderDetailDepositModal,
    WorkOrderDetailEntryModal
  },
  data() {
    return {
      clientNumber: null,
      totalAmount: {},
      totalTime: 0,
      additionalContacts: "",
      entryType: "",
      entryTypeDescription: "",
      client: {},
      workOrder: {},
      entry: {},
      workOrderLines: [],
      clientContacts: [],
      staff: [],
      editMode: false,
      selectedSale: null,
      clientSales: [],
      ticketsString: "",
      workOrderDeposit: {},
      entryOptions: [
          {
              name: "Hardware",
              type: "hardware"
          },
          {
              name: "Software/Licence",
              type: "software"
          },
          {
              name: "Labour",
              type: "labour"
          },
          {
              name: "Bulk Labour",
              type: "bulk_labour"
          },
          {
              name: "Travel",
              type: "travel"
          },
          {
              name: "Outsourced Service",
              type: "outsourced_service"
          },
      ],
      labourRate: [],
      timeList: [],
      labourType: [],
      rateTime: [],
    };
  },
  async created() {
    this.clientNumber = this.$route.params.clientNumber; 

    await this.getClientData(this.clientNumber);
    await this.getWorkOrder();
    await this.getClientSales(this.clientNumber);
  },
  mounted() {
    const startInEditMode = this.$route.params.startInEditMode;
    if (startInEditMode) {
      this.editMode = true;
    }    
  },
  computed: {
    isStaff() {
      return this.userContainsPermission(["STAFF"]);
    },
    open() {
      return this.workOrder.status != "OPEN";
    },
    invoiced() {
      return (
        this.workOrder.status == "INVOICED" ||
        this.workOrder.status == "ZERO_INVOICED"
      );
    },
    combinedTicketRefs() {
      if (this.ticketsString.length > 20) {
        return true;
      }
      return false;
    },
    hourFormat() {
      var text = "minutes";
      var hours = Math.trunc(this.totalTime / 60);
      var minutes = this.totalTime % 60;

      if (minutes == 0) {
        minutes = "00";
      }
      if (hours > 0) {
        text = "hours";
      }
      
      return hours + ":" + minutes + " total " + text;
    },
    cost: {
      get() {
        return this.timesVATNoCurrency(this.entry.exVatCostPerUnit);
      },
      set(cost) {
        this.entry.costPerUnit = cost;
      },
    },
    exVatCost: {
      get() {
        return this.exVATNoCurrency(this.entry.costPerUnit);
      },
      set(exVatCost) {
        this.entry.exVatCostPerUnit = exVatCost;
      },
    },
    bulkCost: {
      get() {
        return this.timesVATNoCurrency(this.entry.costVatExcl);
      },
      set(cost) {
        this.entry.costVatIncl = cost;
      }
    },
    bulkCostExVat: {
      get() {
        return this.exVATNoCurrency(this.entry.costVatIncl);
      },
      set(exVatCost) {
        this.entry.costVatExcl = exVatCost;
      }
    },
    quote() {
      return this.workOrder.workOrderType == "Quote";
    },
    workorder() {
      return !this.quote;
    },
  },
  methods: {
    getWorkOrder(lineAdded) {
      const workOrderNumber = this.$route.params.workOrderNumber;
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}workorders/${workOrderNumber}/workorderlines`).then(
          (response) => {
            this.workOrderLines = response.data.workOrderLines;
            this.totalAmount = response.data.totalAmount;

            if (lineAdded) {
              this.workOrderLines[this.workOrderLines.length - 1].isNew = true;
            } else {
              this.workOrder = response.data.workOrderDetail;
            }

            let arrTicks = [];
            this.totalTime = 0;
            this.workOrderLines.forEach((line) => {
              if (line.time) {
                this.totalTime += line.time;
              }
              if (
                line.ticketReference != "" &&
                arrTicks.findIndex((t) => t == line.ticketReference) == -1
              ) {
                arrTicks.push(line.ticketReference);
              }
            });
            this.ticketsString = arrTicks.join(", ").toString();
          },
          (error) => {
            this.showError("Error fetching Lines", error);
            console.error(error);
          }
        );
    },
    getClientSales(clientNumber) {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}sales/client/${clientNumber}`).then(
      (response) => {
        this.clientSales = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sales for Client", error);
      }
    );
    },
    getClientData(clientNumber) {
      this.getClient(clientNumber).then(
      (response) => {
        this.client = response.data;
      },
      (error) => {
        this.showError("Error Fetching Client for WorkorderDetails", error);
        console.error(error);
      }
    );
    },
    evaluateTypeForDescription(line) {
      if (line.class.includes("Equipment")) {
        return "Hardware";
      } else if (line.class.includes("Software")) {
        return "Software"
      } else if (line.class.includes("Travel")) {
        return "Travel";
      } else if (line.class.includes("Service")) {
        return "Outsourced Service";
      } else if (line.class.includes("Labour") && !line.class.includes("Bulk")) {
        return "Labour";
      } else if (line.class.includes("Bulk")) {
        return "Bulk Labour";
      }
    },
    evaluateTypeForClass(line) {
      if (line.class.includes("Equipment")) {
        return "Hardware";
      } else if (line.class.includes("Software")) {
        return "Software"
      } else if (line.class.includes("Travel")) {
        return "Travel";
      } else if (line.class.includes("Service")) {
        return "Outsourced Service";
      } else if (line.class.includes("Labour") && !line.class.includes("Bulk")) {
        return "Labour";
      } else if (line.class.includes("Bulk")) {
        return "Bulk Labour";
      }
    },
    cancel() {
      this.getWorkOrder();
      this.editMode = false;
    },
    downloadWorkOrderPdf() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}workorders/${this.workOrder.workOrderNumber}/pdf`, { responseType: "arraybuffer", }).then(
          (response) => {
            const contentType = response.headers.get("Content-Type");
            let blob = new Blob([response.data], {
              type: contentType,
            });
            const fileName = response.headers
              .get("Content-Disposition")
              .split("filename = ")[1];
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
          },
          (error) => {
            console.error(error);
            this.showError("Error Downloading Document PDF", error.data);
          }
        );
    },
    emailDialog() {
      const hostUrl = this.$config.aimsAPIv2;

      this.$http.get(`${hostUrl}users/persons/client/${this.workOrder.clientNumber}`).then(
          (response) => {
            this.clientContacts = response.data;
            this.selectedContacts = [];
            this.$refs.emailDialogModal.open();
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Client Contacts", error);
          }
        );
    },
    isSelected(emailAddress) {
      const index = this.selectedContacts.findIndex((p) => p === emailAddress);
      if (index >= 0) {
        return true;
      } else {
        return false;
      }
    },
    contactClicked(emailAddress) {
      if (emailAddress.length > 0) {
        const index = this.selectedContacts.indexOf(emailAddress);
        if (index >= 0) {
          this.selectedContacts.splice(index, 1);
        } else {
          this.selectedContacts.push(emailAddress);
        }
      }
    },
    sendEmail() {
      const hostUrl = this.$config.aimsAPI;
      var addArray = this.additionalContacts.split(",");
      addArray.forEach((e) => {
        this.contactClicked(e);
      });
      if (this.selectedContacts.length > 0) {
        this.$refs.emailDialogModal.loading();
        this.$http.post(`${hostUrl}workorders/${this.workOrder.workOrderNumber}/email`, this.selectedContacts).then(
            () => {
              this.showSuccess("Email Sent");
            },
            (error) => {
              console.error(error);
              this.showError("Error Emailing Document", error);
            }
          )
          .finally(() => {
            this.$refs.emailDialogModal.close();
          });
      }
      this.additionalContacts = "";
      this.selectedContacts = [];
    },
    addEntry(option) {                  
      this.$http.get(this.$config.aimsAPI + "workorders/" + this.workOrder.workOrderNumber + "/getempty/" + option.type + "/entry", this.workOrder).then(
          (response) => {
            this.editEntry(response.data);
          },
          (error) => {
            this.showError("Error Adding " + this.entryTypeDescription, error);
            console.error(error);
          }
        );
    },
    setLinkedSale(selectedSale) {
      this.entry.saleNumber = selectedSale.saleNumber;
    },
    viewSale(saleNumber) {
      this.$http.get(this.$config.aimsAPI + "sales/" + saleNumber).then(
        (response) => {
          const saleProduct = response.data.product;
          const productRoute = this.getProductDefinition(
            saleProduct.productDefinition
          );

          if (productRoute == -1) {
            this.showWarning(
              "Could not view sale #" + saleNumber,
              "'" + saleProduct.description + "' is not supported yet."
            );
          } else {
            this.$router.push({
              name: productRoute.routeName,
              params: {
                saleNumber: saleNumber,
              },
            });
          }
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Product for Sale", error);
        }
      );
    },
    editEntry(line) {      
      this.entryTypeDescription = this.evaluateTypeForDescription(line) + " Entry";
      this.entryType = this.evaluateTypeForClass(line) + " Entry";
      this.entry = this.deepCopyObject(line);
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.$refs.addEntryModal.open();
    },
    saveEntry(data) {
      const { entry, isVatExcl } = data;
      this.entry = entry;
      
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.$refs.addEntryModal.$refs.addEntryModal.isLoading();
          this.entry.effectiveDate = this.dateTimeFormat(
            this.entry.effectiveDate
          );
          if (isVatExcl) {
            this.entry.costPerUnit = this.cost;
            this.entry.costVatIncl = this.bulkCost;
          } else {
            this.entry.exVatCostPerUnit = this.exVatCost;
            this.entry.costVatExcl = this.bulkCostExVat;
          }
          this.$http
            .put(
              this.$config.aimsAPI +
                "workorders/" +
                this.workOrder.workOrderNumber +
                "/update/entry",
              this.entry
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.getWorkOrder(true);
                this.$refs.addEntryModal.$refs.addEntryModal.close();
              },
              (error) => {
                this.showError("Error updating work entry", error);
                console.error(error);
              }
            )
            .finally(() => {
              this.$refs.addEntryModal.$refs.addEntryModal.isLoading();
            });
        }
      });
    },
    updateWorkOrderStatus(action) {
      if (action == "complete") {
        if (this.workOrderLines.length == 0) {
          this.$swal({
            title: "Error: Could not process. There are no Work Entries.",
            type: "warning",
            confirmButtonText: "Ok",
          });
          return;
        } else {
          this.$swal({
              title: "Are you sure?",
              text: "A completed work order will be invoiced on the next action date: " 
              + this.dateTimeFormat(this.workOrder.nextActionDate).split(" ")[0],
              type: "warning",
              confirmButtonText: "Yes",
              showCancelButton: true,
          }).then((result) => {
              if (result.value) {
                this.updateWorkOrderStatusFinal(action);
              }
          });
        }
      } else if (action == "cancel") {
        this.$swal({
              title: "Are you sure?",
              text: "A cancelled work order will not be invoiced",
              type: "warning",
              confirmButtonText: "Yes",
              showCancelButton: true,
          }).then((result) => {
              if (result.value) {
                this.updateWorkOrderStatusFinal(action);
              }
          });
      } else {
        this.updateWorkOrderStatusFinal(action);
      }      
    },
    updateWorkOrderStatusFinal(action) {
      this.$http
        .put(
          this.$config.aimsAPI +
            "workorders/" +
            this.workOrder.workOrderNumber +
            "/" +
            action,
          this.workOrder
        )
        .then(
          (response) => {
            this.showSuccess(response.data.message);
            this.getWorkOrder();
          },
          (error) => {
            this.showError("Error Updating Status", error);
            console.error(error);
          }
        );
    },
    updateWorkOrder() {
      this.workOrder.nextActionDate = this.dateTimeFormat(
        this.workOrder.nextActionDate
      );
      this.$validator.validateAll("workorder").then((valid) => {
        if (valid) {
          this.$http
            .put(this.$config.aimsAPI + "workorders/update", this.workOrder)
            .then(
              () => {
                this.showSuccess("Successfully updated");
                this.getWorkOrder();
                this.editMode = false;
              },
              (error) => {
                this.showError("Error Updating", error);
                console.error(error);
              }
            );
        }
      });
    },
    deleteEntry(line) {
      const deleteType = this.evaluateTypeForClass(line).toLowerCase();
      this.$swal({
        title: "Are you sure you want to delete this Entry?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "workorders/" +
                line.workOrderNumber +
                "/delete/" +
                deleteType +
                "/" +
                line.objKey
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.getWorkOrder();
              },
              (error) => {
                this.showError("Error Deleting", error);
                console.error(error);
              }
            );
        }
      });
    },
    deleteWorkOrder() {
      this.$swal({
        title: "Are you sure you want to delete this?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "workorders/" +
                this.workOrder.workOrderNumber
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.$router.go(-1);
              },
              (error) => {
                this.showError("Error Deleting", error);
                console.error(error);
              }
            );
        }
      });
    },
    addDeposit() {
      this.$refs.addDepositModal.open();
    },
  },
};
</script>