<template>
  <WidgetLayout
    :heading="'Invoices / Credit Notes'"
    :icon="'fa fa-file'"
    :showFooterButtons="false"
  >
    <div class="table-responsive">
          <table class="table table-hover" v-if="documents.length > 0">
            <thead class="text-left">
              <tr>
                <th>Type</th>
                <th>Period</th>                
                <th>Description</th>
                <th v-allowed:view="['STAFF']">Status</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="document in documents"
                :key="document.documentNumber"
                @click="gotToInvoiceOrCreditNote(document)"
                v-tooltip="`View ${document.type}`"
              >
                <td>
                  <span :class="getTypeBadge(document.type)">{{document.type}}</span>
                </td>
                <td>
                  {{ document.billingPeriod }}
                </td>
                <td>
                  <small>{{ document.note }}</small>
                </td>
                <td v-allowed:view="['STAFF']">
                  <span :class="getStatusBadge(document.status)">{{document.status}}</span>
                </td>
                <td>
                  <small>{{ currencyFormat(document.totalAmount) }}</small>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else><span>No Invoices / Credit Notes</span></div>
        </div>
  </WidgetLayout>
</template>

<script>
import WidgetLayout from './WidgetLayout';
export default {
  components: {
    WidgetLayout
  },
  data() {
    return {
      documents: [],
    };
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(this.$config.aimsAPI + "dashboards/client/documents/" + clientNumber)
      .then(
        (response) => {
          this.documents = response.data;
          this.filterDocumentsForClient();
        },
        (error) => {
          this.showError("Error Fetching Client Documents", error);
          this.documents = [];
          console.error(error);
        }
      );
  },
  methods: {
    filterDocumentsForClient() {
      if (this.userContainsPermission(['CLIENT'])) {
        let procDocuments = this.documents.filter((p) => p.status == "PROCESSED");
        let setDocuments = this.documents.filter((p) => p.status == "SETTLED");
        this.documents = procDocuments.concat(setDocuments);
      }
    },
    getTypeBadge(status) {
      var badge = "badge badge-success";

      if (this.userContainsPermission(['CLIENT'])) {
        return "badge bg-info"
      }

      if (status == "INVOICE") {
        badge = "badge bg-green";
      }
      if (status == "CREDITNOTE") {
        badge = "badge bg-red text-white";
      }
      return badge;
    },
    gotToInvoiceOrCreditNote: function(data) {
      this.$router.push({
          name: 'documentedit',
          params: {
            clientNumber: data.clientNumber,
            documentNumber: data.documentNumber,
          },
        });
    },
    getStatusBadge(status) {
      var badge = "badge badge-success";
      if (status == "OPEN") {
        badge = "badge bg-green";
      }
      if (status == "PROCESSED") {
        badge = "badge bg-red text-white";
      }
      return badge;
    },
  },
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}
</style>