<template>
    <modal
        ref="fttxOrderModal"
        :title="modalTitle"
        size="modal-xl"
        save-text="Update"
        dismiss-text="Close"
        :saveNeeded="false"
        :genButtonNeeded="false"
        :genButtonText="'Cancel order'"
        :btnStyle="'btn btn-primary'"
        @clickHandler="cancelOrder()"
        @save="updateOrder(selectedOrder)"
        @close="close"
        @dismiss="dismiss"
    >
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>FNO reference</label>
                        <input 
                            class="form-control" 
                            type="text" 
                            name="FNO Reference"
                            v-model="selectedOrder.fnoReference"                 
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>ISP reference</label>
                        <input 
                            class="form-control" 
                            type="text" 
                            name="ISP Reference"
                            v-model="selectedOrder.ispReference"                       
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>Service</label>
                        <input 
                            class="form-control" 
                            type="text" 
                            name="Service"
                            v-model="selectedOrder.service"                       
                            disabled
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>Location</label>
                        <input class="form-control" v-model="orderDetail.address" disabled />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>End-user name</label>
                        <div class="form-control" style="background-color: #edf1f2;">{{ `${orderDetail.customer_first_name} ${orderDetail.customer_last_name}` }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>End-user phone</label>
                        <input class="form-control" v-model="orderDetail.customer_phone" disabled/>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Order type</label>
                        <input type="text" class="form-control" v-model="selectedOrder.type" disabled>
                    </div>
                    <div class="form-group col-md-6">
                        <label>Status</label>
                        <input type="text" class="form-control" v-model="selectedOrder.status" disabled>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12" v-if="orderDetail.status">
                        <label>Status description</label>
                        <input class="form-control" v-model="orderDetail.status.description" v-tooltip="orderDetail.status.description" disabled />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label>Status changes</label>
                        <div class="table-responsive" style="height: 190px;">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody v-if="orderDetail.status_changes && orderDetail.status_changes.length > 0">
                                    <tr v-for="change in orderDetail.status_changes" :key="change.id">
                                        <td>{{ dateTimeFormat(change.created) }}</td>
                                        <td>{{ `${change.to_status.name} - ${change.to_status.description}` }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="2">No status changes available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-12" v-if="orderDetail.service_profile">
                        <label>Comment</label>
                        <textarea class="form-control" v-model="orderDetail.comment" disabled></textarea>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal,
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        selectedOrder: {
            type: Object,
            required: true
        },
        orderTypes: {
            type: Array,
            required: true         
        },
        orderOperators: {
            type: Array,
            required: true         
        },
        orderStatuses: {
            type: Array,
            required: true         
        },
        orderDetail: {
            type: Object,
            required: true         
        }
    },
    data() {
        return {
            parts: [
                {
                    name: "order",
                    icon: "fa-solid fa-box-open",
                    displayName: "Order",
                    permissions: ["STAFF", "MANAGE_ORDERS"],
                },
                {
                    name: "order-details",
                    icon: "fa-solid fa-circle-info",
                    displayName: "Order Details",
                    permissions: ["STAFF", "MANAGE_ORDERS"],
                }
            ],
        }
    },
    methods: {
        open: function() {
            this.$refs.fttxOrderModal.open();
        },
        close: function() {
            this.$emit("getPagedOrders", false);
        },
        dismiss: function() {
            this.$emit("getPagedOrders", false);
        },
        updateOrder: function(selectedOrder) {
            selectedOrder.json = JSON.stringify(this.orderDetail);
            
            this.$validator.validateAll().then((valid) => {
                if (valid) { 
                    this.$emit("updateOrder", selectedOrder);
                    this.$refs.fttxOrderModal.close();
                }
            });            
        },
        cancelOrder: function() {
            console.log('Cancelling order - to be implemented');  
        }
    }, 
}
</script>

<style lang="scss" scoped>
    .fontsmaller {
      font-size: xx-small;
    }
</style>