<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default">
          <div class="card-body">
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      class="btn btn-success"
                      type="button"
                      @click="newSaleTag()"
                    >
                      New Sale Tag
                    </button>
                    <button
                      class="btn btn-success"
                      type="button"
                      @click="saleTagAssignment()"
                    >
                      Sale Tag Assignments
                    </button>
                  </div>
                </div>
                <div class="row row-spacing">
                  <div class="col-lg-12">
                    <SimpleTable
                      :headings="tableConfig.headings"
                      :dataKeys="tableConfig.dataKeys"
                      :searchFields="[
                        'clientNumber',
                        'name'
                      ]"
                      :data="tags"
                      :clickable="true"
                      @rowClicked="tagClicked"
                    />
                  </div>
                </div>

                <modal
                  :title="'Client Sale Tag'"
                  ref="viewTagModal"
                  size="modal-md"
                  @save="saveTag()"
                  :delete-needed="true"
                  @delete="deleteTag()"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Tag Name</label>
                        <input
                          class="form-control"
                          type="text"
                          v-model="selectedTag.name"
                        />
                      </div>
                      <div class="form-group">
                        <label>Client</label>
                        <input
                          v-if="selectedTag.clientNumber"
                          class="form-control"
                          type="text"
                          v-model="selectedTag.client.toString"
                          disabled
                        />
                        <ObjAutocomplete
                          v-else
                          @objSelected="clientSelected"
                          objClass="za.co.adept.aims.classes.Client"
                          :startingObject="selectedTag.client"
                        />
                      </div>
                    </div>
                   </div>
                </modal>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Modal from "@/components/GeneralComponents/Modal";
  import SimpleTable from "@/components/GeneralComponents/SimpleTable";
  import ObjAutocomplete from "@/components/GeneralComponents/InputControls/ObjAutocomplete";

  export default {
    components: {
      Modal,
      SimpleTable,
      ObjAutocomplete
    },
    data() {
      return {
        tableConfig: {
          headings: [
            "Client#",
            "Client",
            "Name"
          ],
          dataKeys: [
            {
              name: "clientNumber",
            },
            {
              name: "client.toString",
            },
            {
              name: "name",
              cssclass: "text-bold"
            },
          ],
        },
        tags: [],
        selectedTag: {
          client: {}
        }

      }
    },
    created() {
      document.title = this.$route.meta.title;
    },
    mounted: function () {
      this.reload();
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    methods: {
      reload() {
        this.$http.get(this.$config.aimsAPI + "saletags/all").then(
          (response) => {
            this.tags = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching SaleTags", error);
          }
        );
      },
      newSaleTag() {
        this.selectedTag = {};
        this.selectedTag.class =  "za.co.adept.aims.segment.SaleTag";
        this.selectedTag.client = {};
        this.selectedTag.client.toString = "";
        this.selectedTag.value = "";
        this.$refs.viewTagModal.open();
      },
      tagClicked(clickedTag) {
        this.selectedTag = {};
        this.selectedTag = this.deepCopyObject(clickedTag);
        this.$refs.viewTagModal.open();
      },
      clientSelected(client) {
          this.selectedTag.clientNumber = client.clientNumber;
      },
      deleteTag() {
        this.$swal({
          title: "Are you sure you want to delete this Client Sale Tag?",
          text: this.selectedTag.name,
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.$refs.viewTagModal.isLoading();
            this.$http
              .delete(
                this.$config.aimsAPI +
                  "saletags/delete/" +
                  this.selectedTag.objKey
              )
              .then(
                (response) => {
                  this.showSuccess("Successfully deleted Client Sale Tag");
                  this.reload();
                  this.$refs.viewTagModal.close();
                },
                (error) => {
                  this.showError("Error deleting Client Sale Tag", error);
                  console.error(error);
                }
              )
              .finally(() => {
                this.$refs.viewTagModal.isLoading();
              });
          }
        });
      },
      saveTag() {
        this.$refs.viewTagModal.isLoading();
        this.$http
          .post(this.$config.aimsAPI + "saletags/save", this.selectedTag)
          .then(
            (response) => {
              this.showSuccess("Sale Tag Saved.");
              this.reload();
              this.$refs.viewTagModal.close();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Tag", error);
            }
          )
          .finally(() => {
            this.$refs.viewTagModal.isLoading();
          });
      },
      saleTagAssignment() {
        this.$router.push({
          name: "saletagsassignment"
        });
      }
    }
  }

</script>
