<template>
    <div class="row">
        <div class="col-md-6">
            
            <div class="col-md-12" v-allowed:view="['STAFF']">
                <div class="row">
                    <div class="form-group col-8 col-md-4">Type:</div>
                    <div class="form-group col-4 col-md-8">
                        <span :class="getDocumentClass(documentBody)">{{ documentBody.type }}</span>
                    </div>
                </div>
            </div>
            
            <div class="col-md-12">
                <div class="row">
                    <div class="form-group col-8 col-md-4">PO Number:</div>
                    <div class="form-group col-4 col-md-8">
                    {{ purchaseOrder.purchaseOrder }}
                    </div>
                </div>
            </div>

            <div class="col-md-12" v-if="documentBody.type == 'CREDITNOTE'"  v-allowed:view="['STAFF']">
                <div class="row">
                    <div class="form-group col-8 col-md-4">Ticket:</div>
                    <a class="form-group col-4 col-md-8" href="javascript:void(0)" @click="goToTicket()">
                        {{ cnd.ticketNumber ? '#'+cnd.ticketNumber : '' }}
                    </a>
                </div>
            </div>
            
            <div class="col-md-12">
                <div class="row">
                    <div class="form-group col-8 col-md-4">Total Amount:</div>
                    <div class="form-group col-4 col-md-8">
                        <b>{{ currencyFormat(documentBody.totalAmount) }}</b>
                    </div>
                </div>
            </div>
            
            <div class="col-md-12"  v-allowed:view="['STAFF']">
                <div class="row">
                    <div class="form-group col-4 col-md-8">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <input type="checkbox" id="printed" disabled :checked="documentBody.printed">
                                </div>
                            </div>
                            <input type="text" class="form-control" aria-label="Text input with checkbox" v-model="printed" disabled>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <div class="row" v-if="((documentBody.type !== 'CREDITNOTE') && debitOrderClient)">
                    
                    <div class="form-group col-4 col-md-8">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                <input type="checkbox" id="excludeFromDebitOrder" :disabled="disabledExcludeDOs" v-model="documentBody.excludeFromDebitOrder" @change="saveDocument()">
                                </div>
                            </div>
                            <input type="text" class="form-control" v-model="excludeFromDebitOrders" disabled>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            
            <div class="col-md-12">
                <div class="row">
                    <div class="form-group col-md-4">Description:</div>
                    <div class="d-none d-md-block col-md-8">
                        <div class="form-group" v-if="editDescription">
                            <div class="input-group">
                                <input type="text" v-model="documentBody.note" class="form-control col-md-9"/>
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-info" @click="$emit('updateDescription', documentBody.note)">
                                        <i class="fa fa-save"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" v-else @mouseover="$emit('setHover', true)"  @mouseleave="$emit('setHover', false)" @click="$emit('shouldEditDescription', true)" style="cursor: pointer" v-tooltip="'Edit description'" v-allowed:view="['STAFF']">
                            <span class="mr-2">{{ documentBody.note }}</span>
                            <span>
                                <i class="fa fa-edit"></i>
                            </span>
                        </div>
                        <div class="form-group" v-allowed:view="['CLIENT']">
                            <span class="mr-2">{{ documentBody.note }}</span>
                        </div>
                    </div>
                    <div class="col-12 d-block d-md-none">
                        <div class="form-group">
                            <div class="input-group">
                                <input type="text" v-model="documentBody.note" class="form-control col-md-9"/>
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-info" @click="$emit('updateDescription', documentBody.note)">
                                        <i class="fa fa-save"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-md-12"  v-allowed:view="['STAFF']">
                <div class="row">
                    <div class="form-group col-8 col-md-4">Status:</div>
                    <div class="form-group col-4 col-md-8">
                        <b :class="{ 'processed-invoice': documentBody.status === 'PROCESSED', 'open-invoice': documentBody.status === 'OPEN', }">
                            {{ documentBody.status }}
                        </b>
                    </div>
                </div>
            </div>
            
            <div class="col-md-12" v-allowed:view="['STAFF']">
                <div class="row">
                    <div class="form-group col-8 col-md-4">Process Date:</div>
                    <div class="form-group col-4 col-md-8">
                        {{ dateFormat(documentBody.processDate)  }}
                    </div>
                </div>
            </div>
            
            <div class="col-md-12">
                <div class="row">
                    <div class="form-group col-8 col-md-4">Billing Period:</div>
                    <div class="form-group col-4 col-md-8">
                        {{ documentBody.billingPeriod }}
                    </div>
                </div>
            </div>

            <div v-if="documentBody.type == 'CREDITNOTE'"  v-allowed:view="['STAFF']">
                <div class="col-md-12">
                    <div class="row">
                        <div class="form-group col-md-4">Reason:</div>
                        <div class="d-none d-md-block col-md-8">
                            <div class="form-group" v-if="documentBody.status === 'OPEN'" @mouseover="$emit('setHover', true)"  @mouseleave="$emit('setHover', false)" @click="editCreditNoteDetail()" style="cursor: pointer" v-tooltip="'Edit description'" v-allowed:view="['STAFF']">
                                <span class="mr-2">{{ cnd.reason }}</span>
                                <span>
                                    <i class="fa fa-edit"></i>
                                </span>
                            </div>
                            <div class="form-group" v-else v-allowed:view="['STAFF']">
                                <span class="mr-2">{{ cnd.reason }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <CreditDetailModal
            ref="creditDetailModal" 
            :creditDetail="cnd"
            @createCreditDetail="saveCreditNoteDetail()"
        />
    </div>
</template>

<script>
import CreditDetailModal from "@/components/Admin/Modals/CreditDetailModal.vue";

    export default {
        components: {
            CreditDetailModal,
        },
        computed: {
            disabledExcludeDOs() {
                if (this.documentBody.status != 'OPEN') {
                return true; 
                } else {
                return !(this.userContainsPermission(['ADMIN','ACCOUNTS_ADMIN']));
                }
            }
        },
        props: {
            documentLines: {
                type: Array,
                required: true
            },
            documentBody: {
                type: Object,
                required: true
            },
            creditMode: {
                type: Boolean,
                required: true
            },
            editDescription: {
                type: Boolean,
                required: true
            },
            hover: {
                type: Boolean,
                required: true
            },
            purchaseOrder: {
                type: Object,
                required: true
            },
            debitOrderClient: {
                type: Boolean,
                required: true
            },
        },
        data() {
            return {
                printed: 'Printed',
                excludeFromDebitOrders: 'Exclude from Debit Orders',
                editCreditDetail: false,
                cnd: {
                    ticketNumber: '',
                    reason: '',
                },
            }
        },
        mounted: function () {
            this.getCreditNoteDetail();
        },  
        methods: {
            goToTicket() {
                window.open('https://helpdesk.adept.co.za/helpdesk/WebObjects/Helpdesk.woa/wa/TicketActions/view?ticket=' + this.cnd.ticketNumber);
            },
            editCreditNoteDetail: function() {
                this.$refs.creditDetailModal.open();
            },
            getCreditNoteDetail() {
                this.editCreditDetail = false;
                const hostUrl = this.$config.aimsAPI;
                const documentNumber = this.$route.params.documentNumber;

                this.$http.get(`${hostUrl}documents/${parseInt(documentNumber)}/creditNoteDetail`).then(
                    (response) => {
                        if (response.data) {
                            this.cnd = response.data;
                        }
                    },
                    (error) => {
                        this.showError("Error fetching Purchase Order", error);
                        console.error(error);
                    }
                );
            },
            saveCreditNoteDetail() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.put(`${hostUrl}documents/creditNoteDetail/save`, this.cnd).then(
                    (response) => {
                        this.showSuccess("Credit Note Detail Updated");
                        this.getCreditNoteDetail();
                    },
                    (error) => {
                        this.showError("Error fetching Purchase Order", error);
                        console.error(error);
                    }
                );
            },
            getDocumentClass: function(document) {
                let docClass = "";
                switch (document.type) {
                case "INVOICE":
                    docClass = "badge bg-green";
                    break;
                case "INVOICE_RESELLER":
                    docClass = "badge bg-warning";
                    break;
                case "CREDITNOTE":
                    docClass = "badge bg-danger";
                    break;
                default:
                    docClass = "badge bg-success";
                }
                return docClass;
            },
            saveDocument: function() {
                const hostUrl = this.$config.aimsAPI;

                this.$http.put(`${hostUrl}documents/update`, this.documentBody).then(
                    (response) => {
                        this.showSuccess("Successfully updated");
                        this.$emit("documentReload");
                    },
                    (error) => {
                        this.showError("Error Updating", error);
                        console.error(error);
                    }
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
.open-invoice {
    color: orange;
}

.processed-invoice {
    color: green;
}
</style>