<template>
  <div>
    <div class="mb-3" v-if="searchFields && searchFields.length">
      <input type="text" class="form-control" v-if="productClassifications" 
        v-model="productListState.searchInput" :placeholder="'Search ' + searchFields.join(', ').replace('.', ' ')"
      />
      <input type="text" class="form-control" v-else v-model="searchInput" 
        :placeholder="'Search ' + searchFields.join(', ').replace('.', ' ')"
      />
    </div>
    <div class="row" v-if="productClassifications">
      <div class="col-lg-12">
        <div class="row">
          <div class="form-group col-md-2">
            <v-select
              :options="productClassifications.classificationTypes"
              v-model="productListState.selectedClassifications.classificationType"
              clearable
              placeholder="Select Type"
            ></v-select>
          </div>
          <div class="form-group col-md-2">
            <v-select
              :options="productClassifications.classificationSuppliers"
              v-model="productListState.selectedClassifications.classificationSupplier"
              clearable
              placeholder="Select Supplier"
            ></v-select>
          </div>
          <div class="form-group col-md-2">
            <v-select
              :options="productClassifications.classificationProviders"
              v-model="productListState.selectedClassifications.classificationProvider"
              clearable
              placeholder="Select Provider"
            ></v-select>
          </div>
          <div class="form-group col-md-2">
            <v-select
              :options="productClassifications.classificationSegments"
              v-model="productListState.selectedClassifications.classificationSegment"
              clearable
              placeholder="Select Segment"
            ></v-select>
          </div>
          <div class="form-group col-md-2">
            <v-select
              :options="productClassifications.classificationSubSegments"
              v-model="productListState.selectedClassifications.classificationSubsegment"
              clearable
              placeholder="Select Subsegment"
            ></v-select>
          </div>
          <div class="form-group col-md-2">
            <v-select
              :options="['true', 'false']"
              v-model="productListState.selectedClassifications.forSale"
              clearable
              placeholder="Select For Sale"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive table-bordered" v-if="filteredRows.length > 0">
      <table :class="getTableClass()">
        <thead>
          <tr>
            <th v-for="(heading, index) in headings" :key="index">
              {{ heading }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(dataRow, rowIndex) in filteredRows"
            :key="rowIndex"
            @click="rowClicked(dataRow)"
          >
            <td
              :class="dataKey.name == 'created' ? 'text-nowrap' : ''"
              v-for="(dataKey, colIndex) in dataKeys"
              :key="colIndex"
            >
              <span :class="dataKey.cssclass">{{
                getKeyValue(dataRow, dataKey.name)
              }}</span>
              <span v-if="dataKey.type === 'date'" :class="dataKey.cssclass">{{
                getKeyValue(dataRow, dataKey.name) | formatDate
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info col-sm-12" v-else>
      {{ noItemsMessage ? noItemsMessage : "No items to display" }}
    </div>
  </div>
</template>

<script>
import { ProductListState } from '@/stores/pages/Admin/ProductList.ts';

export default {
  props: {
    headings: {
      type: Array,
      required: true,
    },
    dataKeys: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: false,
    },
    clickable: {
      type: Boolean,
      required: false,
    },
    searchFields: {
      type: Array,
      required: false,
    },
    noItemsMessage: {
      type: String,
      required: false,
    },
    productClassifications: {
      type: Object,
      required: false,
    },
  },
  data() {
    const productListState = ProductListState();
    return {
      productListState,
      searchInput: "",
    };
  },
  methods: {
    getKeyValue: function (dataRow, keyName) {
      const keyList = keyName.split(".");

      if (keyList.length > 2) {
        console.error(
          "Only 2 levels supported. This is a simple table after all"
        );
      }

      if (keyList.length == 1) {
        if (keyName === "shapedPoolNumber") {
          return dataRow["shapedpool"].name;
        } else {
          return dataRow[keyName];
        }
      }

      if (keyList.length == 2) {
        return dataRow[keyList[0]][keyList[1]];
      }
    },
    rowClicked: function (dataRow) {
      this.$emit("rowClicked", dataRow);
    },
    getTableClass: function () {
      var tableClass = "table";

      if (this.clickable) {
        tableClass += " table-hover";
      }
      return tableClass;
    },
  },
  computed: {
    filteredRows: function () {
      const searchedData = this.data.filter((dataRow) => {
        if (this.searchFields && this.searchFields.length > 0) {
          for (var f = 0; f < this.searchFields.length; f++) {
            if (this.productClassifications) {
              if (
                (this.getKeyValue(dataRow, this.searchFields[f]) + "")
                  .toLowerCase()
                  .includes(this.productListState.searchInput.toLowerCase())
              ) {
                return true;
              }
            } else {
              if (
                (this.getKeyValue(dataRow, this.searchFields[f]) + "")
                  .toLowerCase()
                  .includes(this.searchInput.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        } else {
          return true;
        }
      });

      if (this.productClassifications) {
        const keys = Object.keys(this.productListState.selectedClassifications);
        if (keys.length > 0) {
          return searchedData.filter((dataRow) => {
            let key;
            let addRow = true;
            for (key of keys) {
              if (addRow && this.productListState.selectedClassifications[key].length > 0) {
                addRow =
                  dataRow[key].toString() == this.productListState.selectedClassifications[key];
              }
            }
            return addRow;
          });
        }
      }
      return searchedData;
    },
  },
};
</script>
