<template>
    <service-screen-layout :parts="parts">
        <template v-slot:domain>
            <WebHostingDomain 
                :domain="saleDefinition.sale.domain"
                :portal="saleDefinition.details.portal"
                @saveSale="saveSale"
                v-if="saleDefinition.sale.domain !== undefined"
            />
        </template>
        <template v-slot:domain-alias>
            <WebHostingDomainAlias 
                :domain="saleDefinition.sale.domain"
            />
        </template>
        <template v-slot:mailboxes>
            <WebHostingMailboxes 
                :domain="saleDefinition.sale.domain"
                :zimbraPackages="saleDefinition.meta.domain.children.mailboxes.children.classOfService.partMeta.options"
                @saveSale="saveSale"
            />
        </template>
        <template v-slot:email-alias>
            <WebHostingEmailAlias 
            :domain="saleDefinition.sale.domain"
            />
        </template>
        <template v-slot:website>
            <div class="col-md-12">
                <div class="row row-spacing">
                    <div class="col-md-6">
                        <label>Website Name</label>
                        <input type="text" class="form-control" v-model="saleDefinition.sale.website.websiteName" disabled>
                    </div>
                    <div class="col-md-3">
                        <WebHostingPackage 
                            :webHostingPackage="saleDefinition.sale.website.webHostingPackage"
                        />
                    </div>
                    <div class="col-md-3">
                        <label>Server</label>
                        <input type="text" class="form-control" v-model="webserver" disabled>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div class="col-md-6">
                        <WebHostingWebsiteAliases 
                            :website="saleDefinition.sale.website"
                            :newWebsiteAlias="saleDefinition.meta.website.children.websiteAliases.objMeta.new"
                            @loadSaleDefinition="loadSaleDefinition"
                        />
                    </div>
                    <div class="col-md-6">
                        <WebHostingWebsiteAdmins 
                            v-if="saleDefinition.sale.website.webHostingPackage.enableFtp==true"
                            :website="saleDefinition.sale.website"
                            :newWebsiteAdmin="saleDefinition.meta.website.children.websiteAdmins.objMeta.new"
                            @loadSaleDefinition="loadSaleDefinition"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:sql>
            <div v-if="saleDefinition.sale.website.webHostingPackage.enableSql==true">
                <WebHostingDatabase 
                :newDatabase="saleDefinition.meta.database.objMeta.new"
                :saleDefinition="saleDefinition"
                @loadSaleDefinition="loadSaleDefinition"
            />
            </div>
            <div v-else>
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <p>No databases available for this product</p>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:php>
            <div v-if="saleDefinition.sale.website.webHostingPackage.enablePHP==true">
                <div class="row">
                    <div class="col-md-2">
                        <label>PHP Enabled</label>
                        <select
                            v-model="saleDefinition.sale.website.webHostingPackage.enablePHP"
                            class="form-control" disabled>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                          </select>
                    </div>
                    <div class="col-md-2">
                        <label>PHP Version</label>
                        <v-select
                         :options="phpVersions"
                         v-model="saleDefinition.sale.website.PhpVersion"
                         :disabled="(!isAdminUser && !isAuthorizedUser)"
                         ></v-select>
                    </div>
                    <div class="col-md-2">
                          <label class="col-md-12">&nbsp;</label>
                          <button type="button" @click="saveSale()" class="btn btn-success">Save</button>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="row row-spacing">
                    <div class="col-md-12">
                        <p>PHP settings are not available on this Package</p>
                    </div>
                </div>
            </div>
        </template>
    </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

import WebHostingDomain from "@/components/ObjParts/SingleCaptured/WebHostingDomain";
import WebHostingDomainAlias from "@/components/ObjParts/MultipleLoaded/WebHostingDomainAlias";
import WebHostingMailboxes from "@/components/ObjParts/MultipleCaptured/WebHostingMailboxes";
import WebHostingEmailAlias from "@/components/ObjParts/MultipleLoaded/WebHostingEmailAlias";
import WebHostingWebsiteAdmins from "@/components/ObjParts/MultipleCaptured/WebHostingWebsiteAdmins";
import WebHostingWebsiteAliases from "@/components/ObjParts/MultipleCaptured/WebHostingWebsiteAliases";
import WebHostingPackage from "@/components/ObjParts/Selected/WebHostingPackage";
import WebHostingDatabase from "@/components/ObjParts/OptionalCaptured/WebHostingDatabase";

export default {
    extends: SaleFunctions,
    components: {
        ServiceScreenLayout,
        WebHostingDomain,
        WebHostingDomainAlias,
        WebHostingMailboxes,
        WebHostingEmailAlias,
        WebHostingWebsiteAdmins,
        WebHostingWebsiteAliases,
        WebHostingPackage,
        WebHostingDatabase
    },
    data() {
        return {
            parts: [
                {
                    name: "domain",
                    icon: "fa-solid fa-at",
                    displayName: "Domain",
                    permissions: ["*"],
                },
                {
                    name: "domain-alias",
                    icon: "fa-solid fa-cloud",
                    displayName: "Domain Alias",
                    permissions: ["*"],
                },
                {
                    name: "mailboxes",
                    icon: "fa-solid fa-boxes-stacked",
                    displayName: "Mailboxes",
                    permissions: ["*"],
                },
                {
                    name: "email-alias",
                    icon: "fa-solid fa-envelope",
                    displayName: "Email Alias",
                    permissions: ["*"],
                },
                {
                    name: "website",
                    icon: "fa-solid fa-globe",
                    displayName: "Website",
                    permissions: ["*"],
                },
                {
                    name: "sql",
                    icon: "fa-solid fa-database",
                    displayName: "Database",
                    permissions: ["*"],
                },
                {
                    name: "php",
                    icon: "fa-solid fa-gear",
                    displayName: "PHP",
                    permissions: ["*"],
                }
            ],
            saleDefinition: {
                sale: {
                    domain: {
                        domainAliases: [],
                        mailboxes: [],
                        accountAliases: []
                    },
                    website: {
                        websiteAdmins: [],
                        websiteAliases: [],
                        websitePhp: {},
                        webHostingPackage: {}
                    },
                    sql: {
                        sqlUser: {}
                    }
                },
                details: {
                    portal: {}
                },
                meta: {
                    website: {
                        children: {
                            websiteAdmins: {
                                objMeta: {
                                    new: {

                                    }
                                }
                            },
                            websiteAliases: {
                                objMeta: {
                                    new: {

                                    }
                                }
                            },
                            websitePhp: {
                                objMeta: {
                                    new: {}
                                }
                            }
                        }
                    },
                    domain: {
                        children: {
                            mailboxes: {
                                children: {
                                    classOfService: {
                                        partMeta: {
                                            options: []
                                        }
                                    }
                                }
                            }
                        }
                    },
                    sql: {
                        objMeta: {
                            new: {

                            },
                            enums: {
                                type: [],
                            }
                        },
                        children: {
                            sqlUser: {

                            }
                        }
                    }
                }
            },
            databaseTypes: [],
            phpVersions: ["5.6","7.4","8.2"],
            webserver: "web1.adept.co.za"
        }
    },
    created(){
        this.loadSaleDefinition();
    },
    computed: {
        isAdminUser() {
        return this.userContainsPermission(['ADMIN']);
      },
      isAuthorizedUser() {
        return this.userContainsPermission(['STAFF']);
      }    
    },
    methods: {
        loadSaleDefinition: function() {
            const saleNumber = this.$route.params.saleNumber;
            this.fetchSaleDefinition(saleNumber).then(  
                (response) => {
                    this.saleDefinition = response.data;     
                    console.log(this.saleDefinition.sale.domain);            
                },
                (error) => {
                    console.error(error);
                    this.showError("Could not load Sale Definition", error);
                }
            );
        },
        saveSale: function() {         
            const hostUrl = this.$config.aimsAPI;
            const saleNumber = this.saleDefinition.sale.saleNumber;

            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                () => {
                    this.showSuccess("Save Success");
                    this.loadSaleDefinition();
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Saving SaleDefinition", error);
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>

</style>