<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <service-wizard-layout
          :title="''"
          :subtitle="''"
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="crossconnect">
            <form novalidate data-vv-scope="crossconnect">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.dataCentre'), }">
                    <label>Data Centre</label>
                    <v-select
                      :options="filledInSaleDefinition.selectableOptions.datacentre"
                      label="description"
                      valueProp="dataCentreNumber"
                      name="dataCentre"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.crossconnect.dataCentreNumber"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('crossconnect.dataCentre')">{{ errors.first("crossconnect.dataCentre") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.description'), }">
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="Description"
                      placeholder="Description"
                      v-model="filledInSaleDefinition.requiredObjs.crossconnect.description"
                    />
                    <span class="has-error" v-if="errors.has('crossconnect.description')">{{ errors.first("crossconnect.description") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.soNumber'), }">
                    <label>Service Order Number</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="Service Order Number"
                      placeholder="Service Order Number"
                      v-model="filledInSaleDefinition.requiredObjs.crossconnect.soNumber"
                    />
                    <span class="has-error" v-if="errors.has('crossconnect.soNumber')">{{ errors.first("crossconnect.soNumber") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.serviceID'), }">
                    <label>Service ID</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="Service ID"
                      placeholder="Service ID"
                      v-model="filledInSaleDefinition.requiredObjs.crossconnect.serviceID"
                    />
                    <span class="has-error" v-if="errors.has('crossconnect.serviceID')">{{ errors.first("crossconnect.serviceID") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.remoteEnd'), }">
                    <label>Remote End</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="Remote End"
                      placeholder="Remote End"
                      v-model="filledInSaleDefinition.requiredObjs.crossconnect.remoteEnd"
                    />
                    <span class="has-error" v-if="errors.has('crossconnect.remoteEnd')">{{ errors.first("crossconnect.remoteEnd") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.type'), }">
                    <label>Rack Size</label>
                    <v-select
                      :options="filledInSaleDefinition.supportingEnums.crossconnect.type"
                      name="Type"
                      v-validate="'required'"
                      placeholder="Type"
                      v-model="filledInSaleDefinition.requiredObjs.crossconnect.type"
                      label="description"
                      valueProp="name"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('crossconnect.type')">{{ errors.first("crossconnect.type") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.nrc'), }">
                    <label>Non Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        v-validate="'required'"
                        name="Non Recurring Cost (Excl. VAT)"
                        placeholder="Non Recurring Cost (Excl. VAT)"
                        v-model="filledInSaleDefinition.requiredObjs.crossconnect.nrcExclVat"
                      />
                    </div>
                    <span class="has-error" v-if="errors.has('crossconnect.nrc')">{{ errors.first("crossconnect.nrc") }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.mrc'), }">
                    <label>Monthly Recurring Cost (Excl. VAT)</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">R</span>
                      </div>
                      <input
                        type="number"
                        class="form-control"
                        v-validate="'required'"
                        name="Monthly Recurring Cost (Excl. VAT)"
                        placeholder="Monthly Recurring Cost (Excl. VAT)"
                        v-model="filledInSaleDefinition.requiredObjs.crossconnect.mrcExclVat"
                      />
                    </div>
                    <span class="has-error" v-if="errors.has('crossconnect.mrc')">{{ errors.first("crossconnect.mrc") }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" :class="{ 'has-error': errors.has('crossconnect.termExpirationDate'), }">
                    <label>Term Expiration Date</label>
                    <div class="input-group">
                        <DateInput 
                            type="date" 
                            v-model="filledInSaleDefinition.requiredObjs.crossconnect.termExpirationDate"
                            format="YYYY-MM-DD"
                            :placeholder="'Term Expiration Date'"
                            style="width: 100%"
                            ref="date"
                            name="Term Expiration Date"
                            v-validate="'required'"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <span class="has-error" v-if="errors.has('crossconnect.termExpirationDate')">{{ errors.first("crossconnect.termExpirationDate") }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import DateInput from "@/components/GeneralComponents/InputControls/DateInput.vue";

export default {
  components: {
    ServiceWizardLayout,
    DateInput,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "crossconnect",
          displayName: "CrossConnect",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        supportingEnums: {
          crossconnect: {
            type: []
          }
        },
        requiredObjs: {
          crossconnect: {},
        },
        selectableOptions: {
          datacentre: []
        }
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
