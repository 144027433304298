<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Data Centre:</label>
            <div class="col-md-6">
              <v-select
                :options="dataCentres"
                placeholder="Select Data Centre"
                @input="getData"
                v-model="selectedDataCentre"
                label="description"
                valueProp="name"></v-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-2 col-form-label">Month:</label>
            <div class="col-md-6">
              <v-select
                :options="periods"
                placeholder="Select Period"
                v-model="selectedPeriod"
                :get-option-label="getLabel"
                @input="getPowerEntries"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="selectedPeriod">
        <div class="col-md-12">
          <div class="card card-default">
            <div class="card-body">
              <div class="table-responsive mb-5" v-if="powerEntries.length > 0 || addingEntry">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Power Service ID</th>
                      <th>Cost on invoice</th>
                      <th>kWh Used</th>
                      <th>Price ex VAT</th>
                      <th v-if="selectedPeriod >= period"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="powerEntry in powerEntries"
                      :key="powerEntry.cabinetPowerEntryNumber"
                    >
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          :value="powerBillingServiceId(powerEntry.cabinetPowerBillingNumber)"
                          disabled
                        />
                      </td>
                      <td>
                        <div class="input-group" :class="entryCostDiff(powerEntry) ? 'changedValue' : ''">
                          <div class="input-group-prepend">
                            <span class="input-group-text">R</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="powerEntry.costExclVat"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          :value="decimalFormat(powerEntry.kwhConsumed)"
                          disabled
                        />
                      </td>
                      <td>
                        {{ currencyFormat(powerEntry.priceExclVat)}}
                      </td>
                      <td
                        v-if="selectedPeriod >= period && powerEntry.objKey > 0"
                      >
                        <button
                          v-tooltip="'Delete Entry'"
                          @click="deleteEntry(powerEntry)"
                          type="button"
                          class="btn btn-sm btn-primary"
                        >
                          <em class="fa fa-trash fa-fw"></em>
                        </button>
                      </td>
                    </tr>

                    <tr v-if="addingEntry">
                      <td>
                        <select
                          class="form-control"
                          v-model="newEntry.cabinetPowerBillingNumber"
                        >
                          <option
                            v-for="powerBilling in filteredCabinetPowerBilling"
                            :key="powerBilling.cabinetPowerBillingNumber"
                            :value="powerBilling.cabinetPowerBillingNumber"
                          >
                            {{ powerBilling.cabinetrental.powerServiceID }}
                          </option>
                        </select>
                      </td>
                      <td>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">R</span>
                          </div>
                          <input
                            type="number"
                            class="form-control"
                            v-model="newEntry.costExclVat"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          class="form-control"
                          :value="decimalFormat(newEntry.kwhConsumed)"
                          disabled
                        />
                      </td>
                      <td>
                        {{ currencyFormat(newEntry.priceExclVat)}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row row-spacing" v-if="selectedPeriod >= period">
                <div class="col-md-12">
                  <button
                    type="button"
                    class="btn btn-info mr-2"
                    @click="addEntry()"
                    v-if="filteredCabinetPowerBilling.length != 0 && !addingEntry"
                  >
                    Add Entry
                  </button>
                  <button
                    type="button"
                    @click="saveEntries()"
                    class="btn btn-primary"
                    v-if="entriesChanged || (newEntry.cabinetPowerBillingNumber && newEntry.costExclVat)"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newEntry: {},
      addingEntry: false,
      dataCentres: [],
      periods: [],
      cabinetPowerBilling: [],
      cabinetRental: [],
      powerEntries: [],
      backupEntries: [],
      period: this.decreaseBillingPeriod(this.getCurrentPeriod()),
      selectedDataCentre: null,
      selectedPeriod: null,
      completeEntry: true,
    };
  },
  computed: {
    filteredCabinetPowerBilling() {
      var res = this.cabinetPowerBilling;

      for (let i = 0; i < this.powerEntries.length; i++) {
        res = res.filter(service => {
          return service.cabinetPowerBillingNumber !== this.powerEntries[i].cabinetPowerBillingNumber;
        })
      }

      return res;
    },  
    entriesChanged() {
      for (let i = 0; i < this.backupEntries.length; i++) {
        if (this.powerEntries[i].costExclVat+"" !== this.backupEntries[i].costExclVat+"") {
          return true
        }
      }
      return false;
    },  
  },
  created() {
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  mounted() {
    this.$http.get(this.$config.aimsAPI + "system/datacentres").then(
      (response) => {
        this.dataCentres = response.data;
      },
      (error) => {
        this.showError("Error fetching Data Centers", error);
        console.error(error);
      }
    );
  },
  methods: {
    getData(dataCentre) {
      this.selectedPeriod = 0;
      this.$http.get(this.$config.aimsAPI + "cabinetpower/" + dataCentre).then(
        (response) => {
          this.cabinetPowerBilling = response.data;
        },
        (error) => {
          this.showError("Error fetching Cabinet Power Billing", error);
          console.error(error);
        }
      );

      let periods = [];
      this.$http
        .get(this.$config.aimsAPI + "cabinetpower/periods/" + dataCentre)
        .then(
          (response) => {
            periods = periods.concat(response.data);
            this.getFuturePeriods(periods);
          },
          (error) => {
            this.showError(
              "Error fetching periods for Cabinet Power Billing",
              error
            );
            console.error(error);
          }
        );
    },
    getFuturePeriods(periods) {
      this.$http
        .get(
          this.$config.aimsAPI +
            "system/billingperiods?max=3&period=" +
            Math.max(...periods, this.decreaseBillingPeriod(this.period))
        )
        .then(
          (response) => {
            this.periods = periods.concat(response.data);
          },
          (error) => {
            this.showError(
              "Error fetching future periods for Cabinet Power Billing",
              error
            );
            console.error(error);
          }
        );
    },
    getPowerEntries(period) {
      this.addingEntry = false;
      this.newEntry = {};
      
      this.$http
        .get(
          this.$config.aimsAPI +
            "cabinetpower/powerentries?period=" +
            period +
            "&dataCentre=" +
            this.selectedDataCentre
        )
        .then(
          (response) => {
            this.backupEntries = JSON.parse(JSON.stringify(response.data));
            this.powerEntries = response.data;
          },
          (error) => {
            this.showError("Error fetching Cabinet Power Entries", error);
            console.error(error);
          }
        );
    },
    addEntry() {
      this.addingEntry = true;

      this.$http
        .get(
          this.$config.aimsAPI +
            "cabinetpower/empty/powerentry/" +
            this.selectedPeriod
        )
        .then(
          (response) => {
            this.newEntry = response.data;
          },
          (error) => {
            this.showError("Error fetching empty Cabinet Power Entry", error);
            console.error(error);
          }
        );
    },
    saveEntries() {
      if (this.addingEntry) {
        this.powerEntries.push(this.newEntry);
        this.addingEntry = false;
        this.newEntry = {};
      }
      
      this.$http
        .post(
          this.$config.aimsAPI + "cabinetpower/powerentries/save",
          this.powerEntries
        )
        .then(
          (response) => {
            this.getPowerEntries(this.selectedPeriod);
            this.showSuccess("Successfully updated Cabinet Power Entries.");
          },
          (error) => {
            this.showError("Error updating Cabinet Power Entries", error);
            console.error(error);
          }
        );
    },
    deleteEntry(powerEntry) {
      this.$swal({
        title: "Are you sure you want to delete Cabinet Power Entry?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(
              this.$config.aimsAPI +
                "cabinetpower/powerentries/" +
                powerEntry.objKey
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.getPowerEntries(this.selectedPeriod);
              },
              (error) => {
                this.showError("Error Deleting Cabinet Power Entry", error);
                console.error(error);
              }
            );
        }
      });
    },
    costExVat(powerentry) {
      return this.$options.filters.currencyFormat(powerentry.costExVat);
    },
    priceExVat(powerentry) {
       return this.$options.filters.currencyFormat(powerentry.priceExclVat);
    },
    getLabel(period) {
      return this.prettifyBillingPeriod(period);
    },
    powerBillingServiceId(cpbn) {
      for (let i = 0; i < this.cabinetPowerBilling.length; i++) {
        if (this.cabinetPowerBilling[i].cabinetPowerBillingNumber == cpbn) {
          return this.cabinetPowerBilling[i].cabinetrental.powerServiceID;
        }
      }
    },
    entryCostDiff(powerEntry) {
      for (let i = 0; i < this.backupEntries.length; i++) {
        if (this.backupEntries[i].cabinetPowerEntryNumber === powerEntry.cabinetPowerEntryNumber) {
          if (this.backupEntries[i].costExclVat+"" !== powerEntry.costExclVat+"") {
            return true
          }
        }
      }
      return false;
    }
  },
};
</script>

<style>
.changedValue input {
  border-color: #ee0000;
}

</style>