<template>
    <WidgetLayout
      :heading="workOrderWigetHeading"
      :icon="'fa fa-bookmark-o'"
      :showFooterButtons="false"
    >
      <div class="table-responsive">
          <table class="table table-hover" v-if="filteredWidgetWorkOrders.length > 0">
            <thead class="text-left">
              <tr>
                <th>Type</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="workorder in filteredWidgetWorkOrders"
                :key="workorder.workOrderNumber"
                @click="goToWorkOrderDetail(workorder.workOrderNumber)" v-tooltip="`View ${workorder.workOrderType}`"
              >
                <td>
                  <small>{{ workorder.workOrderType }}</small>
                </td>
                <td>
                  <small>{{ workorder.description }}</small>
                </td>
                <td>
                  <span :class="getStatusBadge(workorder.status)">{{
                    workorder.status
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else><span>No active {{ workOrderWigetHeading }}</span></div>
        </div>
    </WidgetLayout>
</template>

<script>
import WidgetLayout from './WidgetLayout';
export default {
  components: {
    WidgetLayout
  },
  data() {
    return {
      workorders: [],
      filteredWorkOrders: [],
    };
  },
  computed: {
    workOrderWigetHeading() {
      if (this.userContainsPermission(["STAFF"])) {
        return 'Quotes / Work Orders';
      } else {
         return 'Work Orders';
      }
    },
    filteredWidgetWorkOrders() {
      if (this.userContainsPermission(["CLIENT"])) {
        this.filteredWorkOrders = this.workorders;    
        this.filteredWorkOrders = this.filteredWorkOrders.filter((workOrder) => workOrder.workOrderType != 'Quote' && workOrder.status != 'OPEN')
      } else {
        return this.workorders;
      }
      return this.filteredWorkOrders;
    }
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(
        this.$config.aimsAPI + "dashboards/client/workorders/" + clientNumber
      )
      .then(
        (response) => {
          this.workorders = response.data;
        },
        (error) => {
          this.showError("Error fetching Work Orders", error);
          console.error(error);
        }
      );
  },
  methods: {
    goToWorkOrderDetail: function(data) {
        this.$router.push({
              name: 'workorderdetails',
              params: { workOrderNumber: data },
        })
    },
    getStatusBadge(status) {
      var badge = "badge badge-success";
      if (status == "OPEN") {
        badge = "badge bg-green";
      }
      if (status == "CANCELLED") {
        badge = "badge bg-red text-white";
      }
      return badge;
    },
  },
};
</script>

<style scoped>
.table-responsive {
  height: 255px !important;
}
</style>