<template>
  <div class="row">
    <div class="col-xl-8 col-lg-12">
      <div class="card card-default">
        <service-wizard-layout
          title="Connectivity"
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="line">
            <form novalidate data-vv-scope="line">
              <div
                class="form-group col-md-8"
                :class="{ 'has-error': errors.has('line.circuitNumber') }"
              >
                <label>Circuit Number</label>
                <input
                  v-validate="{regex: /^[A-Z0-9]+(-[A-Z0-9]+)*$/}"
                  placeholder="Circuit Number"
                  name="circuitNumber"
                  class="form-control"
                  v-model="filledInSaleDefinition.requiredObjs.line.circuitNumber"
                />
                <span
                  class="has-error"
                  v-if="errors.has('line.circuitNumber')"
                  >Circuit numbers may only contain capital letters, numbers and hyphens seperating them</span
                >
              </div>
            </form>
          </template>

          
          <template slot="address">
            <div class="row">
              <Address
                class="col-xl-12"
                @addressChanged="lineAddressChanged"
                @addressValid="lineAddressValidChanged"
                :addressStructure="
                  filledInSaleDefinition.requiredObjs.lineAddress
                "
                :addressTypes="
                  filledInSaleDefinition.supportingEnums.lineAddress.addressType
                "
              />
            </div>
          </template>

          <template slot="account">
            <form novalidate data-vv-scope="account-selection">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.Speed'),
                    }"
                  >
                    <label class="control-label">Package *</label>
                    <div class="input-group">
                      <v-select
                        @input="getPackage"
                        ref="speed"
                        name="Speed"
                        v-validate="'verify_selected'"
                        placeholder="Select Package"
                        label="description"
                        :options="filledInSaleDefinition.selectableOptions.connectivityPackage"
                      ></v-select>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.Speed')"
                      >{{ errors.first("account-selection.Speed") }}</span
                    >
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('account-selection.Network'),
                    }"
                  >
                    <label class="control-label">Network *</label>
                    <div class="input-group">
                      <v-select
                        v-validate="'required'"
                        ref="networkgroup"
                        class="mb-1"
                        name="Network"
                        label="description"
                        valueProp="networkGroupNumber"
                        placeholder="Select Network"
                        v-model="
                          filledInSaleDefinition.requiredObjs.pppoe
                            .networkGroupNumber
                        "
                        :options="
                          filledInSaleDefinition.selectableOptions.networkGroup
                        "
                      ></v-select>
                    </div>
                    <span
                      class="has-error"
                      v-if="errors.has('account-selection.Network')"
                      >{{ errors.first("account-selection.Network") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
    <WizardCheckout 
      ref="wizardCheckoutModal"
      :description="packageSelected.description"
      :prices="packageSelected.selectedPrice"
      @checkout="finalize"
    />
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Address from "@/components/GeneralComponents/Address";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import WizardCheckout from '@/components/ObjParts/MultipleLoaded/WizardCheckout'

export default {
  components: {
    Address,
    WizardCheckout,
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "line",
          displayName: "Line",
          icon: "fa fa-plug",
        },
        {
          name: "address",
          displayName: "Line Address",
          icon: "fa fa-link",
        },
        {
          name: "account",
          displayName: "Account Options",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        selectableOptions: {
          connectivityPackage: [],
          networkGroup: [],
        },
        supportingEnums: {
          lineAddress: {
            addressType: [],
          },
        },
        requiredObjs: {
          pppoe: {},
          lineAddress: {
            addressType: [],
          },
          line: {}
        },
      },
      packageSelected: {
        description: "",
        selectedPrice: []
      },
      shapedPools: []
    };
  },
  created() {
    this.$validator.extend(
      "verify_selected",
      (value) => {
        return {
          valid:
            value ||
            (this.packageSelected && this.packageSelected.connectivityPackageNumber),
          data: {
            required: true,
          },
        };
      },
      {
        computesRequired: true,
      }
    );
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;    
    const productNumber = this.filledInSaleDefinition.product.productNumber;
    this.$http
      .get(this.$config.aimsAPI + "shapedpools?productNumber=" + productNumber)
      .then(
        (response) => {
          this.shapedPools = response.body;
        },
        (error) => {
          this.showError("Error fetching Shaped Pools", error);
          console.error(error);
        }
      );
  },
  methods: {
    getShapedPools: function(option) {
      if (this.shapedPools.length > 0) {
        return `[${this.shapedPools.find(sp => sp.shapedPoolNumber === option.shapedPoolNumber).name}]`;
      }
      return "";
    },
    getConnectivityPackagePrice(connectivityPackageNumber) {
      return this.$http.get(
        this.$config.aimsAPI +
          "pricing/connectivitypackages/" +
          connectivityPackageNumber
      );
    },
    async getPackage(packageSelected) {
      if (packageSelected.connectivityPackageNumber) {
        this.packageSelected = packageSelected;
        this.getConnectivityPackagePrice(
          packageSelected.connectivityPackageNumber
        ).then(
          (success) => {
            this.packageSelected.selectedPrice = success.data;
          },
          (error) => {
            this.showError("Error fetching Price for package", error);
            console.error(error);
          }
        );
      }
    },
    validateStep(stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      if (this.filledInSaleDefinition.requiredObjs.uncappedService) {
        this.filledInSaleDefinition.requiredObjs.uncappedService.connectivityPackageNumber =
          this.packageSelected.connectivityPackageNumber;
      } else {
        this.filledInSaleDefinition.requiredObjs.uncappedInternetService.connectivityPackageNumber =
          this.packageSelected.connectivityPackageNumber;
        this.filledInSaleDefinition.requiredObjs.pppoe.profile = "INTERNET";
      }
      this.$refs.wizardCheckoutModal.open();
    },
    finalize(){
      this.$emit('createSale', this.filledInSaleDefinition);
    },
    lineAddressChanged(address) {
      this.filledInSaleDefinition.requiredObjs.lineAddress = address;
    },
    lineAddressValidChanged(isValid) {
      this.lineAddressValid = isValid;
    },
  },
};
</script>