<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          :title="''"
          :subtitle="''"
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="cabinetpower">
            <form novalidate data-vv-scope="cabinetpower">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('cabinetpower.cabinet'),
                    }"
                  >
                    <label>Cabinet</label>
                    <v-select
                      :options="
                        filledInSaleDefinition.selectableOptions.cabinetRental
                      "
                      placeholder="Select Cabinet Rental"
                      label="cabinetLabel"
                      valueProp="cabinetRentalNumber"
                      name="cabinet"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.cabinetPowerBilling
                          .cabinetRentalNumber
                      "
                    ></v-select>
                    <span
                      class="has-error"
                      v-if="errors.has('cabinetpower.cabinet')"
                      >{{ errors.first("cabinetpower.cabinet") }}</span
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('cabinetpower.billing'),
                    }"
                  >
                    <label>Package</label>
                    <v-select
                      :options="
                        filledInSaleDefinition.selectableOptions.cabinetPowerPackage
                      "
                      placeholder="Select Package"
                      label="description"
                      valueProp="cabinetPowerBillingPackageNumber"
                      name="billing"
                      v-validate="'required'"
                      v-model="
                        filledInSaleDefinition.requiredObjs.cabinetPowerBilling
                          .cabinetPowerBillingPackageNumber
                      "
                    ></v-select>
                    <span
                      class="has-error"
                      v-if="errors.has('cabinetpower.billing')"
                      >{{ errors.first("cabinetpower.billing") }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "cabinetpower",
          displayName: "Cabinet Power Billing",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          cabinetPowerBilling: {},
        },
        selectableOptions: {
          cabinetRental: [],
        },
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      const cabinet =
        this.filledInSaleDefinition.selectableOptions.cabinetRental.find(
          (c) =>
            c.cabinetRentalNumber ==
            this.filledInSaleDefinition.requiredObjs.cabinetPowerBilling
              .cabinetRentalNumber
        );
      this.$swal({
        title: "Add power billing for cabinet?",
        text: cabinet.dataCentre,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        }
      });
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
