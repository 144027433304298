<template>
  <modal :title="newPricePackage.virtualServerPackageNumber ? 'Edit Package' : 'Add New Package'" 
   ref="newPriceModal"
   @save="newPricePackage.virtualServerPackageNumber ? editPackage() : createPackage()">
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Description
        </label>
        <input type="text" class="form-control" disabled v-model="newPricePackage.description">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Label
        </label>
        <input type="text" class="form-control" v-model="newPricePackage.label">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Virtual CPUs
        </label>
        <input type="text" class="form-control" v-model="newPricePackage.virtualCPUs">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Virtual RAM
        </label>
        <div class="input-group">
          <input type="text" v-model="newPricePackage.virtualRam" class="form-control">
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Disk Space
        </label>
        <div class="input-group">
          <input type="text" v-model="newPricePackage.diskSpace" class="form-control">
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-10">
        <label class="control-label">
          Data Bundle Size
        </label>
        <div class="input-group">
          <input type="text" v-model="newPricePackage.dataBundleSize" class="form-control">
          <div class="input-group-append">
            <span class="input-group-text">GB</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="form-group col-md-10"
        v-if="newPricePackage.virtualServerPackageNumber"
      >
        <label class="control-label">Expired</label>
        <v-select :options="['true', 'false']" v-model="newPricePackage.expired" placeholder="false"></v-select>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from '@/components/GeneralComponents/Modal'
  export default {
    props: {
      newPricePackage: {
        type: Object,
        required: true
      },
    },
    components: {
      Modal
    },
    methods: {
      open() {
        this.$refs.newPriceModal.open();
      },
      createPackage() {
        this.$refs.newPriceModal.isLoading();
        this.newPricePackage.virtualRam = parseFloat(this.sizeGbToBytes(this.newPricePackage.virtualRam));
        this.newPricePackage.diskSpace = parseFloat(this.sizeGbToBytes(this.newPricePackage.diskSpace));
        this.newPricePackage.dataBundleSize = parseFloat(this.sizeGbToBytes(this.newPricePackage.dataBundleSize));

        this.$http.post(this.$config.aimsAPI + 'pricing/virtualserverpackages/create', this.newPricePackage)
          .then(response => {
              this.$refs.newPriceModal.close();
              this.showSuccess('Successfully Created Package');
              this.$emit("packageAdded", response.data);
          }, error => {
              this.showError("Error Saving Pricing", error);
              console.error(error);
          }).finally(() => {
            this.$refs.newPriceModal.isLoading();
          });
      },
      editPackage() {
        this.$refs.newPriceModal.isLoading();
        this.newPricePackage.virtualRam = parseFloat(this.sizeGbToBytes(this.newPricePackage.virtualRam));
        this.newPricePackage.diskSpace = parseFloat(this.sizeGbToBytes(this.newPricePackage.diskSpace));
        this.newPricePackage.dataBundleSize = parseFloat(this.sizeGbToBytes(this.newPricePackage.dataBundleSize));
        
        this.$http.post(this.$config.aimsAPI + 'pricing/virtualserverpackages/update', this.newPricePackage)
          .then(response => {
              this.$refs.newPriceModal.close();
              this.showSuccess('Successfully Updated Package');
              this.$emit("packageEdited", response.data);
          }, error => {
              this.showError("Error Saving Pricing", error);
              console.error(error);
          }).finally(() => {
            this.$refs.newPriceModal.isLoading();
          });
      }
    }
  }

</script>

<style>

</style>
