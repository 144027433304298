<template>
  <modal
    :title="modalTitle"
    ref="addNewAddon"
    @save="saveNewFttbAddon(newFttbAddon)"
    :disable-save="!inputValueCheck"
  >
    <div class="row">
      <div class="form-group col-md-12">
        <label>Connectivity Package</label>
        <select
          class="form-control"
          v-model="newFttbAddon.connectivityPackageNumber"
        >
          <option
            :value="fttbConnectivityPackage.connectivityPackageNumber"
            v-for="fttbConnectivityPackage in addonConnectivityPackages"
            :key="fttbConnectivityPackage.connectivityPackageNumber"
          >
            {{ fttbConnectivityPackage.description }}({{
              speedBitsToMbits(fttbConnectivityPackage.downloadSpeed)
            }}/{{ speedBitsToMbits(fttbConnectivityPackage.uploadSpeed) }})
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12">
        <label>Add-on Name</label>
        <input class="form-control" v-model="newFttbAddon.name"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12">
        <label>VLAN Name</label>
        <input class="form-control" v-model="newFttbAddon.vlanName"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12">
        <label>VLAN ID</label>
        <input class="form-control" v-model="newFttbAddon.vlanId"/>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  components: {
    Modal,
  },
  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    addonConnectivityPackages: {
      type: Array,
      required: true,
    },
    fttbAddon: {
      type: Object,
      required: true,
    },
    fttbAddons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newFttbAddon: {
          name: '',
          vlanName: '',
          vlanId: '',
          connectivityPackageNumber: null,
      },
      inputValues: {},
    };
  },
  computed: {
    inputValueCheck() {
      if (this.newFttbAddon.name && 
      this.newFttbAddon.vlanName && 
      this.newFttbAddon.vlanId && 
      this.newFttbAddon.connectivityPackageNumber) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    open: function () {
      this.$refs.addNewAddon.open();
      this.newFttbAddon = {
        class: "za.co.adept.aims.fibre.business.components.addons.FttbAddon",
        type: "MULTIPLE_CAPTURED",
      };
    },

    saveNewFttbAddon: function () {
      this.$emit("addFttbAddon", this.newFttbAddon);
      this.showSuccess("Fttb Addon successfully created");
      this.$refs.addNewAddon.close();
    },
    speedBitsToMbits(value) {
      if (value && value > 0) {
        return value / 1000000;
      } else {
        return 0;
      }
    },
  },
};
</script>
