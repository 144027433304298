<template>
    <div class="card-body">
        <table class="table">
            <tbody>
                <SettingTreeTableRow
                    v-for="item in data"
                    :key="item.name"
                    :item="item"
                    :fieldNames="fieldNames"
                    :expandedRows="expandedRows"
                    @toggle="toggleRow"
                    @edit="editSetting"
                />
            </tbody>
        </table>
    </div>
</template>

<script>
import SettingTreeTableRow from '@/components/GeneralComponents/SettingTreeTableRow';

export default {
    components: {
        SettingTreeTableRow
    },
    props: {
        fieldNames: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            expandedRows: []
        };
    },
    methods: {
        handleRowClick(item) {
            const index = this.expandedRows.indexOf(item);
            if (index > -1) {
                this.expandedRows.splice(index, 1);
            } else {
                this.expandedRows.push(item);
            }
        },
        toggleRow(item) {
            this.handleRowClick(item);
        },
        editSetting(item){
            this.$emit('edit', item);
        }
    }
}
</script>

<style>
.treeIcon {
    margin-left: 1em;
    margin-right: 1em;
}
.itemIcon {
    margin-left: -1em;
    margin-right: -1em;
}
.card-body {
    overflow-x: auto;
}
</style>
