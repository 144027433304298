<template>
  <div>
    <LoginLayout
      :loginHeading="'Enter 2FA Code'"
      :primaryButtonText="'Login'"
      :loginErrorMessage="loginError.message"
      :showErrorMessage="loginError.show"
      :showSwitchUser="true"
      :logUserIn="logUserIn"
      :customImage="qrcode"
      @submit="loginToServicePanel"
      @switchUser="switchUser"
    >
      <form class="mt-3" @submit.prevent="loginToServicePanel()" @keyup.enter="loginToServicePanel()" autocomplete="off">
        <div class="row row-spacing">
          <h4 class="col-12 text-center mb-3">OTP</h4>
          <div class="col-12">
            <div class="input-group mb-3">
              <input type="text" class="form-control input-bg text-center" ref="otpInputA" placeholder="_" v-model="otpA" @keyup="focusNext($event, 'B', 'A')" >
              <input type="text" class="form-control input-bg text-center" ref="otpInputB" placeholder="_" v-model="otpB" @keyup="focusNext($event, 'C', 'A')">
              <input type="text" class="form-control input-bg text-center" ref="otpInputC" placeholder="_" v-model="otpC" @keyup="focusNext($event, 'D', 'B')">
              <input type="text" class="form-control input-bg text-center" ref="otpInputD" placeholder="_" v-model="otpD" @keyup="focusNext($event, 'E', 'C')">
              <input type="text" class="form-control input-bg text-center" ref="otpInputE" placeholder="_" v-model="otpE" @keyup="focusNext($event, 'F', 'D')">
              <input type="text" class="form-control input-bg text-center" ref="otpInputF" placeholder="_" v-model="otpF" @keyup="focusNext($event, 'F', 'E')">
            </div>
          </div>
        </div>
      </form>
    </LoginLayout>
  </div>
</template>

<script>
import LoginLayout from '@/layouts/LoginLayout';
  export default {
    components: {
      LoginLayout
    },
    data() {
      return {
        loginCredentials: {
          userName: "",
          password: "",
          otp: null
        },
        loginError: {
          message: "",
          show: false
        },
        qrcode: '',
        logUserIn: false,
        otpStatus: '',

        otpA: "",
        otpB: "",
        otpC: "",
        otpD: "",
        otpE: "",
        otpF: "",
      }
    },
    mounted() {
      document.title = "Login | Service Panel"
      const redirectToken = this.$route.query.token

      this.loginCredentials.userName = atob(this.$localStorage.get("uli"));
      this.loginCredentials.password = atob(this.$localStorage.get("pli"));
      this.otpStatus = this.$localStorage.get("otp");

      if (this.otpStatus == "TOTP_ONBOARD") {
          this.qrcode = this.$localStorage.get("qrc");
      }
      
      if (redirectToken) {
        const redirectDetail = atob(redirectToken);
        const redirectDetails = redirectDetail.split(":");
        this.$localStorage.set("temp-token", redirectDetails[0]);
        
        this.$localStorage.remove("uli");

        this.goToSale(redirectDetails[1]);
      } else {
        this.fromRoute = this.$route.params.fromRoute;

        if (this.$route.query.tokenIssue === "1") {
          this.loginError.message = "You have been logged out due to an Authentication issue, please try logging in again.";
          this.loginError.show = true;
        } else {
          const storedToken = this.$localStorage.get("aims-token");
          if (storedToken) {
            this.goToServicePanelDashboard();
          }
        }
      }
      
      this.$refs.otpInputA.focus();
    },
    methods: {
      loginToServicePanel: function() {
        this.loginError.show = false;
        this.logUserIn = true;

        this.$http.post(this.$config.aimsAPIv2 + "login", this.loginCredentials).then(
          (response) => {
            this.logUserIn = false;

            if (response.body.status == "SUCCESSFUL") {

              this.$localStorage.set("aims-token", response.body.token);
              this.$localStorage.set("uli", btoa(this.loginCredentials.userName));

              this.$localStorage.remove("pli");
              this.$localStorage.remove("otp");
              this.$localStorage.remove("qrc");

              if (this.$localStorage.get("OG_URL")) {
                this.$router.replace(this.$localStorage.get("OG_URL"));
              } else {
                this.$router.replace({
                  name: "dashboard",
                });
              }

            }
          },
          (response) => {
            if (response.status === 0) {
              response.data = {
                message: "Could not contact server"
              };
            }
            
            this.loginError.message = response.data.message;
            this.loginError.show = true;
            this.logUserIn = false;
          }
        );
      },
      switchUser: function() {
        this.$router.replace({
          name: "login",
        })
      },
      goToServicePanelDashboard: function() {
        this.$router.replace({
          name: "dashboard",
        })
      },
      goToSale: function(saleNumber) {
        this.initAuth();
        this.$router.replace({
          name: "search",
          query: {
            saleNumber: saleNumber,
          }
        })
      },
      focusNext(event, next, prev) {
        if (next && prev) {
          if (event.key === 'Backspace') {
            this.$refs[`otpInput${prev}`].focus();
          } else if (prev == 'E') {
            this.loginCredentials.otp = this.otpA + this.otpB + this.otpC + this.otpD + this.otpE + this.otpF;
            this.loginToServicePanel()
          } else {
            this.$refs[`otpInput${next}`].focus();
          }
        }
      },
    },
  }
</script>
<style scoped>
  .input-bg {
    background: #ffffff;
  }
</style>
