<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-default">
          <div class="card-header">
            <h4 class="card-title">Sale Document History</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive table-bordered">
              <table class="table table-hover">
                <thead>
                  <tr class="dark-row">
                    <th>Document Number</th>
                    <th>Document Note</th>
                    <th>Period</th>
                    <th>Type</th>
                    <th>Line Description</th>
                    <th>Created</th>
                    <th>Amount VAT Excl.</th>
                    <th>VAT Amount</th>
                    <th>Amount VAT Incl.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr @click="showBillingBreakdown(documentLine)" v-for="documentLine in documentLines"
                    :key="documentLine.documentLineNumber">
                    <td>{{documentLine.document.documentNumber}}</td>
                    <td>{{documentLine.document.note}}</td>
                    <td>{{documentLine.document.billingPeriod}}</td>
                    <td><span :class="getDocumentClass(documentLine.document)">{{documentLine.document.type}}</span>
                    </td>
                    <td>{{documentLine.description}}</td>
                    <td class="text-nowrap">{{documentLine.created}}</td>
                    <td class="text-nowrap">{{currencyFormat(documentLine.transactionAmount - documentLine.vatAmount)}}</td>
                    <td class="text-nowrap">{{currencyFormat(documentLine.vatAmount)}}</td>
                    <td class="text-nowrap">{{currencyFormat(documentLine.transactionAmount)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DocumentLineBreakdown ref="billingBreakdownModal" />
  </div>
</template>

<script>
  import DocumentLineBreakdown from '@/components/Documents/DocumentLineBreakdown'

  export default {
    components: {
      DocumentLineBreakdown
    },
    created() {
      var saleNumber = this.$route.params.saleNumber;
      if (typeof saleNumber == 'string') {
        saleNumber = parseInt(saleNumber);
      }
      this.saleNumber = saleNumber;

      this.$http.get(this.$config.aimsAPI + 'sales/' + this.saleNumber)
        .then(response => {
          this.sale = response.data;
        }, error => {
          console.error(error);
          this.showError("Error Fetching Document and Lines", error);
        });
    },
    data() {
      return {
        sale: null,
        documentLines: []
      }
    },
    mounted: function () {
      this.getDocumentLineHistory();
    },
    methods: {
      getDocumentLineHistory: function () {
        this.$http.get(this.$config.aimsAPI + "sales/" + this.saleNumber + "/documentlines").then(
          response => {
            this.documentLines = response.data;            
          },
          error => {
            console.error(error);
            this.showError("Error Fetching Document Lines", error);
          });
      },
      getDocumentClass(document) {
        var docClass = '';
        switch (document.type) {
          case 'INVOICE':
            docClass = 'badge bg-green';
            break;
          case 'INVOICE_RESELLER':
            docClass = 'badge bg-warning';
            break;
          case 'CREDITNOTE':
            docClass = 'badge bg-danger';
            break;
          default:
            docClass = 'badge bg-success';
        }
        return docClass;
      },
      showBillingBreakdown(documentLine) {
        this.$refs.billingBreakdownModal.open(documentLine.documentLineNumber);
      }
    }
  }

</script>

<style lang="scss" scoped>
.dark-row {
  th {
    white-space: nowrap;
  }
}
</style>
