<template>
    <modal :title="this.modalTitle + ' Certificate Issue'" ref="addCertificateIssue" :size="'modal-lg'" @save="saveCertificateIssue(newIssue)" @close="close">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{ 'has-error': errors.has('Issuer') }">
                            <label>Issuer</label>
                            <v-select
                                :options="issuers"
                                v-model="newIssue.issuer"
                                label="description"
                                valueProp="name"
                                name="Issuer"
                                :class="{ 'has-error': errors.has('Issuer') }"
                                v-validate="'required'"
                                class="mb-1"
                                ref="issuerInput"
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('Issuer')">{{ errors.first("Issuer") }}</small>
                        </div>
                    </div>
                    <div class="col-md-6" :class="{ 'has-error': errors.has('Product') }">
                        <div class="form-group">
                            <label>Product</label>
                            <v-select
                                :options="products"
                                v-model="newIssue.product"
                                label="description"
                                valueProp="name"
                                name="Product"
                                :class="{ 'has-error': errors.has('Product') }"
                                v-validate="'required'"
                                class="mb-1"
                                ref="productInput"
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('Product')">{{ errors.first("Product") }}</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{ 'has-error': errors.has('Issue date') }">
                            <label>Issue date</label>
                            <div class="input-group">
                                <DateInput 
                                    type="date" 
                                    v-model="newIssue.issueDate"
                                    name="Issue date"
                                    :class="{ 'has-error': errors.has('Issue date') }"
                                    v-validate="'required'"
                                    format="YYYY-MM-DD"
                                    :placeholder="'Select issue date'"
                                    style="width: 100%"
                                    :disabled="disabled"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                </div>
                            </div>
                            <small class="has-error mt-1" v-if="errors.has('Issue date')">{{ errors.first("Issue date") }}</small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" :class="{ 'has-error': errors.has('Expiration date') }">
                            <label>Expiration date</label>
                            <div class="input-group">
                                <DateInput 
                                    type="date" 
                                    v-model="newIssue.expirationDate"
                                    name="Expiration date"
                                    :class="{ 'has-error': errors.has('Expiration date') }"
                                    v-validate="'required'"
                                    format="YYYY-MM-DD"
                                    :placeholder="'Select expiration date'"
                                    style="width: 100%"
                                    :disabled="disabled"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                </div>
                            </div>
                            <small class="has-error mt-1" v-if="errors.has('Expiration date')">{{ errors.first("Expiration date") }}</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{ 'has-error': errors.has('Price Excl. VAT') }">
                            <label>Price Excl. VAT</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">R</span>
                                </div>
                                <input 
                                    type="number" 
                                    class="form-control" 
                                    v-model="newIssue.priceExVat"
                                    name="Price Excl. VAT"
                                    :class="{ 'has-error': errors.has('Price Excl. VAT') }"
                                    v-validate="'required'"
                                />
                            </div>
                            <small class="has-error mt-1" v-if="errors.has('Price Excl. VAT')">{{ errors.first("Price Excl. VAT") }}</small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Billing month</label>
                            <input type="number" class="form-control" v-model="newIssue.billingMonth" disabled />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group" :class="{ 'has-error': errors.has('Certificate') }">
                            <label>Certificate</label>
                            <textarea 
                                class="form-control" 
                                cols="15" rows="5" 
                                v-model="newIssue.certificate"
                                name="Certificate"
                                :class="{ 'has-error': errors.has('Certificate') }"
                                v-validate="'required'"
                            >
                            </textarea>
                            <small class="has-error mt-1" v-if="errors.has('Certificate')">{{ errors.first("Certificate") }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DateInput from "../../GeneralComponents/InputControls/DateInput.vue";

export default {
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        newIssue: {
            type: Object,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        issuers: {
            type: Array,
            required: true
        }
    },
    components : {
        Modal,
        DateInput
    },
    data() {
        return {
            disabled: false,
        }
    },
    watch: {
        newIssue(val) {         
            val.sslCertificateIssueNumber > 0 ? this.disabled = true : this.disabled = false;
        }
    },
    methods: {
        open: function() {
            this.$refs.addCertificateIssue.open();
        },
        close: function() {
        },
        saveCertificateIssue: function(newIssue) {            
            if (this.modalTitle == 'Edit') {
                this.$validator.validateAll().then((valid) => {
                    if (valid) {
                        this.$emit("updateCertificateIssue", newIssue);  
                    }
                });
            } else {
                this.$validator.validateAll().then((valid) => {
                    if (valid) {
                        this.$emit("createCertificateIssue", newIssue);
                    }
                });
            }
        },
    },
}
</script>
