<template>
  <div class="row row-spacing">
    <div class="col-md-12">
      <div
        class="table-responsive table-bordered"
        v-if="searchResults.length > 0"
      >
        <table class="table table-hover">
          <thead>
            <tr class="text-nowrap">
              <th>Type</th>
              <th>Matched</th>
              <th>Quick Info</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="viewResult(searchResult)"
              v-for="(searchResult, index) in searchResults"
              :key="index"
              :class="{
                'search-result-error': Object.keys(
                  searchResult.metaData
                ).includes('Error'),
              }"
            >
              <td class="text-nowrap">
                <i :class="getTypeIcon(searchResult.type)"></i
                ><span> {{ searchResult.label }}</span>
              </td>
              <td>{{ searchResult.result }}</td>
              <td>
                <div
                  v-for="metaKey in Object.keys(searchResult.metaData).filter(
                    (metaKey) => !metaKey.includes('_')
                  )"
                  :key="metaKey"
                >
                  <small
                    ><b>{{ metaKey }}:</b>
                    {{ searchResult.metaData[metaKey] }}</small
                  >
                </div>
              </td>
              <td>
                <div class="dropdown" v-if="availableTypes(searchResult.type)">
                  <a
                    href="#"
                    class="btn btn-info dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    id="dropdownMenuLink"
                    @click="toggleDropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Options
                  </a>
                  <div class="dropdown-menu">
                    <a
                      class="dropdown-item text-dark"
                      @click="goTo(option, searchResult)"
                      v-for="option in options[searchResult.type.toLowerCase()]"
                      :key="option.name"
                      >{{ option.name }}</a
                    >
                  </div>
                </div>
                <a
                  v-if="searchResult.type == 'Sale'"
                  href="javascript:void(0)"
                  @click="goTo('clientdetail', searchResult)"
                  >{{ searchResult.metaData.Client }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>No Results...</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchResults: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getTypeIcon: () => (type) => {
      switch (type) {
        case "Sale":
          return "fa fa-money";
        case "Client":
        case "Reseller":
          return "fa fa fa-building-o";
        case "Person":
          return "fa fa-user";
        case "StaffUser":
          return "fa fa-user-secret";
        case "WorkOrder":
          return "fa fa-briefcase";
        case "PublicIpv4ReverseZone":
        case "PublicIpv6ReverseZone":
        case "PublicIpv4ReverseRecord":
        case "PublicIpv6ReverseRecord":
          return "fa-solid fa-globe";
        default:
          return "fa fa-search";
      }
    },
  },
  data() {
    return {
      options: {
        client: [
          {
            name: "Client",
            routeName: "clientdetail",
          },
          {
            name: "Services",
            routeName: "clientsales",
          },
          {
            name: "Invoices",
            routeName: "clientdocuments",
          },
          {
            name: "Statement",
            routeName: "clientstatement",
          },
          {
            name: "Correspondence",
            routeName: "clientcorrespondence",
          },
          {
            name: "Workorders",
            routeName: "clientworkorders",
          },
        ],
      },
    };
  },
  methods: {
    availableTypes(type) {
      type = type.toLowerCase();
      if (this.options[type]) {
        return true;
      } else {
        return false;
      }
    },
    goTo(option, result) {
      switch (result.type) {
        case "Client":
          this.$router.push({
            name: option.routeName,
            params: {
              clientNumber: result.key,
            },
          });
          break;
        case "Sale":
          this.$router.push({
            name: option,
            params: {
              clientNumber: result.metaData._clientNumber,
            },
          });
          break;
        default:
          this.showWarning(result.type + " type is not available yet.");
          break;
      }
    },
    toggleDropdown(e) {
      $(e.target).dropdown("toggle");
      e.preventDefault();
      e.stopPropagation();
    },
    viewResult(result) {
      if (Object.keys(result.metaData).includes("Error")) {
        this.showWarning(
          "An error occurred while loading this search result",
          result.metaData.Error
        );
        return;
      }
      this.$emit("search-item-clicked", result);
    },
  },
};
</script>
