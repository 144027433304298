<template>
  <div class="form-group">
    <div class="row col-md-10 p-3">
      <button
        class="btn btn-info"
        @click="addSimsModal()"
        type="button"
        v-allowed:view="['STAFF']"
      >
        Add SIM
      </button>

      <div class="col-md-6">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search MSISDN, Serial, Description, IP Address" v-model="searchTerm">
          <div class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </div>
    </div>

    <div class="card table-responsive">
      <table class="table table-hover table-bordered">
        <thead>
        <tr>
          <th @click="sortBy('username')">MSISDN
            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'username' && reverse"></i>
            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'username' && !reverse"></i>
          </th>
          <th @click="sortBy('gsmSerialNumber')">Serial Number
            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'gsmSerialNumber' && reverse"></i>
            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'gsmSerialNumber' && !reverse"></i>
          </th>
          <th @click="sortBy('description')">Description
            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
          </th>
          <th @click="sortBy('firstConnected')">First Connected
            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'firstConnected' && reverse"></i>
            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'firstConnected' && !reverse"></i>
          </th>
          <th @click="sortBy('simStatus')">Status
            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'simStatus' && reverse"></i>
            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'simStatus' && !reverse"></i>
          </th>
          <th @click="sortBy('allocatedNetwork.address')">IP Address
            <i class="fa fa-sort-asc float-right" v-if="sortKey == 'allocatedNetwork.address' && reverse"></i>
            <i class="fa fa-sort-desc float-right" v-if="sortKey == 'allocatedNetwork.address' && !reverse"></i>
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody v-if="computedFiltered.length > 0">
          <tr v-for="(account, index) in computedFiltered" :key="index">
            <td>{{ account.username }}</td>
            <td>{{ account.gsmSerialNumber }}</td>
            <td>{{ account.description }}
              <button
                v-allowed:view="['TECHNICAL_STAFF', 'TECHNICAL', 'ADMIN']"
                type="button"
                v-tooltip="'Edit SIM Details'"
                @click="editSimsModalDesc(account)"
                class="btn btn-sm btn float-right"
              >
                <em class="fa fa-edit fa-fw"></em>
              </button>
            </td>
            <td>{{ dateFormat(account.firstConnected) }}</td>
            <td style="text-align:center"><span :class="getStatusBadge(account.simStatus)" v-tooltip="account.simStatus == 'Inactive' ? 'Deactivated on ' + account.deactivationDate.split(' ')[0] : ''">{{ account.simStatus }}</span></td>
            <td>{{ account.allocatedNetwork.address }}</td>
            <td style="text-align:center; width:15%">
              <button
                type="button"
                v-tooltip="'View SIM Information'"
                @click="viewGsmAccount(account)"
                class="btn btn-sm btn-green"
              >
                <em class="fa fa-external-link"></em>
              </button>
              <button
                type="button"
                v-tooltip="'Edit SIM Status'"
                @click="editGsmAccount(account)"
                class="btn btn-sm btn-info"
              >
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button
                v-allowed:view="['STAFF']"
                type="button"
                v-tooltip="'Delete SIM'"
                @click="deleteGsmAccount(account)"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">No active SIMs</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    gsmAccounts: {
      type: Array,
      required: true,
    },
  },
  data() {
        return {
            isLoaded: false,
            accountsFiltered: [],
            sortKey: 'username',
            reverse: false,
            searchTerm: ""
        }
  },
  computed: {
    computedFiltered() {
            this.accountsFiltered = this.gsmAccounts;

            if (this.searchTerm != "x") {
                let searchText = this.searchTerm;
                this.accountsFiltered = this.accountsFiltered.filter((acc) =>
                    acc.username.toLowerCase().includes(searchText.toLowerCase()) ||
                    acc.gsmSerialNumber.toLowerCase().includes(searchText.toLowerCase()) ||
                    acc.description.toLowerCase().includes(searchText.toLowerCase()) ||
                    acc.allocatedNetwork.address.toLowerCase().includes(searchText.toLowerCase())
                );
            }

            if (this.reverse) {
                return _.orderBy(this.accountsFiltered, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.accountsFiltered, this.sortKey, 'desc');
            }
        },
  },
  methods: {
    viewGsmAccount(account) {
      this.$emit("viewSims", account);
    },
    addSimsModal(obj) {
      this.$emit("addNewSim", obj);
    },
    editSimsModalDesc(obj) {
      this.$emit("editSimDesc", obj);
    },
    editSimsModalCED(obj) {
      this.$emit("editSimCED", obj);
    },
    editGsmAccount(obj) {
      this.$emit("editSimStatus", obj);
    },
    deleteGsmAccount(account) {
      this.$swal({
        title: `Are you sure you want to remove ${account.username}?`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$emit("deleteSim", account);
        }
      });
    },
    getStatusBadge: function(status) {
        let badge = "badge bg-green text-white";

        if (status == "Inactive") {
            badge = "badge bg-danger text-white";
        }
        if (status == "Suspended") {
            badge = "badge bg-warning text-white";
        }
        if (status == "None(Select one)") {
            badge = "badge bg-primary text-white";
        }

        return badge;
    },
    sortBy(sortKey) {
      this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
      this.sortKey = sortKey;
    },
  },
};
</script>

<style scoped>
th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.pull-right {
  float: right;
}
</style>