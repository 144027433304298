<template>
  <div>
    <ul class="nav nav-pills nav-fill" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="connectivityPackage-tab"
          data-toggle="tab"
          href="#connectivityPackage"
          role="tab"
          aria-controls="connectivityPackage"
          aria-selected="true"
          ><i class="fa fa-dropbox"></i> Bandwidth Packages</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="networkRangePackage-tab"
          data-toggle="tab"
          href="#networkRangePackage"
          role="tab"
          aria-controls="networkRangePackage"
          aria-selected="true"
          ><i class="fa fa-plus-square"></i> Network Range Packages</a
        >
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="connectivityPackage"
        role="tabpanel"
        aria-labelledby="connectivityPackage-tab"
      >
        <DataCentrePackagePrices
          ref="packages"
          :connectivityPackages="bandwidthPackages"
          :emptyPackage="emptyPackage"
          :productNumber="productNumber"
          @get-connectivity-packages="getBandwidthConnectivityPackages"
          :userEditRight="userEditRight"
        >
          <template>
            <div class="row row-spacing mb-2 justify-content-between">
              <div class="col-md-auto">
                <button
                   v-if="userEditRight"
                  type="button"
                  @click="$refs.packages.addNewPackage()"
                  class="btn btn-info"
                >
                  Add New Package
                </button>
              </div>
            </div>
          </template>
        </DataCentrePackagePrices>
      </div>
      <div
        class="tab-pane"
        id="networkRangePackage"
        role="tabpanel"
        aria-labelledby="networkRangePackage-tab"
      >
        <NetworkRangePackagePrices
          :productNumber="productNumber"
          :packages="networkRangePackages"
          @getNetworkRangePackages="getNetworkRangePackages"
          :userEditRight="userEditRight"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DataCentrePackagePrices from "@/components/Admin/ConnectivityPackages/DataCentrePackagePrices";
import NetworkRangePackagePrices from "@/components/Admin/NetworkRangePackages/NetworkRangePackagePrices";
export default {
  props: {
    productNumber: {
      type: Number,
      required: true,
    },
    userEditRight: {
      type: Boolean,
      required: true
    },
  },
  components: {
    DataCentrePackagePrices,
    NetworkRangePackagePrices,
  },
  data() {
    return {
      bandwidthPackages: [],
      networkRangePackages: [],
      emptyPackage: {}
    };
  },
  mounted() {
    this.getBandwidthConnectivityPackages();
    this.getNetworkRangePackages();
  },
  methods: {
    getBandwidthConnectivityPackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/datacentrepackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.bandwidthPackages = response.data.datacentreinternetpackages;
            this.emptyPackage = this.deepCopyObject(response.data);
          },
          (error) => {
            this.showError("Error fetching Packages", error);
            console.error(error);
          }
        );
    },
    getNetworkRangePackages() {
      this.$http
        .get(
          this.$config.aimsAPI +
            "pricing/networkrangepackages/product/" +
            this.productNumber
        )
        .then(
          (response) => {
            this.networkRangePackages = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Network Range Packages", error);
          }
        );
    },
  },
};
</script>

<style scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #f05050 !important;
}

.nav-pills .nav-link {
  background-color: #f3f2ee;
}

a {
  color: rgba(0, 0, 0, 0.6);
}
</style>
