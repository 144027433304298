<template>
    <div class="card card-default">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label>Billing Month</label>
                        <v-select
                            :options="billingMonths"
                            v-model="selectedBillingMonth"
                            :get-option-label="getLabel"
                            @input="getApnBulkUsage(selectedBillingMonth)"
                        >
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="row" v-if="showUploadButton">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-4">
                                <form class="mb-1">
                                    <input type="file" class="form-control" :class="{'upload-validation' : showValidation}" @change="fileSelected">
                                    <span class="upload-validation-msg">{{ validationMessage }}</span>
                                </form>
                            </div>
                            <div class="col-md-6">
                                <button class="btn btn-success" @click="uploadFile()" :disabled="!isCorrectFileType || isUploading">Upload File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <IonlineUsageCaptureTable 
                    :apnBulkUsage="apnBulkUsage"
                    :isUploading="isUploading"
                />
            </div>
        </div>
    </div>
</template>

<script>
import IonlineUsageCaptureTable from './IonlineUsageCaptureTable.vue';

export default {
    components: {
        IonlineUsageCaptureTable
    },
    data() {
        return {
            currentPeriod: this.getCurrentPeriod(),
            billingMonths: [],
            selectedBillingMonth: 0,
            validationMessage: "",
            apnBulkUsage: [],
            isUploading: false,
            isCorrectFileType: false,
            apnUsageFile: null,
        }
    },
    created() {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    },
    computed: {
        showValidation() {
            return !this.isCorrectFileType && this.apnUsageFile !== null;
        },
        showUploadButton() {              
            return this.selectedBillingMonth === this.billingMonths[0] && this.apnBulkUsage.length === 0;
        },
        showTable() {
            return (this.selectedBillingMonth) || (this.selectedBillingMonth !== this.billingMonths[0]);
        }
    },
    mounted() {
        this.getBillingMonths();          
    },
    methods: {
        getBillingMonths: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}ionline/billingmonths`).then(
                (response) => {
                    this.billingMonths = response.data;                        
                },
                (error) => {
                    console.error(error);
                    this.showError('Error fetching billing months', error);
                }
            );
        },
        getApnBulkUsage: function(billingMonth) {
            const hostUrl = this.$config.aimsAPI;  

            this.$http.get(`${hostUrl}ionline/usage?period=${billingMonth}`).then(
                (response) => {                  
                    this.apnBulkUsage = response.data;                    
                },
                (error) => {
                    console.error(error);
                    this.showError('Error fetching Apn bulk usage', error);
                }
            );  
        },
        getLabel: function(period) {
            return this.prettifyBillingPeriod(period);
        },
        fileSelected: function(file) {      
                this.apnUsageFile = file.target.files[0];
                this.validateFileType(this.apnUsageFile);
        },
        validateFileType: function(uploadedFile) {   
            this.isCorrectFileType = true; 
            this.validationMessage = '';
        },
        uploadFile: function() {
            this.isUploading = true;
            const hostUrl = this.$config.aimsAPI;
            const headers = { headers: {'Content-Type': 'multipart/form-data'} };
            const formData = new FormData();
            formData.append('file', this.apnUsageFile);

            this.$http.post(`${hostUrl}ionline/usage/upload?period=${this.selectedBillingMonth}`, formData, headers).then(
                (response) => {
                    this.showSuccess("File upload success");
                    this.getApnBulkUsage(this.selectedBillingMonth);
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Uploading File", error);
                }
            ).finally(() => {
                this.isUploading = false;
                this.apnUsageFile = null;
                $('input[type=file]').val(null);
            });      
        }
    }
}
</script>

<style lang="scss" scoped>

</style>