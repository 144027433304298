<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template v-slot:virtualserverdetails>
            <form novalidate data-vv-scope="virtualserverdetails">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Name: </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.virtualServer.name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">vLan Name: </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.virtualServer.vlanName"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">vLan Number: </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.virtualServer.vlanNumber"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group"
                    :class="{
                      'has-error': errors.has('virtualserverdetails.UUID'),
                    }">
                    <label class="control-label">UUID: </label>
                    <input
                      type="text"
                      class="form-control"
                      name="UUID"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.virtualServer.uuid"
                    />
                    <span class="has-error" v-if="errors.has('virtualserverdetails.UUID')">{{
                      errors.first("virtualserverdetails.UUID")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:package>
            <form novalidate data-vv-scope="package" v-if="!filledInSaleDefinition.requiredObjs.virtualServerPackage">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group"
                    :class="{
                      'has-error': errors.has('package.Package'),
                    }">
                    <label class="control-label">Package *</label>
                    <v-select
                      name="packSelect"
                      placeholder="Select Package"
                      :options="filledInSaleDefinition.selectableOptions.virtualServerPackage"
                      @input="packageSelected"
                      label="description"
                    ></v-select>
                    <input
                      type="text"
                      class="form-control"
                      name="Package"
                      v-show="false"
                      v-validate="'min_value:1'"
                      v-model="filledInSaleDefinition.requiredObjs.virtualServer.virtualServerPackageNumber"
                    />
                    <span class="has-error" v-if="errors.has('package.Package')">Must select a package</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Virtual RAM</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="sizeBytesToGb(selectedPackage.virtualRam)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Virtual CPUs</label>
                    <input
                      type="text"
                      class="form-control"
                      readonly
                      :value="selectedPackage.virtualCPUs"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Disk Space</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="sizeBytesToGb(selectedPackage.diskSpace)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Data Bundle Size</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        readonly
                        :value="sizeBytesToGb(selectedPackage.dataBundleSize)"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form novalidate data-vv-scope="package" v-else>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Virtual RAM</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.virtualServerPackage.virtualRam"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Virtual CPUs</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.virtualServerPackage.virtualCPUs"
                    />
                  </div>
                  <div class="form-group">
                    <label class="control-label">Disk Space</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.virtualServerPackage.diskSpace"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="filledInSaleDefinition.requiredObjs.virtualServerPackage">
                    <label class="control-label">Data Bundle Size</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.virtualServerPackage.dataBundleSize"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">GB</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:internetaccess>
            <form novalidate data-vv-scope="internetaccess">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group">
                    <label class="control-label">Name: </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="filledInSaleDefinition.requiredObjs.virtualInternetAccess.name"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="form-group"
                    :class="{
                      'has-error': errors.has('internetaccess.Network'),
                    }">
                    <label class="control-label">Network *</label>
                    <v-select
                      v-model="filledInSaleDefinition.requiredObjs.virtualInternetAccess.networkGroupNumber"
                      name="Network"
                      placeholder="Select Network"
                      label="description"
                      v-validate="'min_value:1'"
                      valueProp="networkGroupNumber"
                      :options="networkOptions"
                    ></v-select>
                    <span class="has-error" v-if="errors.has('internetaccess.Network')">Must select a Network Group</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "virtualserverdetails",
          displayName: "Virtual Server Details",
        },
        {
          name: "package",
          displayName: "Virtual Server Package",
        },
        {
          name: "internetaccess",
          displayName: "Virtual Internet Access",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          virtualServer: {},
          virtualInternetAccess: {},
        },
        selectableOptions: {
          virtualServerPackage: [],
        },
      },
      selectedPackage: {},
      networkOptions: [],
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;

    if (this.filledInSaleDefinition.selectableOptions.virtualServerPackage) {
      this.filledInSaleDefinition.selectableOptions.virtualServerPackage = this.filledInSaleDefinition.selectableOptions.virtualServerPackage.filter(pak => pak.productNumber == this.filledInSaleDefinition.product.productNumber);
    }

    this.networkOptions = this.filledInSaleDefinition.selectableOptions.internetNetworkGroup;
  },
  methods: {
    packageSelected(virtualServerPackage) {
      this.filledInSaleDefinition.requiredObjs.virtualServer.virtualServerPackageNumber = virtualServerPackage.virtualServerPackageNumber;
      this.selectedPackage = this.deepCopyObject(virtualServerPackage);
    },
    onComplete() {
      if (this.filledInSaleDefinition.requiredObjs.virtualServerPackage) {
        this.filledInSaleDefinition.requiredObjs.virtualServerPackage.virtualRam = parseFloat(this.sizeGbToBytes(this.filledInSaleDefinition.requiredObjs.virtualServerPackage.virtualRam))
        this.filledInSaleDefinition.requiredObjs.virtualServerPackage.diskSpace = parseFloat(this.sizeGbToBytes(this.filledInSaleDefinition.requiredObjs.virtualServerPackage.diskSpace))
      }
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>
