<template>
  <div>
    <h4>{{ title }}</h4>
    <apexchart
      type="bar"
      :options="options"
      height="500"
      :series="options.series"
    >
  </apexchart>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    usageData: {
      type: [Array, Object],
      required: true,
    },
    showDownloadedOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: this.createDataSets(),
    };
  },
  watch: {
    usageData() {      
      this.options = this.createDataSets();
    },
  },
  methods: {
    createDataSets() {      
      var labels = [];
      var downloadDataList = [];
      var uploadDataList = [];
      var dataUOM = "MB";
      var allDataOverGB = true;
      const oneGig = 1 * 1024 * 1024 * 1024;
      const usageData = this.usageData;

      if (Array.isArray(usageData)) {
        usageData.forEach((d) => {
          labels.push(d.label);
          downloadDataList.push(d.downloaded);
          if (d.downloaded < oneGig && d.downloaded > 0) {
            allDataOverGB = false;
          }
          if (!this.showDownloadedOnly) {
            uploadDataList.push(d.uploaded);
            if (d.uploaded < oneGig && d.uploaded > 0) {
              allDataOverGB = false;
            }
          }
        });
      } else {
        usageData.data.forEach((d) => {
          labels.push(d.label);
          downloadDataList.push(d.downloaded);
          if (d.downloaded < oneGig && d.downloaded > 0) {
            allDataOverGB = false;
          }
          if (!this.showDownloadedOnly) {
            uploadDataList.push(d.uploaded);
            if (d.uploaded < oneGig && d.uploaded > 0) {
              allDataOverGB = false;
            }
          }
        });
      }

      if (allDataOverGB) {
        dataUOM = "GB";
        downloadDataList = downloadDataList.map((d) => {
          return this.sizeBytesToGb(d);
        });
        if (!this.showDownloadedOnly) {
          uploadDataList = uploadDataList.map((d) => {
            return this.sizeBytesToGb(d);
          });
        }
      } else {
        downloadDataList = downloadDataList.map((d) => {
          return this.sizeBytesToMb(d);
        });
        if (!this.showDownloadedOnly) {
          uploadDataList = uploadDataList.map((d) => {
            return this.sizeBytesToMb(d);
          });
        }
      }

      const downloadDataSet = {
        name: "Download (" + dataUOM + ")",
        data: downloadDataList,
      };

      const uploadDataSet = {
        name: "Upload (" + dataUOM + ")",
        data: uploadDataList,
      };

      if (!this.showDownloadedOnly) {
        return {
          chart: {
            id: this.accountNumber,
            events: {
              dataPointSelection: (e, chart, opts) => {
                const val = opts.dataPointIndex + 1;
                if (opts.w.globals.dataPoints > 23) {
                  this.$emit("hourly-usage", val);
                } else if (opts.w.globals.dataPoints < 13) {
                  this.$emit("daily-usage", val);
                }
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: labels,
          },
          series: [downloadDataSet, uploadDataSet],
          tooltip: {
            x: {
              formatter: function (val) {
                if (usageData.tooltip) {
                  return val + " - (" + usageData.tooltip + ")";
                }
                return val + ":00";
              },
            },
          },
        };
      } else {
        return {
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: labels,
          },
          series: [downloadDataSet],
        };
      }
    },
  },
};
</script>
