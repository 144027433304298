<template>
  <service-screen-layout :parts="parts">
    <template v-slot:admin>
      <div class="row row-spacing">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Domain: </label>
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control-plaintext"
                    readonly
                    :value="saleDefinition.sale.domain.domainName"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"
                  >Admin Username:
                </label>
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control-plaintext"
                    readonly
                    :value="getUsername(saleDefinition.sale.domain)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label"
                  >Current Password:
                </label>
                <div class="col-md-7">
                  <PasswordInput
                    :password="saleDefinition.sale.domain.adminPassword"
                    :userCanEdit="true"
                    @changePassword="changePassword"
                    validation="zimbra"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <button
                  class="btn btn-success"
                  @click="saveSale"
                  type="button"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div 
          class="col-md-5" 
          v-if="saleDefinition.details && 
          saleDefinition.details.portal && 
          saleDefinition.details.portal.url.length > 0 && 
          saleDefinition.details.portal.name.length > 0"
        >  
          <PortalButton :portalDetails="saleDefinition.details.portal"/>
        </div>
      </div>
    </template>
    <template v-slot:domainalias>
      <div class="row row-spacing">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-10">
              <table
                class="table table-bordered"
                v-if="
                  saleDefinition.sale.domain.domainAliases.length > 0
                "
              >
                <thead>
                  <tr>
                    <th>Domain Alias</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="domainAlias in saleDefinition.sale.domain
                      .domainAliases"
                    :key="domainAlias.zimbraDomainAliasNumber"
                  >
                    <td>{{ domainAlias.domainName }}</td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="alert alert-info">
                No Domain Aliases to display
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:mailboxes>
      <div class="row row-spacing">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-10">
              <table
                class="table table-bordered"
                v-if="saleDefinition.sale.domain.mailboxes.length > 0"
              >
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Package</th>
                    <th>Usage</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="mailbox in saleDefinition.sale.domain
                      .mailboxes"
                    :key="mailbox.zimbraMailboxNumber"
                  >
                    <td>{{ mailbox.username }}</td>
                    <td>
                      <v-select
                        :options="
                          saleDefinition.meta.domain.children.mailboxes.children.classOfService.partMeta.options
                        "
                        label="description"
                        v-model="mailbox.classOfService"
                      ></v-select>
                    </td>
                    <td>{{ sizeBytesToMb(mailbox.usage.usage) }} MB</td>
                    <td style="text-align: center">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="saveSale"
                        v-tooltip="'Save Package'"
                      >
                        <em class="fa fa-save fa-fw"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="alert alert-info">
                No Mailboxes to display
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:emailalias>
      <div class="row row-spacing">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-10">
              <table
                class="table table-bordered"
                v-if="
                  saleDefinition.sale.domain.accountAliases.length > 0
                "
              >
                <thead>
                  <tr>
                    <th>Email Alias Username</th>
                    <th>Destination</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="accountAlias in saleDefinition.sale.domain
                      .accountAliases"
                    :key="accountAlias.zimbraMailboxAliasNumber"
                  >
                    <td>{{ accountAlias.username }}</td>
                    <td>
                      <p
                        v-for="alias in aliases(accountAlias.destination)"
                        :key="alias"
                      >
                        {{ alias }}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-else class="alert alert-info">
                No Email Aliases to display
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";
import PortalButton from "@/components/ObjParts/SingleLoaded/PortalButton";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    PasswordInput,
    PortalButton
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  data() {
    return {
      parts: [
        {
          name: "admin",
          icon: "fa fa-info-circle",
          displayName: "Admin",
          permissions: ["*"],
        },
        {
          name: "domainalias",
          icon: "fa fa-cloud",
          displayName: "Domain Alias",
          permissions: ["*"],
        },
        {
          name: "mailboxes",
          icon: "fa fa-envelope",
          displayName: "Mailboxes",
          permissions: ["*"],
        },
        {
          name: "emailalias",
          icon: "fa fa-envelop",
          displayName: "Email Alias",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          domain: {
            domainName: '',
            adminUsername: '',
            adminPassword: '',
            mailboxes: [],
            domainAliases: [],
            accountAliases: [],
          },
        },
        meta: {
          domain: {
            children: {
              mailboxes: {
                children: {
                  classOfService: {
                    partMeta: {
                      options: []
                    }
                  }
                }
              }
            }
          }
        }
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
          console.log(this.saleDefinition);
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
    getUsername({ adminUsername, domainName }) {
      return `${adminUsername}@${domainName}`;
    },
    aliases(aliases) {
      return aliases.split(",");
    },
    changePassword: function(data) {
        if (data) {
          this.saleDefinition.sale.domain.adminPassword = data;
        }
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    sizeBytesToMb(value) {
      if (value && value > 0) {
        return (value / 1024 / 1024).toFixed(2)
      } else {
        return 0;
      }
    }
  },
};
</script>
