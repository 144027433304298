<template>
  <div class="card card-default">
    <div class="card-body">
      <div class="col-md-12">
        <div class="row row-spacing">
          <div class="col-md-4">
            <div class="form-group row">
              <label class="col-sm-4 col-form-label">Month:</label>
              <div class="col-md-8">
                <v-select
                  :options="periods"
                  placeholder="Select Period"
                  v-model="selectedPeriod"
                  @input="getGoogleWorkspaceLicences"
                  :get-option-label="getLabel"
                >
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <hr class="mt-0"/>
        <div class="row row-spacing" v-if="selectedPeriod && (licences.length === 0)">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <h4>Import Google Workspace Billing</h4>
              </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <form class="mb-1">
                      <input type="file" class="form-control" :class="{'upload-validation' : showValidation}" @change="fileSelected">
                    </form>
                    <span class="upload-validation-msg">{{ validationMessage }}</span>
                </div>
                <div class="col-md-8">
                  <button class="btn btn-success" @click="uploadFile()" :disabled="!isCorrectFileType">Upload File</button>
                </div>
              </div>
          </div>
        </div>
        <div class="row row-spacing" v-if="selectedPeriod">
          <div class="col-md-12">
            <div class="card border-light mb-3">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr class="dark-row">
                        <th>Domain</th>
                        <th>Product</th>
                        <th>From</th>
                        <th>Until</th>
                        <th>Qty</th>
                        <th>Unit Price</th>
                        <th>Margin</th>
                        <th>Cost</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody v-if="licences.length > 0">
                      <tr v-for="licence in licences" :key="licence.googleWorkspaceLicenceNumber">
                        <td>
                          {{ getGoogleDomain(licence.googleWorkspaceDomainNumber) }}
                        </td>
                        <td>
                          {{ licence.type }}
                        </td>
                        <td class="date-range-input">
                          {{ licence.validFrom.split(" ")[0] }}
                        </td>
                        <td class="date-range-input">
                          {{ licence.validTo.split(" ")[0] }}
                        </td>
                        <td class="quantity-input">
                          {{ licence.quantity }}
                        </td>
                        <td>
                          {{ currencyFormat(licence.unitPrice) }}
                        </td>
                        <td class="margin-input">
                          {{ licence.margin + " %"}}
                        </td>
                        <td class="cost-input">{{ currencyFormat(cost(licence)) }}</td>
                        <td class="price-input">{{ currencyFormat(amount(licence)) }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="isUploading">
                        <tr>
                            <td colspan="9">
                                <DataLoader 
                                    :loadingText="loadingText"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td colspan="9">No billing entries imported for this period</td>
                      </tr>
                    </tbody>
                    <tfoot v-if="licences.length > 0">
                      <tr class="dark-row-footer text-right">
                        <td colspan="9">Total Cost VAT Excl. <strong>{{ currencyFormat(totalCostExclVat) }}</strong></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataLoader from '@/components/GeneralComponents/DataLoader'

export default {
  components: {
    DataLoader
  },
  data() {
    return {
      periods: [],
      prevPeriod: this.decreaseBillingPeriod(this.getCurrentPeriod()),
      domains: [],
      licences: [],
      licence: {},
      newEntry: {},
      selectedPeriod: null,
      exchangeRate: 0,
      marginPercentage: 0,
      completeEntry: false,
      isNewEntry: false,
      defaultMarginValue: 20,
      googleWorkspaceFile: null,
      isCorrectFileType: false,
      validationMessage: "",
      isUploading: false,
      loadingText: 'Loading Entries...',
      totalCostExclVat: 0,
    };
  },
  watch: {
    newEntry: {
      deep: true,
      handler(entry) {
        this.completeEntry =
          entry.googleWorkspaceDomainNumber > 0 &&
          !entry.type.includes("None") &&
          this.getDay(entry.validFrom) > 0 &&
          this.getDay(entry.validTo) > 0 &&
          entry.quantity > 0 &&
          entry.unitPrice > 0;
      },
    },
  },
  computed: {
    firstLicence() {
      return this.licences.find(Boolean);
    },
    newExchangeRate() {
      return this.exchangeRate !== this.firstLicence.exchangeRate;
    },
    showValidation() {
      return !this.isCorrectFileType && this.googleWorkspaceFile !== null;
    }
  },
  created() {
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
  },
  mounted() {
    this.getGoogleWorkspacePeriods();
    this.getGoogleWorkspaceAvailableDomains(); 
  },
  methods: {
     getGoogleWorkspacePeriods: function() {
      const hostUrl = this.$config.aimsAPI;   
      
      this.$http.get(`${hostUrl}googleworkspace/periods`).then(
        (response) => {
          this.periods = response.data;
            if (!this.periods.includes(this.prevPeriod)) {
              this.periods.push(this.prevPeriod);
            }
        },
        (error) => {
          console.error(error)
          this.showError("Error fetching billing periods for Google Workspace Licences", error)
        },
      );
    },
    getGoogleWorkspaceAvailableDomains: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}googleworkspace/available/domains`).then(
        (response) => {
          this.domains = response.data;
        },
        (error) => {
          console.error(error)
          this.showError("Error fetching available for Google Workspace Licences", error)
        },
      );
    },
    getGoogleWorkspaceLicences: function(period) {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}googleworkspace/licences/${period}`).then(
        (response) => {
          this.licences = response.data;         
          
          this.licences.forEach((licence) => {
            return this.currencyFormat(licence.unitPrice);
          });
          if (this.licences.length > 0) {
            this.exchangeRate = this.firstLicence.exchangeRate;
            this.marginPercentage = this.defaultMarginValue;
          } else {
            this.exchangeRate = this.marginPercentage = 0;
          }
          this.getLicenceTotalCost();
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Google Workspace Licences", error);
        },
      );
    },
    addGoogleWorkspaceEntry: function() {
      const hostUrl = this.$config.aimsAPI;
      const selectedPeriod = this.selectedPeriod;
      const exchangeRate = this.exchangeRate;
      const marginPercentage = this.marginPercentage;

      this.$http.post(`${hostUrl}googleworkspace/licences/add?period=${selectedPeriod}&exchangeRate=${exchangeRate}&margin=${marginPercentage}`).then(
        (response) => {
          this.newEntry = response.data;
          this.licences.push(this.newEntry);
          this.isNewEntry = true;
        },
        (error) => {
          console.error(error);
          this.showError("Error Adding New Google Workspace Licence Entry", error);
        },
      );
    },
    getLabel: function(period) {
      return this.prettifyBillingPeriod(period);
    },
    getDayLabel: function(date) {
      return this.getDay(date);
    },
    amount: function({ unitPrice, quantity }) {
      return unitPrice * quantity;
    },
    cost: function({ unitPrice, quantity, margin }) {
      return (this.amount({ unitPrice, quantity }) - (this.amount({ unitPrice, quantity }) * margin) / 100);
    },
    saveGoogleWorkspaceEntry: function(data) {
      const hostUrl = this.$config.aimsAPI;
      const licences = this.licences;
      const licenceArray = new Array(data)
      if (this.newExchangeRate) {
        this.licences.forEach((licence) => {
          licence.exchangeRate = this.exchangeRate;
        });
      }
      if (licenceArray) {
        this.$http.post(`${hostUrl}googleworkspace/licences/save`, licences).then(
          (response) => {
            this.licences = response.data;
            this.showSuccess("Successfully updated Google Workspace Licence Entry");
            this.isNewEntry = false;
          },
          (error) => {
            console.error(error);
            this.showError("Error saving Google Workspace Licence Entry", error)
          },
        );
      }
    },
    deleteGoogleWorkspaceEntry: function(data) {
      this.$swal({
        title: "Are you sure you want to delete this Googleworkspace Licence entry?",
        text: `${data.googleworkspacedomain.name}: ${data.type}`,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          const hostUrl = this.$config.aimsAPI;
          const objKey = data.objKey;

          this.$http.delete(`${hostUrl}googleworkspace/licences/${objKey}`).then(
            (response) => {
              this.showSuccess(response.data.message);
              this.getGoogleWorkspaceLicences(this.selectedPeriod);
            },
            (error) => {
              console.error(error);
              this.showError("Error Deleting Google Workspace Licence Entry", error);
            },
          );
        }
      });
    },
    getGoogleDomain: function(googleWorkspaceDomainNumber) {
      for (let i = 0; i < this.domains.length; i++) {
        if (this.domains[i].googleWorkspaceDomainNumber == googleWorkspaceDomainNumber) {
            return this.domains[i].name;
        }
      }
      return "n/a"
    },
    fileSelected: function(file) {      
      this.googleWorkspaceFile = file.target.files[0];
      this.validateFileType(this.googleWorkspaceFile);
    },
    validateFileType: function(uploadedFile) {
      if (uploadedFile.name.substring(uploadedFile.name.length-3, uploadedFile.name.length).toLowerCase() === 'csv' && this.googleWorkspaceFile !== null) {
        this.isCorrectFileType = true; 
        this.validationMessage = '';
      } else {
        this.isCorrectFileType = false; 
        this.validationMessage = 'Invalid file type, only files with the following extensions are allowed: .csv'
      }
    },
    uploadFile: function() {
      this.isUploading = true;
      const hostUrl = this.$config.aimsAPI;
      const currentPeriod = this.selectedPeriod;
      const headers = { headers: {'Content-Type': 'multipart/form-data'} };
      const formData = new FormData();
      formData.append('file', this.googleWorkspaceFile);

      this.$http.post(`${hostUrl}googleworkspace/upload/licences?period=${currentPeriod}`, formData, headers).then(
        (response) => {
          this.showSuccess("File upload success");
          this.getGoogleWorkspaceLicences(currentPeriod);
        },
        (error) => {
          console.error(error);
          this.showError("Error Uploading File", error);
        }
      ).finally(() => {
        this.isUploading = false;
        this.googleWorkspaceFile = null;
        $('input[type=file]').val(null);
      })
      ;      
    },
    getLicenceTotalCost: function() {
      const hostUrl = this.$config.aimsAPI;
      const currentPeriod = this.selectedPeriod;

      this.$http.get(`${hostUrl}googleworkspace/licences/total/${currentPeriod}`).then(
        (response) => {
          this.totalCostExclVat = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Total Cost VAT Excl.", error);
        }
      )
    }
  },
};
</script>

<style lang="scss" scoped>
  .upload-validation {
    border: 1px solid #e2231a;
    color: #e2231a;
  }

  .upload-validation-msg {
    color: #e2231a;
    font-size: .9em;
  }

  .table-responsive {
    max-height: calc(90vh - 255px) !important;
  }
  .table {
    .dark-row {
      th {
        background-color: #3a3f51;
        color: #FFFFFF;
        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
      }
    }
    tbody {
      tr {
        td {
          max-width: 100%;
          input {
            text-align: center;
          }
          select {
            option {
              text-align: center;
            }
          }
        }
        .quantity-input, .margin-input, .cost-input, .price-input, .date-range-input {
          width: 7%;
        }
        .input-group .unit-price-input {
          width: 110px !important;
        }
      }
    }
    .dark-row-footer {
      background-color: #3a3f51;
        color: #FFFFFF;
        position: sticky;
        bottom: 0;
        z-index: 2;
        font-size: .95em;
    }
  }
</style>