<template>
    <modal ref="licenceModal" :title="modalTitle" @close="close" @dismiss="dismiss" @save="saveLicence(licence)">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors.has('Start Date') }">
                    <label>Start Date</label>
                    <div class="input-group">
                        <DateInput 
                            type="date" 
                            v-model="licence.startDate"
                            name="Start Date"
                            :class="{ 'has-error': errors.has('Start Date') }"
                            v-validate="'required'"
                            format="YYYY-MM-DD"
                            :placeholder="'Select start date'"
                            style="width: 100%"
                            ref="date"
                            :disabled="isEditing"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <small class="has-error mt-1" v-if="errors.has('Start Date')">{{ errors.first("Start Date") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group" :class="{ 'has-error': errors.has('End Date') }">
                    <label>End Date</label>
                    <div class="input-group">
                        <DateInput 
                            type="date" 
                            v-model="licence.endDate"
                            name="End Date"
                            :class="{ 'has-error': errors.has('End Date') }"
                            v-validate="'required'"
                            format="YYYY-MM-DD"
                            :placeholder="'Select end date'"
                            style="width: 100%"
                            ref="date"
                            :disabled="true"
                        />
                        <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <small class="has-error mt-1" v-if="errors.has('End Date')">{{ errors.first("End Date") }}</small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Quantity</label>
                    <input type="text" class="form-control" v-model="licence.quantity">
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DateInput from "../../GeneralComponents/InputControls/DateInput.vue";

export default {
    components: {
        Modal,
        DateInput
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        licence: {
            type: Object,
            required: true
        },
        isEditing: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        open: function() {
            this.$refs.licenceModal.open();
        },
        close: function() {
            this.clearInputs();
        },
        dismiss: function() {
            this.clearInputs();
        },
        saveLicence: function(licence) {
            this.$validator.validateAll().then((valid) => {
                if (valid) { 
                    this.$emit("saveLicence", licence);
                    this.$refs.licenceModal.close();
                }
            });
        },
        clearInputs: function() {
            this.$refs.date.value = "";
            this.errors.clear();
        }
    },  
}
</script>
