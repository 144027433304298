<template>
  <service-screen-layout :parts="parts">
    <template v-slot:domain>
      <div class="row">
        <div class="col-md-7">
          <div class="form-group d-flex align-items-baseline col-md-10 row-spacing">
            <label class="col-md-3">Name: </label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled
              v-model="saleDefinition.definition.hosting.name"
            />
          </div>
          <div class="form-group d-flex align-items-baseline col-md-10">
            <label class="col-md-3">Minimum TTL: </label>
            <div class="input-group with-focus col-md-5">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.definition.hosting.minimumTtl"
                :disabled="!staffOrClientAccess(productRestriction)"
              />
              <div class="input-group-append" v-if="staffOrClientAccess(productRestriction)">
                <span
                  @click="saveSale()"
                  class="
                    input-group-text
                    btn btn-info
                    text-white
                  "
                >
                  <i class="fa fa-save"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
       <div class="row row-spacing">
          <div class="col-md-12">
            <h4>Default NS Records</h4>
          </div>
        </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <th>Type</th>
              <th>TTL</th>
              <th>Destination</th>
            </thead>
            <tbody>
              <tr
                v-for="host in saleDefinition.definition.hosting.defaultNsHosts"
                :key="host.hostNumber"
              >
                <td>{{ host.type }}</td>
                <td>{{ host.ttl }}</td>
                <td style="word-break: break-word">{{ host.destination }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:hosts>
      <div class="row row-spacing">
        <div class="col-md-4 mb-3">
          <button type="button" @click="addHost()" class="btn btn-info" v-if="staffOrClientAccess(productRestriction)">
            Add Host Record
          </button>
        </div>
        <div class="col-md-4 mb-3">
        
        </div>
        <div class="form-group col-md-4">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Search" v-model="searchTerm">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="table-responsive table-bordered" v-if="saleDefinition.definition.hosting.hosts">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center" v-if="staffOrClientAccess(productRestriction)" style="width: 1em;">
                    <input 
                      type="checkbox"
                      class="checkbox"
                      :checked="deleteAll"
                      @change="addRemoveToGlobal"
                    />
                  </th>
                  <th @click="sortBy('name')">Name
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                  </th>
                  <th @click="sortBy('type')">Type
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'type' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'type' && !reverse"></i>
                  </th>
                  <th @click="sortBy('ttl')">TTL
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ttl' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ttl' && !reverse"></i>
                  </th>
                  <th @click="sortBy('destination')">Destination
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'destination' && reverse"
                    :class="!staffOrClientAccess(productRestriction) ? 'pdr3' : ''"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'destination' && !reverse"
                    :class="!staffOrClientAccess(productRestriction) ? 'pdr3' : ''"></i>
                  </th>
                  <th class="text-center" v-if="staffOrClientAccess(productRestriction)">
                    <TableToFile 
                      v-tooltip="'Download Excel'"
                      :small="true"
                      :fileName="'Hosts'"
                      :fieldProp="{'Name' : 'name', 'Type' : 'type', 'TTL' : 'ttl', 'Destination' : 'destination'}"
                      :dataProp="filteredHostRecords"
                      :propDisable="isDeleting"
                    />
                    <button
                      v-tooltip="'Delete Selected Hosts'"
                      type="button"
                      @click="deleteSelectedHosts()"
                      class="btn btn-sm btn-primary"
                      :disabled="!isDeleting"
                    >
                      <em class="fa fa-trash fa-fw"></em>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(host, index) in filteredHostRecords"
                  :key="host.hostNumber + '-' + index"
                >
                  <td class="text-center">
                    <input 
                        type="checkbox"
                        class="checkbox"
                        :id="host.hostNumber" 
                        :checked="isRowSelected(host.hostNumber)" 
                        @change="(e) => addRemoveToDelete(e, host)"
                      />
                  </td>
                  <td>{{ host.name }}</td>
                  <td>{{ host.type }}</td>
                  <td>{{ host.ttl }}</td>
                  <td class="wrap-text">{{ host.destination }}</td>
                  <td class="text-center" v-if="staffOrClientAccess(productRestriction)">
                    <button
                      v-tooltip="'Edit Host'"
                      type="button"
                      @click="editHost(host)"
                      class="btn btn-sm btn-info m-1"
                      :disabled="isDeleting"
                    >
                      <em class="fa fa-edit fa-fw"></em>
                    </button>
                    <button
                      v-tooltip="'Verify Host'"
                      type="button"
                      @click="verifyHost(host)"
                      class="btn btn-sm btn-green m-1"
                      :disabled="isDeleting"
                    >
                      <em class="fa-solid fa-eye"></em>
                    </button>
                    <button
                      v-tooltip="'Delete Host'"
                      type="button"
                      @click="deleteHost(host)"
                      class="btn btn-sm btn-primary"
                      :disabled="isDeleting"
                    >
                      <em class="fa fa-trash fa-fw"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <modal
        :title="verifyTitle"
        ref="verifyModal"
        :size="host.type == 'TXT' ? 'modal-lg' : host.destination.length > 25 ? 'modal-lg' : 'modal-md'"
        :dismissNeeded="false"
        :saveText="'Close'"
        @save="$refs.verifyModal.close();"
      >
        <form>
          <div style="position:absolute; right:1em; z-index:10" v-if="isStaff">
            <button
              v-tooltip="'Switch View'"
              type="button"
              @click="switchView()"
              class="rotateEffect btn btn-sm btn-warning m-1"
            >
              <em class="fa-solid fa-repeat"></em>
            </button>
          </div>
          <div v-if="!clientView">
            <div class="terminalText">
              <div
                class="row"
                style="text-align:center;"
              >
                <label class="col-md-12">
                  <div class="nsHead">dns1</div>.adept.za.net
                </label>
              </div>
              <div class="row" style="text-align:center" v-for="(res, index) in dns1Result" :key="index+'A'">
                <label class="col-md-12">
                  {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                </label>
              </div>
            </div>
            <br/>

            <div class="terminalText">
              <div
                class="row"
                style="text-align:center;"
              >
                <label class="col-md-12">
                  <div class="nsHead">dns2</div>.adept.za.net
                </label>
              </div>
              <div class="row" style="text-align:center" v-for="(res, index) in dns2Result" :key="index+'B'">
                <label class="col-md-12">
                  {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                </label>
              </div>
            </div>
            <br/>

            <div class="terminalText">
              <div
                class="row"
                style="text-align:center;"
              >
                <label class="col-md-12">
                  <div class="nsHead">dns3</div>.adept.za.net
                </label>
              </div>
              <div class="row" style="text-align:center" v-for="(res, index) in dns3Result" :key="index+'C'">
                <label class="col-md-12">
                  {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                </label>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="terminalText">
              <div
                class="row"
                style="text-align:center;"
              >
                <label class="col-md-12">
                  <div class="nsHead">ns1</div>.adept.co.za
                </label>
              </div>
              <div class="row" style="text-align:center" v-for="(res, index) in ns1Result" :key="index+'A'">
                <label class="col-md-12">
                  {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                </label>
              </div>
            </div>
            <br/>

            <div class="terminalText">
              <div
                class="row"
                style="text-align:center;"
              >
                <label class="col-md-12">
                  <div class="nsHead">ns2</div>.adept.co.za
                </label>
              </div>
              <div class="row" style="text-align:center" v-for="(res, index) in ns2Result" :key="index+'B'">
                <label class="col-md-12">
                  {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                </label>
              </div>
            </div>
            <br/>

            <div class="terminalText">
              <div
                class="row"
                style="text-align:center;"
              >
                <label class="col-md-12">
                  <div class="nsHead">ns3</div>.adept.co.za
                </label>
              </div>
              <div class="row" style="text-align:center" v-for="(res, index) in ns3Result" :key="index+'C'">
                <label class="col-md-12">
                  {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                </label>
              </div>
            </div>
          </div>
        </form>
      </modal>
      <modal
        :title="modalTitle"
        ref="hostModal"
        size="modal-lg"
        @save="saveHost()"
      >
        <form>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Name:</label>
              <div class="input-group col-md-10">
                <input
                  placeholder="Enter Name"
                  :disabled="modalTitle.includes('Edit')"
                  type="text"
                  v-model="host.name"
                  class="form-control"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    >.{{ saleDefinition.definition.hosting.name }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Type:</label>
              <v-select
                :options="availableHostTypes"
                ref="hostType"
                :disabled="modalTitle.includes('Edit')"
                placeholder="Select Type"
                class="col-md-10"
                v-model="host.type"
                valueProp="name"
                label="description"
              ></v-select>

            </div>
          </div>
          <div class="row">
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">TTL:</label>
              <div class="col-md-10">
                <input
                  type="number"
                  min="0"
                  placeholder="Enter TTL"
                  v-model="host.ttl"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <div v-if="host.type == 'SRV'">
            <div class="row">
              <div
                class="
                  form-group
                  col-md-12
                  d-flex
                  align-items-baseline
                  justify-content-center
                "
              >
                <label class="col-md-2">Destination:</label>
                <div class="col-md-10">
                  <input
                    type="text"
                    v-model="host.destination"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div
              class="
                form-group
                col-md-12
                d-flex
                align-items-baseline
                justify-content-center
              "
            >
              <label class="col-md-2">Destination:</label>
              <div class="col-md-10">
                <input
                  type="text"
                  name="host"
                  placeholder="Enter Destination"
                  v-model="host.destination"
                  class="form-control"
                />
                <small v-if="host.type == 'TXT'"
                  >Quotations will be added automatically</small
                >
              </div>
            </div>
          </div>
        </form>
      </modal>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import Modal from "@/components/GeneralComponents/Modal";
import TableToFile from "@/components/GeneralComponents/TableToFile";

import SaleFunctions from "@/pages/Services/SaleFunctions";
export default {
  extends: SaleFunctions,
  components: { ServiceScreenLayout, Modal, TableToFile },
  data() {
    return {
      parts: [
        {
          name: "domain",
          icon: "fa fa-info-circle",
          displayName: "Domain",
          permissions: ["*"],
        },
        {
          name: "hosts",
          icon: "fa fa-cloud",
          displayName: "Hosts",
          permissions: ["*"],
        },
      ],
      saleNumber: this.$route.params.saleNumber,
      saleDefinition: {
        definition: {
          hosting: {},
        },
        product: {

        }
      },
      isStaff: false,
      clientView: false, 
      host: { destination: '' },
      hostingName: '',
      availableHostTypes: [],
      modalTitle: "",
      verifyTitle: "",
      deleteAll: false,
      deletingHosts: [],

      ns1Result: [{ data: "verifying.." }],
      ns2Result: [{ data: "verifying.." }],
      ns3Result: [{ data: "verifying.." }],
      dns1Result: [{ data: "verifying.." }],
      dns2Result: [{ data: "verifying.." }],
      dns3Result: [{ data: "verifying.." }],
      
      searchTerm: "",
      sortKey: 'type',
      reverse: false,
      productRestrictions: [],
      productRestriction: {},
    };
  },
  mounted() {
    if (this.userContainsPermission(["STAFF"])) {
      this.isStaff = true;
    } else {
      this.clientView = true;
    }
    this.loadSale();
  },
  computed: {
    filteredHostRecords() {
      this.hostRecordFiltered = this.saleDefinition.definition.hosting.hosts;

      if (this.searchTerm) {
          this.hostRecordFiltered = this.hostRecordFiltered.filter((host) => 
              host.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              host.type.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
              host.ttl.toString().includes(this.searchTerm) ||
              host.destination.toLowerCase().includes(this.searchTerm.toLowerCase())
          );
      }

      if (this.reverse) {
          return _.orderBy(this.hostRecordFiltered, this.sortKey, 'asc');
      } else {
          return _.orderBy(this.hostRecordFiltered, this.sortKey, 'desc');
      }
    },
    isDeleting() {
      if (this.deletingHosts.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    loadSale() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
          this.hostingName = this.saleDefinition.definition.hosting.name;
          this.getAllProductRestrictions();          
        },
        (error) => {
          this.showError("Could not load Sale Definition", error);
          console.error();
        }
      );
    },
    isRowSelected(data){
      var isDeleting = false;

      for (let i = 0; i < this.deletingHosts.length; i++) {
        if (this.deletingHosts[i].hostNumber == data) {
          isDeleting = true;
        }
      }

      return isDeleting;
    },
    addRemoveToDelete(e, host) {
      if (e.target.checked) {
        this.deletingHosts.push(host);
      } else {
        this.deletingHosts = this.deletingHosts.filter((i) => i.hostNumber != host.hostNumber);
      }
    },
    addRemoveToGlobal(e) {
      if (e.target.checked) {
        this.deletingHosts = [];
        for (let i = 0; i < this.filteredHostRecords.length; i++) {
          this.deletingHosts.push(this.filteredHostRecords[i]);
        }
      } else {
        this.deletingHosts = [];
      }
    },
    deleteSelectedHosts() {

      var tempText = 'Hosts: ';

      for (let i = 0; i < this.deletingHosts.length; i++) {
        tempText += this.deletingHosts[i].name + " [" + this.deletingHosts[i].type + "]";
        if (i != this.deletingHosts.length-1) {
          tempText += ', ';
        }
        
      }

      this.$swal({
        title: 'Delete Selected Hosts',
        text: tempText,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#e2231a',
        cancelButtonColor: '#58b0c2',
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.value) {
          this.$http.post(this.$config.aimsAPI + 'sales/definition/' + this.saleNumber + '/objs/delete', this.deletingHosts)
            .then(
              (response) => {
                this.$swal({
                  type: 'success',
                  title: 'Success',
                  text: "Hosts have been deleted"
                })

                this.deletingHosts = [];

                this.loadSale()
              },(error) => {
                console.error(error);

                this.$swal({
                  type: 'error',
                  title: 'Error',
                  text: "Couldn't delete hosts"
                })
            });
        }
      })
    },
    async verifyDest(nameserver, hostNumber) {
      await this.$http.get(this.$config.aimsAPI + "domains/" + nameserver + "/verify/" + hostNumber)
        .then(
          (response) => {
            if (response.data.length > 0) {
              if (nameserver.includes("dns")) {
                if (nameserver.includes("1")) {
                  this.dns1Result = response.data
                } else if (nameserver.includes("2")) {
                  this.dns2Result = response.data
                } else if (nameserver.includes("3")) {
                  this.dns3Result = response.data
                }
              } else {
                if (nameserver.includes("1")) {
                  this.ns1Result = response.data
                } else if (nameserver.includes("2")) {
                  this.ns2Result = response.data
                } else if (nameserver.includes("3")) {
                  this.ns3Result = response.data
                }
              }
            } else {
              if (nameserver.includes("dns")) {
                if (nameserver.includes("1")) {
                  this.dns1Result = [{ data: "No host found" }]
                } else if (nameserver.includes("2")) {
                  this.dns2Result = [{ data: "No host found" }]
                } else if (nameserver.includes("3")) {
                  this.dns3Result = [{ data: "No host found" }]
                }
              } else {
                if (nameserver.includes("1")) {
                  this.ns1Result = [{ data: "No host found" }]
                } else if (nameserver.includes("2")) {
                  this.ns2Result = [{ data: "No host found" }]
                } else if (nameserver.includes("3")) {
                  this.ns3Result = [{ data: "No host found" }]
                }
              }
            }
          },
          (error) => {
            if (nameserver.includes("dns")) { 
              if (nameserver.includes("1")) {
                this.dns1Result = [{ data: "Error" }]
              } else if (nameserver.includes("2")) {
                this.dns2Result = [{ data: "Error" }]
              } else if (nameserver.includes("3")) {
                this.dns3Result = [{ data: "Error" }]
              }
            } else {
              if (nameserver.includes("1")) {
                this.ns1Result = [{ data: "Error" }]
              } else if (nameserver.includes("2")) {
                this.ns2Result = [{ data: "Error" }]
              } else if (nameserver.includes("3")) {
                this.ns3Result = [{ data: "Error" }]
              }
            }
          }
        );
    },
    popResults(data) {
      this.ns1Result = [{ data: data }];
      this.ns2Result = [{ data: data }];
      this.ns3Result = [{ data: data }];
      this.dns1Result = [{ data: data }];
      this.dns2Result = [{ data: data }];
      this.dns3Result = [{ data: data }];
    },
    verifyHost(host) {
      this.popResults("verifying..");

      this.host = host;
      this.$refs.verifyModal.open();
      if (this.host.name.length > 0) {
        this.verifyTitle = this.host.name + "." + this.hostingName + " " + host.type;
      } else {
        this.verifyTitle = this.hostingName + " " + host.type;
      }

      for (let i = 1; i <= 3; i++) {
        if (!this.isStaff) {
          this.verifyDest('ns' + i + '.adept.co.za', host.hostNumber);
        } else {
          this.verifyDest('ns' + i + '.adept.co.za', host.hostNumber);
          this.verifyDest('dns' + i + '.adept.za.net', host.hostNumber);
        }
      }
    },
    switchView() {
      this.clientView = !this.clientView;
    },  
    addHost() {
      this.$refs.hostType.clearSelection();
      this.modalTitle = "Add Host";
      this.host = {
        class: this.saleDefinition.supportingObjs.hosts.class,
        domainNumber: this.saleDefinition.definition.hosting.domainNumber,
        ttl: this.saleDefinition.definition.hosting.minimumTtl,
        destination: ''
      };
      this.availableHostTypes =
        this.saleDefinition.supportingEnums.Host.type.filter(
          (t) => t.name != "PTR"
        );
      this.$refs.hostModal.open();
    },
    editHost(host) {
      this.host = Object.assign({}, host);
      this.modalTitle = "Edit Host";
      this.$refs.hostModal.open();
    },
    saveHost() {
      let save = true;
      if ((this.host.type == "NS") && ((this.host.name === undefined) || (this.host.name === ""))) {
        save = false;
      }

      if (this.host.type == "TXT") {
        const firstChar = this.host.destination.substring(0, 1);
        const lastChar = this.host.destination.substring(
          this.host.destination.length - 1
        );

        if (firstChar != '"') {
          this.host.destination = '"' + this.host.destination;
        }
        if (lastChar != '"') {
          this.host.destination = this.host.destination + '"';
        }
      }
      
      if (save) {
        this.$refs.hostModal.isLoading();
        if (this.host.objKey === 0) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/create",
              this.host
            )
            .then(
              (response) => {
                this.loadSale();
                this.$refs.hostModal.close();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Host", error);
              }
            )
            .finally(() => {
              this.$refs.hostModal.isLoading();
            });
        } else {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/update",
              this.host
            )
            .then(
              (response) => {
                this.loadSale();
                this.$refs.hostModal.close();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Host", error);
              }
            )
            .finally(() => {
              this.$refs.hostModal.isLoading();
            });
        }
      } else {
        this.showWarningAlert("NS host record must have Name")
      }
    },
    deleteHost(host) {
      this.$swal({
        title: "Are you sure you want to delete this host?",
        text: host.type + ": " + host.name + " " + host.destination,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete/",
              host
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                this.loadSale();
              },
              (error) => {
                console.error(error);
                this.showError("Error deleting host", error);
              }
            );
        }
      });
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    sortBy(sortKey) {
        this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
        this.sortKey = sortKey;
    },
    getAllProductRestrictions: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products/restrictions`).then(
        (response) => {
          this.productRestrictions = response.data;

          this.productRestrictions.filter((restriction) => {
            if (restriction.description.toLowerCase() === this.saleDefinition.product.clientAccessRestriction.toLowerCase()) {
              this.productRestriction = restriction;
            }
          });
        },
        (error) => {
          console.error(error);
          this.showError("Error fetch all product restrictions", error);
        }
      );
    }
  },
};
</script>

<style scoped>
tr>th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.table>thead>th {
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.nsHead {
  color: royalblue;
  display: inline;
}

.terminalText{
  font-family: monospace;
  letter-spacing: -1px;
  word-spacing: 6px;
}

.icon-green {
  color: #37BC9B
}

.icon-yellow {
  color: #FF902B
}

.icon-red {
  color: #F05050
}

.wrap-text {
  width: 35%;
  word-break: break-all;
}

.pdr3 {
  padding-right: 3em;
}

.rotateEffect {
  transition-duration: 0.3s;
}

.rotateEffect:active {
   transform: rotate(180deg);
}
</style>