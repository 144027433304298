<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-info" @click="backToSaleHistory()">Back</button>
      </div>
    </div>
    <div class="row row-spacing">
      <div class="col-md-12" v-for="(history, index) in histories" :key="index">
        <div class="card border-primary" style="cursor:pointer" data-tool="card-collapse">
          <div class="card-header bg-primary" data-tool="card-collapse">
            <div class="card-title font-weight-bold">{{history.changeName}}
              <span class="float-md-right">{{history.username}}</span>
            </div>
          </div>
          <div class="card-wrapper collapse">
            <div class="card-body">
              <div class="table-responsive table-bordered">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th v-if="history.newVersion">Version {{history.newVersion}}</th>
                      <th>Version {{history.version}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-nowrap" v-for="(field, index) in history.fields" :key="index">
                      <td>{{field.name}}</td>
                      <td :class="field.changed ? 'text-primary' : ''" v-if="field.newVal">{{field.newVal}}</td>
                      <td :class="field.changed ? 'text-primary' : ''">{{field.value}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SaleFunctions from '@/pages/Services/SaleFunctions'

  export default {
    extends: SaleFunctions,
    props: ['objKey', 'objClass'],
    data() {
      return {
        histories: [],
        saleDefinition: {}
      }
    },
    async mounted() {
      const saleNumber = this.$route.params.saleNumber;
      await this.getSaleDefinition(saleNumber).then(response => {
          this.saleDefinition = response.data;
        },
        error => {
          console.error(error);
          this.showError('Error fetching Sale Definition', error)
        })
      await this.$http.get(this.$config.aimsAPI + 'system/objdb/obj/history/' + this.objKey + '?objClass=' + this
          .objClass)
        .then(response => {
            this.histories = response.data.history;
            for (let i = 0; i < this.histories.length; i++) {
              if (i >= 1) {
                for (let j = 0; j < this.histories[i].fields.length; j++) {
                  this.histories[i].fields[j].newVal = this.histories[i - 1].fields[j].value;
                  this.histories[i].newVersion = this.histories[i - 1].version;
                }
              }
            }
          },
          error => {
            console.error(error);
            this.showError('Error fetching Object History', error)
          })
    },
    methods: {
      backToSaleHistory() {
        this.$router.go(-1);
      }
    }
  }

</script>
