<template>
    <div>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Package</th>
                    <th>Usage</th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="domain.mailboxes.length > 0">
                <tr v-for="mailbox in domain.mailboxes" :key="mailbox.zimbraMailboxNumber">
                    <td>{{ mailbox.username }}</td>
                    <v-select
                        :options="zimbraPackages"
                        label="description"
                        v-model="mailbox.classOfService"
                      >
                    </v-select>
                    <td>{{ sizeBytesToMb(mailbox.usage.usage) }} MB</td>
                    <td style="text-align: center">
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="$emit('saveSale')"
                        v-tooltip="'Save Package'"
                      >
                        <em class="fa fa-save fa-fw"></em>
                      </button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="4">No Mailboxes to display</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        domain: {
            type: Object,
            required: true
        },
        zimbraPackages: {
            type: Array,
            required: true
        },
    }
}
</script>

<style lang="scss" scoped>

</style>