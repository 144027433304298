<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="pbx">
            <form novalidate data-vv-scope="pbx">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('pbx.description'),
                    }"
                  >
                    <label>Description:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="description"
                      placeholder="User's PBX Package"
                      v-model="filledInSaleDefinition.requiredObjs.pbx.description"
                    />
                    <span class="has-error" v-if="errors.has('pbx.description')">{{
                      errors.first("pbx.description")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                    'has-error': errors.has('pbx.wanatelCloudPbxPackageNumber'),
                    }"
                  >
                    <label>Package:</label>
                    <v-select
                    name="wanatelCloudPbxPackageNumber"
                    placeholder="Select Package"
                    :value="packageLabel"
                    :options="packageOptions"
                    @input="changePackage"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('pbx.wanatelCloudPbxPackageNumber')">{{
                    errors.first("pbx.wanatelCloudPbxPackageNumber")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "pbx",
          displayName: "PBX",
          icon: "fa fa-cubes",
        },
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            sipTrunk: {},
            pbx: {},
        },
        selectableOptions: {

        }
      },
      packageOptions: [],
      packageLabel: '',
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    
    for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.pbxPackage.length; i++) {
        if (this.filledInSaleDefinition.selectableOptions.pbxPackage.length > 0 && i == 0) {
        this.packageLabel = this.filledInSaleDefinition.selectableOptions.pbxPackage[i].description;
        this.filledInSaleDefinition.requiredObjs.pbx.wanatelCloudPbxPackageNumber = this.filledInSaleDefinition.selectableOptions.pbxPackage[i].wanatelCloudPbxPackageNumber;
      }
      this.packageOptions[i] = this.filledInSaleDefinition.selectableOptions.pbxPackage[i].description
    }
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    changePackage(value) {
        for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.pbxPackage.length; i++) {
            if (value == this.filledInSaleDefinition.selectableOptions.pbxPackage[i].description) {
                this.filledInSaleDefinition.requiredObjs.pbx.wanatelCloudPbxPackageNumber = this.filledInSaleDefinition.selectableOptions.pbxPackage[i].wanatelCloudPbxPackageNumber;
            }
        }
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>