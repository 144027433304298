<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="device">
            <form novalidate data-vv-scope="device">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('device.name'),
                    }"
                  >
                    <label>Device Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.device.name"
                    />
                    <span class="has-error" v-if="errors.has('device.name')">{{
                      errors.first("device.name")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('device.adminUsername'),
                    }"
                  >
                    <label>Admin Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="adminUsername"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.device.adminUsername"
                    />
                    <span class="has-error" v-if="errors.has('device.adminUsername')">{{
                      errors.first("device.adminUsername")
                    }}</span>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-6">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('device.password'),
                    }"
                  >
                    <label>Admin Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="password"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.device.password"
                    />
                    <span class="has-error" v-if="errors.has('device.password')">{{
                      errors.first("device.password")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('device.operatingSystem'),
                    }"
                  >
                    <label>Operating System:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="operatingSystem"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.device.operatingSystem"
                    />
                    <span class="has-error" v-if="errors.has('device.operatingSystem')">{{
                      errors.first("device.operatingSystem")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('device.serialNumber'),
                    }"
                  >
                    <label>Serial Number:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="serialNumber"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.device.serialNumber"
                    />
                    <span class="has-error" v-if="errors.has('device.serialNumber')">{{
                      errors.first("device.serialNumber")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "device",
          displayName: "Device",
          icon: "fa fa-desktop",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            device: {},
            line: {}
        },
        selectableOptions: {

        }
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>