<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">Client:</div>
              <div class="col-md-4">Client's Tags:</div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <v-select
                    placeholder="Select a Client"
                    v-model="selectedClientNumber"
                    :options="allClients"
                    valueProp="clientNumber"
                    label="toString"
                    :searchable="true"
                >
                </v-select>
              </div>
              <div class="col-md-4">
                <v-select
                    placeholder="Select a Client's Tag"
                    v-model="selectedTag"
                    :options="allClientTags"
                    label="name"
                    :searchable="false"
                >
                </v-select>
              </div>
              <div class="col-md-4">
                    <button v-if="this.selectedClientNumber != 0"
                      class="btn btn-success"
                      type="button"
                      @click="newSaleTag()"
                    >
                      Create New Tag
                    </button>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>      
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-default">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="table-responsive table-bordered" v-if="filteredByClientTags">
                  <table class="table">
                    <thead>
                      <tr class="text-nowrap">
                        <th>Product</th>
                        <th>Sale#</th>
                        <th>Sale Description</th>
                        <th>Sale Tag Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="sale in allSales" :key="sale.saleNumber">
                        <td class="col-md-3">{{sale.product.description}}</td> 
                        <td class="col-md-1">{{sale.saleNumber}}</td> 
                        <td class="col-md-4">{{sale.description}}</td>

                        <td class="col-md-2" v-if="sale.saletagvalues!=null && sale.saletagvalues.length > 0">
                          <input class="form-control" v-model="sale.saletagvalues[0].value" />
                        </td>
                        <td class="col-md-2" v-else>
                            <input class="form-control" disabled/>                          
                        </td>

                        <td class="col-md-2" v-if="sale.saletagvalues!=null && sale.saletagvalues.length > 0">
                              <button v-tooltip="'Save Value'" @click="saveTagValue(sale.saletagvalues[0])" type="button" class="btn btn-sm btn-info m-1">
                                  <em class="fa fa-save fa-fw"></em>
                              </button>
                              <button v-tooltip="'Delete Value'" @click="deleteTagValue(sale.saletagvalues[0].saleTagValueNumber)" type="button" class="btn btn-sm btn-primary">
                                  <em class="fa fa-trash fa-fw"></em>
                              </button>
                        </td>
                        <td class="col-md-2" v-else>
                          <button v-tooltip="'Create Value'" @click="clickNewSaleTagValue(sale.saleNumber)" type="button" class="btn btn-sm btn-info m-1">
                              <em class="fa fa-plus fa-fw"></em>
                          </button>

                        </td>                           
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="alert alert-info col-sm-12" v-else>
                  <strong>No Sales to display</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      

    <modal
      :title="'Client Sale Tag'"
      ref="viewTagModal"
      size="modal-md"
      @save="saveTag()"
      :delete-needed="false"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Tag Name</label>
            <input
              class="form-control"
              type="text"
              v-model="newTag.name"
            />
          </div>
        </div>
      </div>
    </modal>

    <modal
      :title="'Sale Tag Value'"
      ref="viewTagValueModal"
      size="modal-md"
      @save="modalSaveTagValue()"
      :delete-needed="false"
    >
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Tag Value</label>
            <input
              class="form-control"
              type="text"
              v-model="newSaleTagValue.value"
            />
          </div>
        </div>
      </div>
    </modal>

  </div>  
</template>

<script>
  import Modal from "@/components/GeneralComponents/Modal";

  export default {
    components: {
      Modal,
    },
    computed: {
      filteredByClientTags() {
        if (this.selectedClientNumber != null && this.selectedTag != null) {
            return true;    
        } else {
          return false;
        }
      },
    },
    data() {
      return {
        allClients: [],
        selectedClientNumber: null,
        selectedTag: null,
        allClientTags: [],
        allSales: [],    
        newTag: {
          name: "",
          clientNumber: ""
        },
        newSaleTagValue : {
          saleNumber: "",
          value: ""
        }
      }
    },
    created() {
      document.title = this.$route.meta.title;
    },
    mounted: function () {
      this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
      this.getAllClients();
    },
    watch: {
        selectedClientNumber: {
            handler(newValue, oldValue) {
                if (newValue != null && newValue !== 0) {
                  this.clientSelected(); 
                }  
            },
            deep: true
        },
        selectedTag: {
            handler(newValue, oldValue) {
                if (newValue != null && newValue.saleTagNumber !== null && newValue.saleTagNumber !== 0) {
                  this.tagSelected();
                }  
            },
            deep: true
        }
    },
    methods: {
      saveTag() {
        this.$refs.viewTagModal.isLoading();
        this.$http
          .post(this.$config.aimsAPI + "saletags/save", this.newTag)
          .then(
            (response) => {
              const savedTag = response.data;
              this.showSuccess("Sale Tag Saved.");
              this.clientSelected();
              this.$refs.viewTagModal.close();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Tag", error);
            }
          )
          .finally(() => {
            this.$refs.viewTagModal.isLoading();
          });
      },
      modalSaveTagValue() {
        this.$refs.viewTagValueModal.isLoading();
        this.$http
          .post(this.$config.aimsAPI + "saletags/save/value", this.newSaleTagValue)
          .then(
            (response) => {
              this.selectedTag = response.data;
              this.showSuccess("Sale Tag Value Saved.");
              this.tagSelected();
              this.$refs.viewTagValueModal.close();
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Tag Value", error);
            }
          )
          .finally(() => {
            this.$refs.viewTagValueModal.isLoading();
          });
      },
      getAllClients() {
        this.$http.get(this.$config.aimsAPI + "clients/all").then(
          (response) => {
            this.allClients = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Clients", error);
          }
        );
      },
      getAllSaleTagsForClient(clientNumber) {
        this.$http.get(this.$config.aimsAPI + "saletags/client/"+clientNumber).then(
          (response) => {
            this.selectedTag = null;
            this.allClientTags = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Sale Tags for client", error);
          }
        );
      },
      getAllClientSalesPerTag(clientNumber, saletag) {
        this.allSales = [];
        this.$http.get(this.$config.aimsAPI + "saletags/"+saletag+"/client/"+clientNumber+"/sales").then(
          (response) => {
            this.allSales = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Sales for client", error);
          }
        );
      },
      clientSelected() {
        if (this.selectedClientNumber != null && this.selectedClientNumber != 0) {
          this.allSales = [];    
          this.allClientTags = [];
          this.selectedTag = null;
          this.getAllSaleTagsForClient(this.selectedClientNumber);
        } else {
          this.allSales = [];    
          this.allClientTags = [];
          this.selectedTag = null;
        }
      },
      tagSelected() {
        if (this.selectedTag != null && this.selectedClientNumber != null) {
          this.getAllClientSalesPerTag(this.selectedClientNumber, this.selectedTag.saleTagNumber);
        } else {
          this.allSales = [];    
          this.allClientTags = [];
          this.selectedTag = null;
        }
      },
      newSaleTag() {
        this.newTag = {};
        this.newTag.class =  "za.co.adept.aims.segment.SaleTag";
        this.newTag.clientNumber = this.selectedClientNumber;
        this.newTag.name = "";
        this.$refs.viewTagModal.open();
      },
      clickNewSaleTagValue(saleNumber) {
        this.newSaleTagValue = {};
        this.newSaleTagValue.class =  "za.co.adept.aims.segment.SaleTagValue";
        this.newSaleTagValue.saleNumber = saleNumber;
        this.newSaleTagValue.clientNumber = this.selectedClientNumber;
        this.newSaleTagValue.saleTagNumber = this.selectedTag.saleTagNumber;
        this.newSaleTagValue.value = "";
        
        this.$refs.viewTagValueModal.open();
      },
      saveTagValue(saletagvalue) {
        this.$http
          .post(this.$config.aimsAPI + "saletags/save/value", saletagvalue)
          .then(
            (response) => {
              this.showSuccess("Sale's Tag Value Saved.");
            },
            (error) => {
              console.error(error);
              this.showError("Error Saving Sale's Tag Value", error);
            }
          )
          .finally(() => {
          
          });
      },
      deleteTagValue(saletagvaluenumber) {
        this.$swal({
          title: "Are you sure you want to delete this Sale's Tag Value?",
          type: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.$http
              .delete(
                this.$config.aimsAPI +
                  "saletags/delete/value/" +
                  saletagvaluenumber
              )
              .then(
                (response) => {
                  this.showSuccess("Successfully deleted Sale's Tag Value");
                  this.tagSelected();
                },
                (error) => {
                  this.showError("Error deleting Sale's Tag Value", error);
                  console.error(error);
                }
              )
              .finally(() => {
                
              });
          }
        });
      },
    }
  }

</script>