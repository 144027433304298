<template>
  <div>
    <form class="row" novalidate>
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('Address Type') }"
            >
              <label>Address Type *</label>
              <select
                name="Address Type"
                v-validate="'required'"
                class="form-control"
                v-model="address.addressType"
                @change="addressChanged()"
              >
                <option
                  :value="addressType.name"
                  v-for="addressType in addressTypes"
                  :key="addressType.name"
                >
                  {{ addressType.description }}
                </option>
              </select>
              <span class="has-error" v-if="errors.has('Address Type')">{{
                errors.first("Address Type")
              }}</span>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group">
              <label>Sub Premise</label>
              <input
                placeholder="Sub Premise"
                name="Subpremise"
                class="form-control"
                v-model="address.subpremise"
                @change="addressChanged()"
              />
            </div>
          </div>
          <div class="col-lg-6 mt-4">
            <button type="button" class="btn btn-info float-right" @click="openDetail()">
              <i class="fa-solid fa-circle-exclamation top-right-icon" v-if="detailsMissing"></i>
              Address Details
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <GoogleMapsAddress 
              :addressStructure="addressStructure"
              @addressChanged="addressChanged"
            />
          </div>
        </div>
      </div>
    </form>

    <Modal
      ref="addressDetailsModal"
      :title="'Address Details'"
      size="modal-xl"
      save-text="Save"
      :saveNeeded="true"
      @save="confirmAddress()"
    >
      <div class="row">
        <div class="col-md-3">
          <label>Type *</label>
          <select
            name="Address Type"
            v-validate="'required'"
            class="form-control"
            v-model="address.addressType"
            @change="addressChanged()"
          >
            <option
              :value="addressType.name"
              v-for="addressType in addressTypes"
              :key="addressType.name"
            >
              {{ addressType.description }}
            </option>
          </select>
        </div>
        <div class="col-md-3">
          <label>Street Number</label>
          <input
            type="text"
            class="form-control"
            v-model="address.streetNumber"
            name="Street number"
            v-validate="'required'"
            placeholder="Enter a street number"
          />
        </div>
        <div class="col-md-3">
          <div
              :class="{ 'has-error': errors.has('Street') && !address.streetName }"
            >

            <label>Street</label>
            <input
              type="text"
              class="form-control"
              v-model="address.streetName"
              name="Street"
              v-validate="'required'"
              placeholder="Enter a street"
            />

            <span class="has-error" v-if="errors.has('Street') && !address.streetName">Street Name is required</span>
          </div>
        </div>
      </div>

      <div class="row row-spacing">
        <div class="col-md-3">
            <label>Suburb</label>
            <input
              type="text"
              class="form-control"
              v-model="address.suburb"
              name="Suburb"
              placeholder="Enter a suburb"
            />
        </div>
        <div class="col-md-3">
          <div
              :class="{ 'has-error': errors.has('City') && !address.city}"
            >

            <label>City</label>
            <input
              type="text"
              class="form-control"
              v-model="address.city"
              name="City"
              v-validate="'required'"
              placeholder="Enter a city"
            />

            <span class="has-error" v-if="errors.has('City') && !address.city">City is required</span>
          </div>
        </div>
        <div class="col-md-3">
          <div
              :class="{ 'has-error': errors.has('Province') && !address.province}"
            >

            <label>Province</label>
            <input
              type="text"
              class="form-control"
              v-model="address.province"
              name="Province"
              v-validate="'required'"
              placeholder="Enter a province"
            />

            <span class="has-error" v-if="errors.has('Province') && !address.province">Province is required</span>
          </div>
        </div>
      </div>

      <div class="row row-spacing">
        <div class="col-md-3">
          <div
              :class="{ 'has-error': errors.has('Postal Code') && !address.postalCode}"
            >

            <label>Postal Code</label>
            <input
              type="text"
              class="form-control"
              v-model="address.postalCode"
              name="Postal Code"
              v-validate="'required'"
              placeholder="Enter a postal code"
            />

            <span class="has-error" v-if="errors.has('Postal Code') && !address.postalCode">Postal Code is required</span>
          </div>
        </div>
        <div class="col-md-3">
          <div
              :class="{ 'has-error': errors.has('Country') && !address.country}"
            >

            <label>Country</label>
            <input
              type="text"
              class="form-control"
              v-model="address.country"
              name="Country"
              v-validate="'required'"
              placeholder="Enter a country"
            />

            <span class="has-error" v-if="errors.has('Country') && !address.country">Country is required</span>
          </div>
        </div>
      </div>
      
    </Modal>
  </div>
</template>

<script>
import GoogleMapsAddress from './GoogleMapsAddress.vue';
import Modal from "./Modal.vue";

export default {
  components: {
    Modal,
    GoogleMapsAddress
  },
  props: {
    addressStructure: {
      type: Object,
      required: false,
    },
    addressTypes: {
      type: Array,
      required: false,
    },
  },
  watch: {
    addressStructure: {
      handler(to, from) {
        this.address = this.deepCopyObject(this.addressStructure);
      },
      immediate: true,
    },
    addressTypes: {
      handler(to, from) {
        if (
          to &&
          to.length > 0 &&
          (!this.address.addressType ||
            this.address.addressType === "" ||
            this.address.addressType.includes("None"))
        ) {
          this.address.addressType = this.addressTypes[0].name;
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      address: {},
    };
  },
  computed: {
    detailsMissing() {
      if (this.address && this.address.addressType && this.address.streetName &&
          this.address.city && this.address.province && this.address.country && this.address.postalCode
      ) {
        return false 
      } else {
        return true
      }
    }
  },
  methods: {
    openDetail: function() {
      this.$validator.validateAll()
      this.$refs.addressDetailsModal.open();
    },
    closeDetail: function() {
      this.$refs.addressDetailsModal.close();
    },
    confirmAddress: function() {
      if (!this.detailsMissing) {
        this.closeDetail(); 
      } else {
        this.showWarningAlert("Missing Address Details")
      }
    },
    getLabel: function({description}) {
        return description;
    },
    addressChanged(address) {
      this.$validator.validateAll().then((result) => {
        this.$emit("addressValid", result);
      });      

      if (address) {
        address.addressType = this.address.addressType;

        this.address = address;
        this.$emit("addressChanged", address);
      } else {
        this.$emit("addressChanged", this.address);
      }   
    },
  },
};
</script>

<style>
.top-right-icon {
  position: absolute;
  top: -5px;
  right: 10px;
  color: red;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
  font-size: 1.5em;
}
</style>
