<template>
  <service-screen-layout :parts="parts">
    <template v-slot:line>
      <div class="col-md-12">
        <ConnectivityLine 
          :line="saleDefinition.definition.line" 
          @saveSale="saveSale"
        />
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import ConnectivityLine from "@/components/ObjParts/SingleCaptured/ConnectivityLine";

import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    ConnectivityLine,
  },
  data() {
    return {
      parts: [
        {
          name: "line",
          icon: "fa fa-link",
          displayName: "Line",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        definition: {
          uncappedService: {},
          line: {
            lineAddress: {},
          },
        },
      },
    };
  },
  async mounted() {
    const saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    saveSale() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Pppoe Account", error);
              }
            );
        }
      });
    },
  },
};
</script>
