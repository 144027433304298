<template>
    <div class="card-body">
        <div class="form-group">
            <label>First Names:</label>
            <input type="text" placeholder="Enter first name" class="form-control" v-model="contact.firstNames" :readonly="!canEdit">
        </div>
        <div class="form-group">
            <label>Last Name:</label>
            <input type="text" placeholder="Enter last name" class="form-control" v-model="contact.lastName" :readonly="!canEdit">
        </div>
        <div class="form-group">
            <label>Email:</label>
            <input type="email" placeholder="Enter email" class="form-control" v-model="contact.emailAddress" :readonly="!canEdit">
        </div>
        <div class="form-group">
            <label>Phone:</label>
            <input type="text" placeholder="Enter phone number" class="form-control" v-model="contact.contactPhoneNumber" :readonly="!canEdit">
        </div>
        <div class="form-group">
            <label>Organization:</label>
            <input type="text" placeholder="Enter organization" class="form-control" v-model="contact.companyName" :readonly="!canEdit">
        </div>
        <div class="row" v-if="canEdit">
            <div class="col-md-12 pb-1">
                <span class="pull-right">
                    <button type="button" :disabled="!canEdit" @click="saveContact()" class="btn btn-info ">Save</button>                                                    
                </span>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    props: {
        contact: {
            type: Object,
            required: true
        },
        contactAddress: {
            type: Object,
            required: false
        },
        canEdit: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            changedAddress : {},
            addressValid: {}
        }
    },
    methods: {
        saveContact() {
            var contactArray = new Array();
            contactArray.push(this.contact);
            if (this.addressValid == true) {
                contactArray.push(this.changedAddress);
            }
            this.$emit('saveContact', contactArray);
        },
        contactAddressChanged(address) {
           this.changedAddress = address;
        },
        contactAddressValid(isValid) {
           this.addressValid = isValid;
        }
        
    }
}
</script>