<template>
    <modal :title="modalTitle" ref="addDomainContact" :size="'modal-xl'" @dismiss="dismiss" @close="close" @save="saveDomainContact(domainContact)">
        <div class="row">
            <div class="col-md-10">
                <div class="row align-items-center">
                    <div class="col-md-2">
                        <h4 class="m-0">Contact Details</h4>
                    </div>
                    <div class="col-md-6" :class="{ 'has-error': errors.has('Client Number') }">
                            <v-select
                                ref="clientNumberOptions"
                                placeholder="Select a Client"
                                v-model="domainContact.clientNumber"
                                :options="allClients"
                                valueProp="clientNumber"
                                label="toString"
                                :searchable="true"
                                name="Client Number"
                                :class="{ 'has-error': errors.has('Client Number') }"
                                class="mb-1"
                                v-validate="'required'"
                            >
                            </v-select>
                            <small class="has-error mt-2" v-if="errors.has('Client Number')">{{ errors.first("Client Number") }}</small>
                    </div>
                    <div class="col-md-4">
                        <div class="input-group">
                            <label>Shared Contact</label>
                            <label class="switch">
                                <input type="checkbox" class="form-control" v-model="domainContact.shared">
                                <span class="slider round"></span>
                            </label>
                            <span><strong>{{ domainContact.shared === true ? ' Public' : ' Private' }}</strong></span>
                        </div>
                    </div>
                </div>
                <div v-if="showInputFields == true">
                    <div class="row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('First name') }">
                            <label>First name</label>
                            <input 
                                type="text" 
                                class="form-control"
                                v-model="domainContact.firstNames"
                                name="First name"
                                :class="{ 'has-error': errors.has('First name') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('First name')">{{ errors.first("First name") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Last name') }">
                            <label>Last name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.lastName"
                                name="Last name"
                                :class="{ 'has-error': errors.has('Last name') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Last name')">{{ errors.first("Last name") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Company Name') }">
                            <label>Company Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.companyName"
                                name="Company Name"
                                :class="{ 'has-error': errors.has('Company Name') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Company Name')">{{ errors.first("Company Name") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Email Address') }">
                            <label>Email Address</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.emailAddress"
                                name="Email Address"
                                :class="{ 'has-error': errors.has('Email Address') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Email Address')">{{ errors.first("Email Address") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Telephone Number') }">
                            <label>Telephone Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.contactPhoneNumber"
                                name="Telephone Number"
                                :class="{ 'has-error': errors.has('Telephone Number') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Telephone Number')">{{ errors.first("Telephone Number") }}</small>
                        </div>
                        <div class="form-group col-md-6" :class="{ 'has-error': errors.has('Cellphone Number') }">
                            <label>Cellphone Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.contactCellNumber"
                                name="Cellphone Number"
                                :class="{ 'has-error': errors.has('Cellphone Number') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Cellphone Number')">{{ errors.first("Cellphone Number") }}</small>
                        </div>
                    </div>
                    <hr />
                    <h4>Address Details</h4>
                    <div class="row">
                        <div class="form-group col-md-2" :class="{ 'has-error': errors.has('Street Number') }">
                            <label>Street Number</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.domainContactAddress.streetNumber"
                                name="Street Number"
                                :class="{ 'has-error': errors.has('Street Number') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Street Number')">{{ errors.first("Street Number") }}</small>
                        </div>
                        <div class="form-group col-md-4" :class="{ 'has-error': errors.has('Street Name') }">
                            <label>Street Name</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.domainContactAddress.streetName"
                                name="Street Name"
                                :class="{ 'has-error': errors.has('Street Name') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Street Name')">{{ errors.first("Street Name") }}</small>
                        </div>
                        <div class="form-group col-md-4" :class="{ 'has-error': errors.has('Suburb') }">
                            <label>Suburb</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.domainContactAddress.suburb"
                                name="Suburb"
                                :class="{ 'has-error': errors.has('Suburb') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Suburb')">{{ errors.first("Suburb") }}</small>
                        </div>
                        <div class="form-group col-md-2" :class="{ 'has-error': errors.has('Postal Code') }">
                            <label>Postal Code</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.domainContactAddress.postalCode"
                                name="Postal Code"
                                :class="{ 'has-error': errors.has('Postal Code') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Postal Code')">{{ errors.first("Postal Code") }}</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col" :class="{ 'has-error': errors.has('Address Type') }">
                            <label>Address Type</label>
                            <v-select
                                :placeholder="'Select an address type'"
                                v-model="domainContact.domainContactAddress.addressType"
                                :options="addressTypes"
                                :get-option-label="getLabel"
                                valueProp="name"
                                name="Address Type"
                                :class="{ 'has-error': errors.has('Address Type') }"
                                class="mb-1"
                                v-validate="'required'"
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('Address Type')">{{ errors.first("Address Type") }}</small>
                        </div>
                        <div class="form-group col" :class="{ 'has-error': errors.has('City') }">
                            <label>City</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.domainContactAddress.city"
                                name="City"
                                :class="{ 'has-error': errors.has('City') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('City')">{{ errors.first("City") }}</small>
                        </div>
                        <div class="form-group col-md-4" :class="{ 'has-error': errors.has('Province') }">
                            <label>Province</label>
                            <v-select
                                :placeholder="'Select a Province'"
                                v-model="domainContact.domainContactAddress.province"
                                :get-option-label="getLabel"
                                :options="provinces"
                                valueProp="name"
                                name="Province"
                                :class="{ 'has-error': errors.has('Province') }"
                                class="mb-1"
                                v-validate="'required'"
                            >
                            </v-select>
                            <small class="has-error mt-1" v-if="errors.has('Province')">{{ errors.first("Province") }}</small>
                        </div>
                        <div class="form-group col-md-2" :class="{ 'has-error': errors.has('Country') }">
                            <label>Country</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                v-model="domainContact.domainContactAddress.country"
                                name="Country"
                                :class="{ 'has-error': errors.has('Country') }"
                                v-validate="'required'"
                            >
                            <small class="has-error mt-1" v-if="errors.has('Country')">{{ errors.first("Country") }}</small>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <hr>
                    <div class="col-md-12">
                        <div class="d-flex justify-content-center align-items-center mt-5 mb-4">
                            <p class="font-weight-bold">Select a Client in order to add Domain Contact</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal,
    },
    props: {
        modalTitle: {
            type: String,
            required: true
        },
        allClients: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showInputFields: false,
            domainContact: {
                domainContactAddress: {}
            },
            addressTypes: [
                {
                    name: 'BUSINESS',
                    description: 'Business'
                },
                {
                    name: 'RESIDENTIAL',
                    description: 'Residential'
                }
            ],
            provinces: [
                {
                    name: 'Western Cape',
                    description: 'Western Cape',
                },
                {
                    name: 'Gauteng',
                    description: 'Gauteng',
                },
                {
                    name: 'KwaZulu-Natal',
                    description: 'KwaZulu-Natal',
                },
                {
                    name: 'Eastern Cape',
                    description: 'Eastern Cape',
                },
                {
                    name: 'Mpumalanga',
                    description: 'Mpumalanga',
                },
                {
                    name: 'Limpopo',
                    description: 'Limpopo',
                },
                {
                    name: 'Northern Cape',
                    description: 'Northern Cape',
                },
                {
                    name: 'Free State',
                    description: 'Free State',
                },
                {
                    name: 'North West',
                    description: 'North West',
                },
            ]
        }
    },
    watch: {
        domainContact: {
            handler(newValue, oldValue) {
                if (newValue.clientNumber !== null && newValue.clientNumber !== 0) {
                    return this.showInputFields = true;
                } else {
                    return this.showInputFields = false;
                }  
            },
            deep: true
        }
    },
    methods: {
        open: function() {           
            this.$refs.addDomainContact.open();
            this.domainContact = {
                class: "za.co.adept.aims.domain.DomainContact",
                clientNumber: null,
                domainContactAddress: {
                    class: "za.co.adept.aims.domain.DomainContactAddress",
                }
            }
        },
        close: function() {
            this.clearInputs();
        },
        dismiss: function() {
            this.clearInputs();
        },
        getLabel: function({ description }) {
            return description;
        },
        saveDomainContact: function(domainContact) {
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.$emit('addDomainContacts', domainContact);
                    this.$refs.addDomainContact.close();
                    this.clearInputs();
                }
            });
        },
        clearInputs: function() {
             this.$refs.clientNumberOptions._data.selectedValue = null;
             this.errors.clear();
        }
    },
}
</script>
