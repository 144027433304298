<template>
  <div>
    <div class="row row-spacing">
      <div class="form-group col-md-3">
        <label>Select Date from</label>
        <div class="input-group">
          <input type="date" class="form-control" v-model="invoicesState.date" @change="getAllClientDocuments()" :min="minDate">
          <div class="input-group-append">
              <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
          </div>
        </div>
      </div>
      <div class="form-group col-md-3">
        <label>Search Invoice/Credit Notes</label>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search for Invoices/Credit Notes" v-model="invoicesState.searchTerm">
          <div class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </div>
      <div class="form-group col-md-6" v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']">
        <button
          class="btn btn-primary dropdown-toggle mt-4 pull-right"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          v-allowed:view="['ACCOUNTS_STAFF', 'ADMIN']"
        >
          New Document
        </button>
        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click="newDocument('INVOICE')"
            >Invoice</a
          >
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click="openCreditDetail()"
            >Credit Note</a
          >
          <a
            class="dropdown-item"
            href="javascript:void(0)"
            @click="newDocument('RESELLER_INVOICE')"
            >Reseller Invoice</a
          >
        </div>
      </div> 
    </div>
    <hr>
    <div class="card-scroll-90 mt-4">
      <div class="row">
        <div class="col-md-12">
          <div class="card table-responsive">
            <table class="table table-hover table-bordered" v-if="isLoaded === true">
              <thead>
                <tr>
                  <th @click="sortBy('documentNumber')">Document No.
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'documentNumber' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'documentNumber' && !reverse"></i>
                  </th>
                  <th @click="sortBy('type')">Type
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'type' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'type' && !reverse"></i>
                  </th>
                  <th @click="sortBy('status')" v-allowed:view="['STAFF']">Status
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'status' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'status' && !reverse"></i>
                  </th>
                  <th @click="sortBy('billingPeriod')">Billing Period
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'billingPeriod' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'billingPeriod' && !reverse"></i>
                  </th>
                  <th @click="sortBy('processDate')">Process Date
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'processDate' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'processDate' && !reverse"></i>
                  </th>
                  <th @click="sortBy('note')">Description
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'note' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'note' && !reverse"></i>
                  </th>
                  <th @click="sortBy('totalAmount')">Amount
                    <i class="fa fa-sort-asc float-right" v-if="sortKey == 'totalAmount' && reverse"></i>
                    <i class="fa fa-sort-desc float-right" v-if="sortKey == 'totalAmount' && !reverse"></i>
                  </th>
                </tr>
              </thead>
              <tbody v-if="documents.length > 0">
                <tr
                  @click="documentEdit(document)"
                  v-for="document in filteredDocuments"
                  :key="document.documentNumber"
                  @mouseenter="highlightInvoice(document, true)"
                  @mouseleave="highlightInvoice(document, false)"
                  :class="{
                    'open-invoice': document.status == 'OPEN',
                    highlight:
                      creditNoteHover == document.documentNumber ||
                      invoiceHover == document.documentNumber,
                  }"
                >
                  <td>{{ document.documentNumber }}</td>
                  <td>
                    <span :class="getDocumentClass(document)">
                      {{ getDocumentText(document) }}
                    </span>
                  </td>
                  <td v-allowed:view="['STAFF']">{{ document.status }}</td>
                  <td>{{ document.billingPeriod }}</td>
                  <td>{{ dateFormat(document.processDate) }}</td>
                  <td>{{ document.note }}</td>
                  <td>
                    <strong>{{ currencyFormat(document.totalAmount) }}</strong>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="7">No documents found</td>
                </tr>
              </tbody>
            </table>
            <div v-else-if="isLoaded === false">
              <DataLoader 
                :loadingText="'Loading Invoices/Credit Notes...'"
                style="min-height: 300px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreditDetailModal
      ref="creditDetailModal" 
      :creditDetail="newCreditDetail"
      @createCreditDetail="newCreditNote()"
    />
  </div>
</template>

<script>
import DataLoader from "@/components/GeneralComponents/DataLoader";
import CreditDetailModal from "@/components/Admin/Modals/CreditDetailModal.vue"
import { InvoicesState } from '@/stores/pages/Client/Invoices.ts';

export default {
  components: {
      DataLoader,
      CreditDetailModal
  },
  data() {
    const invoicesState = InvoicesState();
    return {
      invoicesState,
      isLoaded: false,
      clientNumber: null,
      documents: [],
      documentsFiltered: [],
      client: [],
      creditNoteHover: null,
      invoiceHover: null,
      date: null,
      searchTerm: '',
      timePeriod: "",
      billingPeriod: parseInt(this.getCurrentPeriod()),
      processDatesArr: [],
      minDate: new Date().toISOString().split('T')[0],
      newCreditDetail: { 
        ticketNumber: '',
        reason: '',
        lines: []
      },

      sortKey: 'documentNumber',
      reverse: false,
    };
  },
  created() {  
    document.title = this.$route.params.clientNumber + " - Documents";
  },
  mounted() {
    if (this.userContainsPermission(["CLIENT"])) {
        this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Invoices & Credit Notes'); 
    }
    this.clientNumber = this.$route.params.clientNumber;
    this.minDate = (this.minDate.substring(0, 4) - 4) + "-01-01";
    this.getAllClientDocuments();
  },
  computed: {
    filteredDocuments: function() {
      this.documentsFiltered = this.documents;

      if (this.invoicesState.searchTerm.length > 0) {
        this.documentsFiltered = this.documentsFiltered.filter((document) => 
          document.documentNumber.toString().includes(this.invoicesState.searchTerm) ||
          document.billingPeriod.toString().includes(this.invoicesState.searchTerm.toLowerCase()) ||
          document.processDate.includes(this.invoicesState.searchTerm.toLowerCase()) ||
          document.note.toLowerCase().includes(this.invoicesState.searchTerm.toLowerCase())
        );
      }

      if (this.reverse) {
        return _.orderBy(this.documentsFiltered, this.sortKey, 'asc');
      } else {
        return _.orderBy(this.documentsFiltered, this.sortKey, 'desc');
      }
    }
  },
  methods: {
    filterDocumentsForClient: function() {
      if (this.userContainsPermission(['CLIENT'])) {
        let procDocuments = this.documents.filter((p) => p.status == "PROCESSED");
        let setDocuments = this.documents.filter((p) => p.status == "SETTLED");
        this.documents = procDocuments.concat(setDocuments);
      }
    },
    getAllClientDocuments: function() {
      this.isLoaded = false; 
      const hostUrl = this.$config.aimsAPIv2;
      const clientNumber = this.clientNumber;
      if (this.invoicesState && this.invoicesState.date) {
        if (this.invoicesState.date.substring(0, 4) < this.minDate.substring(0, 4)) {
          this.invoicesState.date = this.minDate;
          this.showWarningAlert("Can't select a date before: " + this.minDate);
        }
      } 
      let restcall = `${hostUrl}documents/client/${clientNumber}`;
      if (this.invoicesState.date != null) {
        restcall = `${hostUrl}documents/client/${clientNumber}?startDate=${this.invoicesState.date}`;
      }
      this.$http.get(restcall).then(
          (response) => {
            this.documents = response.data;
            this.filterDocumentsForClient();
            this.isLoaded = true; 
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Client Documents", error);
            this.isLoaded = true; 
          }
      );

    },
    openCreditDetail: function() {
      this.newCreditDetail = { 
        ticketNumber: '',
        reason: '',
        lines: []
      },
      this.$refs.creditDetailModal.open();
    },
    newCreditNote: function() {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;

      this.$http.post(`${hostUrl}documents/client/${clientNumber}/adhoc/creditnote/detail`, this.newCreditDetail).then(
          (response) => {
            response.data.isNew = true;
            this.documents.unshift(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Creating New Document", error);
          }
        );
    },
    newDocument: function(type) {
      const hostUrl = this.$config.aimsAPI;
      const clientNumber = this.clientNumber;

      this.$http.post(`${hostUrl}documents/client/${clientNumber}/adhoc/document?type=${type}`).then(
          (response) => {
            response.data.isNew = true;
            this.documents.unshift(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Creating New Document", error);
          }
        );
    },
    highlightInvoice: function(document, enter) {
      if (enter) {
        if (document.type == "CREDITNOTE") {
          const split = document.note.split("Credit note for invoice #");
          const forInvoiceNumber = split[1];
          this.creditNoteHover = document.documentNumber;
          this.invoiceHover = forInvoiceNumber;
        } else {
          this.creditNoteHover = null;
          this.invoiceHover = null;
        }
      } else {
        this.creditNoteHover = null;
        this.invoiceHover = null;
      }
    },
    documentEdit: function (document, event) {
      this.$router.push({
        name: "documentedit",
        params: {
          clientNumber: document.clientNumber,
          documentNumber: document.documentNumber,
        },
      });
    },
    getDocumentClass: function(document) {
      let docClass = "";

      if (this.userContainsPermission(['CLIENT'])) {
        return "badge bg-info"
      }

      switch (document.type) {
        case "INVOICE":
          docClass = "badge bg-green";
          break;
        case "RESELLER_INVOICE":
          docClass = "badge bg-green";
          break;
        case "CREDITNOTE":
          docClass = "badge bg-danger";
          break;
        default:
          docClass = "badge bg-success";
      }

      return docClass;
    },
    getDocumentText: function(document) {
      let docText = "";

      switch (document.type) {
        case "INVOICE":
          docText = "Invoice";
          break;
        case "RESELLER_INVOICE":
          docText = "Reseller Invoice";
          break;
        case "CREDITNOTE":
          docText = "Credit Note";
          break;
        default:
          docText = "N/A";
      }

      return docText;
    },
    getDocumentLineClass: function(dltype) {
      switch (dltype) {
        case "REMARK":
          return "text-bold";
        case "CLIENT_REMARK":
          return "text-bold";
        default:
          return "px-3";
      }
    },
    isRemark: function(dltype) {
      switch (dltype) {
        case "REMARK":
          return true;
        case "CLIENT_REMARK":
          return true;
        default:
          return false;
      }
    },
    sortBy(sortKey) {
      this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
      this.sortKey = sortKey;
    },
  },
};
</script>

<style scoped>
.open-invoice {
  color: orange;
}

.processed-invoice {
  color: green;
}

.highlight {
  color: orange;
}

.highlight > .badge {
  transform: scale(1.2);
  border: 1px grey solid;
}

th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.pull-right {
  float: right;
}
</style>
