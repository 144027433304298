<template>
    <div class="card-body">
        <hr />
        <div class="row">
            <div class="col-md-4 upgrade-title">
                <p>Data Package / Speed</p>
                <span class="fa fa-database" style="color: orange"></span>
                <span class="fa fa-bolt" style="color: yellow"></span>
            </div>
        </div>
        <hr />
        <div class="row row-spacing">
             <div class="col-md-6">
                 <div v-if="saleDefinition.definition.uncappedService">
                     <div class="form-group">
                         <label>Select a Package</label>
                         <select
                            class="form-control"
                            v-model="saleDefinition.definition.uncappedService.connectivityPackageNumber"
                         >
                            <option 
                                :value="connectivityPackage.connectivityPackageNumber"
                                v-for="connectivityPackage in saleDefinition.selectableOptions.connectivityPackage"
                                :key="connectivityPackage.connectivityPackageNumber"
                                >
                                {{ connectivityPackage.description }}
                            </option>
                         </select>
                     </div>
                 </div>
                 
                 <div v-if="saleDefinition.definition.internetAccess">
                     <div class="form-group">
                         <label>Select a Package</label>
                         <select
                            class="form-control"
                             v-model="saleDefinition.definition.internetAccess.connectivityPackageNumber"
                         >
                            <option 
                                :value="internetConnectivityPackage.connectivityPackageNumber"
                                v-for="internetConnectivityPackage in saleDefinition.selectableOptions.internetConnectivityPackage"
                                :key="internetConnectivityPackage.connectivityPackageNumber"
                            >
                                {{ internetConnectivityPackage.description }}
                            </option>
                         </select>
                     </div>
                 </div>
                 <button class="btn btn-success" @click="confirmPackageChange()">Change Package</button>
             </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            saleNumber: null,
            shapedPools: [],
            saleDefinition: {
                definition: {
                    uncappedService: {},
                    internetAccess: {},
                },
                product: {},
                selectableOptions: {
                    connectivityPackage: [],
                    internetConnectivityPackage: []
                },
            },
            regradeProducts: [],
            availableProducts: [],
        }
    },
    mounted() {
        this.saleNumber = this.$route.params.saleNumber;
        this.getShapedPools();
        this.loadSaleDefinition();
        this.getRegradableProducts();
    },
    computed: {
        
    },
    methods: {
        getShapedPools: function() {
            const hostUrl = this.$config.aimsAPI;
            this.$http.get(`${hostUrl}shapedpools`).then(
                (response) => {
                    this.shapedPools = response.body;
                },
                (error) => {
                    this.showError("Error fetching Shaped Pools", error)
                    console.error(error);
                }
            );
        },

        loadSaleDefinition: function() {
            const hostUrl = this.$config.aimsAPI;
            this.$http.get(`${hostUrl}sales/definition/sale/${this.saleNumber}`).then(
                (response) => {
                    this.saleDefinition = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error loading Sale Definition", error);
                }
            );
        },

        getRegradableProducts: function() {
            const hostUrl = this.$config.aimsAPI;
            this.$http.get(`${hostUrl}sales/definition/${this.saleNumber}/regrades/allowed`).then(
                (response) => {
                    this.regradeProducts = response.data;
                   
                },
                (error) => {
                    console.error(error);
                    this.showError("Error loading Regradable Products");
                }
            );
        },

        confirmPackageChange: function() {
            this.$swal({
                title: "Are you sure you want to a different Package?",
                text: "The billing will change for this client",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.changePackage();
                }
            });
        },

        changePackage: function() {
            if (this.saleDefinition.definition.uncappedService) {
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = this.$route.params.saleNumber;
                const uncappedServiceObj = this.saleDefinition.definition.uncappedService;
                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, uncappedServiceObj).then(
                    (response) => {
                        this.showSuccess("Successfully changed Package");
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Changing Package", error);
                    }
                )
            } else if (this.saleDefinition.definition.internetAccess) {
                const hostUrl = this.$config.aimsAPI;
                const saleNumber = this.$route.params.saleNumber;
                const internetAccessServiceObj = this.saleDefinition.definition.internetAccess;
                this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update`, internetAccessServiceObj).then(
                    (response) => {
                        this.showSuccess("Successfully changed Package");
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Changing Package", error);
                    }
                )
            }
        },
    },
    
}
</script>

<style lang="scss" scoped>
.upgrade-title {
  font-size: 22px;
  text-align: center;
  padding: 5px;
}

</style>