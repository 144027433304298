<template>
    <div class="mt-3">
        <div class="row row-spacing">
            <div class="col-md-6">
                <label><strong>Service</strong></label>
                <multiselect
                    :options="servicesOptions"
                    placeholder="Select a service"
                    :show-labels="true"
                    :allow-empty="false"
                    deselect-label=""
                    label="name"
                    v-model="selectedService"
                >
                </multiselect>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-6">
                <label><strong>Upload Type</strong></label>
                <multiselect
                    :options="uploadOptions"
                    placeholder="Select an upload type"
                    :show-labels="false"
                    :allow-empty="false"
                    deselect-label=""
                    v-model="selectedUploadType"
                >
                </multiselect>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-6 mb-1" v-if="uploadTypeSelected === 'File'">
                <label><strong>File</strong> <i class="fa-solid fa-circle-info" v-tooltip="'[Format: .kml, .kmz]'"></i></label>
                <input type="file" class="form-control mb-1" @change="fileChanged($event)" ref="file">
                <small class="text-danger">{{ inputError }}</small>
            </div>
            <div class="col-md-6" v-if="uploadTypeSelected === 'URL'">
                <label><strong>URL</strong></label>
                <input type="text" class="form-control mb-1" v-model="uploadUrl">
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-6">
               <button class="btn dark-bg upload-btn mx-0" type="button" @click="uploadData()" :disabled="disableUploadButton">
                <div class="d-flex align-items-center justify-content-center">
                    <span class="mr-2">Begin Upload</span>
                    <Circle2 v-if="uploading"/>
                </div>
               </button>
            </div>
        </div>
        <hr />
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered">
                    <table class="table">
                        <thead>
                            <tr class="dark-row">
                                <th>Service</th>
                                <th>Input Type</th>
                                <th>Input</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                            </tr>
                        </thead>
                        <tbody v-if="updates.length > 0">
                            <tr v-for="update in updates" :key="update.updateNumber">
                                <td>{{update.service.name}}</td>
                                <td>{{update.inputType}}</td>
                                <td>{{update.input}}</td>
                                <td class="text-center text-white">
                                    <span :class="getStatusLabelClass(update.status)">{{update.status}}</span>
                                </td>
                                <td>{{update.message}}</td>
                                <td>{{update.startTime}}</td>
                                <td>{{update.endTime}}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="7">No uploads</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Circle2 } from "vue-loading-spinner";

export default { 
    components: {
        Circle2
    },
    props: {
        allServices: {
            type: Array,
            required: true
        }
    },  
    data() {
        return {
            serviceSelected: null,
            updates:[],
            fileSelected: {},
            uploadUrl: '',
            uploadTypeOptions: ["File", "URL"],
            uploading: false,
            services: [
                {
                    values: [],
                },
            ],
            uploadTypeSelected: "File",
            uploadTypes: [
                {
                    values: [],
                },
            ],
            timeoutQuery: null,
            inputError: ""
        }
    },
    mounted() {
        this.fetchUpdates();        
    },
    watch: {
        updates(val) {  
            val.forEach(element => {
                if (element.status === 'PROCESSING') {
                    if (this.timeoutQuery) clearTimeout(this.timeoutQuery);
                    this.timeoutQuery = setTimeout(() => this.fetchUpdates(), 4000);
                }
            });
        },
        fileSelected(val) {    
            const is_valid_file_name = val.name !== '' && (val.name?.includes('.kml') || val.name?.includes('.kmz')); 
            const name_has_not_been_set_after_upload = val.name === undefined;
            
            if (is_valid_file_name || name_has_not_been_set_after_upload) return this.inputError = "";

            return this.inputError = "Invalid file format: acceptable formats are, .kml and .kmz";
        }
    },
    computed: {
        servicesOptions() {
            return this.services[0].values = this.allServices;
        },
        uploadOptions() {
            return this.uploadTypes[0].values = this.uploadTypeOptions;
        },
        disableUploadButton() {                       
            const no_service_or_upload_type_selected = !this.serviceSelected || !this.uploadTypeSelected;
            const no_file_selected = (this.fileSelected.name === undefined || this.inputError !== "") && this.uploadTypeSelected === 'File';
            const no_upload_url_entered = this.uploadUrl === '' && this.uploadTypeSelected === 'URL';
            
            const should_be_disabled = no_service_or_upload_type_selected || no_file_selected || no_upload_url_entered;

            return should_be_disabled;
        },
        selectedService: {
            get() {
                return this.serviceSelected;
            },
            set (newVal) {
                this.serviceSelected = newVal;
            }
        },
        selectedUploadType: {
            get() {
                return this.uploadTypeSelected;
            },
            set(newVal) {
                this.uploadTypeSelected = newVal;
            }
        },
    },
    methods:{
        fileChanged: function(event) {           
            this.fileSelected = event.target.files[0];              
        },
        resetFile: function() {
            this.fileSelected = {};
            this.$refs.file.value = null;
            this.selectedUploadType = 'File';
            this.selectedService = null;
            this.inputError = "";            
        },
        uploadData: function() {
            this.uploading = true;
            const hostUrl = this.$config.aimsAPI;
            const service = this.serviceSelected.serviceNumber;
            const headers = { headers: { 'Content-Type': 'multipart/form-data' } };

            this.uploadTypeSelected === 'File' ? this.uploadWithfile(hostUrl, service, headers) : this.uploadWithUrl(hostUrl, service, headers);
        },
        uploadWithfile: function(hostUrl, service, headers) {            
            const formData = new FormData();
            formData.append('file', this.fileSelected);         
            
            this.$http.post(`${hostUrl}feasibility/services/${service}/upload/file`, formData, headers).then(
                (response) => {      
                    this.showSuccess("Uploading");                   
                    this.resetFile();                                
                    this.fetchUpdates();
                    this.uploading = false;
            },
            (error) => {
                console.error(error);
                this.showError('Error Uploading from File', error);
                this.uploading = false;
            });
        },
        uploadWithUrl: function(hostUrl, service, headers) {
            const formData = new FormData();
            formData.append('url', this.uploadUrl);
            
            this.$http.post(`${hostUrl}feasibility/services/${service}/upload/url`, formData, headers).then(
                (response) => {
                    this.showSuccess("Uploading"); 
                    this.uploadUrl = '';
                    this.selectedUploadType = 'File';
                    this.selectedService = null;
                    this.fetchUpdates();
                    this.uploading = false;
            }, 
            (error) => {
                console.error(error);
                this.showError('Error Uploading from URL', error);
                this.uploading = false;
            });
        },
        getStatusLabelClass: function(status) {
            if (status == 'SUCCESSFUL') return 'badge badge-active';
            if (status == 'FAILED' ) return 'badge badge-warning';
            return 'badge badge-info';
        },
        fetchUpdates: function() {
            const hostUrl = this.$config.aimsAPI;

            this.$http.get(`${hostUrl}feasibility/updates/list`).then(
                (response) => {
                    response.data.map((update) => {
                        update.service = this.allServices[this.allServices.findIndex(s => s.serviceNumber === update.serviceNumber)];
                    });

                    this.updates = response.data;
                }, 
                (error) => {
                    console.error(error);
                    this.showError('Error Fetching updates', error);
                }
            );
        }
    }
}
</script>

<style lang="scss" scoped>
.dark-bg {
    background-color: #3a3f51;
    color: #ffffff;
}
.upload-btn {
    width: 200px;
    .spinner.spinner--circle-2 {
        border-color:   #3a3f51 #ffffff #ffffff !important;
        width: 15px !important;
        height: 15px !important;
        border-width: 2px !important;
    }
}
.dark-row {
    background-color: #3a3f51;

    th {
        background-color: #3a3f51;
        color: #FFFFFF !important;
        position: sticky;
        top: 0;
        z-index: 2;
        font-size: .95em;
        width: calc(100px + 100em);
    }
    .th-width {
        width: calc(100px + 30em);
    }
}
</style>
