<template>
    <div class="col-md-12">
        <div class="row row-spacing">
            <div class="col-md-12">
                <button class="btn btn-info" @click="openDatabaseModal()" :disabled="saleDefinition.sale.database.sqlUser">Add a Database</button>
                <button class="btn btn-warning" @click="deleteDatabase(saleDefinition.sale.database)" :disabled="!saleDefinition.sale.database.sqlUser">Delete Database</button>
            </div>
        </div> 
        
            <div class="row row-spacing" v-if="saleDefinition.sale.database.sqlUser">
                <div class="col-md-12">
                    <div class="row row-spacing">
                        <div class="col-md-3">
                            <label>Database Name</label>
                            <input type="text" class="form-control" v-model="saleDefinition.sale.database.name" disabled>
                        </div>
                        <div class="col-md-3">
                            <label>Sql Username</label>
                            <input type="text" class="form-control" v-model="saleDefinition.sale.database.sqlUser.username" disabled>
                        </div>
                        <div class="col-md-3">
                            <label>Sql Password</label>
                            <input type="text" class="form-control" v-model="saleDefinition.sale.database.sqlUser.password">
                        </div>
                        <div class="col-md-3">
                            <label class="col-md-12">&nbsp;</label>
                            <button class="btn btn-warning" @click="resetPassword()">Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        
        <AddWebHostingDatabaseModal
            ref="addDatabaseModal"
            @saveDatabase="saveDatabase"
        />
    </div>
</template>

<script>
import AddWebHostingDatabaseModal from '@/components/Admin/Modals/AddWebHostingDatabaseModal';

export default {
    components: {
        AddWebHostingDatabaseModal
    },
    props: {
        newDatabase: {
            type: Object,
            required: true
        },
        saleDefinition: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
        }
    },
    methods: {
        saveDatabase: function(data) {
            const hostUrl = this.$config.aimsAPIv2;
            const saleNumber = this.$route.params.saleNumber;
            this.newDatabase.name = data.name;
            this.newDatabase.sqlUser = data.sqlUser;

            this.saleDefinition.sale.database = this.newDatabase;
            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                () => {
                    this.showSuccess("Save Success");
                    this.$emit("loadSaleDefinition");
                },
                (error) => {
                    console.error(error);
                    this.showError("Error Saving SaleDefinition", error);
                }
            ).finally(() => {
            })
        },
        openDatabaseModal: function() {
            this.$refs.addDatabaseModal.open();            
        },
        deleteDatabase: function(data) {
            this.$swal({
                title: "Are you sure you want to delete database?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPI;
                    const saleNumber = this.$route.params.saleNumber;
                    this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, data).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                            this.$emit("loadSaleDefinition");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error deleting Database", error);
                        }
                    );
                }
            });
        },
        resetPassword() {
            this.$swal({
                title: "Are you sure you want to reset the password?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    const hostUrl = this.$config.aimsAPIv2;
                    const saleNumber = this.$route.params.saleNumber;
                    this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
                        () => {
                            this.showSuccess("Save Success");
                            this.$emit("loadSaleDefinition");
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error Saving SaleDefinition", error);
                        }
                    ).finally(() => {
                    })
                }
            });
        }

    }
}
</script>

<style lang="scss" scoped>

</style>