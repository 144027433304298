<template>
  <service-screen-layout
    v-if="saleDefinition.definition.uncappedService"
    :parts="parts"
  >
    <template v-slot:account>
      <div class="row">
        <div class="col-md-7">
          <AccountDetails
            :connectivitypackage="saleDefinition.definition.uncappedService.connectivityPackage"
            :account="saleDefinition.definition.pppoe"
            :sale="saleDefinition.sale"
            @save-sale="saveSale()"
          />
        </div>
        <div class="col-md-5">
          <ConnectivityStatus
            v-if="saleDefinition.definition.pppoe.sessions.length > 0"
            v-allowed:view="['STAFF', 'CLIENT', 'RESELLER', 'SYSTEM']"
            :connectivityService="saleDefinition.definition.uncappedService"
            :session="session"
          />
          <ConnectivityPackage
            v-allowed:view="['STAFF']"
            :bundle="saleDefinition.definition.uncappedService.connectivityPackage"
          />
        </div>
      </div>
    </template>
    <template v-slot:ipaddresses>
      <div class="row">
        <div class="col-md-12">
          <AllocatedIPAddresses
              :editable="false"
              :heading="'Assigned Addresses'"
              :addresses="combinedAllocatedNetworks"
            />
        </div>
      </div>
      <div class="row" v-if="canAddOptionalRoutedIPv4Address">
        <div class="col-md-12">
          <RoutedIpv4Address 
            :address="saleDefinition.definition.pppoe.routedIPv4Network"
            :newRoutedIPv4AddressObj="saleDefinition.supportingObjs.routedIPv4Network"
            :pppoeAccountNumber="saleDefinition.definition.pppoe.pppoeAccountNumber"
            :saleNumber="saleDefinition.sale.saleNumber"
            @reload-sale="getSaleDefinition"
          />
        </div>
      </div>
    </template>
    <template v-slot:usage>
      <ConnectivityUsageGraph
        :currentDailyUsageData="currentDailyUsageData"
        :graphTitle="saleDefinition.product.description"
        :usageType="usageType"
        :timePeriod="usageCaption"
        :usageYear="usageYear"
        :saleStartYear="saleStartYear"
        :usagePeriod="usagePeriod"
        :totalUsage="totalUsage"
        :saleStartPeriod="saleStartPeriod"
        :saleStartDate="saleDefinition.sale.startDate"
        @previousMonthUsage="previousMonthUsage"
        @nextMonthUsage="nextMonthUsage"
        @hourlyUsage="hourlyUsage"
        @dailyUsage="dailyUsage"
        @yearlyUsage="yearlyUsage"
        @previousYearlyUsage="previousYearlyUsage"
        @nextYearlyUsage="nextYearlyUsage"
      />
    </template>
    <template v-slot:sessions>
      <div class="row row-spacing">
        <div class="col-md-12 card-body card-scroll-100">
          <RadiusSessions
            :sessions="saleDefinition.definition.pppoe.sessions"
            cpeAddressName="CPE Address"
            cpeAddressField="cpeAddress"
          />
        </div>
      </div>
    </template>
    <template
      v-slot:line
      v-if="saleDefinition.definition.line"
    >
      <div class="col-md-12">
        <ConnectivityLine
          v-allowed:view="['STAFF', 'CLIENT', 'RESELLER', 'SYSTEM']"
          :line="saleDefinition.definition.line"
          @saveSale="saveSale"
        />
      </div>
    </template>
    <template v-slot:setup v-if="saleDefinition.sale.saleNumber">
      <RouterConfig
        :routerConfig="saleDefinition.definition.routerConfig"
        :saleNumber="saleDefinition.sale.saleNumber"
        @routerConfigSaved="routerConfigSavedHandler"
        @reloadSale="getSaleDefinition"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import AccountDetails from "@/components/ObjParts/SingleConstructed/AccountDetails";
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import RadiusSessions from "@/components/ObjParts/MultipleLoaded/RadiusSessions";
import ApexUsageGraph from "@/components/Graphs/ApexUsageGraph";
import ConnectivityPackage from "@/components/ObjParts/SingleConstructed/ConnectivityPackage";
import ConnectivityStatus from "@/components/ObjParts/SingleConstructed/ConnectivityStatus";
import AllocatedIPAddresses from "@/components/ObjParts/MultipleCaptured/AllocatedIPAddresses";
import ConnectivityLine from "@/components/ObjParts/SingleCaptured/ConnectivityLine";
import RouterConfig from "@/components/ObjParts/OptionalCaptured/RouterConfig";
import ConnectivityUsageGraph from "@/components/Graphs/ConnectivityUsageGraph.vue";

import SaleFunctions from "@/pages/Services/SaleFunctions";
import RoutedIpv4Address from "@/components/ObjParts/OptionalCaptured/RoutedIpv4Address.vue";

export default {
  extends: SaleFunctions,
  components: {
    AccountDetails,
    ServiceScreenLayout,
    RadiusSessions,
    ApexUsageGraph,
    ConnectivityPackage,
    ConnectivityStatus,
    AllocatedIPAddresses,
    ConnectivityLine,
    RouterConfig,
    ConnectivityUsageGraph,
    RoutedIpv4Address
  },
  data() {
    return {
      parts: [
        {
          name: "account",
          icon: "fa fa-user-circle",
          displayName: "Account",
          permissions: ["*"],
        },
        {
          name: "ipaddresses",
          icon: "fa fa-plus-square",
          displayName: "IP Addresses",
          permissions: ["STAFF"],
        },
        {
          name: "usage",
          icon: "fa fa-area-chart",
          displayName: "Usage",
          permissions: ["*"],
        },
        {
          name: "sessions",
          icon: "fa fa-clock-o",
          displayName: "Sessions",
          permissions: ["STAFF"],
        },
        {
          name: "line",
          icon: "fa fa-link",
          displayName: "Line",
          permissions: ["*"],
        },
        {
          name: "setup",
          icon: "fa fa-cogs",
          displayName: "Setup",
          permissions: ["STAFF"],
        },
      ],
      saleNumber: null,
      saleDefinition: {
        product: {
          classificationProvider: "",
        },
        sale: {
          created: null,
          saleNumber: null,
        },
        definition: {
          routerConfig: {},
          pppoe: {
            allocatedIPv4Network: {},
            allocatedIPv6Network: {},
            routedIPv4Network: {},
            sessions: [],
          },
        },
        selectableOptions: {
          bundle: [],
          topup: [],
        },
        supportingEnums: {
          account: {
            cappedType: [],
          },
          allocatedIPv4Addresses: {
            family: [],
          },
          allocatedIPv6Addresses: {
            family: [],
          },
        },
        supportingObjs: {
          dyndns: {},
          discount: {},
          dyndnsdomain: {},
          routerConfig: {},
        },
      },
      newUsageRecipient: "",
      usagePeriod: "",
      usageYear: "",
      usageCaption: "",
      totalUsage: 0,
      currentDailyUsageData: [],
      netmasksV4: [32],
      netmasksV6: [64],
      combinedIpAddresses: [],
      assignChoices: {
        assignIPFamily: null,
        assignIPNetmask: null,
      },
      filteredNetMaskList: [],
      listUsagePeriods: [],
      saleStartPeriod: null,
      usageType: "",
      saleStartYear: null,
    };
  },
  computed: {
    session() {
      return this.saleDefinition.definition.pppoe.sessions.reduce((a, b) =>
        a.lastUpdate > b.lastUpdate ? a : b
      );
    },
    canAddOptionalRoutedIPv4Address() {
      const allowedProducts = ["FrogfootUncappedFtto"];
      return allowedProducts.includes(this.saleDefinition.product.productDefinition);
    },
    combinedAllocatedNetworks() {    
      const allocatedNetworks = [];
      allocatedNetworks.push(this.saleDefinition.definition.pppoe.allocatedIPv4Network, this.saleDefinition.definition.pppoe.allocatedIPv6Network);
      return allocatedNetworks;
    },
  },
  mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.usagePeriod = this.getCurrentPeriod();
    this.usageYear = ("" + this.getCurrentPeriod()).substring(0, 4);
    this.getSaleDefinition();
  },
  methods: {
    getSaleDefinition: function() {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
        (response) => {
          this.saleDefinition = response.data;                    

          if (!this.saleDefinition.definition.line) {
            this.parts = this.parts.filter((p) => p.name != "line");
          }

          this.saleStartPeriod = this.dateToPeriod(this.saleDefinition.sale.startDate);
          this.saleStartYear = this.saleStartPeriod.substring(0, 4);
          this.saleDefinition.definition.pppoe.fullUsername = `${this.saleDefinition.definition.pppoe.username}@${this.saleDefinition.definition.pppoe.pppoeRealm.name}`;
          this.translateCurrentDailyUsageData(this.saleDefinition.definition.pppoe.dailySummary);
          this.getCurrentMonthDataUsage();

          if (this.saleDefinition.definition.lt2p != null) {
            this.combineIpv4andIPv6();
          }
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      )
    },
    getCurrentMonthDataUsage: function() {
      if (this.usagePeriod === this.getCurrentPeriod()) {
        const hostUrl = this.$config.aimsAPI;
        const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
        const usagePeriod = this.usagePeriod;

        this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${usagePeriod}/daily`).then(
            (response) => {                            
              this.translateCurrentDailyUsageData(response.data);
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Usage", error);
            }
          );
      }
    },
    saveSale: function () {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.saleDefinition.definition.pppoe.fullUsername =
                  this.saleDefinition.definition.pppoe.username +
                  "@" +
                  this.saleDefinition.definition.pppoe.pppoeRealm.name;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Pppoe Account", error);
              }
            );
        }
      });
    },
    routerConfigSavedHandler: function(routerConfig) {
      this.saleDefinition.definition.routerConfig = routerConfig;
      this.saveSale();
    },
    dynDnsChanged: function (dyndns) {
      this.saleDefinition.definition.dyndns = dyndns;
    },
    assignIPTypeChanged: function () {
      this.assignChoices.assignIPNetmask = null;

      if (this.assignChoices.assignIPFamily === "IPV6") {
        this.filteredNetMaskList = this.netmasksV6;
        this.assignChoices.assignIPNetmask = this.filteredNetMaskList[0];
      } else {
        this.filteredNetMaskList = this.netmasksV4;
        this.assignChoices.assignIPNetmask = this.filteredNetMaskList[0];
      }
    },
    changeIpv4Range: function(range) {
      this.$swal({
        title:
          "Are you sure you want to change the IPv4 range to /" + range + "?",
        type: "warning",
        text: "The current IP range will be unassigned",
        showCancelButton: true,
        cancelButtonColor: "",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          const hostUrl = this.$config.aimsAPI;
          const saleNumber = this.saleNumber;
          this.saleDefinition.definition.pppoe.allocatedIPv4Network.shortMask = range;
          const allocatedIpv4Network = this.saleDefinition.definition.pppoe.allocatedIPv4Network;

          this.$http.put(`${hostUrl}sales/definition/${saleNumber}/obj/update/`, allocatedIpv4Network).then(
              (response) => {
                this.saleDefinition.definition.pppoe.allocatedIPv4Network = response.data;
                this.showSuccess("IPv4 Network updated successfully");
              },
              (error) => {
                console.error(error);
                this.showError("Error Updating IPv4 Network", error);
              }
            );
        }
      });
    },
    topupAdded: function (topupAdded) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      const accountTopup = {
        class: "za.co.adept.aims.connectivity.ConnectivityTopupAllocation",
        userReadOnly: false,
      };
      accountTopup.connectivityTopupNumber = topupAdded.objKey;
      accountTopup.cappedConnectivityServiceNumber = this.saleDefinition.definition.cappedService.cappedConnectivityServiceNumber;
      accountTopup.pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create/`, accountTopup).then(
          (response) => {
            response.data.isNew = true;
            response.data.topup = this.deepCopyObject(topupAdded);
            this.saleDefinition.definition.cappedService.allocatedTopups.unshift(response.data);
            this.saleDefinition.definition.cappedService.currentlyCapped = false;
            this.showSuccess("Topup successfully added");
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Topup", error);
          }
        );
    },
    assignAddress: function () {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      if (this.assignChoices.assignIPFamily != null) {
        const addaddress = this.deepCopyObject(
        this.saleDefinition.supportingObjs.allocatedIPv4Addresses
      );

      addaddress.family = this.assignChoices.assignIPFamily;
      addaddress.shortMask = this.assignChoices.assignIPNetmask;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create/`, addaddress).then(
          (response) => {
            response.data.isNew = true;
            response.data.allocatedIPv4Addresses = this.deepCopyObject(addaddress);
            this.saleDefinition.definition.pppoe.allocatedIPv4Addresses.unshift(response.data);
            this.showSuccess("IP address successfully added");
          },
          (error) => {
            console.error(error);
            this.showError("Error Assigning IP", error);
          }
        );
      }
    },
    dynDnsDelete: function(dyndns) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;
      
      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, dyndns).then(
          (response) => {
            this.showSuccess("Dyn DNS successfully removed");
            this.saleDefinition.definition.dyndns = {};
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Dyn DNS", error);
          }
        );
    },
    removeAddress: function(addressToRemove) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, addressToRemove).then(
          (response) => {
            if (addressToRemove.family == "IPV6") {
              const allocatedIPv6Addresses = this.saleDefinition.definition.pppoe.allocatedIPv6Addresses;
              const index6 = allocatedIPv6Addresses.findIndex((u) => u.pppoeipv6NetworkNumber === addressToRemove.pppoeipv6NetworkNumber);
              this.saleDefinition.definition.pppoe.allocatedIPv6Addresses.splice(index6, 1);
            } else {
              const allocatedIPv4Addresses = this.saleDefinition.definition.pppoe.allocatedIPv4Addresses;
              const index4 = allocatedIPv4Addresses.findIndex((u) => u.pppoeipv4NetworkNumber === addressToRemove.pppoeipv4NetworkNumber);
              this.saleDefinition.definition.pppoe.allocatedIPv4Addresses.splice(index4, 1);
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Allocated Address", error);
          }
        );
    },
    recipientAdded: function(recipientNumber) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      const newUsageRecipient = {};
      newUsageRecipient.class = "za.co.adept.aims.connectivity.CappedServiceUsageRecipient";
      newUsageRecipient.cappedConnectivityServiceNumber = this.saleDefinition.definition.cappedService.cappedConnectivityServiceNumber;
      newUsageRecipient.recipient = recipientNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/create/`, newUsageRecipient).then(
          (response) => {
            if (this.saleDefinition.definition.cappedService) {
              this.saleDefinition.definition.cappedService.usageRecipients.push(response.data);
            } else {
              this.saleDefinition.definition.uncappedService.usageRecipients.push(response.data);
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Usage Recipient", error);
          }
        );
    },
    recipientRemoved: function(usageRecipient) {
      const hostUrl = this.$config.aimsAPI;
      const saleNumber = this.saleNumber;

      this.$http.post(`${hostUrl}sales/definition/${saleNumber}/obj/delete/`, usageRecipient).then(
          (response) => {
            if (this.saleDefinition.definition.cappedService) {
              const cappedUsageRecipients = this.saleDefinition.definition.cappedService.usageRecipients;
              const index = cappedUsageRecipients.findIndex((u) => u.usageRecipientNumber === usageRecipient.usageRecipientNumber);
              this.saleDefinition.definition.cappedService.usageRecipients.splice(index, 1);
            } else {
              const uncappedUsageRecipients = this.saleDefinition.definition.uncappedService.usageRecipients;
              const index = uncappedUsageRecipients.findIndex((u) => u.usageRecipientNumber === usageRecipient.usageRecipientNumber);
              this.saleDefinition.definition.uncappedService.usageRecipients.splice(index, 1);
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Removing Usage Recipient", error);
          }
        );
    },
    hourlyUsage: function(day) {
      const lastDay = new Date(
        new Date(this.usageCaption).getFullYear(),
        new Date(this.usageCaption).getMonth() + 1,
        0
      ).getDate();
      if (day == "previous") {
        day = parseInt(this.usageCaption.split(" ")[0]) - 1;
      } else if (day == "next") {
        day = parseInt(this.usageCaption.split(" ")[0]) + 1;
      }
      if (day < 1) {
        day = new Date(
          new Date(this.usageCaption).getFullYear(),
          new Date(this.usageCaption).getMonth(),
          0
        ).getDate();
        if (("" + this.usagePeriod).substring(4, 6) == "01") {
          this.usagePeriod = this.usagePeriod - 100 + 11;
          this.usageYear--;
        } else {
          this.usagePeriod--;
        }
      } else if (day > lastDay) {
        day = 1;
        if (("" + this.usagePeriod).substring(4, 6) == "01") {
          this.usagePeriod = this.usagePeriod - 100 + 11;
          this.usageYear++;
        } else {
          this.usagePeriod++;
        }
      }
      this.$http
        .get(
          this.$config.aimsAPI +
            "connectivityaccounts/pppoe/" +
            this.saleDefinition.definition.pppoe.pppoeAccountNumber +
            "/usage/" +
            this.usagePeriod +
            "/day/" +
            day +
            "/hourly"
        )
        .then((response) => {
          this.translateCurrentHourlyUsageData(response.data, day);
        });
    },
    translateCurrentHourlyUsageData: function (usageData, day) {
      this.currentDailyUsageData = [];
      this.totalUsage = 0;
      this.usageCaption =
        day + " " + this.prettifyBillingPeriod(this.usagePeriod);
      this.usageType = "hourly";
      usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        const translatedDataUsageHour = {
          label: c.hour,
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
        this.currentDailyUsageData.push(translatedDataUsageHour);
      });
    },
    dailyUsage: function(month, year) {
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
      const startPeriod =  parseInt(this.saleStartPeriod);  

      this.usagePeriod = this.usagePeriod.toString();
      this.usagePeriod = `${year}${month}`;
      this.usagePeriod = parseInt(this.usagePeriod);  

      if (this.usagePeriod < startPeriod) {        
        this.usagePeriod = startPeriod;
      } 
      
      if (this.usagePeriod > parseInt(this.getCurrentPeriod())) {
        this.usagePeriod = parseInt(this.getCurrentPeriod());
      }

      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/daily`).then(
          (response) => {
            this.translateCurrentDailyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    translateCurrentDailyUsageData: function (usageData) {
      this.currentDailyUsageData = {};
      this.totalUsage = 0;
      this.usageCaption = "" + this.prettifyBillingPeriod(this.usagePeriod);
      this.usageType = "daily";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      usageData = usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        return {
          label: c.day + " " + months[c.month - 1],
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
      });
      this.currentDailyUsageData = {
        tooltip: "Click for hourly usage",
        data: usageData,
      };
    },
    previousMonthUsage() {
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
      const usageMonth = this.usagePeriod.toString().substring(4, 6);  

      if (usageMonth === "01") {
        this.usagePeriod = this.usagePeriod - 100 + 11;
        this.usageYear--;
      } else {
        this.usagePeriod--;
      }

      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/daily`).then(
          (response) => {
            this.translateCurrentDailyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    nextMonthUsage: function() {
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
      const usageMonth = this.usagePeriod.toString().substring(4, 6);   

      if (this.usagePeriod < this.getCurrentPeriod()) {
        if (usageMonth === "12") {
          this.usagePeriod = this.usagePeriod + 100 - 11;
          this.usageYear++;
        } else {
          this.usagePeriod++;
        }

        this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${this.usagePeriod}/daily`).then(
            (response) => {
              this.translateCurrentDailyUsageData(response.data);
            },
            (error) => {
              console.error(error);
              this.showError("Error Fetching Usage", error);
            }
          );
      }
    },
    yearlyUsage: function() {
      const hostUrl = this.$config.aimsAPI;
      const pppoeAccountNumber = this.saleDefinition.definition.pppoe.pppoeAccountNumber;
      const qryPeriod = `${this.usageYear}12`;

      this.usageCaption = this.usageYear;

      this.$http.get(`${hostUrl}connectivityaccounts/pppoe/${pppoeAccountNumber}/usage/${qryPeriod}/monthly`).then(
          (response) => {
            this.translateMonthlyUsageData(response.data);
          },
          (error) => {
            console.error(error);
            this.showError("Error Fetching Usage", error);
          }
        );
    },
    previousYearlyUsage: function() {
      this.usageYear--;
      this.yearlyUsage();
    },
    nextYearlyUsage: function() {
      this.usageYear++;
      this.yearlyUsage();
    },
    translateMonthlyUsageData: function (usageData) {
      this.currentDailyUsageData = {};
      this.totalUsage = 0;
      this.usageType = "monthly";
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      usageData = usageData.map((c) => {
        this.totalUsage += c.inBytes + c.outBytes;
        return {
          label: months[c.month - 1],
          downloaded: c.outBytes,
          uploaded: c.inBytes,
        };
      });
      this.currentDailyUsageData = {
        tooltip: "Click for daily usage",
        data: usageData,
      };
    },
    combineIpv4andIPv6: function () {
      this.combinedIpAddresses = [];
      this.combinedIpAddresses = this.saleDefinition.definition.pppoe.allocatedIPv4Addresses.concat(
        this.saleDefinition.definition.pppoe.allocatedIPv6Addresses
      );
    },
    combineDataAndTopups: function () {
      this.combinedDataAllocated = [];
      this.combinedDataAllocated = this.saleDefinition.definition.pppoe.allocatedIPv4Addresses.concat(
        this.saleDefinition.definition.pppoe.allocatedIPv6Addresses
      );
    },
  },
};
</script>
