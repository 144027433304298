<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:email>
      <div class="card-header" >
        <h4>Email Hosting</h4>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Domain</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.maildomain.toString"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Domain Type</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.maildomain.type"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <table class="table table-bordered row-spacing">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Type</th>
                  <th>Password</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(account, index) in saleDefinition.definition.accounts" :key="index" :class="{'new-table-row': account.isNew}">
                  <td>{{account.username}}</td>
                  <td>{{account.type}}</td>
                  <td>{{account.password}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:settings>
      <div class="row">
        <div class="col-md-8">
          <div class="card-header" >
              <h4>{{saleDefinition.product.toString}}</h4>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label>Web Site</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      v-model="saleDefinition.definition.website.toString"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="saleDefinition.product.productNumber == 438
              || saleDefinition.product.productNumber == 515 
              || saleDefinition.product.productNumber == 518">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">PHP 5</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enablePHP5"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">PERL</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enablePERL"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">Webstats</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        disabled
                        v-model="saleDefinition.definition.website.enableWebstats"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row" 
                v-if="saleDefinition.product.productNumber == 434
                || saleDefinition.product.productNumber == 406 
                || saleDefinition.product.productNumber == 556"
              >
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">SSL</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enableSSL"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4" v-if="saleDefinition.product.productNumber == 434">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">ASP</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enableASP"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4" v-if="saleDefinition.product.productNumber == 434">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">ASPX</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enableASPX"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" 
              v-if="saleDefinition.product.productNumber != 489
              && saleDefinition.product.productNumber != 434">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">PHP 5</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enablePHP5"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">CGI</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enableCGI"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">PERL</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enablePERL"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row"
              v-if="saleDefinition.product.productNumber != 489
              && saleDefinition.product.productNumber != 403
              && saleDefinition.product.productNumber != 559">
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">SSI</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enableSSI"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">Webstats</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox" 
                        v-model="saleDefinition.definition.website.enableWebstats"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <div class="input-group">
                    <label class="col-md-8">Backups</label>
                      <input 
                        type="checkbox"
                        class="form-control col-md-4 checkbox"
                        v-model="saleDefinition.definition.website.enableBackups"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-row row-spacing justify-content-between" v-allowed:view="['STAFF']">
              <div class="form-group col-auto">
                <button type="button" @click="saveSaleDefinition()" class="btn btn-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:admins>
      <div class="card-header" >
        <h4>Web Administrators</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <button type="button" class="btn btn-info" @click="openAdminModal()" v-allowed:view="['STAFF']">Add Admin</button>
            <table class="table table-bordered row-spacing">
              <thead>
                <tr>
                  <th>Username</th>
                  <th>Directory</th>
                  <th v-allowed:view="['STAFF']"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(admin, index) in saleDefinition.definition.website.admins" :key="index" :class="{'new-table-row': admin.isNew}">
                  <td>{{admin.username}}</td>
                  <td>{{admin.directory}}</td>
                  <td style="text-align:center" v-allowed:view="['STAFF']">
                    <button type="button" class="btn btn-info" @click="openAdminModal(admin)"><i class="fa fa-edit"></i></button>
                    <button type="button" class="btn btn-red" @click="deleteAdmin(admin)"><i class="fa fa-trash" style="color: white;"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    
      <modal
        :title="newAdmin.objKey ? 'Edit Admin' : 'Add Admin'"
        ref="adminModal"
        size="modal-md"
        @save="saveAdmin()"
        @dismiss="dismissAdminModal()"
      >
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>Username:</label>
              <input
                class="form-control"
                type="text"
                v-model="newAdmin.username"
                :disabled="newAdmin.objKey != 0"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>Password:</label>
              <input
                class="form-control"
                type="text"
                v-model="newAdmin.password"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>Directory:</label>
              <input
                class="form-control"
                type="text"
                v-model="newAdmin.directory"
              />
            </div>
          </div>
        </div>
        
      </modal>
    </template>

    <template v-slot:alias>
      <div class="card-header" >
        <h4>Web Aliases</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <button type="button" class="btn btn-info" @click="openAliasModal()" v-allowed:view="['STAFF']">Add Alias</button>
            <table class="table table-bordered row-spacing">
              <thead>
                <tr>
                  <th>Alias</th>
                  <th v-allowed:view="['STAFF']"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(alias, index) in saleDefinition.definition.website.aliases" :key="index" :class="{'new-table-row': alias.isNew}">
                  <td>{{alias.toString}}</td>
                  <td style="text-align:center" v-allowed:view="['STAFF']">
                    <button type="button" class="btn btn-red" @click="deleteAlias(alias)"><i class="fa fa-trash" style="color: white;"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

     <modal
        title="Add Alias"
        ref="aliasModal"
        size="modal-md"
        @save="saveAlias()"
        @dismiss="dismissAliasModal()"
      >
        <div class="row">
          <div class="col-md-10">
            <div class="form-group">
              <label>Host:</label>
              <v-select 
                name="host"
                placeholder="Select an Alias"
                :options="hosts" 
                label="name" 
                valueProp="hostNumber"
                v-model="newAlias.hostNumber" 
              ></v-select>
            </div>
          </div>
        </div>
        
      </modal>
    </template>

    <template v-slot:sql>
      <div class="card-header" >
        <h4>MySQL Database</h4>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Database Name</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.sql.name"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Database Type</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  v-model="saleDefinition.definition.sql.type"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="card-body">
            <button type="button" class="btn btn-info" @click="openSqlModal()">Add User</button>
            <table class="table table-bordered row-spacing">
              <thead>
                <tr>
                  <th>SQL Username</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sql, index) in saleDefinition.definition.sql.sqlUser" :key="index" :class="{'new-table-row': sql.isNew}">
                  <td>{{sql.username}}</td>
                  <td style="text-align:center">
                    <button type="button" class="btn btn-info" @click="openSqlModal(sql)"><i class="fa fa-edit"></i></button>
                    <button type="button" class="btn btn-red" @click="deleteSql(sql)"><i class="fa fa-trash" style="color: white;"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    
      <modal
        :title="newSql.objKey ? 'Edit User' : 'Add User'"
        ref="sqlModal"
        size="modal-lg"
        @save="saveSql()"
        @dismiss="dismissSqlModal()"
      >
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Username:</label>
                <input
                  class="form-control"
                  type="text"
                  maxlength="16"
                  v-model="newSql.username"
                  :disabled="newSql.objKey != 0"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Password:</label>
                <PasswordInput
                  :password="newSql.password"
                  :userCanEdit="true"
                  @changePassword="changePassword"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" style="max-height: 350px; overflow-y: scroll">
            <div class="form-group">
              <label>User Rights</label>
            </div>
            <div class="form-group">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.alterPriv"/></td>
                    <td><span>alterPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.createPriv"/></td>
                    <td><span>createPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.createTmpTablePriv"/></td>
                    <td><span>createTmpTablePriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.createViewPriv"/></td>
                    <td><span>createViewPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.deletePriv"/></td>
                    <td><span>deletePriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.dropPriv"/></td>
                    <td><span>dropPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.executePriv"/></td>
                    <td><span>executePriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.filePriv"/></td>
                    <td><span>filePriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.grantPriv"/></td>
                    <td><span>grantPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.indexPriv"/></td>
                    <td><span>indexPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.insertPriv"/></td>
                    <td><span>insertPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.lockTablePriv"/></td>
                    <td><span>lockTablePriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.processPriv"/></td>
                    <td><span>processPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.referencesPriv"/></td>
                    <td><span>referencesPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.reloadPriv"/></td>
                    <td><span>reloadPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.replClientPriv"/></td>
                    <td><span>replClientPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.replSlavePriv"/></td>
                    <td><span>replSlavePriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.selectPriv"/></td>
                    <td><span>selectPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.showDbPriv"/></td>
                    <td><span>showDbPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.showViewPriv"/></td>
                    <td><span>showViewPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.shutdownPriv"/></td>
                    <td><span>shutdownPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.superPriv"/></td>
                    <td><span>superPriv</span></td>
                  </tr>
                  <tr>
                    <td><input type="checkbox" v-model="newSql.updatePriv"/></td>
                    <td><span>updatePriv</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </modal>
      
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    Modal,
    PasswordInput
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "settings",
          icon: "fa fa-cog",
          displayName: "Settings",
          permissions: ["*"],
        },
        {
          name: "admins",
          icon: "fa fa-user-circle",
          displayName: "Admins",
          permissions: ["*"],
        },
        {
          name: "alias",
          icon: "fa fa-tag",
          displayName: "Aliases",
          permissions: ["*"],
        },
      ],
      partsIni: false,
      saleDefinition: {
        definition: {
            website: {
                admins: [],
                aliases: [],
            }
        },
        product: {

        }
      },
      newAdmin: {},
      newAlias: {},
      newSql: {},
      newAccount: {},

      hosts: [],
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.loadSaleDefinition();

    this.$http.get(this.$config.aimsAPIvLegacy + this.$route.params.clientNumber + "/hosts")
    .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].domain) {
            this.hosts.push({ name: response.data[i].name + "." + response.data[i].domain.name,
                            hostNumber: response.data[i].hostNumber});
          } else {
            this.hosts.push({ name: response.data[i].name,
                              hostNumber: response.data[i].hostNumber});
          }
        }
      },(error) => {
        console.error(error);
        this.showError("Error Saving Sale Definition", error);
      }
    );
  },
  methods: {
    loadSaleDefinition() {
      this.getSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
          if (!this.partsIni) {
            this.initializeParts();
          }

          this.dismissAdminModal();
          this.dismissAliasModal();
          if (this.$refs.sqlModal) {
            this.dismissSqlModal();
          }
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    changePassword: function(data) {
        if (data) {
          this.newSql.password = data;
        }
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleNumber + "/update", this.saleDefinition)
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    initializeParts() {
      this.partsIni = true;
      if (this.saleDefinition.definition.maildomain) {
        this.parts.push(
          {
            name: "email",
            icon: "fa fa-envelope",
            displayName: "Email",
            permissions: ["STAFF"],
          }
        )
      }
      if (this.saleDefinition.definition.sql) {
        this.parts.push(
          {
            name: "sql",
            icon: "fa fa-database",
            displayName: "MySQL",
            permissions: ["STAFF"],
          }
        )
      }
    },

    openAdminModal(data) {
      if (data) {
        this.newAdmin = data;
      } else {
        this.newAdmin = this.saleDefinition.supportingObjs.admins;
        this.newAdmin.saleNumber = this.saleDefinition.definition.website.saleNumber;
        this.newAdmin.websiteNumber = this.saleDefinition.definition.website.websiteNumber;
      }
      this.$refs.adminModal.open();
    },
    dismissAdminModal() {
      this.newAdmin = {};
      this.$refs.adminModal.close();
    },
    saveAdmin() {
      this.$refs.adminModal.isLoading();
      this.saleDefinition.definition.website.admins.push(this.newAdmin);
      this.saveSaleDefinition();
    },
    deleteAdmin(data) {
      this.deleteObjPart(data)
        .then((response) => {
          this.showSuccess("Delete Success");
          this.loadSaleDefinition()
        },(error) => {
          console.error(error);
          this.showError("Error Deleting Admin", error);
        });
    },

    openAliasModal(data) {
      if (data) {
        this.newAlias = data;
      } else {
        this.newAlias = this.saleDefinition.supportingObjs.aliases;
        this.newAlias.saleNumber = this.saleDefinition.definition.website.saleNumber;
        this.newAlias.websiteNumber = this.saleDefinition.definition.website.websiteNumber;
      }
      this.$refs.aliasModal.open();
    },
    dismissAliasModal() {
      this.newAlias = {};
      this.$refs.aliasModal.close();
    },
    saveAlias() {
      this.$refs.aliasModal.isLoading();
      this.saleDefinition.definition.website.aliases.push(this.newAlias);
      this.saveSaleDefinition();
    },
    deleteAlias(data) {
      this.deleteObjPart(data)
        .then((response) => {
          this.showSuccess("Delete Success");
          this.loadSaleDefinition()
        },(error) => {
          console.error(error);
          this.showError("Error Deleting Alias", error);
        });
    },

    openSqlModal(data) {
      if (data) {
        this.newSql = data;
      } else {
        this.newSql = this.saleDefinition.supportingObjs.sqlUser;
        this.newSql.saleNumber = this.saleDefinition.definition.sql.saleNumber;
        this.newSql.sqlDatabaseNumber = this.saleDefinition.definition.sql.sqlDatabaseNumber;
      }
      this.$refs.sqlModal.open();
    },
    dismissSqlModal() {
      this.newSql = {};
      if (this.$refs.sqlModal) {
        this.$refs.sqlModal.close();
      }
    },
    saveSql() {
      this.$refs.sqlModal.isLoading();
      this.saleDefinition.definition.sql.sqlUser.push(this.newSql);
      this.saveSaleDefinition();
    },
    deleteSql(data) {
      this.deleteObjPart(data)
        .then((response) => {
          this.showSuccess("Delete Success");
          this.loadSaleDefinition()
        },(error) => {
          console.error(error);
          this.showError("Error Deleting SQL User", error);
        });
    },
  },
};
</script>

<style>
input.checkbox.col-md-4 {
    max-width: 1em;
    left: -1em;
}
</style>