<template>
  <service-screen-layout :parts="parts">
    <template v-slot:admin>
      <div class="row">
        <div class="col-md-9">
          <div class="card card-default">
            <div class="card-header">
              <h4>Admin Details</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label">Domain: </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        class="form-control-plaintext"
                        readonly
                        :value="saleDefinition.sale.domain.domainName"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      >Admin Username:
                    </label>
                    <div class="col-md-7">
                      <input
                        type="text"
                        class="form-control-plaintext"
                        readonly
                        :value="getUsername(saleDefinition.sale.domain)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group row">
                    <label class="col-md-4 col-form-label"
                      >Current Password:
                    </label>
                    <div class="col-md-7">
                      <PasswordInput
                        :password="saleDefinition.sale.domain.adminPassword"
                        :userCanEdit="true"
                        @changePassword="changePassword"
                        validation="zimbra"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3" v-allowed:view="['STAFF']">
                  <div class="form-group">
                    <button
                      class="btn btn-success pull-right"
                      @click="saveSale"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:domainalias>
      <div class="row">
        <div class="col-md-9">
          <div class="card card-default">
            <div class="card-header">
              <h4>Domain Aliases</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <table
                    class="table table-bordered"
                    v-if="
                      saleDefinition.sale.domain.domainAliases.length > 0
                    "
                  >
                    <thead>
                      <tr>
                        <th>Domain Alias</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="domainAlias in saleDefinition.sale.domain
                          .domainAliases"
                        :key="domainAlias.zimbraDomainAliasNumber"
                      >
                        <td>{{ domainAlias.domainName }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="alert alert-info">
                    No Domain Aliases to display
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:mailboxes>
      <div class="row">
        <div class="col-md-9">
          <div class="card card-default">
            <div class="card-header">
              <h4>Mailboxes</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <table
                    class="table table-bordered"
                    v-if="saleDefinition.sale.domain.mailboxes.length > 0"
                  >
                    <thead>
                      <tr>
                        <th>Email address</th>
                        <th>Package</th>
                        <th>Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="mailbox in saleDefinition.sale.domain
                          .mailboxes"
                        :key="mailbox.zimbraMailboxNumber"
                      >
                        <td>{{ mailbox.displayName }}</td>
                        <td>{{ mailbox.mailboxPackage.description }}</td>
                        <td>{{ sizeBytesToMb(mailbox.usage.usage) }} MB</td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="alert alert-info">
                    No Mailboxes to display
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:emailalias>
      <div class="row">
        <div class="col-md-9">
          <div class="card card-default">
            <div class="card-header">
              <h4>Email Aliases</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-10">
                  <table
                    class="table table-bordered"
                    v-if="
                      saleDefinition.sale.domain.accountAliases.length > 0
                    "
                  >
                    <thead>
                      <tr>
                        <th>Email Address</th>
                        <th>Destination</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="accountAlias in saleDefinition.sale.domain
                          .accountAliases"
                        :key="accountAlias.zimbraMailboxAliasNumber"
                      >
                        <td>{{ accountAlias.displayName }}</td>
                        <td>
                          <p
                            v-for="alias in aliases(accountAlias.destination)"
                            :key="alias"
                          >
                            {{ alias }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-else class="alert alert-info">
                    No Email Aliases to display
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    PasswordInput,
  },
  watch: {
    saleDefinition: {
      deep: true,
      handler(newSaleDef, oldSaleDef) {
        if (oldSaleDef.sale.description != newSaleDef.sale.description && this.$parent.$refs.serviceTitle) {
          this.$parent.$refs.serviceTitle.reloadSale();
        }
      },
    },
  },
  data() {
    return {
      parts: [
        {
          name: "admin",
          icon: "fa fa-info-circle",
          displayName: "Admin",
          permissions: ["*"],
        },
        {
          name: "domainalias",
          icon: "fa fa-cloud",
          displayName: "Domain Alias",
          permissions: ["*"],
        },
        {
          name: "mailboxes",
          icon: "fa fa-envelope",
          displayName: "Mailboxes",
          permissions: ["*"],
        },
        {
          name: "emailalias",
          icon: "fa fa-envelop",
          displayName: "Email Alias",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          domain: {
            mailboxes: [],
            domainAliases: [],
            accountAliases: [],
          },
        },
      },
    };
  },
  mounted() {
    this.loadSaleDefinition();
  },
  methods: {
    loadSaleDefinition() {
      const saleNumber = this.$route.params.saleNumber;
      this.fetchSaleDefinition(saleNumber).then(
        (response) => {
          this.saleDefinition = response.data;
          console.log(this.saleDefinition);
        },
        (error) => {
          console.error(error);
          this.showError("Could not load Sale Definition", error);
        }
      );
    },
    getUsername({ adminUsername, domainName }) {
      return `${adminUsername}@${domainName}`;
    },
    aliases(aliases) {
      return aliases.split(",");
    },
    changePassword: function(data) {
        if (data) {
          this.saleDefinition.sale.domain.adminPassword = data;
        }
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          () => {
            this.showSuccess("Save Success");
            this.loadSaleDefinition();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    sizeBytesToMb(value) {
      if (value && value > 0) {
        return (value / 1024 / 1024).toFixed(2)
      } else {
        return 0;
      }
    }
  },
};
</script>
