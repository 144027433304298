<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <div class="card card-body">
          <form-wizard
            @on-complete="onComplete"
            title=""
            subtitle=""
            shape="tab"
            color="#d71921"
          >
            <tab-content
              title="Client Detail"
              icon="fa fa-address-card"
              :before-change="() => validateStep('client-detail')"
            >
              <form novalidate data-vv-scope="client-detail">
                <div class="row">
                  <div class="col-lg-6">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('client-detail.Client Type'),
                      }"
                    >
                      <label>Client Type *</label>
                      <select
                        name="Client Type"
                        v-validate="'required'"
                        v-model="combinedObject.client.type"
                        class="form-control"
                      >
                        <option
                          :value="clientType.name"
                          v-for="clientType in clientObjEnums.type"
                          :key="clientType.name"
                          :disabled="
                            clientType.name != 'PRIVATE' &&
                            clientType.name != 'CORPORATE'
                          "
                        >
                          {{ clientType.description }}
                        </option>
                      </select>
                      <span
                        class="has-error"
                        v-if="errors.has('client-detail.Client Type')"
                        >{{ errors.first("client-detail.Client Type") }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row" v-if="combinedObject.client.type == 'PRIVATE'">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>First Names</label>
                          <input
                            class="form-control"
                            v-model="combinedObject.client.firstnames"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Last Name</label>
                          <input
                            class="form-control"
                            v-model="combinedObject.client.lastname"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="combinedObject.client.type == 'CORPORATE'">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Company Name</label>
                          <input
                            class="form-control"
                            v-model="combinedObject.client.companyname"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label>Registered Company Name</label>
                          <input
                            class="form-control"
                            v-model="combinedObject.client.registeredCompanyName"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </tab-content>
            <tab-content
              title="Payment"
              icon="fa fa-money"
              :before-change="() => validateStep('client-payment')"
            >
              <form novalidate data-vv-scope="client-payment">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Payment Type</label>
                      <select
                        v-model="combinedObject.client.paymenttype"
                        class="form-control"
                      >
                        <option
                          :value="paymentType.name"
                          v-for="paymentType in clientObjEnums.paymenttype"
                          :key="paymentType.name"
                          :disabled="paymentType.name === 'INVOICE_RESELLER'
                          "
                        >
                          {{ paymentType.description }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="combinedObject.client.paymenttype == 'DEBIT'">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Account Type *</label>
                          <select
                            v-model="combinedObject.client.accounttype"
                            class="form-control"
                          >
                            <option
                              :value="accountType.name"
                              v-for="accountType in clientObjEnums.accounttype"
                              :key="accountType.name"
                            >
                              {{ accountType.description }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-if="combinedObject.client.accounttype != 'CREDITCARD'">
                      <b>Bank Details</b>
                      <hr />
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Account Holder</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.accountholder"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Bank Name</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.bank"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Account Number</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.accountnumber"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Branch Code</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.branchcode"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Branch Name</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.branch"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <b>Card Details</b>
                      <hr />
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Account Holder</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.accountholder"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Card Number</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.accountnumber"
                            />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Expiry Date (yyyymm)</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.expirydate"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>Card Type</label>
                            <select
                              v-model="combinedObject.client.cardtype"
                              class="form-control"
                            >
                              <option
                                :value="cardType.name"
                                v-for="cardType in clientObjEnums.cardtype"
                                :key="cardType.name"
                              >
                                {{ cardType.description }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="form-group">
                            <label>CVV2</label>
                            <input
                              class="form-control"
                              v-model="combinedObject.client.cvv2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </tab-content>
            <tab-content
              title="Client Billing Contact"
              icon="fa fa-phone"
              :before-change="() => validateStep('client-billing-contact')"
            >
              <form novalidate data-vv-scope="client-billing-contact">
                <div class="row row-spacing">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-12">
                        <div
                          class="form-group"
                          :class="{'has-error': errors.has('client-billing-contact.First Name'), }"
                        >
                          <label>First Names</label>
                          <input
                            class="form-control"
                            name="First Name"
                            v-validate="'required'"
                            v-model="combinedObject.person.firstNames"
                          />
                          <span class="has-error" v-if="errors.has('client-billing-contact.First Name')">
                            {{ errors.first("client-billing-contact.First Name") }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div
                          class="form-group"
                          :class="{ 'has-error': errors.has( 'client-billing-contact.Last Name' ), }"
                        >
                          <label>Last Name</label>
                          <input
                            class="form-control"
                            name="Last Name"
                            v-validate="'required'"
                            v-model="combinedObject.person.lastName"
                          />
                          <span class="has-error" v-if="errors.has('client-billing-contact.Last Name')">
                            {{ errors.first("client-billing-contact.Last Name") }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div
                          class="form-group"
                          :class="{
                            'has-error': errors.has(
                              'client-billing-contact.Email'
                            ),
                          }"
                        >
                          <label>Email</label>
                          <input
                            type="email"
                            name="Email"
                            v-validate="'required'"
                            class="form-control"
                            v-model="combinedObject.person.emailAddress"
                          />
                          <span
                            class="has-error"
                            v-if="errors.has('client-billing-contact.Email')"
                            >{{
                              errors.first("client-billing-contact.Email")
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-lg-12">
                        <div
                          class="form-group"
                          :class="{ 'has-error': errors.has( 'client-billing-contact.Password' ), }"
                        >
                          <div class="form-group">
                              <label>Password</label>
                              <PasswordInput 
                                name="Password"
                                :password="combinedObject.person.password" 
                                :userCanEdit="true"
                                @changePassword="changePassword"
                                v-validate="'verify_password'"
                              />
                              <span class="has-error" v-if="errors.has('client-billing-contact.Password')">
                              {{ errors.first("client-billing-contact.Password") }}</span> 

                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group" :class="{ 'has-error': errors.has( 'client-billing-contact.Mobile Number' ), }">
                            <label>Mobile Number <i class="fa fa-info-circle mx-2" v-tooltip="'This number may be used for SMS notifications'" style="cursor: pointer;"></i></label>
                            <input
                              class="form-control"
                              name="Mobile Number"
                              v-model="combinedObject.person.telephonecell"
                              v-validate="'required'"
                            />
                            <span class="has-error" v-if="errors.has('client-billing-contact.Mobile Number')">
                              {{ errors.first("client-billing-contact.Mobile Number") }}
                            </span> 
                        </div>
                      </div>
                    </div> 

                  </div>
                </div>
              </form>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ClientFunctions from "@/pages/ClientFunctions";
import PasswordInput from "@/components/GeneralComponents/InputControls/PasswordInput";

export default {
  extends: ClientFunctions,
  components: {
    FormWizard,
    TabContent,
    PasswordInput,
  },
  prop: {
    resellerNumber: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      combinedObject: {
        client: {},  
        person: {}
      },
      clientObjEnums: {},
      createdClient: null
    };
  },
  async mounted() {
    this.getClient("enums").then(
      (success) => {
        this.clientObjEnums = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Client Enums", error);
      }
    );

    this.getClient("emptyClientAndContact").then(
      (success) => {
        this.combinedObject = success.data; 
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Empty Client and Contact", error);
      }
    );
  },
  methods: {
    validateStep: function (stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete: function () {
      this.$http
        .post(this.$config.aimsAPI + "clients/createClientAndContact", this.combinedObject)
        .then(
          (response) => {
            this.createdClient = response.body;

            let clientNo = this.createdClient.clientNumber;
            this.showSuccess(`Client and Contact Created Successfully - # ${clientNo}`);
            this.$router.push({
                  name: "clientsales",
                  params: { clientNumber: clientNo},
             });
          },
          (error) => {
            console.error(error);
            this.showError("Error Creating Client and Contact", error);
          }
        );
    },
    changePassword: function(data) {
        if (data) {
            this.combinedObject.person.password = data;
        }
    },
  },
  created() {
    this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
    this.$validator.extend(
      "verify_password",
      (value) => {
        return {
          valid: this.combinedObject.person.password,
          data: {
            required: true,
          }
        };
      },
      {
        computesRequired: true,
      }
    );

  },
};
</script>
