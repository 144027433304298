<template>
  <ServiceScreenLayout :parts="parts">
    <template v-slot:sip_trunk>
      <VoipDetails 
        :sipTrunk="saleDefinition.sale.sipTrunk"
        @save-sale="saveSaleDefinition"
      />
    </template>
    <template v-slot:did>
      <MultipleDid 
        :didList="saleDefinition.sale.didNumbers"
        @update-dids="updateDids"
        @add-new-did="addNewDid"
        @delete-did="deleteDid"
        @save-sale="saveSaleDefinition"
      />
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import SaleFunctions from "@/pages/Services/SaleFunctions";

import VoipDetails from "@/components/ObjParts/SingleConstructed/VoipDetails";
import MultipleDid from "@/components/ObjParts/MultipleCaptured/MultipleDid";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    VoipDetails,
    MultipleDid,
  },
  data() {
    return {
      saleNumber: null,
      parts: [
        {
          name: "sip_trunk",
          icon: "fa fa-user-circle",
          displayName: "SIP Trunk",
          permissions: ["*"],
        },
        {
          name: "did",
          icon: "fa fa-phone-square",
          displayName: "DID",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        sale: {
          sipTrunk: {},
          didNumbers: {},
        },
      },
    };
  },
  async mounted() {
    this.saleNumber = this.$route.params.saleNumber;
    this.fetchSaleDefinition(this.saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    loadSaleDefinition() {
      this.fetchSaleDefinition(this.saleNumber).then(
        (success) => {
          this.saleDefinition = success.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error Fetching Sale Definition", error);
        }
      );
      return Promise.resolve('Success');
    },
    saveSaleDefinition: function() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPIv2 +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.loadSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Bulk DID Service", error);
                this.loadSaleDefinition();
              }
            );
        }
      });
    },
    updateDids: function(data) {
      this.saleDefinition.sale.didNumbers = data;
      this.saveSaleDefinition();
    },
    addNewDid: function(data) {
      var didObj = this.saleDefinition.meta.didNumbers.objMeta.new;
      didObj.number = data.number;
      didObj.ported = data.ported;
      this.saleDefinition.sale.didNumbers.push(didObj);
      this.saveSaleDefinition();
    },
    deleteDid: function(data) {
      if (this.saleDefinition.sale.didNumbers.length > 1) {
        for (let i = 0; i < this.saleDefinition.sale.didNumbers.length; i++) {
          if (this.saleDefinition.sale.didNumbers[i] == data) {
            this.deleteObjPart(this.saleDefinition.sale.didNumbers[i])
              .then(
                (response) => {
                  this.showSuccess("Delete Success");
                  this.loadSaleDefinition()
                    .then((response) => {
                      this.saleDefinition.sale.didNumbers = this.saleDefinition.sale.didNumbers.filter(ext => (ext != data));
                    }, (error) => {
                      console.error(error);
                      this.showError("Error Deleting DID Number", error);
                    });
                },
                (error) => {
                  console.error(error);
                  this.showError("Error Deleting DID Number", error);
              });
          }
        }
      } else {
        return this.$swal({
          title: "Error",
          text: "You can't delete the last DID Number",
          type: 'warning',
          confirmButtonText: 'Ok',
        });
      }
    },
  },
};
</script>