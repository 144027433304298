<script>
export default {
    methods: {
        getLinkedSaleDocumentation(saleNumber) {
            return this.$http.get(this.$config.aimsAPI + "sales/" + saleNumber + "/sale/documentation");
        },
        fetchSaleDefinition(saleNumber){
            return this.$http.get(this.$config.aimsAPIv2 + "sales/definition/sale/" + saleNumber);
        },
        updateObjPart(obj){
            return this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/obj/update/", obj);
        },
        createObjPart(obj, commit){
            return this.$http.post(this.$config.aimsAPIv2 + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/obj/create?commit=" + commit, obj);
        },
        deleteObjPart(obj){
            return this.$http.post(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber + "/obj/delete/", obj);
        },
        getSaleDefinition(saleNumber) {
           return this.$http.get(this.$config.aimsAPI + "sales/definition/sale/" + saleNumber);
        },
        getSale(saleNumber) {
           return this.$http.get(this.$config.aimsAPI + "sales/" + saleNumber);
        },
        saleActivate(saleNumber) {
            return this.$http.put(this.$config.aimsAPI + "sales/definition/" + saleNumber + "/activate");
        },
        saleSuspend(saleNumber) {
            return this.$swal({
                title: "Are you sure you want to suspend the service ?",
                text: "Suspended services will continue billing.",
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Suspend'
            }).then((result) => {
                if (result.value) {
                    return this.$http.put(this.$config.aimsAPI + "sales/definition/" + saleNumber + "/suspend");
                }
            });
        },

        saleDeactivateOptions(saleNumber) {
            return this.$swal({
                title: "When would you like to DEACTIVATE the service?",
                input: "select",
                inputOptions: {
                    endthismonth: "End of this month",
                    immediate: "Immediately",
                    endnextmonth: "End of next month",
                },
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Deactivate',
            }).then((response) => {
                return this.$http.put(this.$config.aimsAPI + "sales/definition/" + saleNumber + "/deactivate/" + response.value);
            });
        },
        setArchive(options, selected) {
            return this.$swal({
                title: "When should the service archive?",
                text: "Currently: " + selected,
                input: "select",
                inputOptions: options,
                type: 'question',
                showCancelButton: true,
                confirmButtonText: 'Accept',
            }).then((response) => {
                return response.value;
            });
        },

        cancelDeactivate(saleNumber) {
            return this.$http.put(this.$config.aimsAPI + "sales/definition/" + saleNumber + "/deactivate/cancel");
        },

        runCommand(saleNumber, command) {
            this.$swal({
                title: "Run " + command.name,
                text: command.description,
                type: "info",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    this.$http.post(this.$config.aimsAPIv2 + 'sales/definition/' + saleNumber + '/command/' + command.name).then(
                        (response) => {
                            this.showSuccess(response.data.message);
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error triggering this command", error);
                        }
                    )
                }
            });
        }
    }
}
</script>

<style>
   
</style>

