<script>
export default {
    methods: {
        getClient(clientNumber) {
            return this.$http.get(this.$config.aimsAPI + 'clients/' + clientNumber);
        },
        getClientMetaData(clientNumber) {
            return this.$http.get(this.$config.aimsAPI + 'clients/' + clientNumber + "/meta");
        },
        getStaff() {
            return this.$http.get(this.$config.aimsAPI + "staff");
        },
        getResellers() {
            return this.$http.get(this.$config.aimsAPI + "clients/resellers");
        },
        updateClient(client) {
            return this.$http.put(this.$config.aimsAPI + 'clients/update', client);
        },
        revokeReseller(client) {
            return this.$http.put(this.$config.aimsAPI + 'clients/revokereseller', client);
        },
        createReseller(client) {
            return this.$http.put(this.$config.aimsAPI + 'clients/createReseller', client);
        },
        getClientName(client){          
            return client.toString;
        }
    }
}
</script>