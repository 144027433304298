<template>
    <div class="card card-default">
    <div class="card-header">
        <h4>DID Numbers</h4>
    </div>
    <div class="row" style="margin-left: 0px">
        <div class="col-md-1">
          <div class="form-group">
            <button type="button" class="btn btn-info" @click="$refs.addDIDModal.open()" v-allowed:view="['STAFF']">Add DID</button>
         </div>
        </div>
    </div>  
    <div class="card-body">
        <table class="table table-bordered" style="width: 60%">
        <thead>
            <tr>
                <th class="text-center">DID</th>
                <th>Number</th>
                <th>Ported</th>
                <th v-allowed:view="['STAFF']"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(did, index) in didList" :key="index" :class="{'new-table-row': did.isNew}">
                <td style="text-align:center; width: 5%">{{index + 1}}</td>
                <td style="width: 30%">{{did.number}}</td>
                <td style="width: 10%">{{did.ported ? "Yes":"No"}}</td>
                <td style="text-align:center; width: 20%" v-allowed:view="['STAFF']">
                    <button type="button" class="btn btn-info" @click="editDidModal(index)"><i class="fa fa-edit"></i></button>
                    <button v-show='!isDeletable' type="button" class="btn btn-red" @click="deleteDid(did)"><i class="fa fa-trash" style="color: white;"></i></button>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
        <modal
            title="Add New DID Number"
            ref="addDIDModal"
            size="modal-md"
            @save="addDid()"
            @dismiss="dismissAddDid()"
        >
            <div class="row">
                <div class="col-md-10">
                    <div class="form-group">
                    <label>Number:</label>
                    <input
                        class="form-control"
                        type="text"
                        v-model="newDid.number"
                    />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">
                    <div class="form-group">
                    <label>Ported:</label>
                    <select
                      class="form-control"
                      name="ported"
                      placeholder="Ported:"
                      v-model="newDid.ported"
                    >
                      <option value=false>No</option>
                      <option value=true>Yes</option>
                    </select>
                    </div>
                </div>
            </div>
        </modal>
        <modal
            title="Edit DID Number"
            ref="editDIDModal"
            size="modal-md"
            @save="updateDid()"
            @dismiss="dismissEditDid()"
        >
            <div class="row">
                <div class="col-md-10" v-if="didList[editIndex]">
                    <div class="form-group">
                    <label>Number:</label>
                    <input
                        class="form-control"
                        type="text"
                        v-model="editNumber"
                    />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10" v-if="didList[editIndex]">
                    <div class="form-group">
                    <label>Ported:</label>
                    <select
                      class="form-control"
                      name="ported"
                      placeholder="No"
                      v-model="editPorted"
                    >
                      <option value=false>No</option>
                      <option value=true>Yes</option>
                    </select>
                    </div>
                </div>
            </div>
        </modal>
    </div>  
</template>

<script>
    import Modal from "@/components/GeneralComponents/Modal";

  export default {
    components: {
        Modal,
    },
    props: {
        didList: {
            type: Array,
            required: true
        }
    },
    computed: {
        isDeletable() {
            if (this.didList.length > 1) {
                return false;
            }
            return true;
        },
    },
    data() {
        return {
            newDid: {
                number: "",
                ported: false,
            },
            editIndex: 1,
            editNumber: "",
            editPorted: false,
        }
    },
    methods: {
        addDid() {
            var unique = true;
            if (this.newDid.number == null || this.newDid.number == "" || isNaN(this.newDid.number)) {
                return this.$swal({
                        title: "Error",
                        text: "You need to insert a number",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
            } else {
                for (let i = 0; i < this.didList.length; i++) {
                    if (this.didList[i].number == this.newDid.number) {
                        unique = false
                    }
                }
                if (unique) {
                    this.$emit('add-new-did', this.newDid);
                    this.dismissAddDid();
                } else {
                    return this.$swal({
                        title: "Error",
                        text: "That DID number already exists",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
            }
        },
        deleteDid(data) {
            this.$emit('delete-did', data);
        },
        editDidModal(index) {
            this.editIndex = index;
            this.editNumber = this.didList[this.editIndex].number;
            this.$refs.editDIDModal.open();
        },
        updateDid() {
            var unique = true;
            if (this.editNumber == null || this.editNumber == "" || isNaN(this.editNumber)) {
                return this.$swal({
                        title: "Error",
                        text: "You need to insert a number",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
            } else {
                for (let i = 0; i < this.didList.length; i++) {
                    if (this.didList[i].number == this.editNumber && i != this.editIndex) {
                        unique = false
                    }
                }
                if (unique) {
                    this.didList[this.editIndex].number = this.editNumber;
                    this.didList[this.editIndex].ported = this.editPorted;
                    this.$emit('update-dids', this.didList);
                    this.dismissEditDid();
                } else {
                    return this.$swal({
                        title: "Error",
                        text: "That DID number already exists",
                        type: 'warning',
                        confirmButtonText: 'Ok',
                    });
                }
                
            }
        },
        dismissAddDid() {
            this.$refs.addDIDModal.close();
            this.newDid.number = "";
            this.newDid.ported = false;
        },
        dismissEditDid() {
            this.$refs.editDIDModal.close();
            this.editIndex = 0;
            this.editNumber = "";
            this.editPorted = false;
        }
    }
  }

</script>

<style>

</style>
