<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="service">
            <form novalidate data-vv-scope="service">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.name'),
                    }"
                  >
                    <label>Description:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.details.description"
                    />
                    <span class="has-error" v-if="errors.has('service.name')">{{
                      errors.first("service.name")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.text'),
                    }"
                  >
                    <label>Details:</label>
                    <textarea 
                      type="text"
                      name="text"
                      class="form-control"
                      rows="4" 
                      cols="50"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.details.text"
                    ></textarea>
                    <span class="has-error" v-if="errors.has('service.text')">{{
                      errors.first("service.text")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "service",
          displayName: "Service",
          icon: "fa fa-cubes",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          details: {}
        },
        selectableOptions: {

        }
      },
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    this.parts[0].displayName = this.filledInSaleDefinition.product.description;
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>