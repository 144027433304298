<template>
    <modal
        ref="addressConfirmModal"
        :title="'Confirm address'"
        size="modal-xl"
        save-text="Update"
        :saveNeeded="true"
        @save="confirmAddress()"
    >
    <div class="row">
        <div class="col-md-3">
            <div class="form-group" :class="{ 'has-error': errors.has('Type') }">
                <label>Type</label>
                <v-select
                    :options="types"
                    v-model="order.type"
                    valueProp="value"
                    :get-option-label="getLabel"
                    name="Type"
                    v-validate="'required'"
                    placeholder="Select a type"
                >
                </v-select>
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Type')"
                    >{{ errors.first("Type") }}</small
                >
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Unit</label>
                <input 
                    class="form-control" 
                    type="text" 
                    v-model="unitNumber"
                />
            </div>
        </div>
        <div class="col-md-6" v-if="order.type === 3">
            <div class="form-group" :class="{ 'has-error': errors.has('Business name') }">
                <label>Business Name</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="order.business_name"
                    name="Business name"
                    v-validate="order.type === 3 ? 'required' : ''"
                    placeholder="Enter a business name"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Business name')"
                    >{{ errors.first("Business name") }}</small
                >
            </div>
        </div>
        <div class="col-md-2" v-if="order.type === 2">
            <div class="form-group" :class="{ 'has-error': errors.has('Apartment number') }">
                <label>Apartment Number</label>
                <input 
                    class="form-control" 
                    type="text" 
                    v-model="order.apartment_number"
                    name="Apartment number"
                    v-validate="order.type === 2 ? 'required' : ''"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Apartment number')"
                    >{{ errors.first("Apartment number") }}</small
                >
            </div>
        </div>
        <div class="col-md-4" v-if="order.type === 2">
            <div class="form-group" :class="{ 'has-error': errors.has('Building name') }">
                <label>Building Name</label>
                <input 
                    class="form-control" 
                    type="text" 
                    v-model="order.building_name"
                    name="Building name"
                    v-validate="order.type === 2 ? 'required' : ''"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Building name')"
                    >{{ errors.first("Building name") }}</small
                >
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group" :class="{ 'has-error': errors.has('Street number') }">
                <label>Street number</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="order.street_number"
                    name="Street number"
                    v-validate="'required'"
                    placeholder="Enter a street number"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Street number')"
                    >{{ errors.first("Street number") }}</small
                >
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" :class="{ 'has-error': errors.has('Street') }">
                <label>Street</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="order.street"
                    name="Street"
                    v-validate="'required'"
                    placeholder="Enter a street"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Street')"
                    >{{ errors.first("Street") }}</small
                >
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group" :class="{ 'has-error': errors.has('Suburb') }">
                <label>Suburb</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="order.suburb"
                    name="Suburb"
                    v-validate="'required'"
                    placeholder="Enter a suburb"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Suburb')"
                    >{{ errors.first("Suburb") }}</small
                >
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group" :class="{ 'has-error': errors.has('Address') }">
                <label>Address</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="address"
                    name="Address"
                    v-validate="'required'"
                    placeholder="Enter an address"
                />
                <small
                    class="has-error mt-1"
                    v-if="errors.has('Address')"
                    >{{ errors.first("Address") }}</small
                >
            </div>
        </div>
    </div>
    </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";

export default {
    components: {
        Modal
    },
    props: {
        order: {
            type: Object,
            required: true
        },
        types: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            unitNumber: "",
        }
    },
    computed: {
        address() {
            return this.unitNumber ? `Unit ${this.unitNumber}, ${this.order.address}` : this.order.address;
        }
    },
    methods: {
        open: function() {
            this.$refs.addressConfirmModal.open();
        },
        close: function() {
            this.$refs.addressConfirmModal.close();
        },
        confirmAddress: function() {   
            this.$validator.validateAll().then((valid) => {
                if (valid) {
                    this.order.address = this.address;                   
                    this.$emit('confirmed', this.order);
                }
            });     
        },
        getLabel: function({description}) {
            return description;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>