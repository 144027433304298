import { render, staticRenderFns } from "./SynaqInvoiceCapture.vue?vue&type=template&id=019dc356&scoped=true"
import script from "./SynaqInvoiceCapture.vue?vue&type=script&lang=js"
export * from "./SynaqInvoiceCapture.vue?vue&type=script&lang=js"
import style0 from "./SynaqInvoiceCapture.vue?vue&type=style&index=0&id=019dc356&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "019dc356",
  null
  
)

export default component.exports