<template>
    <div>
        <div class="row">
            <div class="col-md-2" v-if="productVersion === 'NEW'">
                <button
                      class="btn btn-success w-100"
                      type="button"
                      @click="$emit('createProduct')"
                    >
                      New Product
                    </button>
            </div>
            <div class="col-md-10">
                <div class="input-group">
                    <input 
                        type="text" 
                        class="form-control" 
                        placeholder="Search Product Number, Description or Product Definition..." 
                        aria-label="Search" 
                        aria-describedby="search-input"
                        v-model="productListState.searchInput"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text" id="search-input">
                            <div class="fa fa-search"></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-2">
                <v-select
                    :options="types"
                    v-model="productListState.filters.classificationType"
                    label="name"
                    clearable
                    placeholder="Select Type"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="suppliers"
                    v-model="productListState.filters.supplier.name"
                    label="name"
                    valueProp="name"
                    clearable
                    placeholder="Select Supplier"
                >
                </v-select>

                <!-- <select class="form-control" v-model="filters.supplier">
                    <option value="">Select supplier</option>
                    <option v-for="supplier in suppliers" :value="supplier.name">
                        {{ supplier.name }}
                    </option>
                </select> -->
            </div>
            <div class="col-md-2">
                <v-select
                    :options="vendors"
                    v-model="productListState.filters.vendor.name"
                    label="name"
                    valueProp="name"
                    clearable
                    placeholder="Select Vendor"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="segments"
                    v-model="productListState.filters.segment.name"
                    label="name"
                    valueProp="name"
                    clearable
                    placeholder="Select Segment"
                    @input="getSubsegments(productListState.filters.segment.name)"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="subSegments"
                    v-model="productListState.filters.subsegment.name"
                    label="name"
                    valueProp="name"
                    clearable
                    placeholder="Select Subsegment"
                >
                </v-select>
            </div>
            <div class="col-md-2">
                <v-select
                    :options="forSaleOpts"
                    v-model="productListState.filters.forSale.name"
                    label="name"
                    valueProp="name"
                    clearable
                    placeholder="Select For Sale"
                >
                </v-select>
            </div>
        </div>
        <div class="row row-spacing">
            <div class="col-md-12">
                <div class="table-responsive table-bordered table-hover">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Description</th>
                                <th>Product Definition</th>
                                <th>Type</th>
                                <th>Supplier</th>
                                <th>Vendor</th>
                                <th>Segment</th>
                                <th>Subsegment</th>
                                <th>For Sale</th>
                            </tr>
                        </thead>
                        <tbody v-if="filteredProducts.length > 0">
                            <tr 
                                v-for="product in filteredProducts" 
                                :key="product.productNumber" 
                                @click="$emit('productClicked', product)"
                            >
                                <td>{{ product.productNumber }}</td>
                                <td>{{ product.description }}</td>
                                <td>
                                    <span class="text-bold">{{ product.productDefinition }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-success">{{ product.classificationType }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-primary">{{ product.supplier.name }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-secondary">{{ product.vendor.name }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-info">{{ product.segment.name }}</span>
                                </td>
                                <td>
                                    <span class="badge badge-info">{{ product.subsegment.name }}</span>
                                </td>
                                <td>
                                    <span class="text-bold">{{ product.forSale }}</span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else-if="isFetchingData">
                            <tr>
                                <td colspan="9">
                                    <DataLoader :loadingText="'Fetching Products...'"/>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="9">No products to display</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataLoader from '@/components/GeneralComponents/DataLoader.vue';

import { ProductListState } from '@/stores/pages/Admin/ProductList.ts';

export default {
    components: {
        DataLoader
    },
    props: {
        products: {
            type: Array,
            required: true,
        },
        productVersion: {
            type: String,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        suppliers: {
            type: Array,
            required: true,
        },
        vendors: {
            type: Array,
            required: true,
        },
        segments: {
            type: Array,
            required: true,
        },
        isFetchingData: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        const productListState = ProductListState();
        return {
            productListState,
            subSegments: [],
            forSaleOpts: [
                {
                    "name": "true",
                },
                {
                    "name": "false",
                },
            ]
        }
    },
    mounted() {
        this.getSubsegments();
    },
    computed: {
        filteredProducts() { 
            if (this.productListState.searchInput) {
                return this.products.filter((p) => 
                    p.productNumber.toString().includes(this.productListState.searchInput) ||
                    p.description.toLowerCase().includes(this.productListState.searchInput.toLowerCase()) ||
                    p.productDefinition.toLowerCase().includes(this.productListState.searchInput.toLowerCase())
                );
            };  
        
            return this.filterProducts(this.products, this.productListState.filters);
        },
    },
    methods: {
        getSubsegments: function(segmentName) {
            const hostUrlv2 = this.$config.aimsAPIv2;
            let path = "";

            if (segmentName) {
                const segment = this.segments.find((segment) => segment.name === segmentName);
                path = `/segment/${segment.segmentNumber}`;
            } 

            this.$http.get(`${hostUrlv2}subsegments${path}`).then(
                (response) => {      
                    this.subSegments = response.data;
                },
                (error) => {
                    console.error(error);
                    this.showError("Error fetching product subsegments", error);
                }
            ); 
        },
        filterProducts: function(products, filters) {
            return products.filter(product => {
                return Object.keys(filters).every(key => {
                    let filterValue = filters[key];
                    let productValue = product[key];

                    if (typeof filterValue !== "object") {
                        let obj = {
                            name: filterValue
                        }

                        filterValue = obj;
                    }
                    
                    if (filterValue.name === undefined || filterValue.name === null || filterValue.name === '' || filterValue.name === 0) {
                        return true;
                    };

                    if (typeof productValue === "boolean") {
                        return productValue.toString() === filterValue.name;
                    };

                    if (typeof productValue === "string") {
                        return productValue === filterValue.name;
                    }

                    return productValue.name === filterValue.name;
                    
                });
            });
        },
    }
}
</script>