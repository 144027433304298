<template>
  <div class="row" style="padding-left: 0px"> 
    <div class="card-header" >
        <h4>PBX</h4>
    </div>
    <div class="col-md-12" v-if="!renewalDate">
      <div class="row">
        <div class="col-md-12">
          <label>Description</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="description"
              :disabled="disabledControls"
            />
          </div>
        </div>
      </div>
      <div class="row row-spacing">
        <div class="col-md-12">
          <label>Package</label>
          <div class="input-group">
            <v-select
              name="package"
              v-model="pbx.description"
              :options="pbxDescriptions"
              :disabled="disabledControls"
            >
            </v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="renewalDate">
      <div class="row">
        <div class="col-md-6">
          <label>Description</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="description"
              :disabled="disabledControls"
            />
          </div>
        </div>
        <div class="col-md-6">
          <label>Package</label>
          <div class="input-group">
            <v-select
              name="package"
              v-model="pbx.description"
              :options="pbxDescriptions"
              :disabled="disabledControls"
            >
            </v-select>
          </div>
        </div>
      </div>
      <div class="row row-spacing">
        <div class="col-md-6">
          <label>Licence Key</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              v-model="licenceKey"
              :disabled="disabledControls"
            />
          </div>
        </div>
        <div class="col-md-6">
          <label>Renewal Date</label>
          <div class="input-group">
            <DateInput
              type="date"
              v-model="renewalDate"
              :disabled="disabledControls"
              style="width: 100%"
            /> 
            <div class="input-group-append">
                <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

  export default {
    props: {
      pbx: {
        type: Object,
        required: true
      },
      pbxDescriptions: {
        type: Array,
        required: false
      },
      description: {
        type: String,
        required: false
      },
      licenceKey: {
        type: String,
        required: false
      },
      renewalDate: {
        type: Date,
        required: false
      },
      disabledControls: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        saveIndex: 1,
      }
    },
    components: {
      DateInput,
    },
    methods: {
      
    }
  }

</script>

<style>

</style>
