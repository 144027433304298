<template>
    <div class="row">
      <div class="col-md-8">
        <div class="card card-default">
          <service-wizard-layout
            title=""
            subtitle=""
            :parts="parts"
            :onComplete="onComplete"
            :validateStep="validateStep"
          >
            <template slot="domain">
              <form novalidate data-vv-scope="domain">
                <div class="row">
                  <div class="col-xl-8 col-lg-12">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('synaqDomain.domainName'),
                      }"
                    >
                      <label>Domain Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="name"
                        placeholder="Domain Name"
                        v-model="filledInSaleDefinition.requiredObjs.synaq.domainName"
                      />
                      <span class="has-error" v-if="errors.has('synaqDomain.domainName')">{{
                        errors.first("synaqDomain.domainName")
                      }}</span>
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </service-wizard-layout>
        </div>
      </div>
    </div>
  </template>

  <script>
  import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
  
  export default {
    components: {
      ServiceWizardLayout,
    },
    props: {
      emptySaleDefinition: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        parts: [
          {
            name: "domain",
            displayName: "Domain",
            icon: "fa fa-info",
          },
        ],
        filledInSaleDefinition: {
          requiredObjs: {
            synaq: {},
          },
        },
      };
    },
    mounted() {
      this.filledInSaleDefinition = this.emptySaleDefinition;      
    },
    methods: {
      onComplete() {
        this.$emit("createSale", this.filledInSaleDefinition);
      },
      validateStep(scope) {
        return this.$validator.validateAll(scope).then((result) => {
          if (result) {
            return true;
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>

  <style>
  </style>
