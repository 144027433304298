<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
          <template slot="service">
            <form novalidate data-vv-scope="service">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.name'),
                    }"
                  >
                    <label>Description:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.service.description"
                    />
                    <span class="has-error" v-if="errors.has('service.name')">{{
                      errors.first("service.name")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('service.text'),
                    }"
                  >
                    <label>Details:</label>
                    <textarea 
                      type="text"
                      name="text"
                      class="form-control"
                      rows="4" 
                      cols="50"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.service.text"
                    ></textarea>
                    <span class="has-error" v-if="errors.has('service.text')">{{
                      errors.first("service.text")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>

          <template slot="network">
            <form novalidate data-vv-scope="network">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('network.name'),
                    }"
                  >
                    <label>VLAN Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.network.name"
                    />
                    <span class="has-error" v-if="errors.has('network.name')">{{
                      errors.first("network.name")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('network.vlan'),
                    }"
                  >
                    <label>VLAN ID:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.network.vlan"
                    />
                    <span class="has-error" v-if="errors.has('network.vlan')">{{
                      errors.first("network.vlan")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('network.name'),
                    }"
                  >
                    <label>Bandwidth:</label>
                    <v-select 
                      name="hostNumber"
                      v-validate="'required'"
                      :options="rateLimits" 
                      label="description" 
                      valueProp="rateLimit"
                      v-model="selectedRateLimit" 
                      placeholder="Choose bandwidth"
                    ></v-select>
                    <span class="has-error" v-if="errors.has('network.name')">{{
                      errors.first("network.name")
                    }}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "service",
          displayName: "Service",
          icon: "fa fa-cubes",
        },
        {
          name: "network",
          displayName: "Network",
          icon: "fa fa-plug",
        }
      ],
      filledInSaleDefinition: {
        requiredObjs: {
          service: {},
          network: {}
        },
        selectableOptions: {

        }
      },
      rateLimits: [{
        rateLimit: '',
        description: ''
      }],
      selectedRateLimit: '',
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;

    this.$http.get(this.$config.aimsAPIvLegacy + "fibre/ratelimits/" + this.filledInSaleDefinition.product.productNumber)
      .then(
        (response) => {
          this.rateLimits = response.data;
        },
        (error) => {
          console.error(error);
        }
      );
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      this.filledInSaleDefinition.requiredObjs.network.rateLimit = this.selectedRateLimit;
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>