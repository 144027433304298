<template>
  <div class="row">
    <div class="col-md-9">
      <div class="card card-default">

        <!-- wizard for Home PBX Service -->
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="partsPbx"
          :onComplete="onComplete"
          :validateStep="validateStep"
          v-if="homePbx"
        >
          <template slot="pbx" v-if="filledInSaleDefinition.requiredObjs.pbx">
            <form novalidate data-vv-scope="pbx">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div class="row">
                    <div class="col-xl-8 col-lg-12">
                      <div
                        class="form-group"
                        :class="{
                          'has-error': errors.has('pbx.description'),
                        }"
                      >
                        <label>Description:</label>
                        <input
                          type="text"
                          class="form-control"
                          v-validate="'required'"
                          name="description"
                          placeholder="Description:"
                          v-model="filledInSaleDefinition.requiredObjs.pbx.description"
                        />
                        <span class="has-error" v-if="errors.has('pbx.description')">{{
                          errors.first("pbx.description")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
          <template slot="did">
            <form novalidate data-vv-scope="did">

              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.number'),
                    }"
                  >
                    <label>DID Number:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="number"
                      placeholder="Number:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.number"
                    />
                    <span class="has-error" v-if="errors.has('did.number')">{{
                      errors.first("did.number")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.ported'),
                    }"
                  >
                    <label>Ported:</label>
                    <select
                      class="form-control"
                      v-validate="'required'"
                      name="ported"
                      placeholder="Ported:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.ported"
                    >
                      <option value=false>No</option>
                      <option value=true>Yes</option>
                    </select>
                    <span class="has-error" v-if="errors.has('did.ported')">{{
                      errors.first("did.ported")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>

        <!-- wizard for Internal Voice Extension Service -->
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="partsVoiceExtension"
          :onComplete="onComplete"
          :validateStep="validateStep"
          v-else-if="isVoiceExtension"
        >
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>

        <!-- wizard for Home Voice Service -->
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
          v-else
        >
          <template slot="sip">
            <form novalidate data-vv-scope="sip">
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.registrar'),
                    }"
                  >
                    <label>Registrar:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="registrar"
                      placeholder="Registrar:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.registrar"
                    />
                    <span class="has-error" v-if="errors.has('sip.registrar')">{{
                      errors.first("sip.registrar")
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.username'),
                    }"
                  >
                    <label>Username:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="username"
                      placeholder="Username:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.username"
                    />
                    <span class="has-error" v-if="errors.has('sip.username')">{{
                      errors.first("sip.username")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('sip.password'),
                    }"
                  >
                    <label>Password:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="password"
                      placeholder="Password:"
                      v-model="filledInSaleDefinition.requiredObjs.sipTrunk.password"
                    />
                    <span class="has-error" v-if="errors.has('sip.password')">{{
                      errors.first("sip.password")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
          <template slot="did">
            <form novalidate data-vv-scope="did">

              <div class="row">
                <div class="col-xl-8 col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.number'),
                    }"
                  >
                    <label>DID Number:</label>
                    <input
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      name="number"
                      placeholder="Number:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.number"
                    />
                    <span class="has-error" v-if="errors.has('did.number')">{{
                      errors.first("did.number")
                    }}</span>
                  </div>

                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.has('did.ported'),
                    }"
                  >
                    <label>Ported:</label>
                    <select
                      class="form-control"
                      v-validate="'required'"
                      name="ported"
                      placeholder="Ported:"
                      v-model="filledInSaleDefinition.requiredObjs.didNumber.ported"
                    >
                      <option value=false>No</option>
                      <option value=true>Yes</option>
                    </select>
                    <span class="has-error" v-if="errors.has('did.ported')">{{
                      errors.first("did.ported")
                    }}</span>
                  </div>

                </div>
              </div>
            </form>
          </template>
        </service-wizard-layout>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";

export default {
  components: {
    ServiceWizardLayout,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      partsPbx: [
        {
          name: "pbx",
          displayName: "PBX",
          icon: "fa fa-cubes",
        },
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
        {
          name: "did",
          displayName: "DID Information",
          icon: "fa fa-phone",
        },
      ],
      partsVoiceExtension: [
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
      ],
      parts: [
        {
          name: "sip",
          displayName: "SIP Trunk",
          icon: "fa fa-info",
        },
        {
          name: "did",
          displayName: "DID Information",
          icon: "fa fa-phone",
        },
      ],
      filledInSaleDefinition: {
        requiredObjs: {
            sipTrunk: {},
            didNumber: {},
        },
      },
      portedOptions: [{
          portedValue: true,
          portedLabel: "Yes"
      }, {
          portedValue: false,
          portedLabel: "No"
      }
      ],
      homePbx: false,
      isVoiceExtension: false,
    };
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;
    if (this.filledInSaleDefinition.product.productDefinition === 'InternalVoiceExtension') {
      this.isVoiceExtension = true;
    } else {
      this.filledInSaleDefinition.requiredObjs.didNumber.ported = false;
    }

    if (this.filledInSaleDefinition.requiredObjs.pbx) {
      this.homePbx = true;
    }
  },
  methods: {
    onComplete() {
      this.$emit("createSale", this.filledInSaleDefinition);
    },
    validateStep(scope) {
      return this.$validator.validateAll(scope).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
</style>
