<template>
    <div class="row">
      <div class="col-md-8">
        <div class="card card-default">
          <service-wizard-layout
            title=""
            subtitle=""
            :parts="parts"
            :onComplete="onComplete"
            :validateStep="validateStep"
          >
            <template slot="details">
              <form novalidate data-vv-scope="cyberRiskAware">
                <div class="row">
                  <div class="col-xl-8 col-lg-12">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('cyberRiskAware.domainName'),
                      }"
                    >
                      <label>Domain Name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="domainName"
                        placeholder="Domain Name"
                        v-model="filledInSaleDefinition.requiredObjs.cyberRiskAware.domainName"
                      />
                      <span class="has-error" v-if="errors.has('cyberRiskAware.domainName')">{{
                        errors.first("cyberRiskAware.domainName")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8 col-lg-12">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('cyberRiskAware.managementUsername'),
                      }"
                    >
                      <label>Management Username</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="managementUsername"
                        placeholder="Management Username"
                        v-model="filledInSaleDefinition.requiredObjs.cyberRiskAware.managementUsername"
                      />
                      <span class="has-error" v-if="errors.has('cyberRiskAware.managementUsername')">{{
                        errors.first("cyberRiskAware.managementUsername")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-8 col-lg-12">
                    <div
                      class="form-group"
                      :class="{
                        'has-error': errors.has('cyberRiskAware.managementPassword'),
                      }"
                    >
                      <label>Management Password</label>
                      <input
                        type="text"
                        class="form-control"
                        v-validate="'required'"
                        name="managementPassword"
                        placeholder="Management Password"
                        v-model="filledInSaleDefinition.requiredObjs.cyberRiskAware.managementPassword"
                      />
                      <span class="has-error" v-if="errors.has('cyberRiskAware.managementPassword')">{{
                        errors.first("cyberRiskAware.managementPassword")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-xl-8 col-lg-12">
                        <div
                            class="form-group"
                            :class="{ 'has-error': errors.has('cyberRiskAware.cyberRiskAwarePackage') }"
                        >
                            <label class="control-label">Package</label>
                            <div class="input-group">
                                <v-select
                                    name="cyberRiskAwarePackage"
                                    placeholder="Select Package"
                                    v-validate="'required'"
                                    label="description"
                                    valueProp="cyberRiskAwarePackageNumber"
                                    v-model="filledInSaleDefinition.requiredObjs.cyberRiskAware.cyberRiskAwarePackageNumber"
                                    :options="filledInSaleDefinition.selectableOptions.cyberRiskAwarePackage"
                                ></v-select>
                            </div>
                            <span
                                class="has-error"
                                v-if="errors.has('cyberRiskAware.cyberRiskAwarePackage')"
                                >{{ errors.first("cyberRiskAware.cyberRiskAwarePackage") }}
                            </span>
                        </div>
                    </div>
                </div>
              </form>
            </template>
          </service-wizard-layout>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
  
  export default {
    components: {
      ServiceWizardLayout,
    },
    props: {
      emptySaleDefinition: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        parts: [
          {
            name: "details",
            displayName: "CyberRisk Aware",
            icon: "fa fa-info",
          },
        ],
        filledInSaleDefinition: {
          requiredObjs: {
            cyberRiskAware: {},
          },
          selectableOptions: {
            cyberRiskAwarePackage: [],
          }
        },
      };
    },
    mounted() {
      this.filledInSaleDefinition = this.emptySaleDefinition;
    },
    methods: {
      onComplete() {
        this.$emit("createSale", this.filledInSaleDefinition);
      },
      validateStep(scope) {
        return this.$validator.validateAll(scope).then((result) => {
          if (result) {
            return true;
          } else {
            return false;
          }
        });
      },
    },
  };
  </script>
  
  <style>
  </style>
  