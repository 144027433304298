<template>
  <service-screen-layout :parts="parts">
    <template v-slot:account>
      <div class="row">
        <div class="col-md-7">

          <div class="form-group col-lg-12 col-xl-6">
            <label>Username:</label>
            <div class="input-group">
              <input id="userText" v-model="saleDefinition.definition.staticL2tpAccount.fullUsername" type="text" class="form-control" disabled="" />
              <div class="input-group-append">
                <span @click="copyText(saleDefinition.definition.staticL2tpAccount.fullUsername)"
                  class="input-group-text btn btn-info rounded text-white border-left-0">
                  <i class="fa fa-clipboard"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group col-lg-12 col-xl-6" v-allowed:view="['STAFF']">
            <label>Password:</label>
            <PasswordInput :password="saleDefinition.definition.staticL2tpAccount.password" :userCanEdit="false" @changePassword="changePassword"/>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:ipaddresses>
      <div class="row">
        <div class="col-md-8">
          <h4>Point to Point Network</h4>
          <div class="table-responsive table-bordered">
            <table class="table">
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Short Mask</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{saleDefinition.definition.staticL2tpAccount.pointToPointIpv4Network.address}}</td>
                  <td>{{saleDefinition.definition.staticL2tpAccount.pointToPointIpv4Network.shortMask}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <hr>
      <div class="row" v-if="saleDefinition.definition.staticL2tpAccount.staticIpv4Network">
        <div class="col-md-12">
          <h4>Routed Networks</h4>

          <div class="form-row">
            <div class="form-group col-auto">
              <button type="button" class="btn btn-success" @click="openAddRouteModal()">
                Add Ipv4 Route
              </button>
            </div>
          </div>
          
          <div class="table-responsive table-bordered" v-if="saleDefinition.definition.staticL2tpAccount.staticIpv4Network.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th>Family</th>
                  <th>Address</th>
                  <th>Short Mask</th>
                  <th>Metric</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="network in saleDefinition.definition.staticL2tpAccount.staticIpv4Network" :key="network.address">
                  <td>{{network.family}}</td>
                  <td>{{network.address}}</td>
                  <td>/{{network.shortMask}}</td>
                  <td>{{network.metric}}</td>
                  <td style="text-align:center"><button class="btn btn-danger" type="button" @click="routeRemoved(network)">Remove</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <modal
          title="Add Route"
          ref="routeModal"
          size="modal-sm"
          save-text="Add Route"
          @save="routeAdded(newRoute)"
          dismiss-text="Cancel"
        >
          <div class="row">
            <div
              class="form-group col-md-12"
              :class="{ 'has-error': errors.has('Address') }"
            >
              <label class="col-md-12 col-form-label">Address:</label>
              <div class="col-md-12">
                <input
                  type="text"
                  class="form-control"
                  v-model="newRoute.address"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="form-group col-md-12"
              :class="{ 'has-error': errors.has('Short Mask') }"
            >
              <label class="col-md-12 col-form-label">Mask:</label>
              <div class="col-md-12">
                <v-select
                  :options="maskOptions"
                  v-model="newRoute.shortMask"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="form-group col-md-12"
              :class="{ 'has-error': errors.has('Metric') }"
            >
              <label class="col-md-12 col-form-label">Metric:</label>
              <div class="col-md-12">
                <input
                  type="number"
                  class="form-control"
                  v-model="newRoute.metric"
                />
              </div>
            </div>
          </div>
        </modal>
      </div>
    </template>
  </service-screen-layout>
</template>

<script>
  import ServiceScreenLayout from '@/layouts/ServiceScreenLayout'
  import PasswordInput from '@/components/GeneralComponents/InputControls/PasswordInput';
  import Modal from "@/components/GeneralComponents/Modal";
  import SaleFunctions from '@/pages/Services/SaleFunctions';

  export default {
    extends: SaleFunctions,
    components: {
      ServiceScreenLayout,
      PasswordInput,
      Modal
    },
    data: function () {
      return {
        parts: [{
            name: 'account',
            icon: 'fa fa-info-circle',
            displayName: 'Account',
            permissions: ['*']
          },
          {
            name: 'ipaddresses',
            icon: 'fa fa-plus-square',
            displayName: 'IP Addresses',
            permissions: ['STAFF']
          },
        ],
        saleDefinition: {
          product: {
            classificationProvider: ''
          },
          sale: {},
          definition: {
            staticL2tpAccount: {
               pointToPointIpv4Network: {},
               staticIpv4Network: [],
               staticIpv6Network: []
            },
          },
          supportingEnums: {
            L2tpIpv4Network: {
              family: [],
              type: []
            },
          },
          supportingObjs: {
            discount: {},
            staticIpv4Network: {},
            staticIpv6Network: {},
          },
        },
        maskOptions: [ 32, 30, 29, 28, 27 ],
        newRoute: {
          address: "",
          family: "IPV4",
          shortMask: "",
          metric: ""
        }
      }
    },
    mounted() {
        this.saleNumber = this.$route.params.saleNumber;
        this.loadSaleDefinition();
    },
    methods: {
      loadSaleDefinition(){
          this.getSaleDefinition(this.saleNumber).then(success => {
            this.saleDefinition = success.data;
            this.saleDefinition.definition.staticL2tpAccount.fullUsername = 
              this.saleDefinition.definition.staticL2tpAccount.username + "@" +
              this.saleDefinition.definition.staticL2tpAccount.l2tprealm.name;
          }, error => {
              console.error(error);
              this.showError("Error Fetching Sale Definition", error);
          });
      },
      openAddRouteModal() {
        this.$refs.routeModal.open();
      },
      routeAdded(route) {
        this.$refs.routeModal.isLoading();
        if (route.family=="IPV4") {
          this.saleDefinition.supportingObjs.staticIpv4Network.l2tpAccountNumber = this.saleDefinition.definition.staticL2tpAccount.l2tpAccountNumber;
          this.saleDefinition.supportingObjs.staticIpv4Network.address = route.address;
          this.saleDefinition.supportingObjs.staticIpv4Network.type = 'ROUTED';
          this.saleDefinition.supportingObjs.staticIpv4Network.family = route.family;
          this.saleDefinition.supportingObjs.staticIpv4Network.shortMask = route.shortMask;
          this.saleDefinition.supportingObjs.staticIpv4Network.metric = route.metric;
          
          this.createObjPart(this.saleDefinition.supportingObjs.staticIpv4Network, true).then(
            response => {
              this.showSuccess("IPv4 Route Created Successfully");
              this.loadSaleDefinition();
              this.clearAndCloseModal();
            },
            error => {
              console.error(error);
              this.showError("Error Creating Route", error);
              this.clearAndCloseModal();
            }
          );
        }
      },
      clearAndCloseModal(){
        this.$refs.routeModal.close();
        this.newRoute = {
            address: "",
            family: "IPV4",
            shortMask: "",
            metric: ""
          }
      },
      routeRemoved(route) {
        this.deleteObjPart(route).then(
          response => {
            if (route.family === 'IPV4') {
              const index = this.saleDefinition.definition.staticL2tpAccount.staticIpv4Network.findIndex(r => r
                .objKey === route.objKey)
              this.saleDefinition.definition.staticL2tpAccount.staticIpv4Network.splice(index, 1);
            }
            this.showSuccess("Route Removed Successfully");
          },
          error => {
            console.error(error);
            this.showError("Error Removing Route", error);
          }
        );

      },
      changePassword: function(data) {
        if (data) {
          this.saleDefinition.definition.staticL2tpAccount.password = data;
        }
      },
      saveSale: function () {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$http.put(this.$config.aimsAPI + "sales/definition/" + this.saleDefinition.sale.saleNumber +
                "/update", this.saleDefinition)
              .then(
                response => {
                  this.showSuccess("Save Success");
                  this.saleDefinition = response.data;
                  this.saleDefinition.definition.staticL2tpAccount.fullUsername =
                    this.saleDefinition.definition.staticL2tpAccount.username + "@" + this.saleDefinition.definition.staticL2tpAccount
                    .l2tprealm.name;
                },
                error => {
                  console.error(error);
                  this.showError("Error Saving L2TP Account", error);
                }
              );
          }
        });
      }

    }
  };

</script>
