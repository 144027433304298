<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    title: {
      type: String,
      required: true,
    },
    usageData: {
      type: Array,
      required: true,
    },
    showDownloadedOnly: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    usageData: function (from, to) {
      const chartData = this.createDatasets();
      this.renderChart({
        labels: chartData.labels,
        datasets: chartData.datasets,
        options: chartData.choptions,
      });
    },
  },
  mounted() {
    if (Object.keys(this.usageData).length > 0) {
      const chartData = this.createDatasets();
      this.renderChart({
        labels: chartData.labels,
        datasets: chartData.datasets,
      });
    }
  },
  methods: {
    createDatasets: function () {
      var labels = [];
      var downloadDataList = [];
      var uploadDataList = [];
      var dataUOM = "MB";
      var allDataOverGB = true;
      const oneGig = 1 * 1024 * 1024 * 1024;
      this.usageData.forEach((d) => {
        labels.push(d.label);
        downloadDataList.push(d.downloaded);
        if (d.downloaded < oneGig && d.downloaded > 0) {
          allDataOverGB = false;
        }
        if (!this.showDownloadedOnly) {
          uploadDataList.push(d.uploaded);
          if (d.uploaded < oneGig && d.uploaded > 0) {
            allDataOverGB = false;
          }
        }
      });

      if (allDataOverGB) {
        dataUOM = "GB";
        downloadDataList = downloadDataList.map((d) => {
          return this.sizeBytesToGb(d);
        });
        if (!this.showDownloadedOnly) {
          uploadDataList = uploadDataList.map((d) => {
            return this.sizeBytesToGb(d);
          });
        }
      } else {
        downloadDataList = downloadDataList.map((d) => {
          return this.sizeBytesToMb(d);
        });
        if (!this.showDownloadedOnly) {
          uploadDataList = uploadDataList.map((d) => {
            return this.sizeBytesToMb(d);
          });
        }
      }

      const downloadDataset = {
        label: "Download (" + dataUOM + ")",
        data: downloadDataList,
        backgroundColor: "#00AAAD",
      };

      const uploadDataset = {
        label: "Upload (" + dataUOM + ")",
        data: uploadDataList,
        backgroundColor: "#CACCCE",
      };

      if (!this.showDownloadedOnly) {
        return {
          labels: labels,
          datasets: [uploadDataset, downloadDataset]
        };
      } else {
        return {
          labels: labels,
          datasets: [downloadDataset],
        };
      }
    },
  },
};
</script>
