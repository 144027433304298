<template>
    <div> 
        <label>Web Hosting Package</label>
        <input type="text" class="form-control" v-model="webHostingPackage.description" disabled>
    </div>
</template>

<script>
export default {
    props: {
        webHostingPackage: {
            type: Object,
            required: true
        },
    }
}
</script>