<template>
    <div>
        <div class="card table-responsive table-bordered">
        <table class="table">
          <thead>
            <tr>
              <td colspan="7">
                <div class="col-md-6">
                  <input
                    class="form-control"
                    v-model="searchText"
                    placeholder="Search Description or Ticket Reference"
                  />
                </div>
              </td>
            </tr>
            <tr class="text-nowrap">
              <th>Type</th>
              <th>Description</th>
              <th>Entry Detail</th>
              <th>Ticket Ref</th>
              <th v-if="isStaff">Link to Sale</th>
              <th>Cost (Ex VAT)</th>
              <th v-if="editMode">Actions</th>
            </tr>
          </thead>
          <tbody v-if="filteredLines.length > 0">
            <tr
              :class="line.isNew ? 'new-table-row' : ''"
              v-for="(line, index) in filteredLines"
              :key="index"
            >
              <td>{{ evaluateTypeForDescription(line) }}</td>
              <td>{{ line.description }}</td>
              <td class="text-nowrap">{{ line.detail }}</td>
              <td>
                {{ line.ticketReference }}
              </td>
              <td v-if="isStaff">
                <button
                  class="btn btn-link"
                  @click="viewSale(line.saleNumber)"
                  v-if="line.saleNumber"
                >
                  Sale #{{ line.saleNumber }}
                </button>
              </td>
              <td class="text-right">
                {{ currencyFormat(line.exVatAmount) }}
              </td>
              <td class="text-center" v-if="editMode">
                <div style="display:inline-flex">
                  <button type="button" class="btn btn-success" @click="$emit('editEntry', line)">
                    <i class="fa fa-pencil"></i>
                  </button>
                  <button type="button" class="btn btn-primary" @click="$emit('deleteEntry', line)">
                    <i class="fa fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">No new workorder lines</td>
            </tr>
          </tbody>
          <tfoot v-if="filteredLines.length > 0">
            <tr v-if="totalAmount.deposit">
              <td colspan="6">
                Deposit required of
                {{ currencyFormat(totalAmount.deposit) }} before completion
              </td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td v-if="workorder && totalTime > 0" class="text-uppercase hourFormat">{{hourFormat}}</td>
              <td v-if="workorder" colspan="1"></td>
              <td v-else colspan="2"></td>
              <td v-if="editMode" colspan="1"></td>
              <td class="text-uppercase">Subtotal</td>
              <td>{{ currencyFormat(totalAmount.subTotal) }}</td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td v-if="editMode" colspan="1"></td>
              <td class="text-uppercase">Vat ( {{ totalAmount.vat }}% )</td>
              <td>{{ currencyFormat(totalAmount.vatAmount) }}</td>
            </tr>
            <tr>
              <td colspan="4"></td>
              <td v-if="editMode" colspan="1"></td>
              <td class="text-uppercase">Total</td>
              <td>{{ currencyFormat(totalAmount.total) }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
</template>

<script>
    export default {
        props: {
            workOrderLines: {
                type: Array,
                required: true
            },
            editMode: {
                type: Boolean,
                required: true
            },
            totalAmount: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                searchText: "",
            }
        },
        computed: {
            isStaff() {
                return this.userContainsPermission(["STAFF"]);
            },
            filteredLines() {
                if (this.searchText == "") {
                    return this.workOrderLines;
                } else {
                    return this.workOrderLines.filter(
                    (l) =>
                        l.description
                        .toLowerCase()
                        .includes(this.searchText.toLowerCase()) ||
                        l.ticketReference
                        .toLowerCase()
                        .includes(this.searchText.toLowerCase())
                    );
                }
            },
        },
        methods: {
            evaluateTypeForDescription(line) {
                if (line.class.includes("Equipment")) {
                    return "Hardware";
                } else if (line.class.includes("Software")) {
                    return "Software"
                } else if (line.class.includes("Travel")) {
                    return "Travel";
                } else if (line.class.includes("Service")) {
                    return "Outsourced Service";
                } else if (line.class.includes("Labour") && !line.class.includes("Bulk")) {
                    return "Labour";
                } else if (line.class.includes("Bulk")) {
                    return "Bulk Labour";
                }
            },
        }
    }
</script>

<style scoped>
.text {
  font-size: 16px !important;
}

.table-wrapper {
  max-height: 550px;
  overflow: auto;
  display: inline-block;
}

.popper {
  width: 880px;
  left: 45% !important;
}

.popper tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.modal-dialog {
  max-width: 600px !important;
}

table tfoot {
  border: none;
}

table tfoot td {
  background: 0 0;
  border: none;
  white-space: nowrap;
  text-align: right;
  padding: 10px 20px;
  border-top: 1px solid #aaa;
}

table tfoot tr:first-child td {
  border-top: none;
}

table tfoot tr td:first-child {
  border: none;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.hourFormat {
  float:left;
  padding-left: 0.75em;
}

.pull-right {
  float: right;
}
</style>
