<template>
  <div
    @keyup.esc="onEscape"
    @keydown.up.prevent="typeAheadUp"
    @keydown.down.prevent="typeAheadDown"
    @keydown.enter.prevent="typeAheadSelect"
    :deselect="clearSelection"
    class="v-select"
    :class="{ disabled: disabled }"
  >
    <button @click="toggle" type="button" class="v-select-toggle" :name="name">
      <div>{{ title }}</div>
      <button
        v-if="selectedValue && clearable"
        type="button"
        class="clear"
        @click="clearSelection()"
      >
        <component :is="childComponent.Deselect" />
      </button>
      <div class="arrow-down"></div>
    </button>
    <div v-show="show" class="v-dropdown-container">
      <div v-show="searchable" class="v-bs-searchbox">
        <input
          :placeholder="labelSearchPlaceholder"
          class="form-control"
          type="text"
          v-model="searchValue"
          autofocus
        />
      </div>
      <ul style="max-height: 150px; overflow: scroll;">
        <li
          v-show="searchable && filteredOptions.length === 0"
          class="v-dropdown-item"
        >
          {{ labelNotFound }} "{{ searchValue }}"
        </li>
        <li v-if="showDefaultOption" class="v-dropdown-item default-option">
          {{ placeholder }}
        </li>
        <li
          :id="getOptionLabel(option)"
          v-for="(option, index) in filteredOptions"
          :key="`v-select-${index}`"
          class="v-dropdown-item"
          :class="{
            selected: isSelectedOption(option, index) && selectedValue,
            disabled: option[valueProp] === disabledProp,
            'd-none': hide(option),
          }"
          @click="onSelect(option, index)"
        >
          {{ getOptionLabel(option) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import childComponent from "./childComponent";
export default {
  name: "VSelect",
  components: { ...childComponent },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledProp: {
      type: String,
      default: "disabled",
    },
    hideProp: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Nothing selected",
    },
    labelNotFound: {
      type: String,
      default: "No results matched",
    },
    labelSearchPlaceholder: {
      type: String,
      default: "Search",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    showDefaultOption: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, String, Number, Boolean],
      default: null,
    },
    valueProp: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    getOptionLabel: {
      type: Function,
      default(option) {
        if (typeof option === "object") {
          return option[this.label];
        }
        return option;
      },
    },
  },
  data() {
    return {
      show: false,
      selectedValue: null,
      searchValue: "",
      typeAheadPointer: -1,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        const index = this.options.findIndex((op) =>
          this.isEqualOption(op, newVal)
        );
        if (index >= 0 && this.options[index] !== newVal) {
          this.onSelect(this.options[index], index);
        } else if (index >= 0) {
          this.onSelect(newVal, index);
        } else if (
          newVal == 0 ||
          (typeof newVal == "string" && newVal.includes("None"))
        ) {
          this.selectedValue = null;
        } else if (newVal != null) {
          this.selectedValue = newVal;
        }
      },
    },
    options(val) {
      let i = 0;
      if (i > 0) {
        this.selectedValue = null;
      }
      i++;
    },
  },
  computed: {
    title() {
      return this.selectedValue
        ? this.getOptionLabel(this.selectedValue)
        : this.placeholder;
    },
    filteredOptions() {
      if (this.searchable && this.searchValue.length > 0) {
        return this.options.filter((item) => {
          if (typeof item === "object") {
            return (
              item[this.label]
                .toLowerCase()
                .indexOf(this.searchValue.toLowerCase()) !== -1
            );
          } else {
            return (
              item.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
            );
          }
        });
      }
      return this.options;
    },
    reversedOptions() {
      return [...this.filteredOptions].reverse();
    },
    lastOptionIndex() {
      return this.filteredOptions.length - 1;
    },
    childComponent() {
      return {
        ...childComponent,
        ...this.components,
      };
    },
  },
  methods: {
    onSelect(option, index) {
      if ((option && !option[this.disabledProp]) || option == 0) {
        if (typeof option === "object" && Object.keys(option).length == 0) {
          this.selectedValue = null;
          if (this.valueProp) {
            option[this.valueProp] = 0;
          }
        } else {
          this.selectedValue = option;
        }
        this.typeAheadPointer = index;
        this.hideDropdown();
        this.$emit("input", this.valueProp ? option[this.valueProp] : option);
      } else if (option === null) {
        this.selectedValue = null;
      }
    },
    hide(option) {
      const index = this.hideProp.findIndex((prop) =>
        typeof option === "object"
          ? option[this.valueProp] === prop
          : option === prop
      );
      if (index == -1) {
        return false;
      } else {
        return true;
      }
    },
    clearSelection() {
      let val;
      if (typeof this.selectedValue === "object") {
        val = {};
      } else {
        val = 0;
      }
      this.onSelect(val, this.typeAheadPointer);
      this.toggle();
    },
    onEscape() {
      this.hideDropdown();
    },
    typeAheadUp() {
      if (!this.show) {
        this.show = true;
      }
      if (this.typeAheadPointer > 0) {
        const nextPointer = this.typeAheadPointer - 1;
        const option = this.filteredOptions[nextPointer];
        const isDisabled = option ? option[this.disabledProp] || false : false;
        if (!isDisabled) {
          this.typeAheadPointer--;
        } else {
          this.typeAheadPointer--;
          this.typeAheadUp();
        }
      } else {
        const nextEnabledOption = this.reversedOptions.findIndex(
          (o) => o[this.disabledProp] !== true
        );
        this.typeAheadPointer = this.lastOptionIndex - nextEnabledOption;
      }
    },
    typeAheadDown() {
      if (!this.show) {
        this.show = true;
      }
      if (this.typeAheadPointer < this.lastOptionIndex) {
        const nextPointer = this.typeAheadPointer + 1;
        const option = this.filteredOptions[nextPointer];
        const isDisabled = option ? option[this.disabledProp] || false : false;
        if (!isDisabled) {
          this.typeAheadPointer++;
        } else {
          this.typeAheadPointer++;
          this.typeAheadDown();
        }
      } else {
        const nextEnabledOption = this.filteredOptions.findIndex(
          (o) => o[this.disabledProp] !== true
        );
        this.typeAheadPointer = nextEnabledOption;
      }
    },
    typeAheadSelect() {
      if (this.filteredOptions[this.typeAheadPointer]) {
        this.onSelect(
          this.filteredOptions[this.typeAheadPointer],
          this.typeAheadPointer
        );
      }
    },
    hideDropdown() {
      this.show = false;
      this.searchValue = "";
    },
    isSelectedOption(option, index) {
      if (this.typeAheadPointer === -1 && this.selectedValue) {
        return this.isEqualOption(option, this.selectedValue);
      }
      return this.typeAheadPointer === index;
    },
    isEqualOption(a, b) {
      if (a && b && typeof a === "object" && typeof b === "object") {
        return (
          a[this.label] === b[this.label] &&
          a[this.valueProp] === b[this.valueProp]
        );
      } else if (a && b && typeof a === "object") {
        return a[this.valueProp] === b;
      }
      return a === b;
    },
    toggle() {
      if (!this.disabled) {
        this.show = !this.show;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
input {
  width: 100%;
}
ul {
  font-size: 0.875rem;
  color: #424242;
  max-height: 330px;
  overflow-y: auto;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  padding: 0px;
  margin: 0px;
}
.v-select {
  position: relative;
  width: 100%;
  height: 35px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    .v-select-toggle {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      opacity: 0.65;
      cursor: not-allowed;
      &:focus {
        outline: 0 !important;
      }
    }
  }
}
.v-select-toggle {
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding: 0.375rem 0.75rem;
  color: #3a3f51;
  background-color: #fff;
  border-color: #eaeaea;
  width: 100%;
  height: 100%;
  text-align: right;
  white-space: nowrap;
  border: 1px solid #eaeaea;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-family: inherit, sans-serif;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: background-color, border-color, box-shadow, 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &:focus {
    background-color: #f5f5f5;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
}
.arrow-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  margin-top: 7px;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.v-dropdown-container {
  position: absolute;
  width: 100%;
  background: red;
  padding: 0.25rem 0;
  margin: 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  z-index: 1000;
}
.v-dropdown-item {
  text-decoration: none;
  padding: 0.125rem 0.5rem;
  user-select: none;
  &:hover:not(.default-option) {
    background-color: #f8f9fa;
  }
  &.disabled {
    color: #9a9b9b;
  }
  &.selected {
    background-color: #007bff;
    color: #fff;
    &:hover {
      background-color: #007bff;
      color: #fff;
    }
  }
  &.disabled {
    cursor: not-allowed;
    &:hover {
      background-color: #fff;
    }
  }
}
.clear {
  position: absolute;
  top: 11px;
  right: 30px;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: #495057 !important;
}
.v-bs-searchbox {
  padding: 4px 8px;
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}
</style>