<template>
  <div>
    <div class="row row-spacing my-3">
      <div class="col-md-6">
        <button v-if="userEditRight" @click="addNewPackagePrice()" type="button" class="btn btn-info">Add New Price Package</button>
      </div>
      <div class="col-md-6 text-right">
          <button class="btn bg-green" @click="showFilters">
            <i class="fa-solid fa-chevron-left" :class="!clicked ? 'fa-chevron-left' : 'fa-chevron-right'"></i> 
            Filter
          </button>
          <div class="filter-container" v-show="clicked">
            <div class="filter-wrapper">
              <div class="filter-body">
                <div class="row">
                    <div class="col-md-12">
                      <label class="form-check-label" for="exampleRadios1">
                        Show expired packages
                      </label>
                      <input class="form-check-input ml-2 mt-1" type="checkbox" name="exampleRadios" id="exampleRadios1" value="option1" @change="toggleShowExpiredPackages" :checked="isChecked">
                    </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                        <label><strong>Search Packages</strong></label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search by Description..." v-model="searchTerm">
                            <div class="input-group-append">
                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>      
    </div>
    <div class="table-responsive table-bordered" v-if="packages.length > 0">
      <table class="table">
        <thead>
          <tr class="text-nowrap">
            <th class="table-font-size table-header-width-6">Description</th>
            <th class="table-font-size table-header-width-3">Size</th>
            <th class="table-font-size table-header-width-2">Setup Fee</th>
            <th class="table-font-size table-header-width-4">Current Price (excl. VAT)</th>
            <th class="table-font-size table-header-width-4">Price (incl. VAT)</th>
            <th class="table-font-size table-header-width-3">Expired</th>
            <th class="table-font-size table-header-width-2">Services</th>
            <th class="table-font-size table-header-width-1">#</th>
            <th class="table-font-size table-header-width-5">Commands</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            :class="pricePackage.expired ? 'bg-primary' : ''" 
            v-for="(pricePackage, rowIndex) in packages"
            :key="rowIndex"
          >
            <td>
                <strong>
                  <span class="font-size">{{ pricePackage.description }}</span>
                </strong>
            </td>
            <td><span class="font-size">{{ pricePackage.size > 0 ? pricePackage.size : 'Uncapped' }} {{ pricePackage.size > 0 ? 'GB' : ''}}</span></td>
            <td>
              <span class="font-size">
                {{
                  getSetupFee(pricePackage.ltebundleprices, period)
                }}
              </span>
            </td>
            <td>
              <span class="font-size">
              {{getPrice(pricePackage.ltebundleprices, period)}}
              </span>
              <span v-if="userEditRight" style="cursor: pointer" v-tooltip="'Edit Row Price'" @click="openPriceModal(pricePackage)"
                class="pull-right">
                <em class="fa fa-edit fa-fw text-green"></em>
              </span>
            </td>
            <td>
              <span class="font-size">{{getPrice(pricePackage.ltebundleprices, period, true)}}</span>                
            </td>
            <td class="text-center">
              <span
                class="badge"
                :class="pricePackage.expired ? 'badge-warning' : 'badge-green'"
                >{{ pricePackage.expired }}
              </span>
            </td>
            <td class="text-nowrap text-center">
                <strong>
                  <span class="font-size">{{ servicesAmount(pricePackage) }}</span>
                </strong>
            </td>
            <td class="text-nowrap">
              <span>{{pricePackage.lteBundleNumber}}</span>
            </td>
            <td class="text-center">
              <button
                v-if="userEditRight"
                v-tooltip="'Edit Package'"
                @click="editPackagePrice(pricePackage)"
                type="button"
                class="btn btn-sm btn-info m-1"
              >
                <em class="fa fa-edit fa-fw"></em>
              </button>
              <button
                v-if="userEditRight"
                v-tooltip="'Delete Package'"
                @click="deletePackagePrice(pricePackage)"
                type="button"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="alert alert-info col-sm-12" v-else>
      <strong>No price packages to display</strong>
    </div>
    <AddPriceModal 
      ref="addPriceModal" 
      :row-price="rowPrice" 
      :new-price="newPrice" 
      @addPrice="addPrice"
      @pricesAdded="getLtePackages()" 
    />
    <LtePriceModal 
      ref="modifyPackage" 
      :pricePackage="newPricePackage" 
      :availableLteProducts="availableProducts"
      :supplier="productSupplier" 
      @save-price="saveNewPricePackage" 
      @update-price="updatePricePackage" 
    />
  </div>
</template>

<script>
  import AddPriceModal from '@/components/Admin/Modals/AddPriceModal'
  import LtePriceModal from '@/components/Admin/Modals/LtePriceModal'
  import PriceFunctions from '@/components/Admin/PriceFunctions'

  export default {
    extends: PriceFunctions,
    props: {
      productNumber: {
        type: Number,
        required: true
      },
      productSupplier: {
        type: String,
        required: true
      },
      userEditRight: {
        type: Boolean,
        required: true
      },
    },
    components: {
      AddPriceModal,
      LtePriceModal
    },
    data() {
      return {
        period: this.getCurrentPeriod(),
        newPrice: {},
        newPricePackage: {},
        mwebpackages: [],
        rowPrice: [],
        availableProducts: [],
        isChecked: false,
        clicked: false,
        searchTerm: '',
        getPackageSaleAmounts: true,
      }
    },
    mounted() {
      this.getLtePackages();
    },
    watch: {
      searchTerm(newVal, oldVal) {      
        if ((this.isChecked || !this.isChecked) && (oldVal !== newVal)) {
          this.getPackageSaleAmounts = false;
        } 
      },
      isChecked(newVal, _) {
        if ((newVal || !newVal) && !this.getPackageSaleAmounts) {
          this.getPackageSaleAmounts = true;
        }
      }
    },
    computed: {
      packages() {  
        if (this.isChecked) {        
          this.filteredPackages = this.mwebpackages;
        } else {
          this.filteredPackages = this.mwebpackages.filter(p => p.expired === false); 
        }   
        this.searchPackages(this.searchTerm); 
        this.getServiceAmounts(this.filteredPackages);        
        return this.filteredPackages;
      },
    },
    methods: {
      getLtePackages() {
        this.$http.get(this.$config.aimsAPI + 'pricing/ltebundles/' + this.productNumber)
          .then(response => {
              this.mwebpackages = response.data;
              this.$http.get(this.$config.aimsAPI + 'mweb/mtn/availableproducts')
                .then(response => {
                    this.availableProducts = response.data;
                  },
                  error => {
                    this.showError('Error fetching Available LTE Products', error)
                    console.error(error);
                  });

            },
            error => {
              this.showError('Error fetching LTE Bundles', error)
              console.error(error);
            });
      },
      addNewPackagePrice() {
        this.newPricePackage = this.deepCopyObject({
          productNumber: this.productNumber,
          class: 'za.co.adept.aims.lte.LteBundle'
        });
        this.$refs.modifyPackage.open();
      },
      editPackagePrice(pricePackage) {
        this.newPricePackage = this.deepCopyObject(pricePackage);
        this.$refs.modifyPackage.open();
      },
      openPriceModal(pPackage) {
        this.rowPrice = this.deepCopyObject(pPackage.ltebundleprices);
        this.newPrice = this.deepCopyObject({
          lteBundleNumber: pPackage.lteBundleNumber,
          class: pPackage.class + 'Price',
          validFrom: 0,
          validUntil: 0
        });
        this.newPrice.setupfee = 0.00,
        this.$refs.addPriceModal.open();
      },
      addPrice(newPrice) {
        this.rowPrice.forEach(price => {
          if (price.validUntil == 0) {
            if (newPrice.validFrom % 100 > 1) {
              price.validUntil = newPrice.validFrom - 1;
            } else {
              price.validUntil = (newPrice.validFrom - 100) + 11;
            }
          }
        });
        this.rowPrice.push(newPrice);
        this.newPrice = this.deepCopyObject({
          lteBundleNumber: newPrice.lteBundleNumber,
          class: newPrice.class,
          validFrom: 0,
          validUntil: 0
        });
      },
      updatePricePackage(pricePackage) {
        this.saveData(pricePackage).then(response => {
            this.showSuccess('Updated Row Successfully');
            this.getLtePackages();
          },
          error => {
            this.showError("Error Saving LTE Price Package", error);
            console.error(error);
          })
      },
      saveNewPricePackage(newPackage) {
        this.$http.post(this.$config.aimsAPI + 'pricing/pricepackages/save', newPackage)
          .then(response => {
            this.showSuccess('Updated Row Successfully');
            response.data.ltebundleprices = [];
            this.openPriceModal(response.data);
          }, error => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          })
      },
      deletePackagePrice(pricePackage) {
        this.$swal({
          title: 'Are you sure you want to delete LTE price package?',
          type: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
        }).then((result) => {
          if (result.value) {
            this.$http.delete(this.$config.aimsAPI + 'pricing/pricepackages/' + pricePackage.objKey +
                '?objClassName=' + pricePackage.class)
              .then(response => {
                this.showSuccess('Successfully deleted LTE price package');
                this.getLteBundles();
              }, error => {
                this.showError("Error Deleting LTE Pricing Package", error);
              })
          }
        });
      },
      getServiceAmounts: function(data) {     
        const hostUrl = this.$config.aimsAPI;  
        if (!this.searchTerm || this.getPackageSaleAmounts) {
          this.$http.post(`${hostUrl}pricing/linkedservices/amount`, data).then(
            (response) => {
              this.packageSalesData = response.data;      
            },
            (error) => {
              this.showError("Error fetching package sales data", error);
              console.error(error);
            }
          );        
        }
      },
      servicesAmount: function(packageData) {
        let saleAmount;        
        this.packageSalesData.filter(data => {
          if (data.lteBundleNumber === packageData.lteBundleNumber) {
            saleAmount = data.sales;
          }
        });   
        return saleAmount;
      },
      toggleShowExpiredPackages: function() {
        this.isChecked = !this.isChecked;
      },
      showFilters: function() {
        this.clicked = !this.clicked;
      },
      searchPackages: function(searchTerm) {
        if (searchTerm) {
            this.filteredPackages = this.filteredPackages.filter(p => p.description.toLowerCase().includes(searchTerm.toLowerCase()));
        }
      }
    },
  }

</script>
<style scoped>
.filter-container {
  position: relative;
  z-index: 999;
}

.filter-container .filter-wrapper {
  position: absolute;
  z-index: 999;
  margin-top: .5em;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 2px;
  width: 350px;
  right: 90;
  top: -42;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.filter-container .filter-wrapper .filter-body {
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  padding: 10px;
  text-align: left;
}

th.table-font-size {
  font-size: .95rem;
}
th.table-header-width-1 {
  width: 10px;
}
th.table-header-width-2 {
  width: 20px;
}
th.table-header-width-3 {
  width: 50px;
}
th.table-header-width-4 {
  width: 60px;
}
th.table-header-width-5 {
  width: 95px;
}
th.table-header-width-6 {
  width: 200px;
}

.font-size {
  font-size: .9rem;
}
</style>