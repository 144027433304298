<template>
  <div>
    <div class="card card-default" v-if="discountAllowed">
      <div class="card-header">
        <h4>Discount</h4>
      </div>
      <div class="modal-body">
        <div
          class="table-responsive table-bordered"
          v-if="discounts.length > 0 && discount"
        >
          <table class="table" :class="canEdit(discount) ? 'table-hover' : ''">
            <thead>
              <tr>
                <th>Discount</th>
                <th>Value</th>
                <th>Valid From</th>
                <th>Valid Until</th>
              </tr>
            </thead>
            <tbody v-if="canEdit(discount)">
              <tr  @click="addNewDiscount">
                <td>
                  <span class="btn-link">{{ discount.reason }}</span>
                </td>
                <td>
                  <div class="input-group" v-if="!percentage(discount)">
                    <span>R {{discount.value}}</span>
                  </div>
                  <div class="input-group" v-if="percentage(discount)">
                      <span>{{discount.value}} %</span>
                  </div>
                </td>
                <td>{{ discount.validFromPeriod }}</td>
                <td>{{ discount.validUntilPeriod }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>
                  {{ discount.reason }}
                </td>
                <td >
                  <div class="input-group" v-if="!percentage(discount)">
                    <span>R {{discount.value}}</span>
                  </div>
                  <div class="input-group" v-if="percentage(discount)">
                      <span>{{discount.value}} %</span>
                  </div>
                </td>
                <td>{{ discount.validFromPeriod }}</td>
                <td>{{ discount.validUntilPeriod }}</td>
              </tr>
              <tr>
                <td colspan="5" class="card-footer">
                 <button
                  type="button"
                  @click="addNewDiscount"
                  class="btn btn-info pull-right"
                 >
                  Edit
                 </button>
               </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="table" v-else>
          <tbody>
            <tr>
              <td colspan="5" class="card-footer">
                <button
                  type="button"
                  @click="addNewDiscount"
                  class="btn btn-info pull-right"
                >
                  Add
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="text-warning" v-else>
      <h4>Discount Not Supported on Product</h4>
    </div>
    
    <modal
      title="Edit Discount"
      size="modal-xl"
      ref="editDiscountModal"
      save-text="Apply"
      @save="applyChanges()"
      @dismiss="discountCancel()"
    >
      <table>
        <tr>
          <div class="row">
            <div class="form-group col-md-1">Reason</div>
            <div class="form-group col-md-1">Ticket Ref</div>
            <div class="form-group col-md-2">Description</div>
            <div class="form-group col-md-2">Valid From</div>
            <div class="form-group col-md-2">Valid Until</div>
            <div class="form-group col-md-1 pl-0 pr-0">Type</div>
            <div class="form-group col-md-2">Value</div>
            <div class="form-group col-md-1"></div>
          </div>
        </tr>

        <tr v-for="discount in discounts" :key="discount.objKey">
          <div class="row">
            <div class="form-group col-md-1 pl-1 pr-0">
              <select
                class="custom-select"
                v-model="discount.reason"
                :disabled="!canEdit(discount)"
              >
                <option v-for="reason in reasons" :key="reason" :value="reason">
                  {{ reason }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-1">
              <input
                :title="discount.ticketNumber"
                type="text"
                class="form-control"
                :disabled="!canEdit(discount)"
                v-model="discount.ticketNumber"
              />
            </div>
            <div class="form-group col-md-2">
              <input
                :title="discount.description"
                type="text"
                class="form-control"
                :disabled="!canEdit(discount)"
                v-model="discount.description"
              />
            </div>
            <div class="form-group col-md-2">
              <select
                :disabled="!canEdit(discount)"
                class="custom-select"
                placeholder="Select Period"
                v-model="discount.validFromPeriod"
              >
                <option v-if="discount.validFromPeriod">
                  {{ discount.validFromPeriod }}
                </option>
                <option v-for="fperiod in periods" :key="fperiod">
                  {{ fperiod }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-2">
              <button
                v-allowed:view="['STAFF', 'RESELLER']"
                type="button"
                @click="setDiscountValidUntil(discount)"
                class="btn btn-link"
                v-if="discount.validUntilPeriod == 0"
              >
                Choose a 'Valid Until Period'
              </button>
              <select
                :disabled="!canEdit(discount)"
                class="custom-select"
                placeholder="Select Period"
                v-model="discount.validUntilPeriod"
                v-else
              >
                <option value="0">Clear Period</option>
                <option v-if="discount.validUntilPeriod">
                  {{ discount.validUntilPeriod }}
                </option>
                <option v-for="tperiod in periods" :key="tperiod">
                  {{ tperiod }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-1 pl-0 pr-0">
              <select class="custom-select" v-model="discount.type" :disabled="!canEdit(discount)">
                <option v-for="dType in discountTypes" :value="dType.description" :key="dType.name"> {{ dType.description }} </option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <div class="input-group">
                <div class="input-group-prepend" v-if="!percentage(discount)">
                  <span class="input-group-text">R</span>
                </div>
                <input
                  type="text"
                  v-model="discount.value"
                  :disabled="!canEdit(discount)"
                  class="form-control"
                />
                <div v-if="percentage(discount)" class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-1">
              <button
                v-tooltip="'Delete Discount'"
                type="button"
                v-if="canEdit(discount) && !discount.new"
                @click="discountDelete(discount)"
                class="btn btn-sm btn-primary"
              >
                <em class="fa fa-trash fa-fw"></em>
              </button>
            </div>
          </div>
        </tr>

        <tr>
          <div class="row">
            <div class="form-group col-md-1 pl-1 pr-0">
              <select
                class="custom-select"
                v-model="selectedDiscount.reason"
                :disabled="!canEdit(selectedDiscount)"
              >
                <option v-for="reason in reasons" :key="reason" :value="reason">
                  {{ reason }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-1">
              <input
                :title="selectedDiscount.ticketNumber"
                type="text"
                class="form-control"
                :disabled="!canEdit(selectedDiscount)"
                v-model="selectedDiscount.ticketNumber"
              />
            </div>
            <div class="form-group col-md-2">
              <input
                type="text"
                class="form-control"
                v-model="selectedDiscount.description"
              />
            </div>
            <div class="form-group col-md-2">
              <select
                class="custom-select"
                placeholder="Select Period"
                v-model="selectedDiscount.validFromPeriod"
              >
                <option v-if="selectedDiscount.validFromPeriod">
                  {{ selectedDiscount.validFromPeriod }}
                </option>
                <option v-for="period in periods" :key="period">
                  {{ period }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <button
                v-allowed:view="['STAFF', 'RESELLER']"
                type="button"
                @click="setDiscountValidUntil(selectedDiscount)"
                class="btn btn-link"
                v-if="selectedDiscount.validUntilPeriod == 0"
              >
                Choose a 'Valid Until Period'
              </button>
              <select
                class="custom-select"
                placeholder="Select Period"
                v-model="selectedDiscount.validUntilPeriod"
                v-else
              >
                {{
                  selectedDiscount
                }}
                <option value="0">Clear Period</option>
                <option v-if="selectedDiscount.validFromPeriod">
                  {{ selectedDiscount.validFromPeriod }}
                </option>
                <option v-for="period in periods" :key="period">
                  {{ period }}
                </option>
                <option v-if="periods[periods.length - 1] < getCurrentPeriod()">
                  {{ getCurrentPeriod() }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-1 pl-0 pr-0">
              <select class="custom-select" v-model="selectedDiscount.type" :disabled="!canEdit(selectedDiscount)">
                <option v-for="dtype in discountTypes" :key="dtype.name" :value="dtype.description"> {{ dtype.description }} </option>
              </select>
            </div>
            <div class="form-group col-md-2">
              <div class="input-group">
                <div
                  class="input-group-prepend"
                  v-if="selectedDiscount.type == 'AMOUNT'"
                >
                  <span class="input-group-text">R</span>
                </div>
                <input
                  type="text"
                  v-model="selectedDiscount.value"
                  class="form-control"
                />
                <div
                  v-if="percentage(selectedDiscount)"
                  class="input-group-append"
                >
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="form-group col-md-1">
              <button
                @click="addDiscount()"
                :disabled="notValid(selectedDiscount)"
                v-tooltip="'Add Discount'"
                type="button"
                class="btn btn-sm btn-info"
              >
                <em class="fa fa-plus fa-fw"></em>
              </button>
            </div>
          </div>
        </tr>
      </table>
    </modal>
    
  </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
export default {
  components: {
    Modal,
  },
  props: {
    discountAllowed: {
      type: Boolean,
      required: true,
    },
    discounts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    discount() {
      let currentDiscount;
      if (this.discounts.length >= 1) {
        currentDiscount = this.discounts[this.discounts.length-1];
      }
      return currentDiscount;
    },
  },
  data() {
    return {
      selectedDiscount: {},
      emptyStructure: {
        class: "za.co.adept.aims.prices.Discount",
        validFromPeriod: 0,
        validUntilPeriod: 0,
        description: "",
        ticketNumber: "",
        staffUserNumber: 0,
        reason: "",
        scope: "Recurring",
        userReadOnly: false,
      },
      reasons: [],
      discountTypes: [
        {
          description: "Amount",
          name: "AMOUNT",
        },
        {
          description: "Percentage",
          name: "PERCENTAGE",
        },
      ],
      periods: [],
    };
  },
  mounted() {
    this.$http.get(this.$config.aimsAPI + "system/discountreasons").then(
      (response) => {
        this.reasons = response.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error fetching Discount Reasons", error);
      }
    );
  },
  methods: {
    canEdit(discount) {
      let currentP = this.getCurrentPeriod();
      return (
        discount.validUntilPeriod == 0 
        || discount.validUntilPeriod >= currentP 
      );
    },
    notValid(selected) {
      return (
        (selected.validUntilPeriod != 0 &&
          selected.validUntilPeriod < this.getCurrentPeriod()) ||
        (selected.validUntilPeriod != 0 &&
          selected.validUntilPeriod < selected.validFromPeriod) ||
        selected.description == "" ||
        selected.type == "__NONE__" ||
        selected.value == null ||
        selected.reason == ""
      );
    },
    addNewDiscount() {
      this.selectedDiscount = this.deepCopyObject(this.emptyStructure);
      if (this.discount === undefined) {
        this.selectedDiscount.validFromPeriod = this.getCurrentPeriod();
      }
      this.filterValidPeriods();
      this.$refs.editDiscountModal.open();
    },
    setDiscountValidUntil(discount) {
      let currentP = this.getCurrentPeriod();
      if (discount.validFromPeriod) {
        if (discount.validFromPeriod < currentP) {
          discount.validUntilPeriod = currentP
        } else {
          discount.validUntilPeriod = discount.validFromPeriod;
        }
      } else {
        discount.validUntilPeriod = this.periods[0];
      }
    },
    addDiscount() {
      this.selectedDiscount.userReadOnly = false;
      const allDiscounts = this.discounts;
      if (this.selectedDiscount.discountNumber == 0) {
        if (this.selectedDiscount.validUntilPeriod == null) {
          this.selectedDiscount.validUntilPeriod = 0;
        }
        allDiscounts.push(this.selectedDiscount);
      }

      const prevIndx = allDiscounts.findIndex(
        (d) => d.validUntilPeriod === 0 && d.discountNumber != 0
      );
      if (prevIndx != -1) {
        const modifiedDiscount = allDiscounts[prevIndx];
        modifiedDiscount.validUntilPeriod = this.decreaseBillingPeriod(
          this.selectedDiscount.validFromPeriod
        );
        allDiscounts.splice(prevIndx, 1);
        allDiscounts.push(modifiedDiscount);
      }

      this.selectedDiscount.new = true;
      allDiscounts.push(this.selectedDiscount);
      var errMessage = this.validateOverlaps(allDiscounts);
      if (errMessage.length == 0) {
        this.selectedDiscount = this.deepCopyObject(this.emptyStructure);
        this.filterValidPeriods();
        this.discounts = allDiscounts;
      } else {
        this.$swal({
          title: "Discount not saved",
          text: errMessage,
          type: "warning",
          confirmButtonText: "OK",
        });
        this.$emit("discountCancel");
      }
    },
    discountDelete(discount) {
      this.$swal({
        title: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$emit("discountDelete", discount);
        }
      });
    },
    discountCancel() {
      this.$emit("discountCancel");
    },
    applyChanges() {
      this.$emit("discountSave", this.discounts);
      this.$refs.editDiscountModal.close();
    },
    getPeriods(period) {
      let subURL = "system/billingperiods?max=12";
      if (period && (period >= this.getCurrentPeriod())) {
        subURL += "&period=" + period;
      }
      this.$http.get(this.$config.aimsAPI + subURL).then(
        (response) => {
          this.periods = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching billing periods", error);
        }
      );
    },
    filterValidPeriods() {
      if (this.discounts.length > 0) {
        this.getPeriods(
          this.discounts[this.discounts.length - 1].validFromPeriod
        );
      } else {
        this.getPeriods();
      }
    },
    validateOverlaps(discs) {
      var validDates = "";
      var openEndDate = false;
      var maxPeriod = 0;
      discs.forEach((d) => {
        if (d.validUntilPeriod != 0) {
          maxPeriod =
            d.validUntilPeriod > maxPeriod ? d.validUntilPeriod : maxPeriod;
          if (d.validUntilPeriod < d.validFromPeriod) {
            validDates = "TO date must be after FROM date.";
          }
          if (openEndDate) {
            validDates =
              "TO date range is open, can not add any dates after that";
          }
        } else if (d.validUntilPeriod == 0) {
          if (openEndDate) {
            validDates = "TO date range is open, can not have two open ranges";
          }
          openEndDate = true;
        }
      });
      
      if (!validDates.length > 0) {
        var selectedIndx = -1;
        if (openEndDate) {
          selectedIndx = discs.findIndex((c) => c.validUntilPeriod === 0);
        } else {
          selectedIndx = discs.findIndex(
            (c) => c.validUntilPeriod === maxPeriod
          );
        }
        var maxFromPeriod = discs[selectedIndx].validFromPeriod;
        discs.forEach((j) => {
          if (j.validFromPeriod > maxFromPeriod) {
            validDates =
              "TO date range is already set, can not set dates before this";
          }
        });
      }

      if (validDates.length === 0) {
        discs.forEach((base) => {
          const { validFromPeriod: StartA, validUntilPeriod: EndA } = base;

          discs.forEach((disc) => {
            const { validFromPeriod: StartB, validUntilPeriod: EndB } = disc;

            if (
              !(StartA === StartB && EndA === EndB) &&
              !(EndA === 0 || EndB === 0) &&
              StartA <= EndB && EndA >= StartB
            ) {
              validDates = "There is a date range overlap.";
            }
          });
        });
      }

      return validDates;
    },
    percentage(discount) {
      return discount.type == "Percentage";
    },
  },
  created() {
    this.getPeriods();
  },
};
</script>
