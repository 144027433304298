<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <service-wizard-layout
          title=""
          subtitle=""
          :parts="parts"
          :onComplete="onComplete"
          :validateStep="validateStep"
        >
         <template v-slot:openserve>
            <form novalidate data-vv-scope="openserve">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" :class="{'has-error': errors.has('openserve.Package')}">
                    <label>Choose Package</label>
                    <v-select 
                      name="Package" 
                      :options="filledInSaleDefinition.selectableOptions.connectivityPackage" 
                      @input="getPackage"
                      valueProp="connectivityPackageNumber" 
                      v-validate="'required'" 
                      class="mb-2" 
                      label="description" 
                      v-model="packageSelected.connectivityPackageNumber" 
                      placeholder="Select a Package">
                    </v-select>
                    <span class="has-error" v-if="errors.has('openserve.Package')">
                      {{errors.first('openserve.Package')}}</span>
                  </div>
                </div>
              </div>
            </form>
          </template>
          <template v-slot:mplsL2tpLink>
            <form novalidate data-vv-scope="mplsL2tpLink">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" :class="{'has-error': errors.has('mplsL2tpLink.vpn'),}">
                    <label class="control-label">MPLS VPN:</label>
                    <v-select
                      name="vpn"
                      placeholder="Select Vpn"
                      v-validate="'required'"
                      v-model="vpnSelected"
                      :options="vpnOptions"
                      @input="vpnChanged"
                    >
                    </v-select>
                    <span class="has-error" v-if="errors.has('mplsL2tpLink.vpn')">{{errors.first("mplsL2tpLink.vpn")}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('mplsL2tpLink.family'),}">
                    <label class="control-label">Network Family:</label>
                      <v-select
                        name="family"
                        placeholder="Select Network Family"
                        :options="filledInSaleDefinition.supportingEnums.p2pIpv4Network.family"
                        label="description"
                        v-model="filledInSaleDefinition.requiredObjs.p2pIpv4Network.family"
                        valueProp="name"
                      >
                      </v-select>
                      <span class="has-error" v-if="errors.has('mplsL2tpLink.family')">{{errors.first("mplsL2tpLink.family")}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('mplsL2tpLink.address'),}">
                    <label class="control-label">IP Address:</label>
                    <input
                      name="address"
                      type="text"
                      class="form-control"
                      v-validate="'required'"
                      v-model="filledInSaleDefinition.requiredObjs.p2pIpv4Network.address"
                    />
                    <span class="has-error" v-if="errors.has('mplsL2tpLink.address')">{{errors.first("mplsL2tpLink.address")}}</span>
                  </div>
                  <div class="form-group" :class="{'has-error': errors.has('mplsL2tpLink.range')}">
                    <label class="control-label">Network Range:</label>
                      <v-select
                        name="range"
                        :options="netmasksV4"
                        placeholder="Select Network Range"
                        v-validate="'required'"
                        :get-option-label="getLabel"
                        v-model="filledInSaleDefinition.requiredObjs.p2pIpv4Network.shortMask"
                      >
                      </v-select>
                      <span class="has-error" v-if="errors.has('mplsL2tpLink.range')">{{errors.first("mplsL2tpLink.range")}}</span>
                    </div>
                </div>
              </div>
            </form>
          </template> 
          <template v-slot:lineInfo>
            <form novalidate data-vv-scope="lineInfo">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group" :class="{'has-error': errors.has('lineInfo.Circuit Number'),}">
                      <label>Circuit Number</label>
                      <input
                        placeholder="Circuit Number"
                        name="Circuit Number"
                        v-validate="'required'"
                        class="form-control"
                        v-model="filledInSaleDefinition.requiredObjs.line.circuitNumber"
                      />
                      <span class="has-error" v-if="errors.has('lineInfo.Circuit Number')">{{ errors.first("lineInfo.Circuit Number") }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row" :before-change="()=>validateStep('rica-address')">
                  <Address class="col-md-8" @addressChanged="lineAddressChanged" 
                                      @addressValid="lineAddressValidChanged" 
                                      :addressStructure="filledInSaleDefinition.requiredObjs.lineAddress" 
                                      :addressTypes="filledInSaleDefinition.supportingEnums.lineAddress.addressType"/>
                </div>
              </div>
            </form>
          </template> 
        </service-wizard-layout>
      </div>
    </div>

  </div>
</template>

<script>
import ServiceWizardLayout from "@/layouts/ServiceWizardLayout";
import Address from "@/components/GeneralComponents/Address";

export default {
  components: {
    ServiceWizardLayout,
    Address,
  },
  props: {
    emptySaleDefinition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parts: [
        {
          name: "openserve",
          displayName: "Openserve"
        },       
        {
          name: "mplsL2tpLink",
          displayName: "MPLS L2TP Link",
        },
        {
          name: "lineInfo",
          displayName: "Line Info",
        },
      ],
      vpnOptions:[],
      vpnSelected: '',
      netmasksV4: [32, 30, 29, 28, 27, 26, 25,24],

      filledInSaleDefinition: {
        selectableOptions: {
          connectivityPackage: [],
        },
        supportingEnums: {
          lineAddress: {
            addressType: [],
          },
          p2pIpv4Network: {
            family: [],
            type: []
          },
        },
        requiredObjs: {
          l2tpAccount: {},
          line: {},
          mplsL2tpLink: {},
          p2pIpv4Network: {
            family: {}
          },          
          lineAddress: {
            addressType: [],
          },
          vpn: {}
        },
      },
      packageSelected: {
        connectivityPackageNumber: null,
      },
      filterPackagesByCapSize: [],
      shapedPools: [],
    };
  },
  created() {
    this.$validator.extend(
      "verify_selected",
      (value) => {
        return {
          valid:
            value ||
            (this.packageSelected &&
              this.packageSelected.connectivityPackageNumber),
          data: {
            required: true,
          },
        };
      },
      {
        computesRequired: true,
      }
    );
  },
  mounted() {
    this.filledInSaleDefinition = this.emptySaleDefinition;    
    this.getFilteredPackageList();
    const productNumber = this.filledInSaleDefinition.product.productNumber;

    for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.vpn.length; i++) {
      this.vpnOptions.push(this.filledInSaleDefinition.selectableOptions.vpn[i].vrfName);
    }

    this.$http
      .get(this.$config.aimsAPI + "shapedpools?productNumber=" + productNumber)
      .then(
        (response) => {
          this.shapedPools = response.body;
        },
        (error) => {
          this.showError("Error fetching Shaped Pools", error);
          console.error(error);
        }
      );
  },
  methods: {
    vpnChanged() {
      for (let i = 0; i < this.filledInSaleDefinition.selectableOptions.vpn.length; i++) {
        if (this.filledInSaleDefinition.selectableOptions.vpn[i].vrfName === this.vpnSelected) {
          this.filledInSaleDefinition.requiredObjs.mplsL2tpLink.mplsVpnNumber = this.filledInSaleDefinition.selectableOptions.vpn[i].mplsVpnNumber;
        }
      }
    },
    getFilteredPackageList() {
      this.distinctCapSizes = [];
        const filteredPackageList = [];
        this.filledInSaleDefinition.selectableOptions.connectivityPackage.forEach(
          (p) => {
            filteredPackageList.push(p);
          }
        );

        const distinctSpeedList = this.distinctList(
          filteredPackageList,
          "connectivityPackageNumber"
        );

        this.filterPackagesByCapSize = this.orderList(
          distinctSpeedList,
          "downloadSpeed"
        );
    },
    getLabel(mask) {
      return "/" + mask;
    },
    getPrice(p) {
      return this.$http.get(
        this.$config.aimsAPI +
          "pricing/connectivitypackages/product/" +
          p +
          "?onlyCurrentPricing=true"
      );
    },
    async getPackage(packageSelected) {
      if (packageSelected && Object.keys(packageSelected).length > 0) {
        this.packageSelected = packageSelected;
  
      }
    },
    validateStep(stepName) {
      return this.$validator.validateAll(stepName).then((result) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      });
    },
    onComplete() {
      this.filledInSaleDefinition.requiredObjs.uncappedService.connectivityPackageNumber =
          this.packageSelected.connectivityPackageNumber;
      this.$swal({
        title: "Confirm new Service",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Create Service",
      }).then((result) => {
        if (result.value) {
          this.$emit("createSale", this.filledInSaleDefinition);
        }
      });
    },
    lineAddressChanged(address) {
      this.filledInSaleDefinition.requiredObjs.lineAddress = address;
    },
    lineAddressValidChanged(isValid) {
      this.lineAddressValid = isValid;
    },
  },
};
</script>




      